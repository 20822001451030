import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import CustomChip from "../UI/chip/chip";
const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 5,
  },
  card: {
    background:
      "linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)",
    height: 80,
  },
  accord: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  title: {
    color: "#242424",
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 10,
  },
  expandIcon: {
    color: "#3374B9",
  },
  text: {
    color: "#828282",
    fontSize: 14,
  },
  accordDetails: {
    background:
      "linear-gradient(91.69deg, rgba(71, 187, 118, 0.1) -14.88%, rgba(51, 116, 185, 0.1) 123.69%)",
  },
});

function SurveyList({ title, activityLog, currentStep }) {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Accordion square={true} elevation={0}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ padding: 0 }}
        >
          <Grid
            container
            className={classes.card}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item sm={7} style={{ paddingLeft: 15 }}>
              <Typography className={classes.title} variant="body1">
                {title}
              </Typography>
            </Grid>
            {title === 'Basic Information' &&
              <Grid item sm={5} className={classes.chip}>
                {
                  (currentStep > 1) ?
                    <CustomChip
                      label='Complete'
                      color="primary"
                      style={{ backgroundColor: "#32A05F" }}
                    />
                    :
                    <CustomChip
                      label='In progress'
                      color="primary"
                      style={{ backgroundColor: "#FF8C56" }}
                    />
                }
              </Grid>}
            {title === 'Materiality Assessment' &&
              <Grid item sm={5} className={classes.chip}>
                {
                  (currentStep > 2) ?
                    <CustomChip
                      label='Complete'
                      color="primary"
                      style={{ backgroundColor: "#32A05F" }}
                    /> :
                    <CustomChip
                      label='In progress'
                      color="primary"
                      style={{ backgroundColor: "#FF8C56" }}
                    />
                }
              </Grid>}
            {title === 'ESG Strategy' &&
              <Grid item sm={5} className={classes.chip}>
                {
                  (currentStep > 3) ?
                    <CustomChip
                      label='Complete'
                      color="primary"
                      style={{ backgroundColor: "#32A05F" }}
                    />
                    :
                    <CustomChip
                      label='In progress'
                      color="primary"
                      style={{ backgroundColor: "#FF8C56" }}
                    />
                }
              </Grid>}
            {title === 'Assessment Approval' &&
              <Grid item sm={5} className={classes.chip}>
                {
                  (currentStep > 4) ?
                    <CustomChip
                      label='Complete'
                      color="primary"
                      style={{ backgroundColor: "#32A05F" }}
                    />
                    :
                    <CustomChip
                      label='In progress'
                      color="primary"
                      style={{ backgroundColor: "#FF8C56" }}
                    />
                }
              </Grid>}
            {title === 'Field Data' &&
              <Grid item sm={5} className={classes.chip}>
                {
                  (currentStep > 5) ?
                    <CustomChip
                      label='Complete'
                      color="primary"
                      style={{ backgroundColor: "#32A05F" }}
                    />
                    :
                    <CustomChip
                      label='In progress'
                      color="primary"
                      style={{ backgroundColor: "#FF8C56" }}
                    />
                }
              </Grid>}
            {title === 'Sustainability Report' &&
              <Grid item sm={5} className={classes.chip}>
                {
                  (currentStep >= 6) ?
                    <CustomChip
                      label='Complete'
                      color="primary"
                      style={{ backgroundColor: "#32A05F" }}
                    />
                    :
                    <CustomChip
                      label='In progress'
                      color="primary"
                      style={{ backgroundColor: "#FF8C56" }}
                    />
                }
              </Grid>}
          </Grid>
        </AccordionSummary>
        <AccordionDetails >
          <Grid container>
            <Grid item xs={12}>
              {activityLog.length > 0 ?
                activityLog.map((log, i) => {
                  return (
                    <Typography style={{ marginTop: i !== 0 ? 15 : '0', fontSize: 14 }} >
                      <b style={{ fontSize: 14 }}>&#62;&#62;</b> {log.activity}
                    </Typography>
                  )
                })
                :
                <Typography style={{ fontSize: 14 }} >
                  No Activity
                </Typography>
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default React.memo(SurveyList);