import React, { useEffect, useState } from "react";
import { withStyles, styled } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import CustomTextfield from "./../../UI/textfield/textfield";
import CustomCheckBox from "./../../UI/checkBox/checkBox";
import CompanyPlaceholder from "../../../images/CompanyPlaceholder.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import "../../../styles/userProfileImage.css";
import "../../../styles/settingScroll.css";
import CustomButton from "./../../UI/button/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import countries from "../../../utils/countries/countries";
import companyDetailsFormStyles from "./companydetailsFormStyles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { viewCompanyInfo } from "actions/generalSettings/generalSettingsAction";
import { updateSupplierOrganization } from "actions/brm/BrmActions";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: "#fff",
  border: `2px solid ${theme.palette.background.paper}`,
}));

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default function CompanyDetailsForm(props) {
  const classes = companyDetailsFormStyles();

  const dispatch = useDispatch();

  const {
    login,
    generalSettings,
    // viewCompanyInfo,
    getIndustries,
    unsetAddress,
    upodateCompanyDetails,
    pushBranchAddress,
    set_snack_bar,
  } = props;

  // States
  const [companyLogo, setCompanyLogo] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [addNewAddress, setAddNewAddress] = useState(false);

  // Basic
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyOperation, setCompanyOperation] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [makeDefault, setMakeDefault] = useState(false);

  const [isin, setISIN] = useState("");

  //sector and industry

  const [sectors, setsectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // Head office
  const [headAddress, setHeadAddress] = useState("");
  const [headLandmark, setHeadLandmark] = useState("");
  const [headPincode, setHeadPincode] = useState("");
  const [headState, setHeadState] = useState("");
  const [headOCountry, setHeadOCountry] = useState("");
  const [inputValue, setInputValue] = React.useState("");

  // Branch office
  const [branchAddress, setBranchAddress] = useState("");
  const [branchLandmark, setBranchLandmark] = useState("");
  const [branchPincode, setBranchPincode] = useState("");
  const [branchState, setBranchState] = useState("");
  const [branchCountry, setBranchCountry] = useState("");
  const [inputBranch, setInputBranch] = useState("");
  const [selectedSectorData, setSelectedSectorData] = useState([]);

  const userType = localStorage.getItem("current_organisation_user_type");

  useEffect(() => {
    let url =
      userType === "SA"
        ? "/businessUnit/view_company_details"
        : "/supplier/get_supplier_details";
    let sourceName = userType === "SA" ? "organisation_id" : "supplier_id";
    let id = userType === "SA" ? login.current_organisation : login.supplier_id;
    dispatch(viewCompanyInfo(login.token, id, url, sourceName));
    getIndustries("");
  }, [login.current_organisation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      generalSettings.companyInfo.logo !== "" &&
      generalSettings.companyInfo.logo !== undefined
    ) {
      setImageURL(generalSettings.companyInfo.logo.url);
    } else {
      setImageURL(CompanyPlaceholder);
    }
    if (
      generalSettings.companyInfo.company_name !== "" &&
      generalSettings.companyInfo.company_name !== undefined
    ) {
      setCompanyName(generalSettings.companyInfo.company_name);
      setCompanyWebsite(generalSettings.companyInfo.website);
      setMakeDefault(generalSettings.companyInfo.default);
    }

    if (
      generalSettings?.companyInfo?.name !== "" &&
      generalSettings?.companyInfo?.name !== undefined
    ) {
      setCompanyName(generalSettings.companyInfo.name);
      setCompanyWebsite(generalSettings.companyInfo.website);
      setMakeDefault(generalSettings.companyInfo.default);
    }

    if (
      generalSettings.companyInfo.email !== "" &&
      generalSettings.companyInfo.email !== undefined
    ) {
      setCompanyEmail(generalSettings.companyInfo.email);
    }

    if (
      generalSettings.companyInfo.company_operation !== "" &&
      generalSettings.companyInfo.company_operation !== undefined
    ) {
      setCompanyOperation(generalSettings.companyInfo.company_operation);
    }
    if (
      generalSettings.companyInfo.employee_count !== "" &&
      generalSettings.companyInfo.employee_count !== undefined
    ) {
      setEmployeeCount(generalSettings.companyInfo.employee_count);
    }
    if (
      generalSettings?.companyInfo?.no_of_employees !== "" &&
      generalSettings?.companyInfo?.no_of_employees !== undefined
    ) {
      setEmployeeCount(generalSettings.companyInfo.no_of_employees);
    }
    if (
      generalSettings?.companyInfo?.isin !== "" &&
      generalSettings?.companyInfo?.isin !== undefined
    ) {
      setISIN(generalSettings.companyInfo.isin);
    }
    if (
      generalSettings.companyInfo.head_office &&
      (generalSettings.companyInfo.head_office.companyAddress ||
        generalSettings.companyInfo.head_office.companyLandmark ||
        generalSettings.companyInfo.head_office.companyPincode ||
        generalSettings.companyInfo.head_office.companyState ||
        generalSettings.companyInfo.head_office.companyCountry)
    ) {
      setHeadAddress(generalSettings.companyInfo.head_office.companyAddress);
      setHeadLandmark(generalSettings.companyInfo.head_office.companyLandmark);
      setHeadPincode(generalSettings.companyInfo.head_office.companyPincode);
      setHeadState(generalSettings.companyInfo.head_office.companyState);
      setHeadOCountry(generalSettings.companyInfo.head_office.companyCountry);
      setInputValue(generalSettings.companyInfo.head_office.companyCountry);
    }
    if (
      generalSettings?.companyInfo?.company_address &&
      (generalSettings?.companyInfo?.company_address[0]?.companyAddress ||
        generalSettings?.companyInfo?.company_address[0]?.landMark ||
        generalSettings?.companyInfo?.company_address[0]?.pinCode ||
        generalSettings?.companyInfo?.company_address[0]?.state ||
        generalSettings?.companyInfo?.company_address[0]?.country)
    ) {
      setHeadAddress(
        generalSettings?.companyInfo?.company_address[0].companyAddress
      );
      setHeadLandmark(
        generalSettings?.companyInfo?.company_address[0].landMark
      );
      setHeadPincode(generalSettings?.companyInfo?.company_address[0].pinCode);
      setHeadState(generalSettings?.companyInfo?.company_address[0].state);
      setHeadOCountry(generalSettings?.companyInfo?.company_address[0].country);
      setInputValue(generalSettings?.companyInfo?.company_address[0].country);
    }
  }, [generalSettings.companyInfo, generalSettings.companyInfo.logo]);

  useEffect(() => {
    if (
      generalSettings.selectedSectors &&
      generalSettings.selectedSectors.length > 0
    ) {
      let masterSectors = [...generalSettings.masterSectors];
      masterSectors.map(
        (masterSec, masterSecIdx) =>
          generalSettings.selectedSectors.some(
            (sec) => sec.sector === masterSec._id
          ) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            generalSettings.selectedSectors.map((basicSec) =>
              basicSec.industry.map(
                (basicInd) =>
                  masterInd._id === basicInd &&
                  (masterSectors[masterSecIdx].industry_data[
                    masterIndIdx
                  ].isSelected = true)
              )
            )
          )
      );
      setsectors(masterSectors);
    } else {
      setsectors(generalSettings.masterSectors);
    }

    const filteredSector = sectors
      .map((sec) => {
        const industries = sec.industry_data
          .filter((ind) => ind.isSelected)
          .map((ind2) => ind2._id);
        if (industries.length) {
          return { sector: sec._id, industry: industries };
        }
        return null;
      })
      .filter((s) => s);

    setSelectedSectorData(filteredSector);
  }, [generalSettings.masterSectors, generalSettings.selectedSectors]); // eslint-disable-line react-hooks/exhaustive-deps

  const ImageUpload = ({ onChange, src }) => {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <SmallAvatar>
            <label htmlFor="photo-upload" className={classes.fileUpload}>
              <Icon name="logoIcon" className={classes.IconColor}>
                edit
              </Icon>
              <input id="photo-upload" type="file" onChange={onChange} />
            </label>
          </SmallAvatar>
        }
      >
        <Avatar className={classes.Avatar} alt="Company Profile" src={src} />
      </Badge>
    );
  };

  const handleUploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    setCompanyLogo(file);
    reader.onloadend = () => {
      setImageURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleAddBranch = () => {
    if (branchAddress === "") {
      set_snack_bar(false, "Please Enter Address..");
    } else if (branchLandmark === "") {
      set_snack_bar(false, "Please Enter Landmark..");
    } else if (branchPincode.search(/^\d{6}$/) === -1) {
      set_snack_bar(false, "Please Enter Valid Pincode..");
    } else if (branchState === "") {
      set_snack_bar(false, "Please Enter State..");
    } else if (branchCountry === "") {
      set_snack_bar(false, "Please Select Country..");
    } else {
      pushBranchAddress({
        branchAddress: branchAddress,
        branchLandmark: branchLandmark,
        branchPincode: branchPincode,
        branchState: branchState,
        branchCountry: branchCountry,
      });
      setBranchAddress("");
      setBranchLandmark("");
      setBranchPincode("");
      setBranchState("");
      setBranchCountry("");
    }
  };

  const AddressStore = (
    <>
      {generalSettings.branchAddresses.length > 0 &&
        generalSettings.branchAddresses.map((address, index) => {
          return (
            <Box my={2} key={index}>
              <Paper>
                <Box display="flex" alignItems="center" p={1}>
                  <Box>
                    <Typography variant="caption" display="block">
                      Address : {address.branchAddress}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Landmark : {address.branchLandmark}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Pincode : {address.branchPincode}
                    </Typography>
                    <Typography variant="caption" display="block">
                      State : {address.branchState}
                    </Typography>
                    <Typography variant="caption" display="block">
                      Country : {address.branchCountry}
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <IconButton
                      onClick={() => {
                        unsetAddress(index);
                      }}
                    >
                      <ClearIcon color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              </Paper>
            </Box>
          );
        })}
    </>
  );

  const handleCloseSectorDialog = () => {
    setIsSectorDialogOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    var sectorL = [...sectors];
    sectorL[sectorIndex].industry_data[industryIndex].isSelected =
      !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    setsectors(sectorL);
    const filteredSector = sectors
      .map((sec) => {
        const industries = sec.industry_data
          .filter((ind) => ind.isSelected)
          .map((ind2) => ind2._id);
        if (industries.length) {
          return { sector: sec._id, industry: industries };
        }
        return null;
      })
      .filter((s) => s);
    setSelectedSectorData(filteredSector);
  };

  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={handleCloseSectorDialog}
      >
        <DialogTitle>
          <Grid container justifyContent="center" lassName={classes.grid}>
            <Grid item xs={4}>
              <Typography className={classes.Typography}>
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            className={clsx(classes.blueText, classes.grid1)}
          >
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div className={clsx(classes.blueText, classes.div)}>
                {sectors.map((sector, index) => (
                  <Accordion
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        name={sector.Sector.substring(0, 4)}
                        className={classes.heading}
                      >
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetails}>
                      {sector.industry_data.map((industry, index2) => (
                        <div
                          className={classes.div1}
                          onClick={() => handleIndustryChange(index2, index)}
                        >
                          <CustomCheckBox
                            name={industry.Industry.substring(0, 4)}
                            checked={industry.isSelected}
                          />
                          <Typography>{industry.Industry}</Typography>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card variant="outlined" className={classes.Card}>
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div className={classes.div2}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      name={industry.Industry.substring(0, 4)}
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleCloseSectorDialog}
            variant="outlined"
            color="primary"
            name="backBtn"
          >
            Cancel
          </CustomButton>
          <CustomButton
            name="NextBtn"
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {isSectorDialogOpen && sectorIndustry()}
      <Card variant="outlined" className={classes.Card1}>
        <CardContent
          className="settingScroll"
          style={{ padding: 100, paddingTop: 50, height: "80vh" }}
        >
          {/* Company Name and Logo */}
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" claassName={classes.typo}>
                {generalSettings.companyInfo.company_name}
              </Typography>
              <div claassName={classes.div3}>
                <CustomCheckBox
                  name="makeDefault"
                  checked={makeDefault}
                  onChange={(e) => setMakeDefault(e.target.checked)}
                />
                <Typography>Make default information for assessment</Typography>
              </div>
            </Grid>
            <Grid
              container
              item
              xs={6}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <ImageUpload src={imageURL} onChange={handleUploadImage} />
              </Grid>
            </Grid>
          </Grid>

          {/* Name and Website field */}
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Box my={2}>
                <CustomTextfield
                  name="cName"
                  variant="outlined"
                  fullWidth
                  label="Company Name"
                  size="small"
                  value={companyName}
                  error={companyName === ""}
                  helperText={
                    companyName === "" && "Company name should not be empty"
                  }
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="webName"
                  variant="outlined"
                  fullWidth
                  label="Official Website Link"
                  size="small"
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="compOperation"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  label="Company Operation"
                  value={companyOperation}
                  onChange={(e) => setCompanyOperation(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="enployeeNum"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="text"
                  label="Number of Employees"
                  value={employeeCount}
                  onChange={(e) => setEmployeeCount(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="contactEmail"
                  variant="outlined"
                  fullWidth
                  label="Contact Email"
                  size="small"
                  type="email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                />
              </Box>
              {isin && (
                <Box my={2}>
                  <CustomTextfield
                    name="ISIN"
                    variant="outlined"
                    fullWidth
                    label="ISIN"
                    size="small"
                    type="email"
                    value={isin}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    disabled
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          <Grid container className={classes.grid2}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" className={classes.typo1}>
                  Sector and Industries
                </Typography>
                <CustomButton
                  onClick={() => {
                    setIsSectorDialogOpen(true);
                  }}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton}
                  disabled={isin ? true : false}
                >
                  Add Sector
                </CustomButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box my={2}>
                {generalSettings.selectedSectors ? (
                  sectors.length > 0 ? (
                    sectors.map((sector) => (
                      <div className={classes.div4}>
                        {sector.industry_data.some(
                          (inds) => inds.isSelected
                        ) && (
                          <>
                            <Typography className={classes.selectedSectorTitle}>
                              {sector.Sector}
                            </Typography>
                            {sector.industry_data.map(
                              (industry) =>
                                industry.isSelected && (
                                  <Chip
                                    name={industry.Industry.substring(0, 4)}
                                    className={classes.sectorChip}
                                    label={industry.Industry}
                                  />
                                )
                            )}
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className={classes.div4}>
                      <Typography className={classes.selectedSectorTitle}>
                        {`${generalSettings?.companyInfo?.sector}`}
                      </Typography>

                      <Chip
                        name={generalSettings?.companyInfo?.industry?.substring(
                          0,
                          4
                        )}
                        className={classes.sectorChip}
                        label={generalSettings?.companyInfo?.industry}
                      />
                    </div>
                  )
                ) : (
                  <Chip className={classes.sectorChip} label="Non Selected" />
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Head Office Address */}
          <Grid container className={classes.grid2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.typo1}>
                Head Office Address
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Box my={2}>
                <CustomTextfield
                  name="headAddress"
                  variant="outlined"
                  fullWidth
                  label="Address"
                  size="small"
                  value={headAddress}
                  onChange={(e) => setHeadAddress(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="headLandmark"
                  variant="outlined"
                  fullWidth
                  label="Landmark"
                  size="small"
                  value={headLandmark}
                  onChange={(e) => setHeadLandmark(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="headPincode"
                  variant="outlined"
                  fullWidth
                  label="Pincode"
                  size="small"
                  type="number"
                  value={headPincode}
                  onChange={(e) => setHeadPincode(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box my={2}>
                <CustomTextfield
                  name="headState"
                  variant="outlined"
                  fullWidth
                  label="State"
                  size="small"
                  value={headState}
                  onChange={(e) => setHeadState(e.target.value)}
                />
              </Box>
              <Box my={2}>
                <Autocomplete
                  getOptionSelected={(option, value) => option.id === value.id}
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  options={countries}
                  // autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => option.label}
                  renderInput={(params) => (
                    <CustomTextfield
                      {...params}
                      label="Country"
                      variant="outlined"
                      value={headOCountry}
                      name="headCountry"
                    />
                  )}
                  onChange={(event, newValue) => {
                    newValue === null
                      ? setHeadOCountry("")
                      : setHeadOCountry(newValue.label);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    newInputValue === null
                      ? setInputValue("")
                      : setInputValue(newInputValue);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Branch Office Address */}
          <Grid container className={classes.grid2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.typo1}>
                Branch Office Address
              </Typography>
            </Grid>
          </Grid>

          {/* Office lists */}
          <Grid container>
            {!addNewAddress && (
              <Grid item xs={12}>
                <CustomButton
                  color="primary"
                  className={classes.CustomButton1}
                  onClick={() => setAddNewAddress(true)}
                >
                  Add Another Address
                </CustomButton>
              </Grid>
            )}
          </Grid>

          {addNewAddress && (
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <Box my={2}>
                  <CustomTextfield
                    name="branchAddress"
                    variant="outlined"
                    fullWidth
                    label="Address"
                    size="small"
                    value={branchAddress}
                    onChange={(e) => setBranchAddress(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchLandmark"
                    variant="outlined"
                    fullWidth
                    label="Landmark"
                    size="small"
                    type="text"
                    value={branchLandmark}
                    onChange={(e) => setBranchLandmark(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <CustomTextfield
                    name="branchPincode"
                    variant="outlined"
                    fullWidth
                    label="Pincode"
                    size="small"
                    type="number"
                    value={branchPincode}
                    onChange={(e) => setBranchPincode(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box my={2}>
                  <CustomTextfield
                    name="branchState"
                    variant="outlined"
                    fullWidth
                    label="State"
                    size="small"
                    type="text"
                    value={branchState}
                    onChange={(e) => setBranchState(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <Autocomplete
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    id="country-select-demo"
                    sx={{ width: 300 }}
                    options={countries}
                    // autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => option.label}
                    renderInput={(params) => (
                      <CustomTextfield
                        {...params}
                        label="Country"
                        variant="outlined"
                        value={branchCountry}
                        name="branchCountry"
                      />
                    )}
                    onChange={(event, newValue) => {
                      newValue === null
                        ? setBranchCountry("")
                        : setBranchCountry(newValue.label);
                    }}
                    inputValue={inputBranch}
                    onInputChange={(event, newInputValue) => {
                      newInputValue === null
                        ? setInputBranch("")
                        : setInputBranch(newInputValue);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.grid3}>
                <CustomButton
                  name="addToList"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleAddBranch();
                  }}
                >
                  + Add To Address List
                </CustomButton>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={10}>
            <Grid item xs={12}>
              {AddressStore}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.Card2}>
        <CardContent className={classes.CardContent}>
          <CustomButton
            name="SaveBtn"
            variant="contained"
            color="primary"
            className={classes.CustomButton2}
            disabled={companyName === ""}
            onClick={() => {
              if (userType === "SA") {
                upodateCompanyDetails(
                  login.token,
                  login.current_organisation,
                  companyName,
                  companyWebsite,
                  {
                    companyAddress: headAddress,
                    companyLandmark: headLandmark,
                    companyPincode: headPincode,
                    companyState: headState,
                    companyCountry: headOCountry,
                  },
                  generalSettings.branchAddresses,
                  companyLogo,
                  makeDefault,
                  companyEmail,
                  companyOperation,
                  employeeCount,
                  selectedSectorData
                );
              } else {
                dispatch(
                  updateSupplierOrganization(
                    login.token,
                    login.supplier_id,
                    isin,
                    companyName,
                    generalSettings?.companyInfo?.industry_id,
                    generalSettings?.companyInfo?.sector_id,
                    headOCountry,
                    companyWebsite,
                    companyOperation,
                    employeeCount,
                    [
                      {
                        companyAddress: headAddress,
                        landMark: headLandmark,
                        pinCode: headPincode,
                        state: headState,
                        country: headOCountry,
                      },
                    ],
                    generalSettings?.companyInfo?.revenue,
                    generalSettings?.companyInfo?.turnover,
                    companyLogo
                  )
                );
              }
            }}
          >
            Save
          </CustomButton>
        </CardContent>
      </Card>
    </>
  );
}
