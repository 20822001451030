import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography, Box, Button, Avatar } from '@material-ui/core';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

function FinalSingleMetricReportHeader(props) {
  const {
    setFinalData,
    setOpen,
    fieldData,
    login,
    assessment_id,
    getCollectMetrics,
  } = props;
  const { currentDetailedMetric } = fieldData;
  const UserDetails = (props) => {
    const { toMap, type } = props;
    return (
      <Grid container item xs={12}>
        <Grid item xs={4}>
          {/* {items.type} */}
          <Typography style={{ fontSize: 'small', color: '#AFAFAF' }}>
            {type}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            {toMap && toMap.length > 0 ? (
              toMap.map((item2, index2) => (
                <Grid item xs={6} key={index2}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={item2.profile_img.url} alt="icons" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item2.name}
                        secondary={item2.designation}
                      />
                    </ListItem>
                  </List>
                </Grid>
              ))
            ) : (
              <Typography style={{ fontSize: 'small', color: '#AFAFAF' }}>
                No {type} yet
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {
        <>
          <Box
            borderBottom={2}
            pb={2}
            mb={2}
            style={{
              borderBottomColor:
                currentDetailedMetric &&
                currentDetailedMetric.metrics_data &&
                currentDetailedMetric &&
                currentDetailedMetric.metrics_data.is_assigned
                  ? currentDetailedMetric &&
                    currentDetailedMetric.metrics_data.is_approved
                    ? '#5BB37F'
                    : '#15314E'
                  : '#9FA1AF',
            }}
          >
            {/* <div style={{ border: '1px solid #5BB37F' }}></div> */}

            <Grid container>
              <Grid item xs={9}>
                <Box display="flex" alignItems="center">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setFinalData({ open: false, index: null, data: null });
                      getCollectMetrics(
                        login.token,
                        assessment_id,
                        fieldData.currentMetric._id,
                        fieldData.currentMetric.main_topic_ids,
                        props.basicInfo.templateType,
                        localStorage.getItem('current_organisation')
                      );
                    }}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                  <Typography>Disclosure points</Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Button
                  startIcon={<SubdirectoryArrowRightIcon />}
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth
                  onClick={() => setOpen()}
                >
                  View thread
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>Disclosure : </Typography>
            <Box ml={2}>
              <Box
                display="flex"
                alignItems="center"
                bgcolor="#EBF6EF"
                borderRadius="6px"
                color={
                  currentDetailedMetric &&
                    currentDetailedMetric.metrics_data &&
                  currentDetailedMetric.metrics_data?.is_completed
                    ? '#5BB37F'
                    : currentDetailedMetric &&
                      currentDetailedMetric.metrics_data?.is_approved
                    ? '#5BB37F'
                    : currentDetailedMetric &&
                      currentDetailedMetric.metrics_data?.is_awaiting_approval
                    ? '#15314E'
                    : currentDetailedMetric &&
                      currentDetailedMetric.metrics_data?.in_progress
                    ? '#15314E'
                    : '#9FA1AF'
                }
                p={1}
              >
                <Box
                  borderRadius="50%"
                  height={10}
                  width={10}
                  bgcolor={ currentDetailedMetric &&
                    currentDetailedMetric.metrics_data &&
                    currentDetailedMetric.metrics_data?.is_completed
                      ? '#5BB37F'
                      : currentDetailedMetric &&
                        currentDetailedMetric.metrics_data?.is_approved
                      ? '#5BB37F'
                      : currentDetailedMetric &&
                        currentDetailedMetric.metrics_data?.is_awaiting_approval
                      ? '#EBD947'
                      : currentDetailedMetric &&
                        currentDetailedMetric.metrics_data?.in_progress
                      ? '#EBD947'
                      : '#9FA1AF'
                 
                  }
               
                />
 
                <Box pl={1}>
                  {currentDetailedMetric && currentDetailedMetric?.metrics_data
                    ? currentDetailedMetric.metrics_data?.is_completed
                      ? 'Completed'
                      : currentDetailedMetric.metrics_data?.is_approved
                      ? 'Approved'
                      : currentDetailedMetric.metrics_data?.is_awaiting_approval
                      ? 'Awaiting Approval'
                      : currentDetailedMetric.metrics_data?.in_progress
                      ? 'In progress'
                      : 'Yet to be assigned'
                    : ''}
            
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              {currentDetailedMetric &&
                currentDetailedMetric.metrics_data &&
                fieldData.currentDetailedMetric.metrics_data.metric_name +
                  fieldData.currentDetailedMetric.metrics_data.metric_desc}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="caption">
              Project roles :{' '}
              {fieldData &&
                fieldData.currentDetailedMetric &&
                fieldData.currentDetailedMetric.admins &&
                fieldData.currentDetailedMetric.admins.length}{' '}
              admin,{' '}
              {fieldData &&
                fieldData.currentDetailedMetric &&
                fieldData.currentDetailedMetric.approvers &&
                fieldData.currentDetailedMetric.approvers.length}
              approvers &{' '}
              {fieldData &&
                fieldData.currentDetailedMetric &&
                fieldData.currentDetailedMetric.assignees &&
                fieldData.currentDetailedMetric.assignees.length}{' '}
              Assignees
            </Typography>
          </Box>
          <Box mt={2}>
            <Grid container>
              <UserDetails
                type="Admins"
                toMap={
                  fieldData.currentDetailedMetric &&
                  fieldData.currentDetailedMetric.admins
                }
              />
              <UserDetails
                type="Approvers"
                toMap={
                  fieldData.currentDetailedMetric &&
                  fieldData.currentDetailedMetric.approvers
                }
              />
              <UserDetails
                type="Assignees"
                toMap={
                  fieldData.currentDetailedMetric &&
                  fieldData.currentDetailedMetric.assignees
                }
              />
            </Grid>
          </Box>
        </>
      }
    </>
  );
}

export default FinalSingleMetricReportHeader;
