import { useEffect } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import { IoIosArrowForward } from "react-icons/io";
import SpecificQuestion from "./RightComps/SpecificQuestion";
import CustomButton from "../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSelectedQuestion } from "../../../../../actions/taskActions/TaskAction";

// ---------- Stylings ----------

const headerStyle = () => {
  return {
    color: "#15314E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const questionsStyle = () => {
  return {
    color: "#242424",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    maxWidth: "72em",
  };
};

// ------------------------------

// ---------- Main Render ----------
const RedoSpecific = ({ questions = [], activeTab, setRedoSpecific }) => {
  const dispatch = useDispatch();
  const { selectedQuestionRedo } = useSelector((state) => state.taskReducer);

  const handleSelectQuestion = async (quesId, action) => {
    await dispatch(
      UpdateSelectedQuestion(
        quesId,
        activeTab?._id,
        action === "select" ? "add" : "remove"
      )
    );
  };

  useEffect(() => {
    const removeSelectedQuestion = async () => {
      await dispatch(
        UpdateSelectedQuestion(
          selectedQuestionRedo?.question_id || null,
          activeTab?._id,
          "remove"
        )
      );
    };

    removeSelectedQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);


  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
      {selectedQuestionRedo ? (
        <SpecificQuestion
          question={selectedQuestionRedo}
          activeTab={activeTab}
          handleSelectQuestion={handleSelectQuestion}
        />
      ) : (
        <>
          {/* header */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            style={{ borderBottom: "1px solid #EBF1F8" }}
          >
            <Box display={"flex"} sx={{ gap: 20 }}>
              <Typography style={headerStyle()}>Que No.</Typography>
              <Typography style={headerStyle()}>Question</Typography>
            </Box>
            <Typography style={headerStyle()}>Explore</Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            style={{
              padding: "0px 18px 0px 35px",
              gap: 20,
              overflowY: "auto",
              height: "56vh",
              scrollBehavior: "smooth",
            }}
          >
            {/* main questions */}
            {questions.map((e, index) => (
              <Box
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} sx={{ gap: 50 }}>
                  <Typography style={questionsStyle()}>{index + 1}</Typography>
                  <Typography style={questionsStyle()}>{e.question}</Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={() => handleSelectQuestion(e.question_id, "select")}
                >
                  <IoIosArrowForward color={"#3374B9"} />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box
            style={{
              display: "flex",
              position: "absolute",
              right: 10,
              bottom: 10,
              gap: 10,
            }}
          >
            <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                setRedoSpecific(false);
              }}
            >
              Submit Changes On Section
            </CustomButton>
          </Box>
        </>
      )}
    </Box>
  );
};
export default RedoSpecific;
