import encrypt from "../shared/sharedActions";
import UNIVERSAL from "../../config/config";
import {
  SET_IMPORT_FILE_LIST,
  SET_FILE_PROCESSING_PROGRESS,
  SET_FUNCTIONAL_TAGS,
  SET_LOCATION_TAGS,
  SET_VALUE_CHAIN_TAGS,
  SET_IMPORT_FILE_DATA,
  SET_CALCULATED_DATA,
  SET_DATA_HUB_CHARTS,
  SET_DATA_PROCESSING_STATUS,
  SET_ALL_FILE_PROCESSING_STATUS,
  SET_CLEAR_CHARTS,
  SET_SELECTED_TOPIC,
  SET_SELECTED_KPI_TYPE,
  SET_BASELINE_VALUE,
  SET_TARGETLINE_VALUE,
  SET_KPI_DRAWER_STATE,
  SET_SUGGESTED_DATA,
  SET_AUTOFILL,
  SET_FILE_VALIDATION,
  SET_FILE_ERROR,
  SET_FILE_ERROR_MESSAGE,
  HAS_PUBLIS_ERROR,
  SET_OVERLAPPING_FILES,
  SET_PUBLISH_STATUS,
  SET_GENERAL_TAB_DATA,
  SET_GENERAL_TAB_REPORT_DATA,
  SET_SELECTED_ASSESSMENT,
  SET_CHART_DATA,
  SET_SELECTED_TAB_CHART_DATA,
  SET_INACTIVE_CHART_DATA,
  SET_NEW_LAYOUT,
  SET_FRAMEWORKS,
} from "../../constants/datahubConst/datahubConst";

import { set_snack_bar } from "../snackbar/snackbar_action";
import { set_loader, unset_loader } from "../loader/loader_action";

export const setImportFileList = (payload) => {
  return {
    type: SET_IMPORT_FILE_LIST,
    payload,
  };
};
export const setChartData = (payload) => {
  return {
    type: SET_CHART_DATA,
    payload,
  };
};

export const setImportFileData = (payload) => {
  return {
    type: SET_IMPORT_FILE_DATA,
    payload,
  };
};

export const setCalculatedData = (payload) => {
  return {
    type: SET_CALCULATED_DATA,
    payload,
  };
};

export const setFileProcessingProgress = (payload) => {
  return {
    type: SET_FILE_PROCESSING_PROGRESS,
    payload,
  };
};

export const setFunctionalTags = (payload) => {
  return {
    type: SET_FUNCTIONAL_TAGS,
    payload,
  };
};

export const setLocationTags = (payload) => {
  return {
    type: SET_LOCATION_TAGS,
    payload,
  };
};

export const setValueChainTags = (payload) => {
  return {
    type: SET_VALUE_CHAIN_TAGS,
    payload,
  };
};

export const setDataHubCharts = (payload) => {
  return {
    type: SET_DATA_HUB_CHARTS,
    payload,
  };
};

export const setDataProcessingStatus = (payload) => {
  return {
    type: SET_DATA_PROCESSING_STATUS,
    payload,
  };
};

export const setClearDashboardChart = () => {
  return {
    type: SET_CLEAR_CHARTS,
  };
};

export const setSelectedTopic = (payload) => {
  return {
    type: SET_SELECTED_TOPIC,
    payload,
  };
};

export const setSelectedKpiType = (payload) => {
  return {
    type: SET_SELECTED_KPI_TYPE,
    payload,
  };
};

export const setBaseLineValue = (payload) => {
  return {
    type: SET_BASELINE_VALUE,
    payload,
  };
};

export const setTargetLineValue = (payload) => {
  return {
    type: SET_TARGETLINE_VALUE,
    payload,
  };
};

export const setKpiDrawerState = (payload) => {
  return {
    type: SET_KPI_DRAWER_STATE,
    payload,
  };
};

export const setFrameworks = (payload) => {
  return {
    type: SET_FRAMEWORKS,
    payload,
  };
};

export const setKpiSuggestions = (payload) => {
  return {
    type: SET_SUGGESTED_DATA,
    payload,
  };
};

export const setAutoFillSuggestions = (payload) => {
  return {
    type: SET_AUTOFILL,
    payload,
  };
};

export const setFileValidation = (payload) => {
  return {
    type: SET_FILE_VALIDATION,
    payload,
  };
};

export const setFileError = (payload) => {
  return {
    type: SET_FILE_ERROR,
    payload,
  };
};

export const setErrorMessage = (payload) => {
  return {
    type: SET_FILE_ERROR_MESSAGE,
    payload,
  };
};

export const hasPublishError = (payload) => {
  return {
    type: HAS_PUBLIS_ERROR,
    payload,
  };
};

export const setOverlappingFiles = (payload) => {
  return {
    type: SET_OVERLAPPING_FILES,
    payload,
  };
};

export const setPublishFileProcessingProgress = (payload) => {
  return {
    type: SET_PUBLISH_STATUS,
    payload,
  };
};

export const setGeneralTabData = (payload) => {
  return {
    type: SET_GENERAL_TAB_DATA,
    payload,
  };
};

export const setGeneralTabTaskData = (payload) => {
  return {
    type: "SET_GENERAL_TAB_TASK_DATA",
    payload,
  };
};

export const setGeneralTabReportData = (payload) => {
  return {
    type: SET_GENERAL_TAB_REPORT_DATA,
    payload,
  };
};

export const setSelectedAssesment = (payload) => {
  return {
    type: SET_SELECTED_ASSESSMENT,
    payload,
  };
};

export const setSelectedTabChartData = (payload) => {
  return {
    type: SET_SELECTED_TAB_CHART_DATA,
    payload,
  };
};

export const setInactiveChartData = (payload) => {
  return {
    type: SET_INACTIVE_CHART_DATA,
    payload,
  };
};

export const setNewChartsLayout = (payload) => {
  return {
    type: SET_NEW_LAYOUT,
    payload,
  };
};

export const uploadImportFile = (
  token,
  organisation_id,
  import_template_name,
  tab_name,
  import_file,
  data_stream_name,
  data_stream_description,
  data_duration,
  functional_tag,
  location_tag,
  value_chain_tag,
  frameworkId
) => {
  let formData = new FormData();
  const data = encrypt({
    "user-token": token,
    organisation_id,
    import_template_name,
    tab_name,
    import_file,
    data_stream_name,
    data_stream_description,
    data_duration,
    functional_tag,
    location_tag,
    value_chain_tag,
    frameworkId,
  });
  formData.append("data", data);
  formData.append("import_file", import_file);
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/data_hub/upload_import_file", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //here we will get document id and need to call getFileProcessingProgress API
          dispatch(getImportFileList(token, organisation_id, tab_name));
          dispatch(
            getFileProcessingProgress(
              token,
              responseJson.result.processing_file_id,
              organisation_id,
              tab_name
            )
          );
          dispatch(setFileValidation(responseJson.status));
        }
        if (!responseJson.status) {
          //here we will Handle file error
          dispatch(setFileValidation(responseJson.status));
          dispatch(setFileError(true));
          dispatch(setErrorMessage(responseJson.message));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getImportFileList = (token, organisation_id, tab_name) => {
  const data = encrypt({
    "user-token": token,
    organisation_id,
    tab_name,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/get_import_file_list", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //list of uploaded and processed files
          dispatch(
            getAllFileProcessingStatus(token, organisation_id, tab_name)
          );
          dispatch(setImportFileList(responseJson.result));
          dispatch(setImportFileData([]));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getImportFileData = (token, file_id) => {
  const data = encrypt({
    "user-token": token,
    file_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/get_import_file_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //processed file data
          let array = [];
          let calculatedData;
          for (let key in responseJson.result) {
            //to remove the calculated data from the resutl object
            if (Array.isArray(responseJson.result[key])) {
              array.push({ [key]: responseJson.result[key] });
            } else {
              calculatedData = responseJson.result[key];
            }
          }
          dispatch(setImportFileData(array));
          dispatch(setCalculatedData(calculatedData));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getAllFrameworks = (token) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/framework/view_frameworks_by_type", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setFrameworks(responseJson.result));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const addTags = (token, organisation_id, tag_type, tag_name) => {
  const data = encrypt({
    "user-token": token,
    organisation_id,
    tag_type,
    tag_name,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/add_tags", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //label added successfully and need to call get tags
          dispatch(getTags(token, organisation_id));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getTags = (token, organisation_id) => {
  const data = encrypt({
    "user-token": token,
    organisation_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/get_tags", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //will get all the tags and need to dispatch them based on the types
          dispatch(setFunctionalTags(responseJson.result.functional_tag));
          dispatch(setLocationTags(responseJson.result.location_tag));
          dispatch(setValueChainTags(responseJson.result.value_chain_tag));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const deleteTags = (token, organisation_id, tag_type, tag_id) => {
  const data = encrypt({
    "user-token": token,
    organisation_id,
    tag_type,
    tag_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/delete_tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //label added successfully and need to call get tags
          dispatch(getTags(token, organisation_id));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const restartMatatikaJob = (
  token,
  datastream_id,
  organisation_id,
  tab_name
) => {
  const data = encrypt({
    "user-token": token,
    datastream_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/restart_matatika_job", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            getFileProcessingProgress(
              token,
              datastream_id,
              organisation_id,
              tab_name
            )
          );
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getFileProcessingProgress = (
  token,
  processing_file_id,
  organisation_id,
  tab_name
) => {
  const data = encrypt({
    "user-token": token,
    processing_file_id,
    organisation_id,
    tab_name,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/get_file_processing_progress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //will get the file processing progress and need to call this api recrussively still the progress is 100%
          dispatch(
            setDataProcessingStatus({
              id: processing_file_id,
              status: responseJson.result.status,
            })
          );
          // dispatch(setFileProcessingProgress(responseJson.result));

          console.log(responseJson.result.status);
          let timeoutInterval;
          if (
            responseJson.result.status === "QUEUED" ||
            responseJson.result.status === null ||
            responseJson.result.status === undefined ||
            responseJson.result.status === "RUNNING"
          ) {
            console.log("timeout triggered");
            timeoutInterval = setTimeout(() => {
              dispatch(
                getFileProcessingProgress(
                  token,
                  processing_file_id,
                  organisation_id,
                  tab_name
                )
              );
            }, 45 * 1000);
          } else if (
            responseJson.result.status === "COMPLETE" ||
            responseJson.result.status === "STOPPED"
          ) {
            clearTimeout(timeoutInterval);
            console.log("timeout stopped");
            return;
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

// cube js API

export const getDataHubChart = (token, organisation_id, type) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisation_id: organisation_id,
      type,
    });

    return fetch(UNIVERSAL.BASEURL + "/dashboard/get_dashboard_charts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson);
          dispatch(setDataHubCharts(responseJson.result));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const setAllFileProcessingStatus = (payload) => {
  return {
    type: SET_ALL_FILE_PROCESSING_STATUS,
    payload,
  };
};

export const getAllFileProcessingStatus = (
  token,
  organisation_id,
  tab_name
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisation_id: organisation_id,
      tab_name: tab_name,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/data_hub/get_all_file_processing_status",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAllFileProcessingStatus(responseJson.result));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// export const addDataHubChart = (token, organisation_id, newChartObj) => {
//   return (dispatch) => {
//     const data = encrypt({
//       "user-token": token,
//       organisationId: organisation_id,
//       newChartObj,
//     });

//     return fetch(UNIVERSAL.BASEURL + "/dashboard/add_dashboard_chart", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ data: data }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           console.log(newChartObj?.type)
//           dispatch(getDashboardCharts(token, organisation_id, newChartObj?.type));

//           // dispatch(getDataHubChart(token, organisation_id, newChartObj.type));
//           dispatch(set_snack_bar(responseJson.status, responseJson.message));
//         }
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };
// };

export const deleteDataHubChart = (token, organisation_id, chart_id, type) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisation_id: organisation_id,
      chart_id: chart_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/dashboard/delete_dashboard_chart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getDataHubChart(token, organisation_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const updateDashboardLayout = (token, organisation_id, new_layout) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisation_id: organisation_id,
      new_layout: new_layout,
    });

    return fetch(UNIVERSAL.BASEURL + "/dashboard/save_dashboard_layout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getDataHubChart(token, organisation_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getMatatikaJobStatus = (token, datastream_id) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      datastream_id: datastream_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/data_hub/get_matatika_job_status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(
          setDataProcessingStatus({
            id: datastream_id,
            status: responseJson.result,
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// matatika publish

export const publishDataStream = (
  token,
  datastream_id,
  old_datastream_id = "",
  organisation_id
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      datastream_id,
      old_datastream_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/data_hub/publish_data_stream", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const TAB_NAME = "survey_tab";
          dispatch(getPublishDataStreamProgress(token, datastream_id));
          dispatch(getImportFileList(token, organisation_id, TAB_NAME));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getPublishDataStreamProgress = (token, document_id) => {
  const data = encrypt({
    "user-token": token,
    document_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/get_publish_status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //will get the file processing progress and need to call this api recrussively still the progress is 100%
          // dispatch(
          //   set_snack_bar()
          // );
          // dispatch(setFileProcessingProgress(responseJson.result));

          let timeoutInterval;
          if (
            responseJson.result.publish_status === "QUEUED" ||
            responseJson.result.publish_status === null ||
            responseJson.result.publish_status === undefined ||
            responseJson.result.publish_status === "RUNNING"
          ) {
            timeoutInterval = setTimeout(() => {
              dispatch(getPublishDataStreamProgress(token, document_id));
            }, 15 * 1000);
          } else if (
            responseJson.result.publish_status === "COMPLETE" ||
            responseJson.result.publish_status === "STOPPED"
          ) {
            clearTimeout(timeoutInterval);
            // dispatch(
            //   setPublishFileProcessingProgress({
            //     id: document_id,
            //     status: true,
            //   })
            // );
            return;
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const checkPubishDataExist = (token, organisation_id, datastream_id) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisation_id,
      datastream_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/data_hub/check_date_range_overlap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            publishDataStream(token, datastream_id, "", organisation_id)
          );
        }
        if (!responseJson.status) {
          dispatch(setOverlappingFiles(responseJson.result));

          dispatch(hasPublishError(true));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const deletePublishedDatastream = (
  token,
  new_datastream_id,
  old_datastream_id = null
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      new_datastream_id,
      old_datastream_id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/data_hub/delete_old_and_update_new_procedure",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          old_datastream_id &&
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(publishDataStream(token, new_datastream_id));
        }
        console.log(responseJson);
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const deleteUnPublishedDatastream = (token, datastream_id) => {
  const data = encrypt({
    "user-token": token,
    datastream_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/data_hub/delete_datastream ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const tab_name = "survey_tab";
          const organisation_id = localStorage.getItem("current_organisation");
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getImportFileList(token, organisation_id, tab_name));
        }
        console.log(responseJson);
      })
      .catch((err) => console.log(err));
  };
};

// suggestion API

export const getViewSuggestions = (
  token,
  organization_id,
  kpi_name,
  framework_id,
  start_date = "2023-04-01",
  end_date = "2024-03-31"
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organization_id,
      kpi_name,
      start_date,
      end_date,
      framework_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/data_hub/view_suggestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setKpiSuggestions(responseJson.result));
        }
        if (!responseJson.status) {
          dispatch(setKpiSuggestions([]));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// elastic unpublish
export const unPublishedDatastream = (token, datastream_id) => {
  const data = encrypt({
    "user-token": token,
    datastream_id,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/elastic_search/delete_day_wise_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const tab_name = "survey_tab";
          const organisation_id = localStorage.getItem("current_organisation");
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getImportFileList(token, organisation_id, tab_name));
        }
        console.log(responseJson);
      })
      .catch((err) => console.log(err));
  };
};

// static dashoboard general tab

export const getGeneralTabData = (token, organisation_id, assessment_id) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisation_id,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/dashboard/get_general_tab_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setGeneralTabData(responseJson.result));
          dispatch(
            setGeneralTabTaskData(
              responseJson.result.my_task_assessments.map((t) => ({
                ...t,
                hasOpen: false,
              }))
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getGeneralTabReportData = (
  token,
  // organisation_id,
  assessment_id
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/dashboard/get_assessment_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setGeneralTabReportData(responseJson.result));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getDashboardCharts = (
  token,
  organisationId,
  type,
  active = false,
  framework_type
  // assessment_id
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisationId,
      type,
      active,
      framework_type,

      // assessment_id,
    });
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/dashboard/get_dashboard_charts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (active) {
          if (responseJson?.status) {
            dispatch(setSelectedTabChartData(responseJson?.result));
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(unset_loader());
          } else {
            dispatch(setSelectedTabChartData([]));
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(unset_loader());
          }
        } else {
          if (responseJson?.status) {
            dispatch(setInactiveChartData(responseJson?.result));
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(unset_loader());
          } else {
            dispatch(setInactiveChartData([]));
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(unset_loader());
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const addDataHubChart = (token, organisation_id, newChartObj) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      organisationId: organisation_id,
      newChartObj,
    });

    return fetch(UNIVERSAL.BASEURL + "/dashboard/add_dashboard_chart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(newChartObj?.type);
          dispatch(
            getDashboardCharts(token, organisation_id, newChartObj?.type, true)
          );

          // dispatch(getDataHubChart(token, organisation_id, newChartObj.type));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const updateDashboardChartsLayout = (
  token,
  newLayout
  // assessment_id
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      newLayout,

      // assessment_id,
    });
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.BASEURL + "/dashboard/update_dashboard_charts_layout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson?.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const removeDashboardCharts = (
  token,
  chartId,
  organisationId,
  type,
  active

  // assessment_id
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chartId,

      // assessment_id,
    });
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/dashboard/delete_dashboard_charts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson?.status) {
          dispatch(getDashboardCharts(token, organisationId, type, active));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          // dispatch(setSelectedTabChartData([]));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const addDashboardNewCharts = (
  token,
  chartId,
  active,
  organisationId,
  type
  // assessment_id
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chartId,
      active,
      organisationId,
      type,
      // assessment_id,
    });
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.BASEURL + "/dashboard/toggle_dashboard_charts_active_status",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson?.status) {
          dispatch(getDashboardCharts(token, organisationId, type, true));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
