import {
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CustomCheckBox from "../../../UI/checkBox/checkBox";
import planStyles from "../plansStyles";
import CloseIcon from "@material-ui/icons/Close";

function PlanCard(props) {
  const { setUpgradePlans, setChecked, checked, upgradePlan, login } = props;
  const classes = planStyles();
 
  return (
    <div>
      <Grid md={12} container>
        <Grid md={5}></Grid>
        <Grid md={5}>
          <Typography className={classes.Upgrade}>Upgrade Plans</Typography>
        </Grid>
        <Grid>
          <Icon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUpgradePlans(false);
              setChecked(false);
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </Icon>
        </Grid>
      </Grid>
      <Grid style={{ padding: "10px 110px" }}>
        <Card
          variant="elevation"
          id="drawer-container"
          elevation={1}
          // className={classes.cardContainer}
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 5,
            height: "100%",
            width: "100%",
          }}
        >
          <CardContent className={classes.cardContent}>
            <Typography style={{ fontWeight: 600 }}>Premium Plan</Typography>
            <Typography style={{ fontWeight: 600, fontSize: 25 }}>
              ₹ {props.generalSettings?.planAmount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 13,
                  display: "inline",
                  color: "#3374b9",
                }}
              >
                /Month
              </span>
            </Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>
              Enjoy Premium plan with these following benifits
            </Typography>
          </CardContent>
          <CardContent style={{ backgroundColor: "#EBF1F8" }}>
            <ul className={classes.ul}>
              <li>
                <Typography className={classes.li}>
                  Max Upto 20 Users
                </Typography>
              </li>

              <li>
                <Typography className={classes.li}>Customer support</Typography>
              </li>

              <li>
                <Typography className={classes.li}>
                  Max Upto 10 Company Profiles
                </Typography>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid>
        <CustomCheckBox value={checked} onChange={() => setChecked(!checked)} />
        <Typography
          style={{
            fontSize: 12,
            color: "grey",
            display: "inline",
          }}
        >
          Would you like to opt for payment mandate to avoid delayed payments
        </Typography>
        <div>
        {localStorage.getItem("current_organisation_user_type") !== "A" && <Button
            disabled={!checked}
            onClick={() =>
              upgradePlan(
                login.token,
                login.current_organisation,
                login?.selectedOrganization?.company_name,
                login?.selectedOrganization?.logo?.url,
                props.generalSettings?.planAmount
              )
            }
            className={classes.payButton}
          >
            Proceed To Pay
          </Button>}
        </div>
      </Grid>
    </div>
  );
}

export default PlanCard;
