import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import DataHubSurveyButtons from "./dataHubSurveyButton";
import DataHubSurveyFile from "../../widgets/dataHubSurvey/dataHubSurveyFile";
import DataHubBreadCrumbs from "../dataHub/dataHubBreadCrumbs";
import DataHubTableTabs from "../../widgets/dataHubSurvey/dataHubTableTabs";
import sideTabIcon from "../../../images/sidebar.svg";
import AreaChart from "../../UI/dataHubCalculatedCharts/areaChart";
import MultiLineChart from "../../UI/dataHubCalculatedCharts/multiLineChart";
import LineChart from "../../UI/dataHubCalculatedCharts/lineChart";
import PieChart from "../../UI/dataHubCalculatedCharts/pieChart";
import BarChart from "../../UI/dataHubCalculatedCharts/EnvBarChart";
import EnvBarChart from "../../UI/dataHubCalculatedCharts/EnvBarChart";
import AppDashBoard from "../../UI/dataHubCalculatedCharts/AppDashBoard";
// import DashBoard from '../../UI/dataHubCalculatedCharts/DashBoard';

function DataHubSurveyScreen(props) {
  const {
    match,
    location,
    history,
    dataHub,
    setImportFileData,
    getDataHubChart,
    login,
    updateDashboardLayout
  } = props;
    

  const {token, selectedOrganization} = login
  const { state } = location;

  const { params } = match;
  const { page } = params;
  const { goBack } = history;
  const { date } = location;
  const pathNames = props.location.pathname.split("/").filter((x) => x);

  const [selectedMainTab, setSelectedMainTab] = useState(0);

  const [selectedTab, setSelectedTab] = useState(0);
  const [showTabs, setShowTabs] = useState(true);
  const [selectedTabName, setSelectedTabName] = useState("");

  useEffect(() => {
    if (dataHub.importFileData.length > 0) {
      setSelectedTabName(Object.keys(dataHub.importFileData[selectedTab])[0]);
    } else {
      setSelectedTabName("");
    }
  }, [selectedTab, dataHub.importFileData]);

  const handleTableTabChange = (newValue) => {
    setSelectedTab(newValue);
  };
  const handleMainTabChange = (event, newValue) => {
    setSelectedMainTab(newValue);
  };
  const toggleNotebookDrawer = () => {
    setShowTabs(!showTabs);
  };

  return (
    <Container maxWidth="false" disableGutters="true">
      <Typography
        component="div"
        style={{
          backgroundColor: "#F3F4F4",
          minHeight: "91vh",
          marginLeft: "-10px",
        }}
      >
        <DataHubBreadCrumbs
          tabName={page}
          pathNames={pathNames}
          back={goBack}
          setImportFileData={setImportFileData}
        />
        <DataHubSurveyButtons
          handleMainTabChange={handleMainTabChange}
          selectedMainTab={selectedMainTab}
          date={date}
        />
        <Grid container>
          {selectedMainTab === 0 && (
            <>
              <Grid xs={2} item>
                <div
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "#FEFEFE",
                    borderRadius: "8px",
                    width: "56px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "8px",
                    cursor: "pointer",
                  }}
                  onClick={toggleNotebookDrawer}
                >
                  <img src={sideTabIcon} alt="sideTabIcon" />
                </div>
                <div
                  id="tabs-drawer"
                  style={{ height: "400px", position: "relative" }}
                >
                  <Drawer
                    anchor="left"
                    open={showTabs}
                    close={toggleNotebookDrawer}
                    variant="persistent"
                    PaperProps={{
                      style: {
                        backgroundColor: "transparent",
                        border: "none",
                        position: "absolute",
                        zIndex: "1",
                      },
                    }}
                    ModalProps={{
                      container: document.getElementById("tabs-drawer"),
                      style: { position: "absolute" },
                    }}
                  >
                    <DataHubTableTabs
                      value={selectedTab}
                      noOfSheets={dataHub.importFileData}
                      handleChange={handleTableTabChange}
                    />
                  </Drawer>
                </div>
              </Grid>
              <Grid item xs={10}>
                <DataHubSurveyFile
                  files={dataHub.importFileData}
                  selectedTab={selectedTab}
                  selectedTabName={selectedTabName}
                  showTabs={showTabs}
                />
              </Grid>
            </>
          )}
          {/* {selectedMainTab === 1 && (
            <>
              <Grid
                container
                md={12}
                xs={12}
                spacing={2}
                style={{
                  padding: "1% 1% 1% 3%",
                  // marginLeft: '2%',
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                  (data) => (
                    <>
                      <Grid
                        item
                        md={
                          data === 7
                            ? 4
                            : data === 9
                            ? 4
                            : data === 8
                            ? 8
                            : data === 1
                            ? 4
                            : data === 2
                            ? 4
                            : data === 3
                            ? 4
                            : data === 10
                            ? 7
                            : data === 11
                            ? 4
                            : 6
                        }
                        style={{
                          height:
                            data === 1 || data === 2 || data === 3
                              ? "150px"
                              : "360px",
                          // marginBottom: '2%',
                        }}
                      >
                        <EnvBarChart
                          d={data}
                          dataHub={dataHub.calculatedData}
                        />
                      </Grid>
                    </>
                  )
                )}
                {/* <Grid item md={12}>
                <DashBoard />

                </Grid> 
              </Grid>
            </>
          )} */}
          {selectedMainTab === 1 && (
            <Grid container md={12}>
              <Grid item md={12}>
                <AppDashBoard
                  getDataHubChart={getDataHubChart}
                  charts={dataHub?.charts}
                  dataStream_id={state}
                  dataHub={dataHub}
                  login={login}
                  updateDashboardLayout={updateDashboardLayout}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Typography>
    </Container>
  );
}

export default React.memo(DataHubSurveyScreen);
