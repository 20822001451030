import {
  SET_IMPORT_FILE_DATA,
  SET_FILE_PROCESSING_PROGRESS,
  SET_IMPORT_FILE_LIST,
  SET_FUNCTIONAL_TAGS,
  SET_LOCATION_TAGS,
  SET_VALUE_CHAIN_TAGS,
  SET_CALCULATED_DATA,
  SET_DATA_HUB_CHARTS,
  SET_DATA_PROCESSING_STATUS,
  SET_ALL_FILE_PROCESSING_STATUS,
  SET_CLEAR_CHARTS,
  SET_SELECTED_TOPIC,
  SET_SELECTED_KPI_TYPE,
  SET_BASELINE_VALUE,
  SET_TARGETLINE_VALUE,
  SET_KPI_DRAWER_STATE,
  SET_SUGGESTED_DATA,
  SET_FRAMEWORKS,
  SET_AUTOFILL,
  SET_FILE_VALIDATION,
  SET_FILE_ERROR,
  SET_FILE_ERROR_MESSAGE,
  HAS_PUBLIS_ERROR,
  SET_OVERLAPPING_FILES,
  SET_PUBLISH_STATUS,
  SET_GENERAL_TAB_DATA,
  SET_GENERAL_TAB_REPORT_DATA,
  SET_SELECTED_ASSESSMENT,
  SET_GENERAL_TAB_TASK_DATA,
  SET_CHART_DATA,
  SET_SELECTED_TAB_CHART_DATA,
  SET_INACTIVE_CHART_DATA,
  SET_NEW_LAYOUT,
} from "../../constants/datahubConst/datahubConst";

const initial_state = {
  importFileList: [],
  functionalTags: [],
  locationTags: [],
  valueChainTags: [],
  fileProcessingProgress: 0,
  importFileData: [],
  calculatedData: {},
  charts: [],
  dataProcessingStatus: {},
  selectedTopic: null,
  selectedKpiType: "BRSR",
  kpiCubeCharts: [],
  baselineValue: 0,
  targetlineValue: 0,
  isKPiDrawerOpen: false,
  frameworks: [],
  suggestedData: [],
  autoFill: false,
  isFileValid: false,
  hasFileError: false,
  errorMessage: "",
  hasPublisError: false,
  overlappingFiles: {},
  publishFileStatus: {},
  generalTabData: {},
  reportDetails: {},
  selectedAssessment: false,
  taskGeneralTab: [],
  createChart: "",
  selectedTabChartsData: [],
  inactiveCharts: [],
  newLayout: {},
};

export default function dataHubReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_IMPORT_FILE_LIST:
      return { ...state, importFileList: action.payload };
    case SET_IMPORT_FILE_DATA:
      return { ...state, importFileData: action.payload };
    case SET_FILE_PROCESSING_PROGRESS:
      return { ...state, fileProcessingProgress: action.payload };
    case SET_FUNCTIONAL_TAGS:
      return { ...state, functionalTags: action.payload };
    case SET_LOCATION_TAGS:
      return { ...state, locationTags: action.payload };
    case SET_VALUE_CHAIN_TAGS:
      return { ...state, valueChainTags: action.payload };
    case SET_CALCULATED_DATA:
      return { ...state, calculatedData: action.payload };
    case SET_DATA_HUB_CHARTS:
      return { ...state, charts: action.payload };
    case SET_DATA_PROCESSING_STATUS:
      let dataProcessingStatus = state.dataProcessingStatus;
      dataProcessingStatus[action.payload.id] = action.payload.status;
      return { ...state, dataProcessingStatus: dataProcessingStatus };
    case SET_ALL_FILE_PROCESSING_STATUS:
      return { ...state, dataProcessingStatus: action.payload };
    case SET_CLEAR_CHARTS:
      return { ...state, charts: [] };
    case SET_SELECTED_TOPIC:
      return { ...state, selectedTopic: action.payload };
    case SET_SELECTED_KPI_TYPE:
      return { ...state, selectedKpiType: action.payload };
    case SET_BASELINE_VALUE:
      return { ...state, baselineValue: action.payload };
    case SET_TARGETLINE_VALUE:
      return { ...state, targetlineValue: action.payload };
    case SET_KPI_DRAWER_STATE:
      return { ...state, isKPiDrawerOpen: action.payload };
    case SET_FRAMEWORKS:
      return { ...state, frameworks: action.payload };
    case SET_SUGGESTED_DATA:
      return { ...state, suggestedData: action.payload };
    case SET_AUTOFILL:
      return { ...state, autoFill: action.payload };
    case SET_FILE_VALIDATION:
      return { ...state, isFileValid: action.payload };
    case SET_FILE_ERROR:
      return { ...state, hasFileError: action.payload };
    case SET_FILE_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case SET_OVERLAPPING_FILES:
      return { ...state, overlappingFiles: action.payload };
    case HAS_PUBLIS_ERROR:
      return { ...state, hasPublisError: action.payload };
    case SET_PUBLISH_STATUS:
      return { ...state, publishFileStatus: action.payload };
    case SET_GENERAL_TAB_DATA:
      return { ...state, generalTabData: action.payload };

    case SET_GENERAL_TAB_REPORT_DATA:
      return { ...state, reportDetails: action.payload };
    case SET_SELECTED_ASSESSMENT:
      return { ...state, selectedAssessment: action.payload };
    case SET_GENERAL_TAB_TASK_DATA:
      return { ...state, taskGeneralTab: action.payload };
    case SET_CHART_DATA:
      return {
        ...state,
        createChart: { ...state.createChart, ...action.payload },
      };
    case SET_SELECTED_TAB_CHART_DATA:
      return { ...state, selectedTabChartsData: action.payload };
    case SET_INACTIVE_CHART_DATA:
      return { ...state, inactiveCharts: action.payload };
    case SET_NEW_LAYOUT:
      return { ...state, newLayout: action.payload };
    default:
      return state;
  }
}
