/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import CustomTextfield from "../../../UI/textfield/textfield";
import calculateFields from "./calculateFields";

function TypeThreeCalculation(props) {
  const { suggestedData, autoFill } = useSelector((state) => state.dataHub);

  const {
    typeThreeData,
    colIndex,
    validation,
    classes,
    rowIndex,
    handleChange,
    setIsUpsertMetricData,
    isUpsertMetricData,
    calculate,
  } = props;

  React.useEffect(() => {
    setTimeout(() => {
      calculateFields(
        calculate,
        colIndex,
        rowIndex,
        typeThreeData,
        (event, row_index, col_index) => {
          if (
            parseInt(event.target.value) !== 0 &&
            !isNaN(event.target.value) &&
            typeThreeData[rowIndex].columns[colIndex].data !==
              event.target.value
          ) {
            handleChange(event, row_index, col_index);
          }
        }
      );
    }, 100);
  }, [typeThreeData]);

  React.useEffect(() => {
    if (
      validation &&
      validation.type === "number" &&
      typeThreeData[rowIndex].columns[colIndex].data !== ""
    ) {
      setTimeout(() => {
        let data = typeThreeData[rowIndex].columns[colIndex].data;
        const res = typeof data === "string" ? data.split(".") : null;
        if (res && res.length > 1 && res[1].length > 2) {
          const maindata = parseFloat(
            typeThreeData[rowIndex].columns[colIndex].data
          ).toFixed(2);
          if (
            maindata !== typeThreeData[rowIndex].columns[colIndex].data &&
            !isNaN(maindata)
          ) {
            handleChange(
              { target: { value: parseFloat(maindata) } },
              rowIndex,
              colIndex
            );
          }
        }
      }, 50);
    }
  }, [typeThreeData]);

  const handleAutofilleValue = (columnName, rowName, rowIndex, colIndex) => {
    const filterColumn = suggestedData.filter(
      (c) => c.column_name === columnName
    );

    const filterRow =
      filterColumn?.length > 0 &&
      filterColumn[0]?.data.find((r) => r.row === rowName);

    const handleTotal = filterRow ? filterRow?.total : "";

    handleChange(handleTotal, rowIndex, colIndex, true);
  };

  useEffect(() => {
    autoFill &&
      handleAutofilleValue(
        typeThreeData[rowIndex].columns[colIndex].column,
        typeThreeData[rowIndex].row,
        rowIndex,
        colIndex
      );
    if (autoFill && !isUpsertMetricData) {
      setIsUpsertMetricData(true);
    }
  }, [autoFill]);

  return (
    <TableCell align="csnter">
      {Boolean(
        typeThreeData[rowIndex].columns[colIndex].column ===
          "Current Financial Year - Remarks" ||
          typeThreeData[rowIndex].columns[colIndex].column ===
            "Previous Financial Year - Remarks"
      ) ? (
        <CustomTextfield
          type="text"
          name={`Text_type3${colIndex}`}
          size="small"
          variant="outlined"
          className={classes.CustomTextfield}
          value={typeThreeData[rowIndex].columns[colIndex].data}
          onChange={(e) => {
            handleChange(e, rowIndex, colIndex);
            if (!isUpsertMetricData) {
              setIsUpsertMetricData(true);
            }
          }}
        />
      ) : (
        <CustomTextfield
          type={!validation ? "text" : validation.type}
          onWheel={(e) => e.target.blur()}
          name={`Text_type3${colIndex}`}
          size="small"
          variant="outlined"
          className={classes.CustomTextfield}
          value={typeThreeData[rowIndex].columns[colIndex].data}
          onChange={(e) => {
            handleChange(e, rowIndex, colIndex, false);
            if (!isUpsertMetricData) {
              setIsUpsertMetricData(true);
            }
          }}
        />
      )}
    </TableCell>
  );
}

export default TypeThreeCalculation;
