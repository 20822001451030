import { Typography } from "@material-ui/core";
import CustomTextfield from "../../../../UI/textfield/textfield";
import { useDispatch } from "react-redux";

const BasicData = ({
  chartTitle,
  setChartTitle,
  setChartDescription,
  chartDescription,
}) => {
  const dispatch = useDispatch();
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
        All the basic details of a the chart shall be entered here.
      </Typography>
      <CustomTextfield
        name="SusTeamMsg"
        variant="outlined"
        fullWidth
        size="small"
        label="Chart Name"
        value={chartTitle}
        onChange={(e) => {
          setChartTitle(e.target.value);
        }}
      />
      {/* <div
          style={{
            border: "1px solid #E9E9E9",
            backgroundColor: "inherit",
            borderRadius: 8,
            padding: 8,
            marginTop: 8,
          }}
        >
          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
            All the basic details of a the chart shall be entered here.
          </Typography>
        </div> */}
      <CustomTextfield
        name="SusTeamMsg"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        size="small"
        label="Descriprion of the chart"
        value={chartDescription}
        onChange={(e) => {
          setChartDescription(e.target.value);
        }}
        classes={{
          root: {
            label: {
              fontSize: 10,
            },
          },
        }}
      />
    </div>
  );
};
export default BasicData;
