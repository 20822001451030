// import React from "react";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import ChartRenderer from "../component/ChartRenderer";
// import Dashboard from "../component/Dashboard";
// import DashboardItem from "../component/DashboardItem";

// const DashboardPage = ({ charts, dataStream_id }) => {
// const dashboardItems = [
//   {
//     id: 0,
//     name: "Total waste Recycled(in metric tonne) ",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Recycling.Recyled_value"],
//         order: {
//           "Recycling.Recyled_value": "desc",
//         },
//         dimensions: ["Recycling.Recycled_quantity"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Recycling.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "bar",
//     },
//   },

//   {
//     id: 1,
//     name: "Percentage of Female Board of Directors",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Percentage_Female_BOD.femaleBOD_value"],
//         order: {
//           "Percentage_Female_BOD.femaleBOD_value": "desc",
//         },
//         dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Percentage_Female_BOD.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "pie",
//     },
//   },
//   {
//     id: 2,
//     name: "Waste generated",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Total_Waste.total_waste_generated"],
//         order: {
//           "Total_Waste.total_waste_generated": "desc",
//         },
//         dimensions: ["Total_Waste.total_waste"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Total_Waste.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "bar",
//     },
//   },
//   {
//     id: 3,
//     name: "Total Energy Generated",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Total_Energy.Total_Energy"],
//         order: {
//           "Total_Energy.Total_Energy": "desc",
//         },
//         dimensions: ["Total_Energy.Energy"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Total_Energy.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "pie",
//     },
//   },
//   {
//     id: 4,
//     name: "Emission Intensity",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Total_Emission.Total_Emission"],
//         order: {
//           "Total_Emission.Total_Emission": "desc",
//         },
//         dimensions: ["Total_Emission.Emission"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Total_Emission.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "bar",
//     },
//   },
//   {
//     id: 5,
//     name: "Total Energy Consumed (in joules or multipls)",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
//         order: {
//           "Total_Energy_Consumption.Total_Energy_Consumption": "desc",
//         },
//         dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Total_Energy_Consumption.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "pie",
//     },
//   },
//   {
//     id: 6,
//     name: "Rehabilitation and Resettlement",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Rehabilitated.Total_Rehabilitated"],
//         order: {
//           "Rehabilitated.Total_Rehabilitated": "desc",
//         },
//         dimensions: ["Rehabilitated.Rehabilitated"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Rehabilitated.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "pie",
//     },
//   },
//   {
//     id: 7,
//     name: "Minimum Wages Paid",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Average_Wages.Total_Wages"],
//         order: {
//           "Average_Wages.Total_Wages": "desc",
//         },
//         dimensions: ["Average_Wages.Wages"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Average_Wages.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "bar",
//     },
//   },
//   {
//     id: 8,
//     name: "Total Waste Genrated",
//     vizState: {
//       query: {
//         limit: 5000,
//         measures: ["Total_Waste_Generated_for_recycling.Total_Waste_Generated"],
//         order: {
//           "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc",
//         },
//         dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
//         timeDimensions: [],
//         filters: [
//           {
//             member: "Total_Waste_Generated_for_recycling.datastreamid",
//             operator: "equals",
//             values: [dataStream_id],
//           },
//         ],
//       },
//       chartType: "bar",
//     },
//   },
// ];

//   const Empty = () => (
//     <div
//       style={{
//         textAlign: "center",
//         padding: 12,
//       }}
//     >
//       <Typography variant="h5" color="inherit">
//         There are no charts on this dashboard. Use Playground Build to add one.
//       </Typography>
//     </div>
//   );
//   return dashboardItems.length ? (
//     <div
//       style={{
//         display: "flex",
//         flexWrap: "wrap",
//         width: "100%",
//         flexDirection: "row",
//         justifyContent: "space-evenly",
//       }}
//     >
//       {dashboardItems.map((item, index) => (
//         <DashboardItem title={item.name} type={item.vizState}>
//           <ChartRenderer vizState={item.vizState} />
//         </DashboardItem>
//       ))}
//     </div>
//   ) : (
//     <Empty />
//   );
// };
// export default DashboardPage;

// old code;

// new code

import React from "react";

import ChartRenderer from "../component/ChartRenderer";
import Dashboard from "../component/Dashboard";
import DashboardItem from "../component/DashboardItem";

const defaultLayout = (i) => ({
  x: i?.layout?.x || 0,
  y: i?.layout?.y || 0,
  w: i?.layout?.w || 4,
  h: i?.layout?.h || 8,
  minW: 4,
  minH: 8,
});

const DashboardPage = ({
  dataStream_id,
  dashboardCharts,
  updateDashboardLayout,
  selectedOrganization,
  token,
  open,
  deleteDataHubChart,
}) => {
  const dashboardItems = [
    {
      id: 100,
      name: "Total waste Recycled(in metric tonne) ",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Recycling.Recyled_value"],
          order: {
            "Recycling.Recyled_value": "desc",
          },
          dimensions: ["Recycling.Recycled_quantity"],
          timeDimensions: [],
          filters: [
            {
              member: "Recycling.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "bar",
      },
      layout: {
        x: 0,
        y: 16,
        w: 6,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Recycling.Recycled_quantity"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 1,
      name: "Percentage of Female Board of Directors",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Percentage_Female_BOD.femaleBOD_value"],
          order: {
            "Percentage_Female_BOD.femaleBOD_value": "desc",
          },
          dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
          timeDimensions: [],
          filters: [
            {
              member: "Percentage_Female_BOD.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "pie",
      },
      layout: {
        x: 0,
        y: 8,
        w: 4,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Percentage_Female_BOD.percentageoffemaleBOD"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 2,
      name: "Waste generated",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Total_Waste.total_waste_generated"],
          order: {
            "Total_Waste.total_waste_generated": "desc",
          },
          dimensions: ["Total_Waste.total_waste"],
          timeDimensions: [],
          filters: [
            {
              member: "Total_Waste.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "bar",
      },
      layout: {
        x: 0,
        y: 0,
        w: 12,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Total_Waste.total_waste"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 3,
      name: "Total Energy Generated",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Total_Energy.Total_Energy"],
          order: {
            "Total_Energy.Total_Energy": "desc",
          },
          dimensions: ["Total_Energy.Energy"],
          timeDimensions: [],
          filters: [
            {
              member: "Total_Energy.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "pie",
      },
      layout: {
        x: 8,
        y: 8,
        w: 4,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Total_Energy.Energy"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 4,
      name: "Emission Intensity",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Total_Emission.Total_Emission"],
          order: {
            "Total_Emission.Total_Emission": "desc",
          },
          dimensions: ["Total_Emission.Emission"],
          timeDimensions: [],
          filters: [
            {
              member: "Total_Emission.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "bar",
      },
      layout: {
        x: 6,
        y: 16,
        w: 6,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Total_Emission.Emission"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 5,
      name: "Total Energy Consumed (in joules or multiples)",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
          order: {
            "Total_Energy_Consumption.Total_Energy_Consumption": "desc",
          },
          dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
          timeDimensions: [],
          filters: [
            {
              member: "Total_Energy_Consumption.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "pie",
      },
      layout: {
        x: 4,
        y: 8,
        w: 4,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Total_Energy_Consumption.Energy_Consumption"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 6,
      name: "Rehabilitation and Resettlement",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Rehabilitated.Total_Rehabilitated"],
          order: {
            "Rehabilitated.Total_Rehabilitated": "desc",
          },
          dimensions: ["Rehabilitated.Rehabilitated"],
          timeDimensions: [],
          filters: [
            {
              member: "Rehabilitated.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "pie",
      },
      layout: {
        x: 0,
        y: 24,
        w: 4,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Rehabilitated.Rehabilitated"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 7,
      name: "Minimum Wages Paid",
      vizState: {
        query: {
          limit: 5000,
          measures: ["Average_Wages.Total_Wages"],
          order: {
            "Average_Wages.Total_Wages": "desc",
          },
          dimensions: ["Average_Wages.Wages"],
          timeDimensions: [],
          filters: [
            {
              member: "Average_Wages.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "bar",
      },
      layout: {
        x: 4,
        y: 24,
        w: 8,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Average_Wages.Wages"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
    {
      id: 8,
      name: "Total Waste Genrated",
      vizState: {
        query: {
          limit: 5000,
          measures: [
            "Total_Waste_Generated_for_recycling.Total_Waste_Generated",
          ],
          order: {
            "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc",
          },
          dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
          timeDimensions: [],
          filters: [
            {
              member: "Total_Waste_Generated_for_recycling.datastreamid",
              operator: "equals",
              values: [dataStream_id],
            },
          ],
        },
        chartType: "bar",
      },
      layout: {
        x: 0,
        y: 32,
        w: 12,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: {
        x: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
        y: ["measures"],
        fillMissingDates: true,
        joinDateRange: false,
      },
    },
  ];

  const dashboard_datahub_chart = dataStream_id
    ? dashboardItems
    : dashboardCharts;

  const dashboardItem = (item) => {
 
    return (
      <div key={item.id} data-grid={defaultLayout(item)}>
        <DashboardItem
          key={item.id}
          deleteDataHubChart={deleteDataHubChart}
          itemId={item.id}
          title={item.name}
          token={token}
          id={item.id}
          selectedOrganization={selectedOrganization}
        >
          {open === false && (
            <ChartRenderer
              vizState={item.vizState}
              pivotConfig={item?.pivotConfig}
              backgroundColor={item?.chartColors?.backgroundColors}
              borderColor={item?.chartColors?.borderColor}
              chartX_AxisCustomize={item?.chartColors?.chartX_AxisCustomize}
              chartY_AxisCustomize={item?.chartColors?.chartY_AxisCustomize}
            />
          )}
        </DashboardItem>
      </div>
    );
  };

  const Empty = () => (
    <div
      style={{
        textAlign: "center",
        padding: 12,
      }}
    >
      <h2>There are no charts on this dashboard</h2>
    </div>
  );

  return dashboard_datahub_chart.length ? (
    <div style={{ padding: "20px" }}>
      <Dashboard
        token={token}
        dataStream_id={dataStream_id}
        selectedOrganization={selectedOrganization}
        dashboardItems={dashboard_datahub_chart}
        updateDashboardLayout={updateDashboardLayout}
      >
        {dashboard_datahub_chart.map(dashboardItem)}
      </Dashboard>
    </div>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
