import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CustomButton from "../../../UI/button/button";

import CustomTextfield from "../../../UI/textfield/textfield";

import PublishRoundedIcon from "@material-ui/icons/PublishRounded";

import GetApp from "@material-ui/icons/GetApp";

import { brmRightStyles } from "./brmRightStyle";

import { useDispatch, useSelector } from "react-redux";
import { addSupplierStakeHolder } from "../../../../actions/brm/BrmActions";
// import { addMember } from "../../../../actions/member/member";

const stakeHolderCategoryList = [
  "Board of Directors",
  "Executive Team",
  "Employees",
  "Investors & Shareholders",
  "Suppliers & Vendors",
  "Consultants",
  "Customers",
  "Government Agencies",
];

export default function UserDialog({
  addNewUserDialogOpen,
  handleCloseUserDialog,
}) {
  const classes = brmRightStyles();
  const dispatch = useDispatch();

  const { brmReducer, login } = useSelector((state) => state);

  // const { token, bankId } = useSelector((state) => state?.login);
  // const { organizationDetails } = useSelector((state) => state?.brmReducer);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [role, setRole] = useState("");
  const [emailError, setEmailError] = useState(false);
  const type = "";

  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      // uploadUserSheet(login.token, login.current_organisation, file);
      handleCloseUserDialog();
    }
  };


  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
  };

  function handleAddUser() {
    dispatch(
      addSupplierStakeHolder(
        login?.token,
        brmReducer?.supplierOrganizationDetails?._id || login?.supplier_id,
        login?.selectedOrganization?._id,
        name,
        email,
        role,
        category,
        login?.selectedOrganization?.company_name
      )
    );
    handleCloseUserDialog();
  }

  return (
    <Dialog open={addNewUserDialogOpen} maxWidth="md">
      <DialogTitle>
        <Grid container justifyContent="center" className={classes.mainGrid}>
          <Grid item xs={3}>
            <Typography className={classes.Typography3}>
              Add Stakeholders
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          className={classes.grid2}
        >
          <Grid item md={12} className={classes.grid3}>
            <>
              <CustomButton
                name="uploadTemp"
                startIcon={<PublishRoundedIcon />}
                className={classes.CustomButton2}
                variant="outlined"
                color="secondary"
                component="label"
              >
                Upload Excel
                <input
                  id="excel-upload"
                  type="file"
                  hidden
                  cursor="pointer"
                  onChange={(e) => uploadHandler(e)}
                />
              </CustomButton>
              <a
                download
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
              >
                <CustomButton
                  name="uploadTemp"
                  startIcon={<GetApp />}
                  className={classes.CustomButton2}
                  variant="contained"
                  size="small"
                  color="secondary"
                  component="label"
                >
                  Download Excel
                </CustomButton>
              </a>
            </>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.grid2}>
          <Grid item xs={12} md={6} className={classes.grid4}>
            <CustomTextfield
              name="userName"
              label="Name"
              variant="outlined"
              size="small"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid5}>
            <CustomTextfield
              name="userRole"
              label="Role"
              variant="outlined"
              size="small"
              fullWidth
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={6} className={classes.grid4}>
            <CustomTextfield
              name="userEmail"
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError ? "Invalid email format" : ""}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid5}>
            <CustomTextfield
              name="userCategory"
              label="Category"
              variant="outlined"
              size="small"
              fullWidth
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {stakeHolderCategoryList.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </CustomTextfield>
          </Grid>

          <Grid item xs={12} md={6} className={classes.grid4}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} className={classes.grid7}>
          <Grid item xs={3} md={6} className={classes.grid3}>
            <CustomButton
              name="cancelBtn"
              color="primary"
              variant="outlined"
              onClick={() => handleCloseUserDialog()}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item xs={5} md={6} className={classes.grid6}>
            <CustomButton
              name="addNewBtn"
              color="primary"
              variant="contained"
              className={classes.CustomButton3}
              disabled={
                name === "" ||
                email === "" ||
                category === "" ||
                role === "" ||
                emailError
              }
              onClick={handleAddUser}
            >
              Add New User
            </CustomButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
