import React from "react";



import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import TopicCards from "./TopicCard";
import DataSetsCardTitle from "../DataSetsCardTitle";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBF1F8",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBF1F8",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EBF1F8",
    },
  },
});

function DataSetLeft() {
  const classes = useStyles();

  return (
    <Paper
      elevation={3}
      style={{
        backgroundColor: "#FEFEFE",
        height: "100%",
      }}
    >
      <DataSetsCardTitle title={"Categories/ Topics"} />

      <Box
        style={{
          padding: "10px",
        }}
      >
        <TextField
          className={classes.root}
          size="small"
          type="text"
          label={
            <Typography
              style={{ color: "#3374B9", fontSize: "18px", marginTop: "-5px" }}
            >
              Search
            </Typography>
          }
          color="primary"
          variant="outlined"
          fullWidth={true}
          // value={""}
          // onChange={(e) => onSearchHandler(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#3374B9", fontSize: "30px" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box style={{ height: "75%", overflow: "scroll" }}>
        <TopicCards />
      </Box>
    </Paper>
  );
}

export default DataSetLeft;
