import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
  },
});

function DataHubTabs(props) {
  const { value, tabs = [] } = props;

  const tab = tabs.map((t) => {
    return (
      <Tab
        style={{
          backgroundColor: value === t.value ? "#FFFFFF" : "transparent",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          borderRadius: "5px 5px 0px 0px",
          textTransform: "capitalize",
        }}
        label={<span>{t.tab}</span>}
        name={t.tab}
      />
    );
  });

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar
        position="static"
        color="inherit"
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <Tabs
          value={value}
          onChange={props.handleTabs}
          aria-label="simple tabs example"
        >
          {tab}
        </Tabs>
      </AppBar>
    </MuiThemeProvider>
  );
}

export default React.memo(DataHubTabs);
