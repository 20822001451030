// import encrypt from "../../../redux/actions/shared/sharedActions";
// import UNIVERSAL from "../../../config";
import {
  SET_SELECTED_SECTION,
  SET_TASK_TYPE,
  SET_TASK_ASSIGNED_ORGANIZATIONS,
  SET_TASK_ASSIGNED_SURVEY,
  SET_TASK_SELECTED_ASSIGNED_SURVEY,
  SET_SELECTED_TASK_QUESTION,
  SET_SELECTED_QUESTION_REDO,
  SET_SURVEY_CHAT,
  SET_CONTRIBUTED_QUESTION_DETAILS,
  SET_QUESTION_COMMENT,
  SET_SURVEY_CHAT_MEMBERS,
  SET_MAIN_QUESTION_COMMENT,
  SET_ORIGINAL_SELECTED_TASK_QUESTION,
  SET_TASK_ORGS_TOTAL,
} from "../../constants/task/taskConstants";
import {
  selectSection1,
  selectSection2,
} from "../../components/screen/taskScreen/taskTemp";
import { set_loader, unset_loader } from "../loader/loader_action";
import UNIVERSAL from "../../config/config";
// import {
//   getCompanyInsights,
//   getControversyData,
//   setCompanyInsights,
// } from "../riskEvaluator/riskEvaluatorActions";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setSurveyTableDocs } from "actions/surveyListing/surveyListingAction";

export const setSelectedTaskQuestion = (payload) => {
  return {
    type: SET_SELECTED_TASK_QUESTION,
    payload,
  };
};
export const setOriginalSelectedTaskQuestion = (payload) => {
  return {
    type: SET_ORIGINAL_SELECTED_TASK_QUESTION,
    payload,
  };
};

export const setSelectedSection = (payload) => {
  return {
    type: SET_SELECTED_SECTION,
    payload,
  };
};

export const setTaskType = (payload) => {
  return {
    type: SET_TASK_TYPE,
    payload,
  };
};

export const setTaskAssignedOrganizations = (payload) => {
  return {
    type: SET_TASK_ASSIGNED_ORGANIZATIONS,
    payload,
  };
};

export const setTaskOrgsTotal = (payload) => {
  return {
    type: SET_TASK_ORGS_TOTAL,
    payload,
  };
};

export const setTaskAssignedSurvey = (payload) => {
  return {
    type: SET_TASK_ASSIGNED_SURVEY,
    payload,
  };
};

export const setTaskSelectedAssignSurvey = (payload) => {
  return {
    type: SET_TASK_SELECTED_ASSIGNED_SURVEY,
    payload,
  };
};

export const setSelectedQuestionRedo = (payload) => {
  return {
    type: SET_SELECTED_QUESTION_REDO,
    payload,
  };
};

export const setSurveyChat = (payload) => {
  return {
    type: SET_SURVEY_CHAT,
    payload,
  };
};

export const setSurveyChatMembers = (payload) => {
  return {
    type: SET_SURVEY_CHAT_MEMBERS,
    payload,
  };
};

export const setContributedQuestionDetails = (payload) => {
  return {
    type: SET_CONTRIBUTED_QUESTION_DETAILS,
    payload,
  };
};

export const setQuestionComment = (payload) => {
  return {
    type: SET_QUESTION_COMMENT,
    payload,
  };
};
export const setMainQuestionComment = (payload) => {
  return {
    type: SET_MAIN_QUESTION_COMMENT,
    payload,
  };
};

// -------------------------------------------------------------------
// export const SetSelectedSection = (_id, task_type) => {
//   return (dispatch) => {
//     dispatch(set_loader);

//     dispatch(setTaskType(task_type));

//     if (_id === "65dc3ba9b0dc39a9437051aa3") {
//       dispatch(setSelectedSection(selectSection1));
//     } else if (_id === "65dc3ba9b0dc39a9437051aa4") {
//       dispatch(setSelectedSection(selectSection2));
//     }

//     dispatch(unset_loader);
//   };
// };

// -------------------------------------------------------
// function for uploading all docs for table type survey
export const uploadAllTableDocs = (tableData, tableTypeDocs) => {
  return async (dispatch, getState) => {
    dispatch(set_loader());

    // Getting token, supplier id, and selected task organization
    const { token, supplier_id } = getState().login;
    const organization_id = getState().supplierAdmin.selectedCorporate._id;

    // Helper function to upload a single file
    const uploadFile = async (fileData) => {
      const formData = new FormData();
      const data = encrypt({
        "user-token": token,
        supplier_id,
        organization_id,
      });

      formData.append("data", data);
      formData.append("file", fileData);

      try {
        const response = await fetch(
          UNIVERSAL.BASEURL + "/upload_file/upload_file",
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();
        if (result.status) {
          return result.result;
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error(`Failed to upload file:`, error);
        return null;
      }
    };

    // Create an array of promises for file uploads
    const uploadPromises = tableTypeDocs.map(({ fileData }) =>
      uploadFile(fileData)
    );

    try {
      // Wait for all uploads to complete
      const uploadResults = await Promise.all(uploadPromises);

      // Create a deep copy of tableData to avoid mutating the original data
      const updatedTableData = tableData.map((row) =>
        row.map((cell) => ({ ...cell }))
      );

      // Update tableData with the uploaded files
      tableTypeDocs.forEach(({ rowIdx, colIdx }, index) => {
        const uploadResult = uploadResults[index];
        if (
          uploadResult &&
          updatedTableData[rowIdx] &&
          updatedTableData[rowIdx][colIdx]
        ) {
          updatedTableData[rowIdx][colIdx].data = uploadResult;
        } else {
          console.warn(
            `Invalid indices or upload result: rowIdx=${rowIdx}, colIdx=${colIdx}, uploadResult=${uploadResult}`
          );
        }
      });

      // Log the URLs of the uploaded files
      // console.log("TableData: ", tableData);
      // console.log("TableTypeDocs: ", tableTypeDocs);
      // console.log("Updated UploadResults: ", uploadResults);
      // console.log("Updated TableData: ", updatedTableData);

      // reset table docs
      dispatch(setSurveyTableDocs([]));

      dispatch(unset_loader());

      return updatedTableData;
    } catch (error) {
      console.error("Error uploading files:", error);
      dispatch(unset_loader());
      return tableData; // Return the original tableData in case of error
    }
  };
};

// -------------------------------------------------------

export const UpdateQuestionOption = (ques_id, selectedOpt, survey_id) => {
  console.log(ques_id, survey_id, selectedOpt);

  return async (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const { selectedSection, selectedTaskQuestion, selectedQuestionRedo } =
        getState().taskReducer;

      const surveyToUpdate = selectedSection.some(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        const questionToUpdate = selectedTaskQuestion?.questions.some(
          (question) => question.question_id === ques_id
        );

        if (questionToUpdate) {
          if (selectedQuestionRedo) {
            dispatch(
              setSelectedQuestionRedo({
                ...selectedQuestionRedo,
                submitted_data: selectedOpt,
              })
            );
            const updatedQuestion = selectedTaskQuestion?.questions.map(
              (question) =>
                question.question_id === ques_id
                  ? { ...question, submitted_data: selectedOpt }
                  : question
            );

            // questionToUpdate.submitted_data = selectedOpt;
            // Dispatch action to update selectedSection
            dispatch(
              setSelectedTaskQuestion({
                ...selectedTaskQuestion,
                questions: updatedQuestion,
              })
            );
          } else {
            // Update the selectedOpt of the question
            const updatedQuestion = selectedTaskQuestion?.questions.map(
              (question) =>
                question.question_id === ques_id
                  ? { ...question, submitted_data: selectedOpt }
                  : question
            );
            console.log(updatedQuestion, " -----------");

            // questionToUpdate.submitted_data = selectedOpt;
            // Dispatch action to update selectedSection
            dispatch(
              setSelectedTaskQuestion({
                ...selectedTaskQuestion,
                questions: updatedQuestion,
              })
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export const UpdateQuestionCommentValue = (
  ques_id,
  comment_value,
  survey_id
) => {
  console.log("for comments: ", ques_id, survey_id, comment_value);

  return async (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const { selectedSection, selectedTaskQuestion, selectedQuestionRedo } =
        getState().taskReducer;

      const surveyToUpdate = selectedSection.some(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        const questionToUpdate = selectedTaskQuestion?.questions.some(
          (question) => question.question_id === ques_id
        );

        if (questionToUpdate) {
          if (selectedQuestionRedo) {
            dispatch(
              setSelectedQuestionRedo({
                ...selectedQuestionRedo,
                comment_value: comment_value,
              })
            );
            const updatedQuestion = selectedTaskQuestion?.questions.map(
              (question) =>
                question.question_id === ques_id
                  ? { ...question, comment_value: comment_value }
                  : question
            );

            // questionToUpdate.submitted_data = selectedOpt;
            // Dispatch action to update selectedSection
            dispatch(
              setSelectedTaskQuestion({
                ...selectedTaskQuestion,
                questions: updatedQuestion,
              })
            );
          } else {
            // Update the selectedOpt of the question
            const updatedQuestion = selectedTaskQuestion?.questions.map(
              (question) =>
                question.question_id === ques_id
                  ? { ...question, comment_value: comment_value }
                  : question
            );

            // questionToUpdate.submitted_data = selectedOpt;
            // Dispatch action to update selectedSection
            dispatch(
              setSelectedTaskQuestion({
                ...selectedTaskQuestion,
                questions: updatedQuestion,
              })
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export const UpdateSurveyComplete = (type, survey_id) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const selectedSection = getState().taskReducer.selectedSection;

      const surveyToUpdate = selectedSection.find(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        if (type === "add") {
          surveyToUpdate.isSurveyComplete = true;
        } else {
          surveyToUpdate.isSurveyComplete = false;
          surveyToUpdate.questions.forEach((question) => {
            question.selectedOpt = "";
          });
        }
        dispatch(setSelectedSection(selectedSection));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export const UpdateSelectedQuestion = (ques_id, survey_id, type) => {
  return (dispatch, getState) => {
    dispatch(set_loader);
    const { selectedSection, selectedTaskQuestion } = getState().taskReducer;

    try {
      if (type === "add") {
        const selectedSurvey = selectedSection.some(
          (survey) => survey._id === survey_id
        );

        if (selectedSurvey) {
          const selectedQuestion = selectedTaskQuestion?.questions.find(
            (question) => question.question_id === ques_id
          );

          if (selectedQuestion) {
            dispatch(setSelectedQuestionRedo(selectedQuestion));
          }
        }
      } else {
        dispatch(setSelectedQuestionRedo(null));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

//----------------------------new----------------//

export function getAssignOrganizationsTask(
  token,
  supplier_id,
  keyword = "",
  limit,
  starting_after
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      keyword,
      limit,
      starting_after,
    });
    return fetch(UNIVERSAL.BASEURL + "/supplier/get_assigned_organizations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setCorporates(responseJson?.result));
          dispatch(setTaskAssignedOrganizations(responseJson?.result));
          dispatch(setTaskOrgsTotal(responseJson?.total));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getTaskAssignedSurveys(token, supplier_id, organization_id) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_assigned_surveys", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setTaskAssignedSurvey(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getTaskAssignedSections(
  token,
  supplier_id,
  organization_id,
  survey_id,
  frequency_id,
  version,
  role
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      survey_id,
      frequency_id,
      version,
      role,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_assigned_sections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSelectedSection(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getTaskAssignedQuestions(
  token,
  supplier_id,
  organization_id,
  section_id,
  frequency_id,
  version,
  role
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      section_id,
      frequency_id,
      version,
      role,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_assigned_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSelectedTaskQuestion(responseJson?.result));
          dispatch(setOriginalSelectedTaskQuestion(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function submitAnswerTask(
  token,
  supplier_id,
  organization_id,
  question_id,
  frequency_id,
  answer,
  survey_id,
  section_id,
  commentText
) {
  function isObject(variable) {
    if (variable !== null && typeof variable === "object") {
      // Check if the variable has the 'document' property directly on the object
      return variable.hasOwnProperty("document") || variable instanceof File;
    }
    // Return false if the variable is not an object
    return false;
  }

  function removeDocumentProperty(obj) {
    if (
      obj !== null &&
      typeof obj === "object" &&
      obj.hasOwnProperty("document")
    ) {
      const { document, ...rest } = obj;
      return rest; // Indicate that the property was removed
    }
    return false; // Indicate that the property was not found or not removed
  }

  const hasDocument = isObject(answer);

  console.log(hasDocument, answer, " =-=-=-=-=-=-=-");

  var formData = new FormData();

  let data;

  if (
    hasDocument &&
    (!answer?.document || answer?.document.hasOwnProperty("url") !== true)
  ) {
    let updatedAnswer = removeDocumentProperty(answer);
    data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      question_id,
      frequency_id,
      answer: updatedAnswer,
      survey_id,
      section_id,
      comment_value: commentText,
    });

    formData.append("data", data);
    formData.append("document", answer?.document || answer);
  } else {
    data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      question_id,
      frequency_id,
      answer,
      survey_id,
      section_id,
      comment_value: commentText,
    });
  }

  return (dispatch) => {
    dispatch(set_loader(true));

    return fetch(
      UNIVERSAL.BASEURL + "/survey/submit_answer",
      hasDocument &&
        (!answer?.document || answer?.document.hasOwnProperty("url") !== true)
        ? {
            method: "POST",
            body: formData,
          }
        : {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: data }),
          }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setSelectedTaskQuestion(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getTaskApprovalChat(
  token,
  supplier_id,
  organization_id,
  survey_id,
  frequency_id,
  section_id,
  question_id
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      survey_id,
      section_id,
      question_id,
      frequency_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/supplier_chat/view_chat", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSurveyChat(responseJson?.result?.chat_data));
          dispatch(setSurveyChatMembers(responseJson?.result?.members_data));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getTaskSubmittedQuestions(
  token,
  question_id,
  frequency_id,
  user
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,

      question_id,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_submission_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setContributedQuestionDetails({ ...responseJson?.result, user })
          );
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function approveTaskSubmittedQuestion(
  token,
  question_id,
  frequency_id,
  approved_answer_data,
  selectedSubmissionId,
  getParam,
  supplier_id,
  organization_id
) {
  // console.log(supplier_id, organization_id);

  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      frequency_id,
      question_id,
      approved_answer_data,
      chat_id: selectedSubmissionId,
      supplier_id,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/approve_submit_answer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(
          //   setContributedQuestionDetails({ ...responseJson?.result, user })
          // );
          dispatch(
            getTaskAssignedQuestions(
              getParam?.token,
              getParam?.supplier_id,
              organization_id,
              getParam?.section_id,
              getParam?.frequency_id,
              getParam?.version,
              getParam?.role
            )
          );
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
