import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import YouTube from 'react-youtube';
import Tour from 'reactour';
import CustomButton from '../UI/button/button';
import Accordions from '../UI/accordions/Accordions';

function HelperDrawer({
  activeStep,
  topicTab,
  reportTab,
  setHelperDailogue,
  getHelpFlow,
  updateHelpFlow,
  login
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourContent, setTourContent] = useState(null);
  const [completedStepsArr, setCompletedStepsArr] = useState([]);
  const [currentTourStep, setCurrentTourStep] = useState(0);
  const [faq, setFaq] = useState([]);
  const [current, setCurrent] = useState(0);

  const STEP_1 = 'step1';
  const TOPIC_1 = 'topic1';
  const TOPIC_2 = 'topic2';
  const TOPIC_3 = 'topic3';
  const STEP_3 = 'step3';
  const STEP_4 = 'step4';
  const REPORT_1 = 'report1';
  const REPORT_2 = 'report2';
  const REPORT_3 = 'report3';
  const STEP_6 = 'step6';

  //getting completed steps array on first load

  const completedSteps = JSON.parse(localStorage.getItem('completed_steps'));

  useEffect(() => {
    if (completedSteps.length <= 11) {
      getHelpFlow(login.token);
    }
  }, [activeStep]);

  //checking whether the user completed the step or not
  useEffect(() => {
    if (Array.isArray(completedSteps)) {
      if (activeStep === 0 && !completedSteps.includes(STEP_1)) {
        setIsTourOpen(true);
      }
      if (activeStep === 1) {
        if (topicTab === 0 && !completedSteps.includes(TOPIC_1)) {
          setIsTourOpen(true);
        }
        if (topicTab === 1 && !completedSteps.includes(TOPIC_2)) {
          setIsTourOpen(true);
        }
        if (topicTab === 2 && !completedSteps.includes(TOPIC_3)) {
          setIsTourOpen(true);
        }
      }
      if (activeStep === 2 && !completedSteps.includes(STEP_3)) {
        setIsTourOpen(true);
      }
      if (activeStep === 3 && !completedSteps.includes(STEP_4)) {
        setIsTourOpen(true);
      }
      if (activeStep === 4) {
        if (reportTab === 0 && !completedSteps.includes(REPORT_1)) {
          setIsTourOpen(true);
        }
        if (reportTab === 1 && !completedSteps.includes(REPORT_2)) {
          setIsTourOpen(true);
        }
        if (reportTab === 2 && !completedSteps.includes(REPORT_3)) {
          setIsTourOpen(true);
        }
      }
      if (activeStep === 5 && !completedSteps.includes(STEP_6)) {
        setIsTourOpen(true);
      }
    }
    setCurrent(0);
  }, [activeStep, topicTab, reportTab]);

  const handleClose = () => {
    setIsTourOpen(false);
    setIsDrawerOpen(false);
    setCurrent(0);
    setCurrentTourStep(0);
    if (activeStep === 0) {
      updateHelpFlow(login.token, STEP_1);
    }
    if (activeStep === 1) {
      if (topicTab === 0) {
        updateHelpFlow(login.token, TOPIC_1);
      }
      if (topicTab === 1) {
        updateHelpFlow(login.token, TOPIC_2);
      }
      if (topicTab === 2) {
        updateHelpFlow(login.token, TOPIC_3);
      }
    }
    if (activeStep === 2) {
      updateHelpFlow(login.token, STEP_3);
    }
    if (activeStep === 3) {
      updateHelpFlow(login.token, STEP_4);
    }
    if (activeStep === 4) {
      if (reportTab === 0) {
        updateHelpFlow(login.token, REPORT_1);
      }
      if (reportTab === 1) {
        updateHelpFlow(login.token, REPORT_2);
      }
      if (reportTab === 2) {
        updateHelpFlow(login.token, REPORT_3);
      }
    }
    if (activeStep === 5) {
      updateHelpFlow(login.token, STEP_6);
    }
  };

  const handleTourOpen = () => {
    setIsDrawerOpen(false);

    setIsTourOpen(true);
  };
  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const opts = {
    height: '200px',
    width: '380px',
    playerVars: {
      autoplay: 1
    }
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  useEffect(() => {
    if (activeStep === 0) {
      setTourContent(stepOneTourConfig);
      setFaq(step1Faq);
    }
    if (activeStep === 1 && topicTab === 0) {
      setTourContent(stepTwoFirstTabTourConfig);
      setFaq(step2Faq);
    }
    if (activeStep === 1 && topicTab === 1) {
      setTourContent(stepTwoSecondTabTourConfig);
      setFaq(step2Faq);
    }
    if (activeStep === 1 && topicTab === 2) {
      setTourContent(stepTwoThirdTabTourConfig);
      setFaq(step2Faq);
    }
    if (activeStep === 2) {
      setTourContent(stepThreeTourConfig);
      setFaq(step3Faq);
    }
    if (activeStep === 3) {
      setTourContent(stepFourTourConfig);
      setFaq(step4Faq);
    }
    if (activeStep === 4 && reportTab === 0) {
      setTourContent(stepFiveFirstTabTourConfig);
      setFaq(step5Faq);
    }
    if (activeStep === 4 && reportTab === 1) {
      setTourContent(stepFiveSecondTabTourConfig);
      setFaq(step5Faq);
    }
    if (activeStep === 4 && reportTab === 2) {
      setTourContent(stepFiveThirdTabTourConfig);
      setFaq(step5Faq);
    }
    if (activeStep === 5) {
      setTourContent(stepSixTourConfig);
      setFaq(step6Faq);
    }
  }, [activeStep, topicTab, reportTab]);

  const stepOneTourConfig = [
    {
      selector: '[data-tut="reactour__basicLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Basic Assessment Details
          </Typography>
          <Typography style={{ fontSize: 14, marginTop: 25, color: '#9FA1AF' }}>
            This section has basic information like sustainability messages and
            ESG goals can be captured. We can also add the assessment year and
            the framework preference here.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__basicRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Basic Company Details
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you configure the industry and sector of the
            company, you can choose multiple combinations here. Some information
            like company address and logo and other important information is
            also captured here.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: ({ goTo }) => (
        <div style={{ width: '520px' }}>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__helperBtn"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Help
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section gives you access to help documentation, FAQs,
            Walkthrough guide and videos which help you understand how to use
            all features in respective sections
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__helper_drawer"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: ({ goTo }) => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Help
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section gives you access to help documentation, FAQs,
            Walkthrough guide and videos which help you understand how to use
            all features in respective sections
          </Typography>
        </div>
      )
    }
  ];

  const stepTwoFirstTabTourConfig = [
    {
      selector: '[data-tut="reactour__materialLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Select Topics
          </Typography>

          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has the full list of all the recommended topics
            shortlisted by our intelligent system in correlation to the industry
            and sectors you picked in the previous step. You're free to add more
            custom topics or explore the full list of topics by clicking on the
            recommended topics dropdown. Use the plus icon on each topic to
            shortlist them.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__materialRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Selected Topics
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you edit or update the shortlisted topics that
            need to be sent out to relevant stakeholders.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepTwoSecondTabTourConfig = [
    {
      selector: '[data-tut="reactour__materialLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Stakeholder List
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you create and update your stakeholder list. You
            need to add details like role, designation, type etc. along with the
            email id.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__materialRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Selected Stakeholders
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you see the list of stakeholders with whom you
            have shared the materiality survey. There is also an option to see
            the preview of the survey here.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepTwoThirdTabTourConfig = [
    {
      selector: '[data-tut="reactour__materialLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Analysis
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you finalize the topics and also dig deeper into
            the results of the surveys from the stakeholders. You can see more
            information about each topic by clicking on them.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__materialRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Materiality Map
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you see the materiality map which plots all the
            topics for easy visualization. Below the map the stakeholder list
            and their survey submission statuses are listed.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepThreeTourConfig = [
    {
      selector: '[data-tut="reactour__esgLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Topic Selected
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            the material topics are listed based on the analysis from step 2. In
            this step, we can add additional information against each topic.
            Upon clicking on any topic, we can configure activities and KPI’s
            against them on the right hand side section. To make things easier,
            use the suggestions under activities and KPI’s to speed up your
            configurations.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__esgRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Selected Topic Toggle
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you add activities and KPI’s against each topic.
            Please click the Add Activity or Add KPI button to proceed. You can
            add additional information against each activity and KPI, like
            budget, time period etc. If this information needs to be sourced
            from other users, please use the Assign button on the top-left
            corner of this section to do so.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepFourTourConfig = [
    {
      selector: '[data-tut="reactour__assessmentLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Report View
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This pdf viewer shows all the information from the previous steps
            and might reload a few times according to the changes you make to
            the document. We have provided an option to download the PDF for
            easy access, and furthermore the same can be opened using MS-Word
            for any further customizations.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__assessmentRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Setup Document
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has various options to further customize the PDF based
            on the needs of the user. We have options to add front/back cover,
            logos, header/footer and sharing capabilities.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__addCover"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Add Cover
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has the customization options to add front/back cover,
            logos and text to the PDF doc. Please add all assets and use the
            ‘Apply’ button to add them finally to the PDF doc.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__addHeaderFooter"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Add Header and Footer
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has the customization options to add a header/footer,
            logos and page numbers to the PDF doc. Please add all relevant info
            and use the ‘Apply’ button to add them finally to the PDF doc
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__viewComments"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            View Comments
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you see all the comments added by the users whom
            you have shared the document with. They could be Approvers or
            Reviewers. Comments you add will be visible to them and vice versa.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__share"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Share
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you share the document with the collaborators.
            These collaborators could be Approvers (who can approve/reject or
            comment) or Reviewers (who can only comment).
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepFiveFirstTabTourConfig = [
    {
      selector: '[data-tut="reactour__reportLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Collect Data
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you pick the framework of your choice and see the
            shortlisted topics matched up against the disclosures of that
            particular framework. The disclosure hierarchy can be expanded and
            visualized.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__reportRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Selected Data Input
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you drill down to the actual disclosures and
            sub-disclosures of the framework picked. The assign button lets you
            select and share the disclosures and collect the data from
            collaborators.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepFiveSecondTabTourConfig = [
    {
      selector: '[data-tut="reactour__reportLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Review Data
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you review the disclosures shared with users from
            the previous step. Only the topics that have been shared will be
            visible here and clicking each will load further details on the
            right hand side.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__reportRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Metric Input
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you review all the data points submitted by
            various users you had shared the disclosures with. You can pick from
            the options submitted by the users and edit them if required. You
            can also omit certain disclosures if required and add the reasons to
            the report
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepFiveThirdTabTourConfig = [
    {
      selector: '[data-tut="reactour__reportLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Frezee Data
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you freeze the data collected for the assessments
            into a blockchain. Each time the freeze button is pressed, the
            entire data collected till that point is frozen and saved to the
            blockchain.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__reportRight"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Blockchain Record Data
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you review all data that has been frozen and added
            to the blockchain. The entire history of all the hashes are
            available here and the links can be used to verify the data in the
            blocks.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const stepSixTourConfig = [
    {
      selector: '[data-tut="reactour__sustainabilityLeft"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Report View
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This pdf viewer shows all the information from the previous steps
            and might reload a few times according to the changes you make to
            the document. We have provided an option to download the PDF for
            easy access, and furthermore the same can be opened using MS-Word
            for any further customizations.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__setupDocument"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Setup Document
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has various options to further customize the PDF based
            on the needs of the user. We have options to add front/back cover,
            logos, header/footer and sharing capabilities.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__addCover"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Add Cover
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has the customization options to add front/back cover,
            logos and text to the PDF doc. Please add all assets and use the
            ‘Apply’ button to add them finally to the PDF doc.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__addHeaderFooter"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Add Header and Footer
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section has the customization options to add a header/footer,
            logos and page numbers to the PDF document. Please add all relevant
            info and use the ‘Apply’ button to add them finally to the PDF
            document
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__viewComments"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            View Comments
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you see all the comments added by the users whom
            you have shared the document with. They could be Approvers or
            Reviewers. Comments you add will be visible to them and vice versa.
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__share"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Share
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            This section lets you share the document with the collaborators.
            These collaborators could be Approvers (who can approve/reject or
            comment) or Reviewers (who can only comment).
          </Typography>
        </div>
      )
    },
    {
      selector: '[data-tut="reactour__proceed"]',
      style: {
        minWidth: '560px',
        minHeight: '200px'
      },
      content: () => (
        <div>
          <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
            Proceed To Next Step
          </Typography>
          <Typography style={{ fontSize: 13, marginTop: 25 }}>
            Please click the proceed or continue button to move on to the next
            step of the process. Do this once you're done with this step and all
            your changes will be saved.
          </Typography>
        </div>
      )
    }
  ];

  const videoId = {
    step1: '6ZZr9riOSls',
    step2: 'osB_uNwxQ9o',
    step3: 'QIeBCp_d6GE',
    step4: '1yzOP0ntKE8',
    step5: '1yzOP0ntKE8',
    step6: '1yzOP0ntKE8'
  };

  const step1Faq = [
    {
      title: 'Why do we collect company information?',
      desc: 'This company information is required for filling all kinds of disclosure frameworks. Hence we collect all this data to easily facilitate the same.'
    },
    {
      title: 'What are reporting frameworks? ',
      desc: 'These are standards set in place by various global entities, government bodies, stock exchanges etc. across various countries. Depending on which part of the world you are operating from/to and which clients you cater to, different disclosures will need to be made periodically. These disclosures are generically called reporting frameworks.'
    },
    {
      title: 'Which reporting framework do I choose?',
      desc: 'That is a decision left for your upper management and you can choose to report in more than one format. Various factors need to be considered while making this decision like, operating locations, regional and national regulations etc.   '
    },
    {
      title: 'What are ESG Goals?',
      desc: 'These are goals set by your company to grow sustainably, broadly classified under environmental, social and governance related aspects.'
    },
    {
      title: 'How can I set up my company’s Sector & Industry?',
      desc: ' It can be set either while signup or in the first step on the right side there is an option for it as well. In that popup you can select multiple industry sector combinations that apply to your scope of work.'
    }
  ];

  const step2Faq = [
    {
      title: 'What is Materiality Assessment? ',
      desc: ' Materiality assessment is the process of identifying, refining, and assessing numerous potential environmental, social and governance issues that could affect your business, and/or your stakeholders, and condensing them into a short-list of topics that inform company strategy, targets, and reporting.'
    },
    {
      title: 'Why is Materiality Assessment important?',
      desc: "The process of materiality assessment shapes a company's sustainability strategy and defines its reporting. It helps a company analyze risk factors and upgrade its business process for future prospects. Materiality assessment is also an important tool to meet the expectations of stakeholders."
    },
    {
      title: 'What are the types of topics?',
      desc: 'The different material topics are categorized into Environment, Social capital, Human capital, Business model and Governance.'
    },
    {
      title: 'How are topics recommended to me?',
      desc: 'The topics listed are an intelligently curated list generated by our system which takes into account the industry and sector you have picked in the previous step.'
    },
    {
      title: 'How do I shortlist topics?',
      desc: ' For shortlisting topics, just need to hover over each topic and click on the plus (+) icon against the respective topic. You can also use the info (i) icon to read more details about the topic.'
    },
    {
      title: 'What are custom topics? ',
      desc: 'If you are not satisfied with the list of topics available, you can use the custom topic button to create one which can be categorized under any of the five categories.'
    }
  ];

  const step3Faq = [
    {
      title: 'What is Activity and KPI ?',
      desc: 'Activities and KPI’s are the actionables that can be tracked against each material topic.'
    },
    {
      title: 'How are you generating the suggestions under Activity and KPI ?',
      desc: 'Based on the various topics shortlisted in step 2, our system will intelligently suggest activities and KPIs that might be relevant to you. Your free to use them or create your own as per need.'
    }
  ];

  const step4Faq = [
    {
      title: 'What is the purpose of this document?',
      desc: 'It can be used for two things. One, it can be used to get internal approval about the ESG plans for the assessment year from various stakeholders like BOD, directors etc. Secondly this document can be used to submit relevant information for SBT (Science based targets).'
    },
    {
      title: 'How can I share it with my colleagues ?',
      desc: 'Using the share option available in the right hand side of the document, you can share it with one or multiple people whom you need to collaborate with.'
    }
  ];

  const step5Faq = [
    {
      title: 'What are the different frameworks available ?',
      desc: 'Currently our system supports three major frameworks, GRI, SASB and BRSR.'
    },
    {
      title: 'Can I omit a disclosure ?',
      desc: 'Yes, depending on the type of the information that is being collected the user has the right to disclose or omit the information. While reviewing  the answers in the fifth step, you have the option to provide reasons and omit any disclosure.'
    },
    {
      title: 'Is it necessary to use the blockchain ?',
      desc: "No, it's entirely optional."
    },
    {
      title: 'Is it safe to store data on a blockchain ?',
      desc: 'Yes, it is safe to store the data on the blockchain. To be clear, the actual data never leaves the system. Just a representative encrypted key that can link back to your data.'
    },
    {
      title:
        'How is blockchain used for ESG reporting ? What information is stored on the blockchain ? Why is the data stored on the blockchain ?',
      desc: 'The blockchain technology is a way of storing multiple copies of the same data across various locations to ensure that no single person has the access or ability to mutate the data. Any change required can only be done in consensus by all parties. This is also known as DLT (distributed ledger technology).     With respect to ESG, with DLT we can ensure data auditability and traceability. Using DLT we can store a key which represents your data on  blockchain, but this key in no way can be used to regenerate or retrieve any information about your company or its internal activities. But by doing so, we are able to ensure that the actual company data has not been tampered with and the authenticity of the collected data is ensured.'
    }
  ];

  const step6Faq = [
    {
      title: 'What is the purpose of this document?',
      desc: 'It can be used for two things. One, it can be used to get internal approval about the ESG plans for the assessment year from various stakeholders like BOD, directors etc. Secondly this document can be used to submit relevant information for SBT (Science based targets).'
    },
    {
      title: 'How can I share it with my colleagues ?',
      desc: 'Using the share option available in the right hand side of the document, you can share it with one or multiple people whom you need to collaborate with.'
    }
  ];

  const handlePrevStep = () => {
    setCurrent((prev) => {
      let prevStep = prev < tourContent.length + 1 ? prev - 1 : prev;
      return prevStep;
    });
    if (activeStep === 0 && current === 4) {
      isDrawerOpen && setIsDrawerOpen(false);
    }
  };

  const handleNextStep = () => {
    setTimeout(() => {
      setCurrent((prev) => {
        let nextStep = prev < tourContent.length - 1 ? prev + 1 : prev;
        return nextStep;
      });
    }, 150);
    if (activeStep === 0 && current === 3) {
      setIsDrawerOpen(true);
    }
    if (activeStep === 0 && current === 4) {
      setIsDrawerOpen(false);
    }
  };

  const helperDrawer = () => {
    return (
      <>
        <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawer}>
          <div style={{ width: 430 }} data-tut="reactour__helper_drawer">
            <Grid container justifyContent="space-between">
              <Grid item md={6} xs={6}>
                <Typography
                  style={{
                    fontSize: '21px',
                    lineHeight: '32px',
                    fontWeight: '600',
                    paddingTop: '12px',
                    paddingLeft: '32px'
                  }}
                >
                  Help Center
                </Typography>
              </Grid>
              <Grid item md={2} xs={3}>
                <IconButton onClick={handleDrawer}>
                  <CloseIcon style={{ fontSize: 28, color: '#242424' }} />
                </IconButton>
              </Grid>
            </Grid>
            <div style={{ padding: 24 }}>
              <Card
                elevation={3}
                style={{
                  borderRadius: 25,
                  width: 380,
                  height: 200,
                  margin: '30px 10px 100px 10px '
                }}
              >
                {activeStep === 0 && (
                  <YouTube
                    videoId={videoId.step1}
                    opts={opts}
                    onReady={onReady}
                  />
                )}
                {activeStep === 1 && (
                  <YouTube
                    videoId={videoId.step2}
                    opts={opts}
                    onReady={onReady}
                  />
                )}
                {activeStep === 2 && (
                  <YouTube
                    videoId={videoId.step3}
                    opts={opts}
                    onReady={onReady}
                  />
                )}
                {activeStep === 3 && (
                  <YouTube
                    videoId={videoId.step4}
                    opts={opts}
                    onReady={onReady}
                  />
                )}
                {activeStep === 4 && (
                  <YouTube
                    videoId={videoId.step5}
                    opts={opts}
                    onReady={onReady}
                  />
                )}
                {activeStep === 5 && (
                  <YouTube
                    videoId={videoId.step6}
                    opts={opts}
                    onReady={onReady}
                  />
                )}
              </Card>
              <CustomButton
                onClick={handleTourOpen}
                style={{
                  textTransform: 'none',
                  color: '#3374B9',
                  fontFamily: 'Poppins',
                  fontWeight: '400'
                }}
              >
                Click here
              </CustomButton>
              <span>for quick tour</span>

              <Typography
                style={{
                  fontSize: '21px',
                  lineHeight: '32px',
                  textTransform: 'capitalize',
                  paddingTop: '30px',
                  paddingBottom: '24px',
                  paddingLeft: '10px'
                }}
              >
                Follow up questions
              </Typography>
              <div>
                {faq.map(({ title, desc }) => (
                  <Accordions title={title} content={desc} />
                ))}
              </div>
            </div>
          </div>
        </Drawer>
      </>
    );
  };

  return (
    <div>
      {isDrawerOpen && helperDrawer()}
      <Tour
        onRequestClose={handleClose}
        steps={tourContent}
        isOpen={isTourOpen}
        rounded={5}
        showNumber={true}
        showNavigation={true}
        startAt={0}
        prevStep={handlePrevStep}
        nextStep={handleNextStep}
        goToStep={current}
        getCurrentStep={(curr) => {
          setCurrentTourStep(curr);
        }}
        lastStepNextButton={
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => {
              if (activeStep === 0) {
                setCompletedStepsArr((prev) => [...prev, activeStep]);
                setIsTourOpen(false);
                updateHelpFlow(login.token, STEP_1);
                currentTourStep === 3 && setIsDrawerOpen(true);
              }
              if (activeStep === 1) {
                if (topicTab === 0) {
                  setIsTourOpen(false);
                  updateHelpFlow(login.token, TOPIC_1);
                }
                if (topicTab === 1) {
                  setIsTourOpen(false);
                  updateHelpFlow(login.token, TOPIC_2);
                }
                if (topicTab === 2) {
                  setIsTourOpen(false);
                  updateHelpFlow(login.token, TOPIC_3);
                }
              }
              if (activeStep === 2) {
                setCompletedStepsArr((prev) => [...prev, activeStep]);
                setIsTourOpen(false);
                updateHelpFlow(login.token, STEP_3);
              }
              if (activeStep === 3) {
                setCompletedStepsArr((prev) => [...prev, activeStep]);
                setIsTourOpen(false);
                updateHelpFlow(login.token, STEP_4);
              }
              if (activeStep === 4) {
                if (reportTab === 0) {
                  setIsTourOpen(false);
                  updateHelpFlow(login.token, REPORT_1);
                }
                if (reportTab === 1) {
                  setIsTourOpen(false);
                  updateHelpFlow(login.token, REPORT_2);
                }
                if (reportTab === 2) {
                  setIsTourOpen(false);
                  updateHelpFlow(login.token, REPORT_3);
                }
              }
              if (activeStep === 5) {
                setHelperDailogue(false);
                updateHelpFlow(login.token, STEP_6);
              }
            }}
          >
            {currentTourStep === 4 && 'Close'}
            {currentTourStep !== 4 && 'Next'}
          </CustomButton>
        }
        prevButton={
          <CustomButton
            variant="contained"
            color="primary"
            disabled={current === 0}
          >
            Previous
          </CustomButton>
        }
        nextButton={
          <CustomButton variant="contained" color="primary">
            {activeStep === 0 && currentTourStep === 3 ? 'Open' : 'Next'}
          </CustomButton>
        }
      />
      <Fab
        size="small"
        style={{
          backgroundColor: '#000000',
          color: '#ffffff'
        }}
        data-tut="reactour__helperBtn"
        onClick={handleDrawer}
      >
        <Typography>?</Typography>
      </Fab>
    </div>
  );
}
export default React.memo(HelperDrawer);
