import { diff } from "deep-object-diff";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";
import { viewAllUsers } from "../../actions/generalSettings/generalSettingsAction";
import UNIVERSAL from "../../config/config";
import encrypt from "../shared/sharedActions";
import {
  SET_MASTER_TOPICS,
  SET_TOPICS_DATA,
  SET_SELECTED_TOPIC_DATA,
  SET_TOPIC_TAB,
  SET_SELECTED_TOPIC_DETAILS,
  SET_GRAPH_DATA,
  SET_STAKEHOLDER_DATA,
  SET_ASSIGNED_STAKEHOLDER_DATA,
  SET_OPPORTUNITY,
  SET_RISK,
  SET_FILE,
  SET_WEIGHTAGE,
  SET_RELEVANCE,
  SET_MATERIALITY_MAP_TOTAL_PART,
  SET_MATERIALITY_MAP_RESPONDED,
  SET_TOTAL_TOPICS_COUNT,
  SET_TOPIC_FILTER,
  SET_ASSIGNED_MATERIALITY_TOPIC,
  SET_IS_SURVEY_COMPLETED,
  SET_TOPICS_COMPLETED_UPTO,
  SET_OBJECT_DIFFERENCE,
} from "../../constants/materialityConst/materialityConst";
import {
  setMaturityMatrixAssignedMembers,
  viewAssignedMembers,
} from "../maturityMatrix/maturityMatrixActions";

export const setMainTopics = (payload) => ({
  type: SET_MASTER_TOPICS,
  payload: payload,
});

export const setTopicData = (payload) => ({
  type: SET_TOPICS_DATA,
  payload: payload,
});

export const setSelectedTopicData = (payload) => ({
  type: SET_SELECTED_TOPIC_DATA,
  payload: payload,
});
export const setTopicFilter = (payload) => ({
  type: SET_TOPIC_FILTER,
  payload: payload,
});

export const setTopicTab = (payload) => ({
  type: SET_TOPIC_TAB,
  payload: payload,
});

export const setSelectedTopicDetails = (payload) => ({
  type: SET_SELECTED_TOPIC_DETAILS,
  payload: payload,
});

export const setAssignedMaterialityTopics = (payload) => ({
  type: SET_ASSIGNED_MATERIALITY_TOPIC,
  payload: payload,
});

export const setIsSurveyCompleted = (payload) => ({
  type: SET_IS_SURVEY_COMPLETED,
  payload,
});

export const setTopicCompletedUpto = (payload) => ({
  type: SET_TOPICS_COMPLETED_UPTO,
  payload,
});
export const setStakeholderData = (payload) => ({
  type: SET_STAKEHOLDER_DATA,
  payload: payload,
});

export const setAssignedStakeholderData = (payload) => ({
  type: SET_ASSIGNED_STAKEHOLDER_DATA,
  payload: payload,
});

export const setGraphData = (payload) => ({
  type: SET_GRAPH_DATA,
  payload: payload,
});

export const setWeightage = (payload) => ({
  type: SET_WEIGHTAGE,
  payload: payload,
});

export const setRelevance = (payload) => ({
  type: SET_RELEVANCE,
  payload: payload,
});

export const setFile = (payload) => ({
  type: SET_FILE,
  payload: payload,
});

export const setRisk = (payload) => ({
  type: SET_RISK,
  payload: payload,
});

export const setOpportunity = (payload) => ({
  type: SET_OPPORTUNITY,
  payload: payload,
});

export const setMaterialityMapTotalPart = (payload) => ({
  type: SET_MATERIALITY_MAP_TOTAL_PART,
  payload: payload,
});

export const setMaterialityResponded = (payload) => ({
  type: SET_MATERIALITY_MAP_RESPONDED,
  payload: payload,
});

export const setTotalTopicCount = (payload) => ({
  type: SET_TOTAL_TOPICS_COUNT,
  payload: payload,
});

export const getTopics = (
  token,
  assessment_id,
  limit,
  starting_after,
  filter,
  framework_id
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
      limit,
      starting_after,
      filter,
      framework_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/get_topics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setMainTopics(responseJson.result.main_topics));
          dispatch(setTopicData(responseJson.result.topics_data));
          dispatch(setTotalTopicCount(responseJson.result.total));
        }
        if (!responseJson.status) {
          dispatch(setMainTopics([]));
          dispatch(setTopicData([]));
          dispatch(setTotalTopicCount([]));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const upsertAssessmentTopic = (
  token,
  assessment_id,
  topic_id,
  isMainSection,
  relevance,
  weightage,
  additional_params,
  file,
  topic_name,
  materialityAssessment,
  framework_id,
  nextTopic_id
) => {
  return (dispatch) => {
    let formData = new FormData();
    dispatch(set_loader());

    const checkDiff = Object.keys(
      diff(
        {
          relevance,
          weightage,
          additional_params,
          file,
        },
        {
          ...materialityAssessment.upsert_assessment_topic,
        }
      )
    ).length;

    if (checkDiff === 0) {
      dispatch(unset_loader());
      dispatch(
        getMaterialityAssessmentTask(token, assessment_id, nextTopic_id)
      );
      dispatch(getSelectedTopic(token, assessment_id));
    } else {
      const data = encrypt({
        "user-token": token,
        assessment_id,
        topic_id,
        relevance,
        weightage,
        additional_params,
        file,
        topic_name,
        organisation_id: localStorage.getItem("current_organisation"),
        framework_id,
      });
      formData.append("data", data);
      formData.append("file", file);

      return fetch(UNIVERSAL.BASEURL + "/topic/upsert_assessment_topic", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            if (isMainSection) {
              dispatch(getSelectedTopic(token, assessment_id));
            }
          }
          dispatch(unset_loader());
          dispatch(
            getMaterialityAssessmentTask(token, assessment_id, nextTopic_id)
          );
        })
        .catch((error) => {
          console.error(error);
        });
      // .finally(() => {
      //   dispatch(unset_loader());
      // });
    }
  };
};

export const addCustomTopic = (token, assessment_id, name, parent_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
      name,
      parent_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/add_custom_topic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getSelectedTopic(token, assessment_id));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getSelectedTopic = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/get_selected_topics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setSelectedTopicData(responseJson.result));
        } else {
          dispatch(setSelectedTopicData([]));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

//for getting the assigned topics for the task section
export const getAssignedMaterialityTopics = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/get_assigned_materiality_topics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setTopicCompletedUpto(responseJson.result.completed_upto));
          dispatch(setIsSurveyCompleted(responseJson.result.completed));
          dispatch(
            setAssignedMaterialityTopics(responseJson.result.topics_data)
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getTopicData = (token, assessment_topic_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_topic_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/get_topic_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setSelectedTopicDetails(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getMaterialityMapData = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/materiality_map_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.result === null) {
            dispatch(setGraphData([]));
            dispatch(setStakeholderData([]));
            dispatch(setMaterialityMapTotalPart([]));
            dispatch(setMaterialityResponded([]));
          } else {
            dispatch(setGraphData(responseJson.result.graph_data));
            dispatch(setStakeholderData(responseJson.result.stakeholder_data));
            dispatch(
              setMaterialityMapTotalPart(responseJson.result.total_participants)
            );
            dispatch(setMaterialityResponded(responseJson.result.responded));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const deleteAssessmentTopic = (
  token,
  assessment_topic_id,
  assessment_id,
  limit,
  starting_after,
  filter,
  topic_name
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_topic_id,
      topic_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/delete_assessment_topic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSelectedTopic(token, assessment_id));
          dispatch(
            getTopics(token, assessment_id, limit, starting_after, filter)
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const deleteAllAssessmentTopic = (
  token,
  assessment_id,
  limit,
  starting_after,
  filter
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/delete_all_assessment_topic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSelectedTopic(token, assessment_id));
          dispatch(
            getTopics(token, assessment_id, limit, starting_after, filter)
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getStakeholder = (token, assessment_id, step_no) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      assessment_id,
      step_no,
    });

    return fetch(UNIVERSAL.BASEURL + "/assignMembers/view_stakeholder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          if (step_no === 1) {
            dispatch(setMaturityMatrixAssignedMembers(responseJson.result));
          } else {
            dispatch(setAssignedStakeholderData(responseJson.result));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };
};

export const addStakeholder = (
  token,
  assessment_id,
  organisation_id,
  user,
  emailSubject,
  stakeholderContent,
  step_no
) => {
  return (dispatch) => {
    dispatch(set_loader());
    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      "user-token": token,
      assessment_id,
      organisation_id,
      data: user,
      emailSubject,
      stakeholderContent,
      url,
      domain_name,
    });
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/add_stakeholder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          setTimeout(() => {
            dispatch(getStakeholder(token, assessment_id, step_no));
          }, 500);
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const editStakeholder = (
  token,
  assessment_id,
  user_id,
  designation,
  organisation,
  category,
  type,
  step_no,
  organisation_id,
  weightage
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
      user_id,
      designation,
      organisation,
      category,
      type,
      step_no,
      weightage,
    });

    return fetch(UNIVERSAL.BASEURL + "/assignMembers/edit_stakeholder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          if (step_no === 2) {
            dispatch(getStakeholder(token, assessment_id, step_no));
          } else if (step_no === 1) {
            dispatch(viewAssignedMembers(token, assessment_id, step_no));
            dispatch(viewAllUsers(token, organisation_id, ""));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const deleteStakeholder = (
  token,
  assessment_id,
  stakeholder_id,
  step_no
) => {
  return (dispatch) => {
    dispatch(set_loader());
    let organisation_id = localStorage.getItem("current_organisation");
    const data = encrypt({
      "user-token": token,
      stakeholder_id,
      organisation_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/assignMembers/delete_stakeholder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getStakeholder(token, assessment_id, step_no));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getMaterialityAssessmentTask = (
  token,
  assessment_id,
  topic_id
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      topic_id: topic_id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/assessments/get_materiality_assessment_task",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch({
            type: SET_OBJECT_DIFFERENCE,
            payload: {
              relevance: responseJson.result.relevance,
              weightage: responseJson.result.weightage,
              additional_params: {
                risk:
                  responseJson.result.additional_params === null
                    ? ""
                    : responseJson.result.additional_params.risk,
                opportunity:
                  responseJson.result.additional_params === null
                    ? ""
                    : responseJson.result.additional_params.opportunity,
              },
              file:
                responseJson.result.file === ""
                  ? null
                  : responseJson.result.file,
            },
          });
          dispatch(setRelevance(responseJson.result.relevance));
          dispatch(
            setOpportunity(
              responseJson.result.additional_params === null
                ? ""
                : responseJson.result.additional_params.opportunity
            )
          );
          dispatch(
            setRisk(
              responseJson.result.additional_params === null
                ? ""
                : responseJson.result.additional_params.risk
            )
          );
          dispatch(setWeightage(responseJson.result.weightage));
          dispatch(
            setFile(
              responseJson.result.file === "" ? null : responseJson.result.file
            )
          );
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
    // .finally(() => {
    //   console.log("unset_loader");
    //   dispatch(unset_loader());
    // });
  };
};

export const selectAllTopics = (token, assessment_id, filter, framework_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
      filter,
      framework_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/select_all_topics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getSelectedTopic(token, assessment_id));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const addUser = (
  token,
  organisation_id,
  name,
  email,
  designation,
  organisation,
  category,
  type,
  weightage,
  assessment_id
) => {
  return (dispatch) => {
    dispatch(set_loader());
    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      "user-token": token,
      organisation_id,
      name,
      email,
      designation,
      organisation,
      category,
      type,
      url,
      domain_name,
      weightage,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/userDetails/add_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(viewAllUsers(token, organisation_id, ""));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const uploadUserSheet = (token, organisation_id, file) => {
  return (dispatch) => {
    dispatch(set_loader());
    var formData = new FormData();
    const data = encrypt({
      "user-token": token,
      organisation_id,
    });
    formData.append("file", file);
    formData.append("data", data);
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/add_uploaded_users", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((responseJson) => {
        if (responseJson.status) {
          setTimeout(() => {
            dispatch(viewAllUsers(token, organisation_id, ""));
          }, 200);
        }
      })
      .catch((err) => console.log(err));
  };
};
