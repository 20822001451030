import React, { useEffect } from "react";

import StepRight from "../../stepLayout/right/StepRight";
import { Box, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../../../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import { Question } from "../../../../selfAssessment/selfAssessmentResponse";
import OptimizeInitialRender from "../../../../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/OptimizeInitialRender";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  getCompletedQuestions,
  setCompletedQuestions,
  setQuestionStatus,
} from "actions/surveyListing/surveyListingAction";
import QuestionBody from "components/widgets/surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

function Step4Right({ response }) {
  const nonClickableStyle = {
    pointerEvents: "none",
  };

  const { token, current_organisation, supplier_id } = useSelector(
    (state) => state.login
  );
  const { selectedAssignSurvey, selectedCorporate } = useSelector(
    (state) => state.supplierAdmin
  );
  const { completedQuestions } = useSelector((state) => state.surveyListing);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCompletedQuestions(
        token,
        selectedAssignSurvey?.survey_id, // survey_id
        selectedCorporate?._id, // organization_id
        selectedAssignSurvey?.frequency_id, // frequency_id
        supplier_id, // supplier_id
        selectedAssignSurvey?.version // version
      )
    );

    return () => {
      dispatch(setCompletedQuestions([]));
      dispatch(setQuestionStatus({}));
    };
  }, []);

  return (
    <StepRight>
      <>
        <Box
          style={{
            overflowY: "scroll",
            height: "90%",
          }}
        >
          <OptimizeInitialRender chunkSize={1}>
            {completedQuestions?.map((section) => (
              <div key={section?.section_id}>
                <BlueBackgroundCard heading={section?.section_name} />
                <OptimizeInitialRender chunkSize={1}>
                  {section?.questions?.map((ques) => (
                    <>
                      <Question
                        key={ques?.question_id}
                        question={ques?.question?.question}
                        type={ques?.question?.question_type}
                        weightage={ques?.question?.weightage}
                        submittedAnswer={ques?.approved_data?.answer}
                        removeEditOption={true}
                        options={ques?.question?.answers}
                        hideWeightage
                      />

                      {ques?.question?.comment && (
                        <QuestionBody
                          questionType={"Comment box"}
                          questionId={ques?.question_id}
                          answers={ques?.approved_data?.comment_value}
                          isFrom={"questionComment"}
                          comment_value={ques?.approved_data?.comment_value}
                          removeEditOption
                        />
                      )}
                    </>
                  ))}
                </OptimizeInitialRender>
              </div>
            ))}
          </OptimizeInitialRender>
        </Box>

        {/* <Box style={{ overflow: "scroll", height: "90%" }}>
          <OptimizeInitialRender chunkSize={1}>
            {Object.keys(response).map((question, index) => {
              return (
                <div key={index} style={nonClickableStyle}>
                  <BlueBackgroundCard heading={question} />
                  <OptimizeInitialRender chunkSize={1}>
                    {response[question].map((question) => (
                      <Question
                        key={question?._id}
                        question={question.question}
                        options={question.answers}
                        type={question.question_type}
                        weightage={question.weightage}
                        submittedAnswer={question.submitted_answer}
                        removeEditOption={true}
                        comment={question?.comment}
                      />
                    ))}
                  </OptimizeInitialRender>
                </div>
              );
            })}
          </OptimizeInitialRender>
        </Box> */}
      </>
    </StepRight>
  );
}

export default Step4Right;
