import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import useDebounce from "../../../customHooks/useDebouncec";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  Grid: { marginTop: 20 },
  TableCell: { minWidth: 100 },
  Grid1: { marginTop: 20 },
  CustomTextfield: { width: "100%" },
  input: { display: "none" },
  Box: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box1: { borderBottom: "2px solid #3374B9" },
});

export default function SurveyTypeTwo(props) {
  const {
    surveyData,
    surveyResultData,
    isUpsertMetricData,
    setIsUpsertMetricData,
    addMetricsData,
    fieldData,
    login,
    assessment_id,
    metricId,
    index,
    parentId,
    removeFile,
    metric_name,
    metric_desc,
    metric,
    setOnSubmitObject,
    validation,
  } = props;
  const classes = useStyles();
  const [rows] = useState(["Row1"]);
  const [typeTwoData, setTypeTwoData] = useState([]);

  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");

  const { suggestedData, autoFill } = useSelector((state) => state.dataHub);

  useEffect(() => {
    if (autoFill && suggestedData) {
      // Check if autoFill is true and suggestedData is available
      const rowsData = suggestedData[0].data;
      const newTypeTwoData = rowsData.map((rowData, rowIndex) => {
        return {
          row: `Row${rowIndex + 1}`,
          columns: surveyData.columns.map((col, colIndex) => {
            return {
              column: col,
              data: rowData[colIndex],
            };
          }),
        };
      });
      setIsUpsertMetricData(true);
      setTypeTwoData(newTypeTwoData);
      console.log(newTypeTwoData);
    } else {
      // Initialize the data as before
      const data =
        surveyData.columns !== null &&
        rows.map((r, idx) => {
          return {
            row: `Row${rows.length}`,
            columns: surveyData.columns.map((c) => {
              return {
                column: c,
                data: "",
              };
            }),
          };
        });
      setTypeTwoData(data);
      console.log("2nd : ", data);
      setIsUpsertMetricData(false);
    }
  }, [autoFill, surveyData.columns]); // eslint-disable-line

  useEffect(() => {
    // if (
    //   fieldData.assignedMetric.every((m) => m.parent_metric_id === parentId)
    // ) {
    if (props.metric.hasOwnProperty("comment")) {
      setMetricComment(props.metric.comment);
    } else {
      setMetricComment(
        fieldData?.assignedMetric.final_data.map((m) => m.comment)[index]
      );
    }
    // }
    // if (
    //   fieldData.assignedMetric.map((m) => m.file) &&
    //   fieldData.assignedMetric.every((m) => m.parent_metric_id === parentId)
    // ) {
    if (props.metric.hasOwnProperty("file") && props.metric.file !== "") {
      setMetricFile(props.metric.file);
    } else {
      setMetricFile(
        fieldData?.assignedMetric.final_data.map((m) => m.file)[index]
      );
    }
    // }
  }, [fieldData?.assignedMetric, parentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (surveyResultData !== null && surveyResultData !== "") {
      setTypeTwoData(surveyResultData);
    } else {
      const data =
        surveyData.columns !== null &&
        rows.map((r, idx) => {
          return {
            row: `Row${rows.length}`,
            columns: surveyData.columns.map((c) => {
              return {
                column: c,
                data: "",
              };
            }),
          };
        });
      setTypeTwoData(data);
    }
  }, [fieldData.currentMetric]); //surveyResultData, parentId eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e, rowIndex, colIndex) => {
    let value = e.target.value;
    setTypeTwoData((prevState) => {
      prevState[rowIndex].columns[colIndex].data = value;
      return [...prevState];
    });
  };

  const addDataToArray = (index) => {
    setTypeTwoData((prevState) => {
      return [
        ...prevState,
        {
          row: `Row${index}`,
          columns: typeTwoData[0].columns.map((c) => {
            return {
              column: c.column,
              data: "",
            };
          }),
        },
      ];
    });
  };

  useDebounce(
    () => {
      setOnSubmitObject({
        token: login.token,
        assessment_id,
        metricId,
        currentMetric_id: fieldData.currentMetric._id,
        data_type: typeTwoData,
        metricComment,
        metricFile,
        metric_name,
        metric_desc,
        parent_id: parentId,
        parent_metric_id: metric.parent_metric_id,
      });

      // if (isUpsertMetricData) {
      //   addMetricsData(
      //     login.token,
      //     assessment_id,
      //     metricId,
      //     fieldData.currentMetric._id,
      //     typeTwoData,
      //     metricComment,
      //     metricFile,
      //     '',
      //     fieldData.currentMetric._id,
      //     metric_name,
      //     metric_desc,
      //   );
      // }
      // setIsUpsertMetricData(false);
    },
    100,
    [typeTwoData, metricComment, metricFile]
  );

  const handleDeleteRow = ({ row }) => {
    const deletedRow = typeTwoData.filter((rowData) => rowData.row !== row);
    const updateRowIndex = deletedRow.map((rowData, index) => ({
      ...rowData,
      row: `Row${index + 1}`,
    }));
    setTypeTwoData(updateRowIndex);
    setIsUpsertMetricData(true);
  };

  return (
    <>
      {typeTwoData.length > 0 && (
        <Grid container justifyContent="space-evenly" className={classes.Grid}>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      {typeTwoData.length > 0 &&
                        typeTwoData[0].columns &&
                        typeTwoData[0].columns.map((col, index) => (
                          <TableCell
                            key={index}
                            className={classes.TableCell}
                            align="center"
                            style={{ minWidth: 100 }}
                          >
                            {col.column}
                          </TableCell>
                        ))}
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeTwoData.length > 0 &&
                      typeTwoData.map((row, rowIndex, rowArr) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">
                              <Typography>{row.row}</Typography>
                            </TableCell>
                            {row.columns &&
                              row.columns.map((column, colIndex) => {
                                return (
                                  <TableCell key={colIndex} align="center">
                                    <CustomTextfield
                                      type={
                                        !validation ? "text" : validation.type
                                      }
                                      name={`Text_type2${colIndex}`}
                                      size="small"
                                      variant="outlined"
                                      className={classes.CustomTextfield}
                                      value={
                                        typeTwoData[rowIndex].columns[colIndex]
                                          .data
                                      }
                                      onChange={(e) => {
                                        handleChange(e, rowIndex, colIndex);
                                        if (!isUpsertMetricData) {
                                          setIsUpsertMetricData(true);
                                        }
                                      }}
                                    />
                                  </TableCell>
                                );
                              })}
                            {rowArr.length > 1 && (
                              <TableCell
                                align="center"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteRow(row)}
                              >
                                <DeleteOutlineIcon color="action" />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={8} sm={10}></Grid>
          <Grid item xs={4} sm={2} className={classes.Grid1}>
            <CustomButton
              color="secondary"
              onClick={() => addDataToArray(typeTwoData.length + 1)}
            >
              Add More
            </CustomButton>
          </Grid>
        </Grid>
      )}
      <Box>
        <Box my={2}>
          <Typography className={classes.blueText}>Comments</Typography>
          <CustomTextfield
            name={`Comment_type2${metricId}`}
            label="Additional Comment"
            fullWidth
            multiline
            rows={3}
            size="small"
            variant="outlined"
            value={metricComment}
            onChange={(e) => {
              setMetricComment(e.target.value);
              if (!isUpsertMetricData) {
                setIsUpsertMetricData(true);
              }
            }}
          />
        </Box>
        <Box my={2}>
          {metricFile === null || metricFile === "" ? (
            <>
              <input
                id={metricId}
                type="file"
                index={index}
                onChange={(event) => {
                  setMetricFile(event.target.files[0]);

                  if (!isUpsertMetricData) {
                    setIsUpsertMetricData(true);
                  }
                }}
                className={classes.input}
              />
              <label htmlFor={metricId}>
                <Box display="flex" component="span" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box} width="100%">
                    <Typography variant="caption" color="textSecondary">
                      Upload document
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <AttachFileIcon color="disabled" />
                <Box mx={2} className={classes.Box1} width="100%">
                  <Typography variant="caption" color="primary">
                    {metricFile.name}
                  </Typography>
                </Box>
                <ClearIcon
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    removeFile(
                      login.token,
                      assessment_id,
                      5,
                      metricFile.url,
                      "file",
                      null,
                      null,
                      null,
                      metricId
                    );
                    setMetricFile(null);
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
