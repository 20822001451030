import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

// icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  rootSelect: {
    padding: "5px 14px",
  },
}));

const ResponseSectionTopCard = ({
  selectedResponse,
  currentFrequency,
  setCurrentFrequency,
  totalQues,
  totalSections,
}) => {
  const classes = useStyles();

  // states
  const [dropdownValue, setDropdownValue] = useState(
    currentFrequency?.frequency_id || ""
  );

  // Update dropdownValue when currentFrequency changes
  useEffect(() => {
    setDropdownValue(currentFrequency?.frequency_id || "");
  }, [currentFrequency]);

  // Function to handle dropdown value change
  const handleDropdownChange = (event) => {
    const newFrequencyId = event.target.value;
    const newFrequency = selectedResponse.frequency.find(
      (freq) => freq.frequency_id === newFrequencyId
    );
    setDropdownValue(newFrequencyId);
    setCurrentFrequency(newFrequency);
  };

  // Function to generate options for popup
  const MakeCard = ({ heading, value, type }) => {
    const key = `${heading}-${value}`;

    return (
      <Box
        key={key}
        sx={{
          m: 1,
          width: "100%",
          height: "96px",
          borderRadius: "8px",
          padding: "16px 0px 16px 0px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            marginBottom: 10,
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "21px",
            color: "#15314E",
          }}
        >
          {heading}
        </Typography>
        {type === "status" ? (
          <Box
            sx={{
              padding: "4px 8px 4px 8px",
              borderRadius: "80px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              height: "24px",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: value ? "#154328" : "#8C7038",
              backgroundColor: value ? "#BFE2CD" : "#FFEFD0",
            }}
          >
            <FiberManualRecordIcon
              style={{ width: 8, height: 8, marginRight: 5 }}
            />
            {value ? "Complete" : "Incomplete"}
          </Box>
        ) : type === "common" ? (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        ) : type === "popup-quoter" || type === "popup-monthly" ? (
          <Select
            value={dropdownValue}
            onChange={handleDropdownChange}
            variant="outlined"
            classes={{ root: classes.rootSelect }}
            style={{
              width: "80%",
              maxHeight: "30px",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#15314E",
              boxShadow: "#C0D4E9",
              backgroundColor: "#FEFEFE",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  padding: "0px 6px",
                },
              },
            }}
          >
            {selectedResponse?.frequency?.map((freq) => (
              <MenuItem
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    dropdownValue === freq?.frequency_id
                      ? "#EBF1F8"
                      : "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                key={freq?.frequency_id}
                value={freq?.frequency_id}
              >
                {freq?.date}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <MakeCard
        heading="No. of Survey Sections "
        value={totalSections}
        type={"common"}
      />
      <MakeCard
        heading="No. of Survey Questions "
        value={totalQues}
        type={"common"}
      />
      <MakeCard
        heading="Survey status"
        value={currentFrequency?.is_completed}
        type={"status"}
      />
      <MakeCard
        heading={`Frequency: ${"Monthly"}`}
        value={null}
        type={"popup-monthly"}
      />
    </Box>
  );
};

export default ResponseSectionTopCard;
