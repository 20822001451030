import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	LinearProgress,
	Menu,
	MenuItem,
	Paper,
	Select,
	Typography,
	withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import {
	Business,
	Environmental,
	GeneralDisclosure,
	GeneralDisclosureWhite,
	Governance,
	Human,
	Social,
} from "../../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import reportDataLeftStyles from "../reportDataLeftStyles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomTextfield from "../../../UI/textfield/textfield";
import CustomButton from "../../../UI/button/button";
function AuditTab({ generalSettings, ...props }) {
	const classes = reportDataLeftStyles();
	const {
		fieldData,
		getMetrics,
		login,
		assessment_id,
		basicInfo,
		getSustainabilityDocx,
		getSustainabilityXBRL,
		getSustainabilityExcel,
	} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});


	const Accordion = withStyles({
		root: {
			width: "100%",
			borderBottom: "1px solid rgba(0, 0, 0, .125)",
			boxShadow: "none",
			"&:not(:last-child)": {
				borderBottom: 0,
			},
			"&:before": {
				display: "none",
			},
			"&$expanded": {
				margin: "auto",
			},
		},
		expanded: {},
	})(MuiAccordion);

	const AccordionSummary = withStyles({
		root: {
			width: "100%",
			// backgroundColor: 'rgba(0, 0, 0, .03)',
			padding: "5px",
			// borderBottom: '1px solid rgba(0, 0, 0, .125)',
			marginBottom: -1,
			// minHeight: 56,
			// '&$expanded': {
			// minHeight: 56,
			// },
		},
		content: {
			"&$expanded": {
				margin: "12px 0",
			},
		},
		expanded: {},
	})(MuiAccordionSummary);

	const AccordionDetails = withStyles((theme) => ({
		root: {
			padding: "5px 5px",
			width: "100%",
			// pointerEvents:'none' ,
		},
	}))(MuiAccordionDetails);

	const [expand, setExpand] = useState(false);
	const [subExpand, setSubExpand] = useState(false);
	const handleAccordioChange = (panel, id) => (event, isExpanded) => {
		setExpand(isExpanded ? panel : false);
	};
	const handleSubAccordioChange = (panel, id) => (event, isExpanded) => {
		setSubExpand(isExpanded ? panel : false);
	};
	const accordionRef = useRef(null);

  const handleDownloadSelect = (e) => {
    localStorage.getItem('status')=== "TRIAL" && props.set_snack_bar(true, "You Need Premium Subscription To Access This Feature")
    setAnchorEl(null);
  };
  const handleDownloadWord = () => {
   localStorage.getItem('status')=== "TRIAL" ? props.set_snack_bar(true, "You Need Premium Subscription To Access This Feature") : getSustainabilityDocx(login.token, assessment_id);
    setAnchorEl(null);
  };

  const handleDownloadXBRL = () => {
    localStorage.getItem('status')=== "TRIAL" ? props.set_snack_bar(true, "You Need Premium Subscription To Access This Feature") :  getSustainabilityXBRL(login.token, assessment_id);
    setAnchorEl(null);
  };

	const handleDownloadExcel = () => {
		localStorage.getItem('status')=== "TRIAL" ? props.set_snack_bar(true, "You Need Premium Subscription To Access This Feature") :  getSustainabilityExcel(login.token, assessment_id);
		setAnchorEl(null);
	};

	const MetricCard = ({ mainTopic, masterTopics, index }) => {
		// console.log('masterTopics', masterTopics);
		const mainTopicArray = [
			"Social Capital",
			"Human Capital",
			"Environmental",
			"Environment",
			"Business Model & Innovation",
			"Leadership & Governance",
			"General Disclosure",
			"SECTION A",
			"SECTION B",
			"SECTION C",
		];

		return (
			<>
				<Box>
					{/* <Paper style={{ border:'1px solid lightGrey',boxShadow: '1px 1px 1px' }} elevation={1}> */}
					<Accordion
						ref={accordionRef}
						TransitionProps={{ unmountOnExit: false }}
						expanded={expand === `pannel${index}`}
						onChange={handleAccordioChange(`pannel${index}`)}
					>
						{/* <MetricMainCard /> */}
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							{/* <MetricMainCard /> */}
							{mainTopic.topic_name === "Social Capital" && (
								<>
									{" "}
									<Box
										className={clsx(
											classes.metricCardHeader,
											classes.BoxBaground
										)}
									>
										<Social className={classes.fontSize} />
										<div
											style={{
												fontFamily: "poppins",
												border: "1px solid #DAF1EE",
												backgroundColor: "#DAF1EE",
												margin: "2px",
												borderRadius: "5px",
												width: "3px",
												height: "23px",
												fontWeight: "bold",
											}}
										></div>
										<Typography className={classes.Typography}>
											{mainTopic.topic_name}
										</Typography>
									</Box>
									<Typography
										style={{
											fontSize: "1em",
											color: "grey",
											fontFamily: "poppins",
										}}
									>
										{" "}
										{mainTopic.description && mainTopic.description}
									</Typography>
								</>
							)}
							{mainTopic.topic_name === "Human Capital" && (
								<Box
									className={clsx(
										classes.metricCardHeader,
										classes.BoxBaground1
									)}
								>
									<Human className={classes.fontSize} />
									<Typography className={classes.Typography}>
										{mainTopic.topic_name}
									</Typography>
								</Box>
							)}
							{(mainTopic.topic_name === "Environmental" ||
								mainTopic.topic_name === "Environment") && (
								<Box
									className={clsx(
										classes.metricCardHeader,
										classes.BoxBaground2
									)}
								>
									<Environmental className={classes.fontSize} />
									<Typography className={classes.Typography}>
										{mainTopic.topic_name}
									</Typography>
								</Box>
							)}
							{mainTopic.topic_name === "Business Model & Innovation" && (
								<Box
									className={clsx(
										classes.metricCardHeader,
										classes.BoxBaground3
									)}
								>
									<Business className={classes.fontSize} />
									<Typography className={classes.Typography}>
										{mainTopic.topic_name}
									</Typography>
								</Box>
							)}
							{mainTopic.topic_name === "Leadership & Governance" && (
								<Box
									className={clsx(
										classes.metricCardHeader,
										classes.BoxBaground4
									)}
								>
									<Governance className={classes.fontSize} />
									<Typography className={classes.Typography}>
										{mainTopic.topic_name}
									</Typography>
								</Box>
							)}
							{mainTopic.topic_name === "General Disclosure" && (
								<Box
									className={clsx(
										classes.metricCardHeader,
										classes.BoxBaground5
									)}
								>
									<GeneralDisclosureWhite className={classes.fontSize} />
									<Typography className={classes.Typography}>
										{mainTopic.topic_name}
									</Typography>
								</Box>
							)}
							{(mainTopic.topic_name === "SECTION A" ||
								mainTopic.topic_name === "SECTION B" ||
								mainTopic.topic_name === "SECTION C") && (
								<Box
									className={clsx(
										classes.metricCardHeader1
										// classes.BoxBaground5
									)}
								>
									<Grid container alignItems="center">
										<GeneralDisclosure className={classes.fontSize} />
										<div
											style={{
												border: "1px solid #DAF1EE",
												backgroundColor: "#DAF1EE",
												margin: "2px",
												borderRadius: "5px",
												width: "3px",
												height: "23px",
												fontWeight: "bold",
											}}
										></div>
										<Typography className={classes.Typography}>
											{mainTopic.topic_name}
											{/* {mainTopic.topic_name} */}
										</Typography>
									</Grid>
									<div>
										<Typography
											style={{
												fontSize: "1em",
												fontFamily: "poppins",
												color: "grey",
												paddingLeft: "8px",
											}}
										>
											{" "}
											{mainTopic.description && mainTopic.description}
										</Typography>
									</div>
									<Grid>
										{/* <Typography
                      variant="caption"
                      style={{
                        fontSize: '13px',
                        color: 'grey',
                        paddingLeft: '10px',
                      }}
                    >
                      Principle wise Perfomance disclosures
                    </Typography> */}
									</Grid>
								</Box>
							)}
							{!mainTopicArray.includes(mainTopic.topic_name) && (
								<Box
									className={clsx(
										classes.metricCardHeader,
										classes.BoxBaground4
									)}
								>
									<Typography className={classes.Typography}>
										{mainTopic.topic_name}
									</Typography>
								</Box>
							)}
						</AccordionSummary>
						<AccordionDetails>
							<Grid container>
								{mainTopic.sub_metrics.length !== 0 &&
									mainTopic.sub_metrics.map(
										(metric) =>
											metric && (
												<Grid
													item
													className={`${
														currentSelected._id === metric._id
															? classes.selectedMetricCardBar
															: classes.metricCardBar
													}`}
												>
													<Grid
														container
														alignItems="center"
														justifyContent="space-between"
														onClick={(e) => {
															getMetrics(
																login.token,
																assessment_id,
																metric._id,
																true,
																basicInfo.templateType
															);
															setCurrentSelected(metric);
														}}
													>
														<Grid md={11}>
															<div
																style={{
																	display: "inline-flex",
																	justifyContent: "space-between",
																	alignItems: "center",
																	// width: '72%',
																	height: "30px",
																	padding: "5px",
																	border: "none",
																	backgroundColor: "#EBF1F8",
																	color: "#3374B9",
																	borderRadius: "5px",
																}}
															>
																<div
																	style={{
																		border: "1px solid #3374B9",
																		backgroundColor: "#3374B9",
																		margin: "2px",
																		borderRadius: "5px",
																		width: "3px",
																		height: "18px",
																		fontWeight: "bold",
																	}}
																></div>
																<div>
																	<Typography
																		style={{
																			padding: "2px 5px 2px 2px",
																			fontFamily: "poppins",
																		}}
																	>
																		{` ${metric.metric_name}`}
																	</Typography>
																</div>
															</div>
														</Grid>

														<Grid md={1}>
															<ArrowForwardIcon
																className={classes.metricIcon}
															/>
														</Grid>

														{false && (
															<Grid
																container
																style={{
																	alignContent: "center",
																	paddingRight: "15px",
																}}
																item
																xs={5}
															>
																<Grid md={1} item>
																	{" "}
																	<Typography
																		style={{
																			fontSize: "13px",
																			fontWeight: "500",
																			fontFamily: "poppins",
																		}}
																	>
																		28%
																	</Typography>
																</Grid>
																<Grid md={11} style={{ padding: "8px" }} item>
																	<LinearProgress
																		variant="determinate"
																		style={{
																			color: "#3374B9",
																			height: "5px",
																			borderRadius: "8px",
																		}}
																		value={28}
																	/>
																</Grid>
															</Grid>
														)}
													</Grid>

													{(metric.metric_desc || mainTopic.description) && (
														<Grid>
															<Typography className={classes.Typography1}>
																{metric.metric_desc && metric.metric_desc}
																{/* {mainTopic.topic_name
                            ? mainTopic.topic_name
                            : metric.metric_name} */}
															</Typography>
															<Typography
																style={{
																	fontSize: "1em",
																	color: "grey",
																	paddingLeft: "20px",
																}}
															>
																{" "}
																{mainTopic.description && mainTopic.description}
															</Typography>
														</Grid>
													)}
												</Grid>
											)
									)}
								{mainTopic.sub_topics.length > 0 &&
									mainTopic.sub_topics.map((subTopic, i) => {
										return (
											subTopic && (
												<Accordion
													expanded={subExpand === `pannel${i}`}
													onChange={handleSubAccordioChange(`pannel${i}`)}
													// TransitionProps={{ unmountOnExit: false }}
												>
													<AccordionSummary expandIcon={<ExpandMoreIcon />}>
														<div item>
															<div
																style={{
																	display: "inline-flex",
																	justifyContent: "space-between",
																	alignItems: "center",
																	// width: '72%',
																	// height: '30px',
																	padding: "5px",
																	border: "none",
																	backgroundColor: "#EBF1F8",
																	color: "#3374B9",
																	borderRadius: "5px",
																}}
															>
																<div
																	style={{
																		border: "1px solid #3374B9",
																		backgroundColor: "#3374B9",
																		margin: "2px",
																		borderRadius: "5px",
																		width: "3px",
																		height: "18px",
																		fontWeight: "bold",
																	}}
																></div>
																<div>
																	<Typography
																		style={{ padding: "2px 5px 2px 2px" }}
																	>
																		{subTopic && subTopic.topic_name}
																	</Typography>
																</div>
															</div>
														</div>
														{false && (
															<Grid
																container
																style={{ alignContent: "center" }}
																item
																xs={5}
															>
																<Grid md={1} item>
																	{" "}
																	<Typography
																		style={{
																			fontSize: "13px",
																			fontWeight: "500",
																		}}
																	>
																		28%
																	</Typography>
																</Grid>
																<Grid md={11} style={{ padding: "8px" }} item>
																	<LinearProgress
																		variant="determinate"
																		style={{
																			color: "#3374B9",
																			height: "5px",
																			borderRadius: "8px",
																		}}
																		value={28}
																	/>
																</Grid>
															</Grid>
														)}
														{/* <div>
                            <Typography
                              style={{ fontSize: '1em', color: 'grey' }}
                            >
                              {subTopic.description && subTopic.description}
                            </Typography>
                          </div> */}
													</AccordionSummary>
													<AccordionDetails>
														<Grid md={12} sm={12}>
															{subTopic &&
																subTopic.sub_metrics.length > 0 &&
																subTopic &&
																subTopic.sub_metrics.map((metric) => {
																	return (
																		metric && (
																			<Grid
																				// justifyContent="center"
																				className={`${
																					currentSelected._id === metric._id
																						? classes.selectedMetricCardBar
																						: classes.metricCardBar
																				}`}
																			>
																				<Grid
																					item
																					container
																					justifyContent="space-between"
																					alignItems="center"
																					onClick={(e) => {
																						getMetrics(
																							login.token,
																							assessment_id,
																							metric._id,
																							true,
																							basicInfo.templateType
																						);
																						setCurrentSelected(metric);
																					}}
																				>
																					{/* <Grid>
        {`disclosure ${metric.metric_name.split(':')[0]}`}
      </Grid> */}
																					<Grid md={11}>
																						<div
																							style={{
																								display: "inline-flex",
																								justifyContent: "space-between",
																								alignItems: "center",
																								// width: '72%',
																								// height: '30px',
																								padding: "5px",
																								border: "none",
																								backgroundColor: "#EBF1F8",
																								color: "#3374B9",
																								borderRadius: "5px",
																							}}
																						>
																							<div
																								style={{
																									border: "1px solid #3374B9",
																									backgroundColor: "#3374B9",
																									margin: "2px",
																									borderRadius: "5px",
																									width: "3px",
																									height: "20px",
																									fontWeight: "bold",
																								}}
																							></div>
																							<div>
																								<Typography
																									style={{
																										padding: "2px 5px 2px 2px",
																									}}
																								>
																									{` ${metric.metric_name}`}
																								</Typography>
																							</div>
																						</div>
																					</Grid>
																					<Grid md={1}>
																						{" "}
																						<ArrowForwardIcon
																							className={classes.metricIcon}
																						/>
																					</Grid>
																					{/* // percentage for submetrics */}
																					{/* <Grid
                                        container
                                        style={{ alignContent: 'center' }}
                                        item
                                        xs={5}
                                      >
                                        <Grid md={1} item>
                                          {' '}
                                          <Typography
                                            style={{
                                              fontSize: '13px',
                                              fontWeight: '500',
                                            }}
                                          >
                                            28%
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          md={11}
                                          style={{ padding: '8px' }}
                                          item
                                        >
                                          <LinearProgress
                                            variant="determinate"
                                            style={{
                                              color: '#3374B9',
                                              height: '5px',
                                              borderRadius: '8px',
                                            }}
                                            value={28}
                                          />
                                        </Grid>
                                      </Grid> */}
																				</Grid>
																				<div>
																					<Typography
																						style={{
																							fontSize: "1em",
																							color: "grey",
																						}}
																					>
																						{metric.description &&
																							metric.description}
																					</Typography>
																				</div>
																			</Grid>
																		)
																	);
																})}
															{subTopic &&
																subTopic.sub_topics.length > 0 &&
																subTopic &&
																subTopic.sub_topics.map((subTopic) => {
																	return (
																		subTopic && (
																			<Accordion
																				TransitionProps={{
																					unmountOnExit: false,
																				}}
																			>
																				<AccordionSummary
																					expandIcon={<ExpandMoreIcon />}
																				>
																					<div item>
																						<div
																							style={{
																								display: "flex",
																								justifyContent: "space-between",
																								alignItems: "center",
																								// width: '72%',
																								// height: '30px',
																								padding: "5px",
																								border: "none",
																								backgroundColor: "#EBF1F8",
																								color: "#3374B9",
																								borderRadius: "5px",
																							}}
																						>
																							<div
																								style={{
																									border: "1px solid #3374B9",
																									backgroundColor: "#3374B9",
																									margin: "2px",
																									borderRadius: "5px",
																									width: "3px",
																									height: "18px",
																									fontWeight: "bold",
																								}}
																							></div>
																							<div>
																								<Typography
																									style={{
																										padding: "2px 5px 2px 2px",
																									}}
																								>
																									{subTopic &&
																										subTopic.topic_name}
																								</Typography>
																							</div>
																						</div>
																					</div>
																					{false && (
																						<Grid
																							container
																							style={{ alignContent: "center" }}
																							item
																							xs={5}
																						>
																							<Grid md={1} item>
																								{" "}
																								<Typography
																									style={{
																										fontSize: "13px",
																										fontWeight: "500",
																									}}
																								>
																									28%
																								</Typography>
																							</Grid>
																							<Grid
																								md={11}
																								style={{ padding: "8px" }}
																								item
																							>
																								<LinearProgress
																									variant="determinate"
																									style={{
																										color: "#3374B9",
																										height: "5px",
																										borderRadius: "8px",
																									}}
																									value={28}
																								/>
																							</Grid>
																						</Grid>
																					)}
																					{/* <div>
                                        <Typography
                                          style={{ fontSize: '1em', color: 'grey' }}
                                        >
                                          {subTopic.description && subTopic.description}
                                        </Typography>
                                      </div> */}
																				</AccordionSummary>
																				<AccordionDetails>
																					<Grid md={12} sm={12}>
																						{subTopic &&
																							subTopic.sub_metrics.length > 0 &&
																							subTopic &&
																							subTopic.sub_metrics.map(
																								(metric) => {
																									return (
																										metric && (
																											<Grid
																												className={`${
																													currentSelected._id ===
																													metric._id
																														? classes.selectedMetricCardBar
																														: classes.metricCardBar
																												}`}
																											>
																												<Grid
																													item
																													container
																													alignItems="center"
																													onClick={(e) => {
																														getMetrics(
																															login.token,
																															assessment_id,
																															metric._id,
																															true,
																															basicInfo.templateType
																														);
																														setCurrentSelected(
																															metric
																														);
																													}}
																												>
																													{/* <Grid>
        {`disclosure ${metric.metric_name.split(':')[0]}`}
      </Grid> */}
																													<Grid md={11} item>
																														<div
																															style={{
																																display:
																																	"inline-flex",
																																justifyContent:
																																	"space-between",
																																alignItems:
																																	"center",
																																// width: '72%',
																																// height: '30px',
																																padding: "5px",
																																border: "none",
																																backgroundColor:
																																	"#EBF1F8",
																																color:
																																	"#3374B9",
																																borderRadius:
																																	"5px",
																															}}
																														>
																															<div
																																style={{
																																	border:
																																		"1px solid #3374B9",
																																	backgroundColor:
																																		"#3374B9",
																																	margin: "2px",
																																	borderRadius:
																																		"5px",
																																	width: "3px",
																																	height:
																																		"20px",
																																	fontWeight:
																																		"bold",
																																}}
																															></div>
																															<div>
																																<Typography
																																	style={{
																																		padding:
																																			"2px 5px 2px 2px",
																																	}}
																																>
																																	{` ${metric.metric_name}`}
																																</Typography>
																															</div>
																														</div>
																													</Grid>
																													<Grid item md={1}>
																														<ArrowForwardIcon
																															className={
																																classes.metricIcon
																															}
																														/>
																													</Grid>
																												</Grid>
																												<div>
																													<Typography
																														style={{
																															fontSize: "1em",
																															color: "grey",
																														}}
																													>
																														{metric.description &&
																															metric.description}
																													</Typography>
																												</div>
																											</Grid>
																										)
																									);
																								}
																							)}
																					</Grid>
																				</AccordionDetails>
																			</Accordion>
																		)
																	);
																})}
														</Grid>
													</AccordionDetails>
												</Accordion>
											)
										);
									})}
							</Grid>
						</AccordionDetails>
					</Accordion>
					{/* </Paper> */}
				</Box>
			</>
		);
	};

	const shareDialog = () => {
		return (
			<Dialog open={shareDialogOpen} fullWidth="true" maxWidth="sm">
				<DialogTitle>
					<Grid container justifyContent="center" className={classes.mainGrid2}>
						<Grid item xs={4}>
							<Typography className={classes.Typography3}>
								Share Report
							</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						spacing={1}
						direction="column"
						alignItems="flex-start"
						// style={{}}
					>
						<Grid item md={12} className={classes.grid8}>
							<Paper elevation={0} className={classes.Paper}>
								<Autocomplete
									size="small"
									multiple
									// value={emails}
									options={generalSettings.allUsers.map((user) => user.email)}
									renderTags={(value, getTagProps) => {
										return value.map((option, index) => (
											<>
												<Chip
													variant="outlined"
													label={option}
													{...getTagProps({ index })}
												/>
											</>
										));
									}}
									onChange={(event, newValue) => {
										// setEmails(newValue);
									}}
									renderInput={(params) => (
										<>
											<CustomTextfield
												{...params}
												size="small"
												variant="outlined"
												fullWidth
												label="Search By Emai ID or Name"
												name="asgEmailStep3"
											/>
										</>
									)}
								/>
							</Paper>
						</Grid>
						{true && (
							<>
								<Grid item className={classes.grid8}>
									<CustomTextfield
										label="Email Subject"
										name="subject"
										fullWidth
										size="small"
										variant="outlined"
										style={{ marginTop: 10 }}
										// onChange={stakeHolderContentChange}
										helperText="hint : survey ESG requires contribution"
									/>
								</Grid>
								<Grid item className={classes.grid8}>
									<Typography className={classes.Typography5}>
										Description
									</Typography>
								</Grid>
								<Grid item className={classes.grid8}>
									<CustomTextfield
										name="content"
										multiline="true"
										onChange={(e) => {
											// stakeHolderContentChange(e);
										}}
										placeholder="Describe the Report"
										className={classes.CustomTextfield}
										fullWidth
										variant="outlined"
										inputProps={{ className: classes.shareContentText }}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container spacing={2} className={classes.grid7}>
						<Grid item xs={8} md={8} className={classes.grid3}>
							<CustomButton
								color="primary"
								variant="outlined"
								onClick={() => {
									setShareDialogOpen(false);
									// setEmails([]);
								}}
							>
								Cancel
							</CustomButton>
						</Grid>
						<Grid item xs={4} md={4} className={classes.grid6}>
							<CustomButton
								color="primary"
								variant="contained"
								className={classes.CustomButton3}
								onClick={() => {
									// handleShareAssessment();
									// setCheckedState(
									//   new Array(generalSettings.allUsers.length).fill(false)
									// );
									setShareDialogOpen(false);
								}}
							>
								Share Report
							</CustomButton>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	};
	return (
		<div>
			{shareDialogOpen && shareDialog()}
			<Grid>
				<Grid md={12}>
					<Button
						onClick={() => setShareDialogOpen(true)}
						className={classes.shareButton}
					>
						Share report
						<ShareOutlinedIcon
							className={classes.headerIcon}
							color="primary"
							variant="outlined"
						/>
					</Button>

					<Button
						className={classes.downloadButton}
						onClick={(e) => setAnchorEl(e.currentTarget)}
					>
						Download <ExpandMoreIcon className={classes.headerIcon} />
					</Button>
					<Menu
						id="simple-menu"
						variant="selectedMenu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(null)}
						style={{ top: "45px", left: "20px", width: "280px" }}
					>
						<MenuItem value="Completed" onClick={handleDownloadSelect}>
							PDF
						</MenuItem>
						<MenuItem value="Approved" onClick={handleDownloadWord}>
							Word
						</MenuItem>
						<MenuItem value="Approved" onClick={handleDownloadXBRL}>
							XBRL
						</MenuItem>
						<MenuItem value="Approved" onClick={handleDownloadExcel}>
							EXCEL
						</MenuItem>
					</Menu>
				</Grid>
				<Grid container>
					<Grid
						item
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							border: "1px solid lightGrey",
							borderRadius: "8px",
							width: "130px",
							height: "110px",
							// backgroundColor: '#EBF1F8',
							marginTop: "20px",
							marginLeft: "15px",
						}}
					>
						<Grid style={{ width: "100%" }}>
							{" "}
							<Typography
								style={{
									// wordBreak: 'break',
									color: "#3374B9",
									fontSize: "14px",
									textAlign: "left",
									padding: "8px 2px 8px 8px",
									// textAlign: 'center',
								}}
							>
								Data Points Assigned
							</Typography>
						</Grid>
						<Grid style={{ width: "100%" }}>
							<Typography
								style={{
									fontSize: "36px",
									alignContent: "bottom",
									padding: "0px 12px",
									color: "#3374B9",
									fontWeight: 500,
								}}
							>
								{fieldData.reviewData &&
								fieldData.reviewData.total_data_points === 0
									? "-"
									: fieldData.reviewData &&
									  fieldData.reviewData.total_data_points}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							border: "1px solid lightGrey",
							borderRadius: "8px",
							width: "130px",
							height: "110px",
							// backgroundColor: '#EBF1F8',
							marginTop: "20px",
							marginLeft: "15px",
						}}
					>
						<Grid style={{ width: "100%" }}>
							{" "}
							<Typography
								style={{
									// wordBreak: 'break',
									color: "#3374B9",
									fontSize: "14px",
									textAlign: "left",
									padding: "8px 2px 8px 8px",
									// textAlign: 'center',
								}}
							>
								Data Points Collected
							</Typography>
						</Grid>
						<Grid style={{ width: "100%" }}>
							<Typography
								style={{
									fontSize: "36px",
									alignContent: "bottom",
									padding: "0px 12px",
									color: "#3374B9",
									fontWeight: 500,
								}}
							>
								{fieldData.reviewData &&
								fieldData.reviewData.total_data_points_collected === 0
									? "-"
									: fieldData.reviewData &&
									  fieldData.reviewData.total_data_points_collected}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							border: "1px solid lightGrey",
							borderRadius: "8px",
							width: "130px",
							height: "110px",
							// backgroundColor: '#EBF1F8',
							marginTop: "20px",
							marginLeft: "15px",
						}}
					>
						<Grid style={{ width: "100%" }}>
							{" "}
							<Typography
								style={{
									// wordBreak: 'break',
									color: "#3374B9",
									fontSize: "14px",
									textAlign: "left",
									padding: "8px 2px 8px 8px",
									// textAlign: 'center',
								}}
							>
								Data Points Completed
							</Typography>
						</Grid>
						<Grid style={{ width: "100%" }}>
							<Typography
								style={{
									fontSize: "36px",
									alignContent: "bottom",
									padding: "0px 12px",
									color: "#3374B9",
									fontWeight: 500,
								}}
							>
								{fieldData.reviewData &&
								fieldData.reviewData.total_data_points_completed === 0
									? "-"
									: fieldData.reviewData &&
									  fieldData.reviewData.total_data_points_completed}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							border: "1px solid lightGrey",
							borderRadius: "8px",
							width: "130px",
							height: "110px",
							// backgroundColor: '#EBF1F8',
							marginTop: "20px",
							marginLeft: "15px",
						}}
					>
						<Grid style={{ width: "100%" }}>
							{" "}
							<Typography
								style={{
									// wordBreak: 'break',
									color: "#3374B9",
									fontSize: "14px",
									textAlign: "left",
									padding: "8px 2px 8px 8px",
									// textAlign: 'center',
								}}
							>
								Data Points Approved
							</Typography>
						</Grid>
						<Grid style={{ width: "100%" }}>
							<Typography
								style={{
									fontSize: "36px",
									alignContent: "bottom",
									padding: "0px 12px",
									color: "#3374B9",
									fontWeight: 500,
								}}
							>
								{fieldData.reviewData &&
								fieldData.reviewData.total_data_points_approved === 0
									? "-"
									: fieldData.reviewData &&
									  fieldData.reviewData.total_data_points_approved}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Box className={classes.Box1}>
				{fieldData.reviewData &&
					fieldData.reviewData.final_data &&
					fieldData.reviewData.final_data.map((topic, i) => {
						return <MetricCard mainTopic={topic} index={i} />;
					})}
			</Box>
		</div>
	);
}

export default AuditTab;
