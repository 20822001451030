import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "../../../../UI/button/button";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
  },
  button: {
    marginRight: theme.spacing(2),
    minWidth: "100px",
    textTransform: "capitalize",
  },
}));

export default function SurveyButton({
  activeStep,
  handleBack,
  handleNext,
  steps,
  surveyCompleted,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { completedQuestions, questionStatus } = useSelector(
    (state) => state.surveyListing
  );
  const { selectedAssignSurvey } = useSelector((state) => state?.supplierAdmin);
  // console.log(questionStatus, selectedAssignSurvey?.is_default);
  const goBack = () => {
    history.goBack(); // This function takes you back to the previous route
  };

  console.log(selectedAssignSurvey);

  return (
    <div className={classes.root}>
      {activeStep < steps.length && (
        <CustomButton
          disabled={activeStep === 0 || surveyCompleted}
          onClick={handleBack}
          className={classes.button}
          color="primary"
          // style={{ color: "#3374B9" }}
        >
          Previous
        </CustomButton>
      )}

      {activeStep < steps.length && (
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() =>
            handleNext(activeStep === steps.length - 1 ? true : false)
          }
          className={classes.button}
          disabled={
            (activeStep !== steps.length - 1
              ? false
              : questionStatus?.no_of_completed_questions !==
                questionStatus?.total_no_of_questions) ||
            // : selectedAssignSurvey?.is_default
            // ? questionStatus?.no_of_completed_questions !==
            //   questionStatus?.total_no_of_questions
            // : completedQuestions.length === 0

            surveyCompleted
          }
          // style={{ background: "#3374B9" }}
        >
          {console.log(questionStatus)}

          {activeStep === steps.length - 1 ? "Submit Response" : "Proceed"}
        </CustomButton>
      )}
      {activeStep === steps.length && (
        <CustomButton
          variant="contained"
          color="primary"
          onClick={goBack}
          className={classes.button}
          // style={{ background: "#3374B9" }}
        >
          Go to Survey listing
        </CustomButton>
      )}
    </div>
  );
}
