import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";

import moment from "moment";
// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

// redux imports
import { useDispatch, useSelector } from "react-redux";

import NoData from "../../NoData/NoData";
import {
  getSurveys,
  setSelectedSection,
  setSelectedSurvey,
} from "../../../../actions/supplier/supplierAction";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#5C90C7",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#3374B9",
      },
    },
    "& .MuiPaginationItem-root": {
      "&:hover": {
        color: "#fff",
        backgroundColor: "#A1BFDF",
      },
    },
  },
  // table styles
  tableContainer: {
    height: "90%",
    boxShadow: "none",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRows: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    cursor: "pointer",
  },

  // survey status
  activeStatus: {
    padding: "8px 12px 8px 12px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
    fontWeight: "500",
    color: "#154328",
    backgroundColor: "#BFE2CD",
  },
  inactiveStatus: {
    padding: "8px 12px 8px 12px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "max-content",
    fontWeight: "500",
    color: "#8C7038",
    backgroundColor: "#FFEFD0",
  },

  // buttons
  actionButton: {
    fontWeight: "500",
    textTransform: "none",
    color: "#3374B9",
    width: "5rem",
  },
}));

const renderSurveyStatus = (status) => {
  const status_class =
    status === true
      ? {
          padding: "8px 12px 8px 12px",
          borderRadius: "80px",
          display: "flex",
          alignItems: "center",
          width: "max-content",
          fontWeight: "500",
          color: "#154328",
          backgroundColor: "#BFE2CD",
        }
      : {
          padding: "8px 12px 8px 12px",
          borderRadius: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "max-content",
          fontWeight: "500",
          color: "#8C7038",
          backgroundColor: "#FFEFD0",
        };
  return (
    <Box style={status_class}>
      <FiberManualRecordIcon
        style={{ width: 10, height: 10, marginRight: 5 }}
      />
      {status === true ? "Active" : "Inactive"}
    </Box>
  );
};

// ----------------- Main Render -----------------
const SurveyInMain = ({ searchKeyword }) => {
  // from redux
  const dispatch = useDispatch();
  const { surveyData, surveyPaginationPage, total_survey } = useSelector(
    (state) => state.supplier
  );

  const { token, current_organisation } = useSelector((state) => state.login);

  const classes = useStyles();
  const history = useHistory();

  // table headings
  const headings = [
    "Survey Name",
    // "Survey Type",
    "Last Modified",
    "Status",
    "Created by",
    "Action",
  ];

  // states
  const [publishedRows, setPublishedRows] = useState({});
  const [anchorEl, setAnchorEl] = useState(null); // For Menu anchor element
  const [surveyOption, setSurveyOption] = useState(null);
  const [page, setPage] = useState(1);

  const limit = 5;

  // --------------- inner functions ---------------
  // publish handler
  const handlePublish = (index) => {
    setPublishedRows((prevRows) => {
      return {
        ...prevRows,
        [index]: !prevRows[index],
      };
    });
  };

  // Menu open/close handler
  const handleMenuToggle = (event, survey) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
    setSurveyOption(survey);
  };

  // Menu item click handler
  const handleMenuItemClick = (event) => {
    const menuItemText = event.currentTarget.textContent;

    // Determine which menu item was clicked
    switch (menuItemText) {
      case "Edit":
        // Logic for Edit
        console.log("Edit survey:", surveyOption);
        break;
      case "Duplicate":
        // Logic for Duplicate
        console.log("Duplicate survey:", surveyOption);
        break;
      case "Delete Survey":
        // Logic for Delete Survey
        console.log("Deleting survey:", surveyOption);
        break;
      default:
        break;
    }

    // Close the menu
    handleMenuToggle();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Survey Status

  // Button click handler to prevent propagation
  const handleButtonClick = (event) => {
    event.stopPropagation();
  };

  // Survey click handler
  const handleSurveyClick = (selectedSurvey) => {
    // setting selected survey
    dispatch(setSelectedSurvey(selectedSurvey));

    // Navigate to the survey details
    history.push(`/supplier/survey_details`);
  };

  const fetchSurvey = (page) => {
    const starting_after = (page - 1) * limit;
    dispatch(
      getSurveys(
        token,
        current_organisation,
        searchKeyword,
        limit,
        starting_after
      )
    );
  };

  // -----------------------------------------------
  useEffect(() => {
    fetchSurvey(page);
    dispatch(setSelectedSection(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchKeyword]);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {!surveyData || surveyData.length === 0 ? (
        // Check for No Survey Data
        <NoData />
      ) : (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {headings.map((heading, index) => (
                  <TableCell key={index} className={classes.tableHead}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {surveyData.slice(startIndex, endIndex).map((survey, index) => ( */}
              {surveyData.map((survey, index) => (
                <TableRow
                  className={classes.tableRows}
                  key={index}
                  onClick={() => handleSurveyClick(survey)}
                >
                  <TableCell>{survey.survey_name}</TableCell>
                  {/* <TableCell>{survey.survey_type}</TableCell> */}
                  <TableCell>
                    {moment(survey.last_modified_on).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell>{renderSurveyStatus(survey.status)}</TableCell>
                  <TableCell>{survey?.created_by}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {/* <Button
                    className={classes.actionButton}
                    onClick={(e) => {
                      handleButtonClick(e);
                      handlePublish(index);
                    }}
                  >
                    {!publishedRows[index] ? "Publish" : "Unpublish"}
                  </Button> */}
                      <IconButton
                        aria-controls="survey-actions-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleButtonClick(e);
                          // handleMenuToggle(e, survey);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Survey Actions Menu */}
          <Menu
            id="survey-actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuToggle}
            PaperProps={{
              style: {
                borderRadius: 8,
                width: 200,
              },
            }}
          >
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                marginBottom: "10px",
              }}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <EditOutlinedIcon style={{ color: "#3374B9", marginRight: 8 }} />
              Edit
            </MenuItem>
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                marginBottom: "10px",
              }}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <FileCopyOutlinedIcon
                style={{ color: "#3374B9", marginRight: 8 }}
              />
              Duplicate
            </MenuItem>
            <MenuItem
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#D0766B",
              }}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <DeleteOutlinedIcon
                style={{ color: "#C45446", marginRight: 8 }}
              />
              Delete Survey
            </MenuItem>
          </Menu>
        </TableContainer>
      )}

      <Box style={{ position: "absolute", right: 10, bottom: 10 }}>
        <Pagination
          color="primary"
          classes={{ root: classes.root }}
          variant="outlined"
          count={Math.ceil(total_survey / limit)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default SurveyInMain;
