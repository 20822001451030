import React, { useEffect, useRef, useState, useCallback } from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import { FormControlLabel } from "@material-ui/core";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import Autocomplete from "@material-ui/lab/Autocomplete";
import MoreIcon from "@material-ui/icons/MoreVert";

import LinearProgress from "@material-ui/core/LinearProgress";

import ReportSkeleton from "../skeletons/reportSkeleton";
import { withStyles } from "@material-ui/core/styles";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import CustomButton from "../../UI/button/button";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import addcover from "../../../images/add_cover.png";
import share from "../../../images/share.png";
import headerfooter from "../../../images/headerfooter.png";
import viewcomments from "../../../images/view_comments.png";
import useDebounce from "../../../customHooks/useDebouncec";
import sustainabilityReportPDFStyles from "./sustainablityReportPDFStyles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import tablecontent from "../../../images/table_of_content.png";
import FontSelector from "../assesmentApproval/fontSelector";
import ColorGradient from "../assesmentApproval/colorGradient";
import Image from "../../../images/bcd.png";

const InnerAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const InnerAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const InnerAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CommentsAccordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
  },
})(MuiAccordion);
const CommentsAccordionSummary = withStyles({
  root: {
    backgroundColor: "#DDE9F6",
    minHeight: "64px",
    borderRadius: "5px",
    borderTop: "none",
  },
})(MuiAccordionSummary);

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const SustainabilityReportPDF = (props) => {
  const {
    login,
    assessment_id,
    sustainabilityReport,
    viewCommentsToc,
    addCommentToc,
    addCover,
    generalSettings,
    materialityAssessment,
    viewAllUsers,
    getStakeholder,
    assignMembers,
    editToc,
    viewAllComments,
    viewComments,
    comments,
    addHeaderFooter,
    viewAssignedMembers,
    updateUserRole,
    deleteAssignedMember,
    removeFile,
    getSustainabilityReportPdf,
    setSustainabilityPdfProgress,
    setAssessmentSkeleton,
    resetPdfProgress,
  } = props;

  const classes = sustainabilityReportPDFStyles();

  const [setupOption, setSetupOption] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [commentsAccordionExpanded, setCommentsAccordionExpanded] =
    useState(false);

  const [coverImage, setCoverImage] = useState("");

  const [coverImageUrl, setCoverImageUrl] = useState("");

  const [coverImageUrlPdf, setCoverImageUrlPdf] = useState("");
  const [coverLogo, setCoverLogo] = useState("");

  const [coverLogoUrl, setCoverLogoUrl] = useState("");
  const [coverLogoUrlPdf, setCoverLogoUrlPdf] = useState("");
  const [coverHeadline, setCoverHeadline] = useState("");

  const [coverSubline, setCoverSubline] = useState("");

  const [coverRregularText, setCoverRregularText] = useState("");

  const [backCoverImage, setBackCoverImage] = useState("");

  const [backCoverImageUrl, setBackCoverImageUrl] = useState("");
  const [backCoverImageUrlPdf, setBackCoverImageUrlPdf] = useState("");
  const [backCoverLogo, setBackCoverLogo] = useState("");

  const [backCoverLogoUrl, setBackCoverLogoUrl] = useState("");
  const [backCoverLogoUrlPdf, setBackCoverLogoUrlPdf] = useState("");
  const [coverBackHeadline, setCoverBackHeadline] = useState("");

  const [coverBackSubline, setCoverBackSubline] = useState("");
  const [coverBackRegular, setCoverBackRegular] = useState("");

  const [headerText, setHeaderText] = useState("");

  const [headerLogo, setHeaderLogo] = useState("");

  const [headerLogoUrl, setHeaderLogoUrl] = useState("");
  const [headerLogoUrlPdf, setHeaderLogoUrlPdf] = useState("");
  const [footerText, setFooterText] = useState("");

  const [isPageNumberVisible, setIsPageNumberVisible] = useState(true);
  const [isPNumberDefaultPosition, setIsPNumberDefaultPosition] =
    useState(true);

  const [showSendButton, setShowSendButton] = useState(false);
  const [showTocInput, setShowTocInput] = useState(false);
  const [idToShowInput, setIdToShowInput] = useState("");

  const [toc, setToc] = useState([]);
  const [comment, setComment] = useState("");
  const [focusedTocInputId, setFocusedTocInputId] = useState(null);
  const [commentTocId, setCommentTocId] = useState("");
  const [tocId, setTocId] = useState("");

  const initial = {
    name: null,
    id: "",
    visible: true,
    order: "",
    assessment_topic_id: "",
  };
  const [editTocObj, setEditTocObj] = useState(initial);
  const [editTocName, setEditTocName] = useState("");

  const [editTocAssessmentId, setEditTocAssessmentId] = useState("");

  const [search, setSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [userTypes, setUserTypes] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [hideLoader, setHideLoader] = useState(false);
  const [toCallPdfApi, setToCallPdfApi] = useState(true);

  const [showRevomeUserIcon, setShowRevomeUserIcon] = useState(false);
  const [userId, setUserId] = useState("");
  const [showDeleteUserDailogue, setShowDeleteUserDailogue] = useState(false);
  const [removeUserEmail, setRemoveUserEmail] = useState("");
  const [showResetPdfProgressDialogue, setShowResetPdfProgressDialogue] =
    useState(false);

  const [blob, setBlob] = useState("");

  const [localSustainablityReport, setLocalSustainablityReport] = useState([]);
  const [allComment, setAllComment] = useState([]);

  // font state
  const [selectedFont, setSelectedFont] = useState("");
  const [selectedColor, setSelectedColor] = useState({});
  const [imageLogo, setImageLogo] = useState("");

  const handleFontComplete = (font) => {
    setSelectedFont(font);
  };

  const handleColorComplete = (color) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    setSelectedFont(sustainabilityReport.font);
    setSelectedColor(sustainabilityReport.color);
    setImageLogo(
      sustainabilityReport.basicData && sustainabilityReport.basicData?.url
    );
  }, [
    sustainabilityReport.font,
    sustainabilityReport.color,
    sustainabilityReport.basicData,
  ]);

  useEffect(() => {
    function handleCommentFilter(c) {
      return localSustainablityReport.some((l) => {
        return l.assessment_topic_id === ""
          ? l.toc_id === c.toc_id
          : l.assessment_topic_id === c.assessment_topic_id;
      });
    }
    const commentInit =
      comments.commentToc &&
      comments.commentToc.length > 0 &&
      comments.commentToc.filter((c) => {
        return handleCommentFilter(c);
      });

    setAllComment(commentInit);
  }, [localSustainablityReport, comments.commentToc]);

  const approver = "To Approve";
  const reviewer = "To Comment";

  useEffect(() => {
    if (
      (sustainabilityReport.pdfProgress[assessment_id] === 0 ||
        !sustainabilityReport.pdfProgress.hasOwnProperty(assessment_id)) &&
      toCallPdfApi
    ) {
      getSustainabilityReportPdf(
        login.token,
        assessment_id,
        sustainabilityReport.jwt_has,
        6
      );
    }
  }, [sustainabilityReport.pdfProgress[assessment_id], toCallPdfApi]);

  useEffect(() => {
    setLocalSustainablityReport(
      sustainabilityReport.tableOfContent.filter(
        (t) => (t.visible === true && t.page_no) || t.visible === false
      )
    );
  }, [sustainabilityReport]);

  useEffect(() => {
    return () => {
      if (sustainabilityReport.pdfProgress[assessment_id] === 100) {
        setSustainabilityPdfProgress({ id: assessment_id, progress: 0 });
      }
    };
  }, [sustainabilityReport.pdfProgress]);

  useEffect(() => {
    if (
      sustainabilityReport.pdfProgress[assessment_id] === 0 ||
      sustainabilityReport.pdfProgress[assessment_id] === null ||
      !sustainabilityReport.pdfProgress.hasOwnProperty(assessment_id)
    ) {
      hideLoader && setHideLoader(false);
    }
    if (sustainabilityReport.pdfProgress[assessment_id] === 100) {
      setTimeout(() => {
        setHideLoader(true);
      }, 500);
    }
  }, [sustainabilityReport.pdfProgress[assessment_id]]);

  useEffect(() => {
    setBlob(sustainabilityReport.pdfBlob[assessment_id]);
  }, [login.token, assessment_id, sustainabilityReport.pdfBlob[assessment_id]]);

  useEffect(() => {
    if (blob !== "" && hideLoader) {
      setAssessmentSkeleton(false);
    }
  }, [hideLoader, blob]);

  const handleAccordionChange = (panel, id) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    id && setTocId(id);
  };

  const handleAccordioChangeColor = useCallback(
    (panel, id) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  useEffect(() => {
    viewCommentsToc(props.login.token, tocId);
  }, [tocId, commentTocId]);

  const handleCommentsAccordionChange =
    (panel, id, assessment_topic_id) => (event, isExpanded) => {
      let assessmentTopicparam;
      setCommentsAccordionExpanded(isExpanded ? panel : false);
      if (assessment_topic_id) {
        assessmentTopicparam = assessment_topic_id;
      } else {
        assessmentTopicparam = "";
      }
      if (isExpanded) {
        viewComments(props.login.token, id, assessmentTopicparam, 6);
      }
    };

  const handleEditTableOfContentClick = () => {
    setSetupOption(4);
  };

  const handleTocVisibility = (
    name,
    id,
    visibility,
    order,
    assessment_topic_id
  ) => {
    setEditTocObj({
      name: name,
      id: id,
      visible: visibility,
      order: order,
      assessment_topic_id: assessment_topic_id,
    });
    let newLocalReport = localSustainablityReport.map((toc, i) => {
      if (
        (toc.toc_id && toc.toc_id === id) ||
        (toc.assessment_topic_id &&
          toc.assessment_topic_id === assessment_topic_id)
      ) {
        editToc(
          login.token,
          name,
          id,
          assessment_topic_id,
          6,
          visibility,
          order,
          assessment_id,
          false
        );
        return { ...toc, visible: visibility };
      }
      return toc;
    });
    setLocalSustainablityReport(newLocalReport);
  };
  const [tocApplyDisabler, setTocEyeDisabler] = useState(false);
  const textFieldRef = useRef();

  useDebounce(
    () => {
      textFieldRef.current && textFieldRef.current.blur();
    },
    2000,
    [editTocName]
  );

  const api = (toc) => {
    if (editTocObj.name !== "" && editTocObj.name !== toc.name) {
      editToc(
        login.token,
        editTocObj.name,
        editTocObj.id,
        editTocObj.assessment_topic_id,
        6,
        editTocObj.visible,
        editTocObj.order,
        assessment_id,
        true
      );
      let newLocalReport = localSustainablityReport.map((toc, i) => {
        if (
          toc.toc_id === editTocObj.id &&
          toc.assessment_topic_id === editTocObj.assessment_topic_id
        ) {
          return { ...toc, name: editTocObj.name };
        }
        return toc;
      });
      setLocalSustainablityReport(newLocalReport);
      setTocEyeDisabler(false);
      setEditTocObj(initial);
    }
  };

  const handleUploadCoverImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setCoverImage(file);
      reader.onloadend = () => {
        setCoverImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearCoverImage = () => {
    coverImageUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        6,
        coverImageUrlPdf,
        "front_cover_img"
      );
    setCoverImage("");
    setCoverImageUrl("");
  };

  const handleUploadCoverLogoImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setCoverLogo(file);
      reader.onloadend = () => {
        setCoverLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearCoverLogoImage = () => {
    coverLogoUrlPdf &&
      removeFile(login.token, assessment_id, 6, coverLogoUrlPdf, "front_logo");
    setCoverLogo("");
    setCoverLogoUrl("");
  };

  const handleUploadBackCoverImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setBackCoverImage(file);
      reader.onloadend = () => {
        setBackCoverImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearBackCoverImage = () => {
    backCoverImageUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        6,
        backCoverImageUrlPdf,
        "back_cover_img"
      );
    setBackCoverImage("");
    setBackCoverImageUrl("");
  };

  const handleUploadBackCoverLogoImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setBackCoverLogo(file);
      reader.onloadend = () => {
        setBackCoverLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearBackCoverLogoImage = () => {
    backCoverLogoUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        6,
        backCoverLogoUrlPdf,
        "back_logo"
      );
    setBackCoverLogo("");
    setBackCoverLogoUrl("");
  };

  const handleUploadHeaderLogoImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setHeaderLogo(file);
      reader.onloadend = () => {
        setHeaderLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearHeaderLogoImage = () => {
    headerLogoUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        6,
        headerLogoUrlPdf,
        "header_logo"
      );
    setHeaderLogo("");
    setHeaderLogoUrl("");
  };

  const handleShowInputToc = (e, topic) => {
    setShowTocInput(true);
    setIdToShowInput(e.target.id);
    setEditTocObj({
      name: e.target.innerText,
      id: topic.toc_id,
      visible: topic.visible,
      order: topic.order,
      assessment_topic_id: topic.assessment_topic_id,
    });
    setEditTocAssessmentId(topic.assessment_topic_id);
  };

  const handleViewCommentsClick = () => {
    setSetupOption(3);
  };

  const handleNewCommentSubmit = (id, assessment_topic_id, toc_name) => {
    comment.length !== 0 && setCommentTocId(id);
    const assessmentTopicParams = assessment_topic_id
      ? assessment_topic_id
      : "";
    const tocParam = assessment_topic_id ? "" : id;
    addCommentToc(
      props.login.token,
      id,
      comment,
      assessmentTopicParams,
      6,
      toc_name,
      assessment_id
    );
    setFocusedTocInputId(null);
    setComment("");
    setShowSendButton(false);
    setTimeout(() => {
      viewComments(login.token, tocParam, assessmentTopicParams, 6);
      viewAllComments(login.token, assessment_id, 6);
    }, [200]);
  };

  const handleEditToc = (e) => {
    setEditTocName(e.target.value);
    setEditTocObj((preValue) => ({ ...preValue, name: e.target.value }));
    // if (e.target.value !== '' && e.target.value !== editTocObj.name) {
    //   setTimeout(() => setEditTocName(e.target.value), 2000);
    // }
  };
  // console.log(editTocObj)

  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    setToc(sustainabilityReport.tableOfContent);
  }, [sustainabilityReport.tableOfContent]);

  useEffect(() => {
    if (sustainabilityReport.coverData !== null) {
      if (
        sustainabilityReport.coverData.back &&
        sustainabilityReport.coverData.back.text
      ) {
        setCoverBackHeadline(sustainabilityReport.coverData.back.text.heading);

        setCoverBackSubline(sustainabilityReport.coverData.back.text.subText);

        setCoverBackRegular(
          sustainabilityReport.coverData.back.text.regularText
        );
      }
      if (
        sustainabilityReport.coverData.front &&
        sustainabilityReport.coverData.front.back_img &&
        sustainabilityReport.coverData.front.back_img.url
      ) {
        setCoverImageUrlPdf(sustainabilityReport.coverData.front.back_img.url);
        setCoverImageUrl(sustainabilityReport.coverData.front.back_img.url);
      }
      if (
        sustainabilityReport.coverData.front &&
        sustainabilityReport.coverData.front.logo &&
        sustainabilityReport.coverData.front.logo.url
      ) {
        setCoverLogoUrlPdf(sustainabilityReport.coverData.front.logo.url);
        setCoverLogoUrl(sustainabilityReport.coverData.front.logo.url);
      }
      if (
        sustainabilityReport.coverData.front &&
        sustainabilityReport.coverData.front.text
      ) {
        setCoverHeadline(sustainabilityReport.coverData.front.text.heading);

        setCoverSubline(sustainabilityReport.coverData.front.text.subText);

        setCoverRregularText(
          sustainabilityReport.coverData.front.text.regularText
        );
      }
      if (
        sustainabilityReport.coverData.back &&
        sustainabilityReport.coverData.back.back_img &&
        sustainabilityReport.coverData.back.back_img.url
      ) {
        setBackCoverImageUrl(sustainabilityReport.coverData.back.back_img.url);
        setBackCoverImageUrlPdf(
          sustainabilityReport.coverData.back.back_img.url
        );
      }
      if (
        sustainabilityReport.coverData.back &&
        sustainabilityReport.coverData.back.logo &&
        sustainabilityReport.coverData.back.logo.url
      ) {
        setBackCoverLogoUrl(sustainabilityReport.coverData.back.logo.url);
        setBackCoverLogoUrlPdf(sustainabilityReport.coverData.back.logo.url);
      }
    }
  }, [sustainabilityReport.coverData, assessment_id]);

  //reseting the state value of cover data on unmounting
  useEffect(() => {
    return () => {
      setCoverHeadline("");
      setCoverSubline("");
      setCoverRregularText("");
      setCoverImageUrl("");
      setCoverLogoUrl("");
      setCoverBackHeadline("");
      setCoverBackSubline("");
      setCoverBackRegular("");
      setBackCoverImageUrl("");
      setBackCoverLogoUrl("");
    };
  }, [sustainabilityReport.coverData, assessment_id]);
  useEffect(
    () => {
      if (Object.keys(sustainabilityReport.headerFooterData).length > 0) {
        if (sustainabilityReport.headerFooterData.footer_text) {
          setFooterText(sustainabilityReport.headerFooterData.footer_text);
        }
        if (sustainabilityReport.headerFooterData.header_text) {
          setHeaderText(sustainabilityReport.headerFooterData.header_text);
        }
        if (sustainabilityReport.headerFooterData.logo) {
          setHeaderLogoUrl(sustainabilityReport.headerFooterData.logo.url);
          setHeaderLogoUrlPdf(sustainabilityReport.headerFooterData.logo.url);
        }
        if (sustainabilityReport.headerFooterData.hasOwnProperty("page_no")) {
          setIsPageNumberVisible(sustainabilityReport.headerFooterData.page_no);
        }
        if (
          sustainabilityReport.headerFooterData.hasOwnProperty(
            "is_page_no_position_left"
          )
        ) {
          setIsPNumberDefaultPosition(
            sustainabilityReport.headerFooterData.is_page_no_position_left
          );
        }
      }
    },
    [sustainabilityReport.headerFooterData],
    assessment_id
  );

  //resetting the state for headerfooterdata on unmounting

  useEffect(() => {
    return () => {
      setFooterText("");
      setHeaderText("");
      setHeaderLogoUrl("");
      setIsPageNumberVisible(true);
      setIsPNumberDefaultPosition(true);
    };
  }, [sustainabilityReport.headerFooterData, assessment_id]);

  const searchArr = materialityAssessment.assignedStakeholdersData.filter(
    (user) => user.user_data.email.includes(search === "" ? undefined : search)
  );

  const option = ["Approvers", "Reviewers"];
  const role = userTypes === "Approvers" ? 1 : 2;
  const approversArr = sustainabilityReport.approversReviewersList.filter(
    (data) => data.role === 1
  );
  const reviewersArr = sustainabilityReport.approversReviewersList.filter(
    (data) => data.role === 2
  );

  const option1 =
    generalSettings.allUsers &&
    generalSettings.allUsers.length > 0 &&
    generalSettings.allUsers.filter(
      (user) => typeof user.name === "string" && user.name.includes(inputValue)
    );

  const option2 =
    generalSettings.allUsers &&
    generalSettings.allUsers.length > 0 &&
    generalSettings.allUsers.filter(
      (user) =>
        typeof user.email === "string" && user.email.includes(inputValue)
    );

  useEffect(() => {
    viewAllUsers(login.token, login.current_organisation, keyword);
    getStakeholder(login.token, assessment_id, 6);
  }, [login.current_organisation, shareDialogOpen]);

  const handleAssignShareDialog = () => {
    setShareDialogOpen(false);
    assignMembers(
      login.token,
      emails,
      6,
      assessment_id,
      login.current_organisation,
      [],
      role,
      stakeholderContent.subject,
      stakeholderContent.content
    );
    setTimeout(() => {
      viewAssignedMembers(login.token, assessment_id, 6);
    }, 500);
  };
  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
  };

  useEffect(() => {
    viewAssignedMembers(login.token, assessment_id, 6);
  }, []);

  const handleDeleteUser = (id) => {
    deleteAssignedMember(login.token, id, assessment_id, 6);
    setShowDeleteUserDailogue(false);
  };

  const handleApproverChange = (e) => {
    let id = e.target.name;
    updateUserRole(login.token, id, 2, assessment_id, 6);
  };

  const handleReviewerChange = (e) => {
    // setReviewer(e.target.value);
    let id = e.target.name;
    updateUserRole(login.token, id, 1, assessment_id, 6);
  };
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At ${
      login.selectedOrganization && login.selectedOrganization.company_name
    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });

  const stakeHolderContentChange = (e) => {
    const { name, value, defaultValue } = e.target;
    setStakeholderContent((prev) => {
      return { ...prev, [name]: value };
    });
    if (stakeholderContent.content === "") {
      setStakeholderContent((prev) => {
        return { ...prev, [name]: defaultValue };
      });
    }
  };
  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.shareGrid}>
            <Grid item xs={3}>
              <Typography className={classes.shareTypo}>Share List</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
          >
            <Grid item md={12} className={classes.autoWidth}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                value={emails}
                multiple
                options={
                  option1.length > 0
                    ? option1.map((option) => option.email)
                    : option2.map((option) => option.email)
                }
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                onChange={(event, newValue) => {
                  setEmails(newValue);
                }}
                renderInput={(params) => (
                  <CustomTextfield
                    {...params}
                    name="asgEmailStep6"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Search By Emai ID or Name"
                  />
                )}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue == null ? " " : newInputValue);
                  setKeyword(newInputValue == null ? " " : newInputValue);
                }}
              />
            </Grid>

            <Grid item className={classes.halfWidth}>
              <CustomTextfield
                label="Approver / Reviewers"
                fullWidth
                InputLabelProps={{
                  style: { color: "#3374B9" },
                }}
                select
                size="small"
                variant="outlined"
                classaName={classes.CustomTextfield}
                value={userTypes}
                onChange={(e) => {
                  setUserTypes(e.target.value);
                }}
              >
                {option.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {true && (
              <>
                <Grid item className={classes.autoWidth}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.CustomTextfield}
                    onChange={stakeHolderContentChange}
                    helperText="hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.autoWidth}>
                  <Typography className={classes.typo8}>Content</Typography>
                </Grid>
                <Grid item className={classes.autoWidth}>
                  <CustomTextfield
                    name="content"
                    multiline="true"
                    onChange={(e) => {
                      stakeHolderContentChange(e);
                    }}
                    defaultValue={`At ${
                      login.selectedOrganization &&
                      login.selectedOrganization.company_name
                    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`}
                    fullWidth
                    variant="outlined"
                    className={classes.costumTextfield1}
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={3} md={6} className={classes.grid2}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShareDialogOpen(false);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.costumBtn}
                disabled={!userTypes || !emails.length > 0}
                onClick={() => {
                  handleAssignShareDialog();
                }}
              >
                {emails.length > 0 ? "Share Report" : "Add New User"}
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const removeUserDailogue = () => {
    return (
      <Dialog open={showDeleteUserDailogue} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid4}>
            <Grid item xs={5}>
              <Typography className={classes.typo1}>
                Delete User from list
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.typo2}>
            The user will only be deleted from the list and can still be found
            on the company profile. Are you sure want to delete the user ?
          </Typography>
          <Box className={classes.box}>
            <Chip variant="outlined" label={removeUserEmail} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid1}>
            <Grid item xs={3} md={6} className={classes.grid2}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setShowDeleteUserDailogue(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.costumBtn1}
                onClick={() => handleDeleteUser(userId)}
              >
                Delete User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    viewAllComments(login.token, assessment_id, 6);
  }, []);

  const handleAddCoverComplete = () => {
    const preColor = [
      ...sustainabilityReport.preSelectedColor,
      selectedColor.subline,
    ];

    setCoverImageUrlPdf(coverImageUrl);

    setCoverLogoUrlPdf(coverLogoUrl);

    setBackCoverImageUrlPdf(backCoverImageUrl);
    setBackCoverLogoUrlPdf(backCoverLogoUrl);
    setToCallPdfApi(false);
    setSustainabilityPdfProgress({
      id: assessment_id,
      progress: 0,
    });
    addCover(
      login.token,
      assessment_id,
      6,
      {
        heading: coverHeadline,
        subText: coverSubline,
        regularText: coverRregularText,
      },
      {
        heading: coverBackHeadline,
        subText: coverBackSubline,
        regularText: coverBackRegular,
      },
      coverImage,
      coverLogo,
      backCoverImage,
      backCoverLogo,
      sustainabilityReport.jwt_has,
      selectedFont,
      selectedColor,
      [...new Set(preColor)]
    );
  };

  const handleHeaderFooterComplete = () => {
    setHeaderLogoUrlPdf(headerLogoUrl);
    setToCallPdfApi(false);
    setSustainabilityPdfProgress({
      id: assessment_id,
      progress: 0,
    });
    addHeaderFooter(
      login.token,
      assessment_id,
      6,
      headerText,
      footerText,
      headerLogo,
      sustainabilityReport.jwt_has,
      isPageNumberVisible,
      isPNumberDefaultPosition
    );
  };

  const [filteredComments, setFilteredComments] = useState([]);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    let filtered =
      allComment.length > 0 &&
      allComment.filter((obj) => {
        return (
          typeof obj.name === "string" &&
          obj.name.toUpperCase().includes(searchText.toUpperCase())
        );
      });
    setFilteredComments(filtered);
  }, [searchText, allComment]);

  const commentsDisplay = (toMap) => {
    return toMap
      .filter((toc) => toc.visible)
      .map((toc, i) => {
        return (
          <>
            <CommentsAccordion
              variant="elevation"
              elevation={0}
              expanded={commentsAccordionExpanded === toc.name}
              onChange={handleCommentsAccordionChange(
                toc.name,
                toc.toc_id,
                toc.assessment_topic_id
              )}
              classes={{ root: classes.CommentsAccordion }}
              key={toc.toc_id + i}
            >
              <CommentsAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.CustomTextfield}
              >
                <Box className={classes.box1}>
                  <Typography>{toc.name}</Typography>
                  <Box className={classes.box6}>{toc.comments}</Box>
                </Box>
              </CommentsAccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box>
                    {comments.comments.map((comment, id) => {
                      return (
                        comment.toc_id === toc.toc_id && (
                          <Box key={id}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              p="16px 16px 0 16px"
                            >
                              <Box display="flex" alignItems="center">
                                <Avatar className={classes.avatar}></Avatar>
                                <Typography className={classes.typo3}>
                                  {comment.user_name}
                                </Typography>
                              </Box>

                              <Typography className={classes.typo3}>
                                {formatDate(comment.created_on)}
                              </Typography>
                            </Box>
                            <Box p="16px">
                              <Box pb="10px">{comment.comment}</Box>
                            </Box>
                          </Box>
                        )
                      );
                    })}
                  </Box>
                  <Box>
                    <CustomTextfield
                      name={toc.name.substring(0, 4)}
                      fullWidth
                      size="small"
                      id="show-reply"
                      variant="outlined"
                      label="Add Reply"
                      value={focusedTocInputId === toc.toc_id ? comment : ""}
                      onFocus={() => {
                        setFocusedTocInputId(toc.toc_id);
                        setShowSendButton(true);
                      }}
                      onChange={(e) => {
                        e.target.value !== " " && setComment(e.target.value);
                      }}
                    />
                    {showSendButton && comment && (
                      <CustomButton
                        endIcon={<Icon>send</Icon>}
                        variant="outlined"
                        className={classes.sendBtn}
                        color="primary"
                        onClick={() =>
                          handleNewCommentSubmit(
                            toc.toc_id,
                            toc.assessment_topic_id,
                            toc.name
                          )
                        }
                      >
                        Send
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            </CommentsAccordion>
          </>
        );
      });
  };

  useEffect(() => {
    if (sustainabilityReport.pdfProgressCounter[assessment_id] === 120) {
      setShowResetPdfProgressDialogue(true);
    }
  }, [sustainabilityReport.pdfProgressCounter[assessment_id]]);

  const resetPdfProgressDialog = () => {
    return (
      <Dialog open={showResetPdfProgressDialogue} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={5}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Reset PDF rendering
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ paddingBottom: "50px" }}>
            PDF is taking too much time than usual. Do you want to wait or
            restart the process?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={3}
              md={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setShowResetPdfProgressDialogue(false)}
              >
                Wait
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} style={{ paddingRight: 10 }}>
              <CustomButton
                color="primary"
                variant="contained"
                style={{ paddingLeft: 45, paddingRight: 45 }}
                onClick={() => {
                  resetPdfProgress(login.token, assessment_id, 6);
                  setSustainabilityPdfProgress({
                    id: assessment_id,
                    progress: 0,
                  });
                  setShowResetPdfProgressDialogue(false);
                }}
              >
                Reload
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {shareDialogOpen && shareDialog()}
      {showDeleteUserDailogue && removeUserDailogue()}
      {showResetPdfProgressDialogue && resetPdfProgressDialog()}
      <Grid container spacing={2} className={classes.grid5}>
        <Grid item xs={12} md={7} data-tut="reactour__sustainabilityLeft">
          {!hideLoader && (
            <LinearProgressWithLabel
              value={
                sustainabilityReport.pdfProgress[assessment_id]
                  ? sustainabilityReport.pdfProgress[assessment_id]
                  : 0
              }
            />
          )}
          {sustainabilityReport.assessmentSkeleton || !hideLoader ? (
            <ReportSkeleton />
          ) : (
            <iframe
              src={blob}
              title="pdf-test"
              width="100%"
              height="100%"
              name={Date.now()}
            />
          )}
        </Grid>

        <Grid item md={1}></Grid>
        <Grid item xs={12} md={4} className={classes.grid6}>
          <Card
            variant="outlined"
            className={classes.setupCard}
            data-tut="reactour__setupDocument"
          >
            <div style={{ display: "flex" }}>
              {setupOption !== 0 && (
                <ArrowBackIcon
                  name="backBtn"
                  className={classes.arrow}
                  onClick={() => {
                    setSetupOption(0);
                    setSearchText("");
                  }}
                />
              )}
              {setupOption === 0 && (
                <Typography variant="body1" className={classes.cardTitle}>
                  Setup Document
                </Typography>
              )}
              {setupOption === 1 && (
                <div className={classes.mainDiv}>
                  <Typography variant="body1" className={classes.cardTitle}>
                    Customise layout
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    className={classes.costunBtn2}
                    onClick={handleAddCoverComplete}
                    disabled={
                      sustainabilityReport.pdfProgress[assessment_id] !== 100
                        ? true
                        : false
                    }
                  >
                    Apply
                  </CustomButton>
                </div>
              )}
              {setupOption === 2 && (
                <div className={classes.mainDiv}>
                  <Typography variant="body1" className={classes.cardTitle}>
                    Add Header/Footer
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    className={classes.costunBtn2}
                    onClick={handleHeaderFooterComplete}
                    disabled={
                      sustainabilityReport.pdfProgress[assessment_id] !== 100
                        ? true
                        : false
                    }
                  >
                    Apply
                  </CustomButton>
                </div>
              )}
              {setupOption === 3 && (
                <Typography variant="body1" className={classes.cardTitle}>
                  Add Comment
                </Typography>
              )}
              {setupOption === 4 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" className={classes.cardTitle}>
                    Table Of Content
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setSustainabilityPdfProgress({
                        id: assessment_id,
                        progress: 0,
                      });
                      !toCallPdfApi && setToCallPdfApi(true);
                      viewAllComments(login.token, assessment_id, 6);
                    }}
                    disabled={
                      sustainabilityReport.pdfProgress[assessment_id] !== 100 ||
                      tocApplyDisabler
                    }
                  >
                    Apply
                  </CustomButton>
                </div>
              )}
              {setupOption === 5 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -10,
                    }}
                  >
                    <Typography variant="body1" className={classes.cardTitle}>
                      Stakeholder List
                    </Typography>

                    <CustomButton
                      variant="contained"
                      color="secondary"
                      className={classes.costunBtn3}
                      onClick={() => handleShareDialogOpen()}
                    >
                      Share
                    </CustomButton>

                    <IconButton
                      aria-label="display more actions"
                      edge="end"
                      color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>
                  </div>
                  <Divider variant="middle" className={classes.divider} />
                </div>
              )}
            </div>
            {setupOption === 0 && (
              <Box height={500} overflow="scroll">
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem
                    onClick={() => setSetupOption(1)}
                    button
                    data-tut="reactour__addCover"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={addcover}
                        alt="cover"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Customise layout" />
                  </ListItem>
                  <ListItem
                    onClick={() => setSetupOption(2)}
                    button
                    data-tut="reactour__addHeaderFooter"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={headerfooter}
                        alt="headerfooter"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Add Header/Footer" />
                  </ListItem>
                  <ListItem
                    onClick={() => handleViewCommentsClick()}
                    button
                    data-tut="reactour__viewComments"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={viewcomments}
                        alt="comments"
                      />
                    </ListItemIcon>
                    <ListItemText primary="View Comments" />
                  </ListItem>
                  <ListItem
                    onClick={() => handleEditTableOfContentClick()}
                    button
                    disabled={!hideLoader}
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={tablecontent}
                        alt="table of content"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Edit Table Of Content" />
                  </ListItem>
                  <ListItem
                    onClick={() => setSetupOption(5)}
                    button
                    data-tut="reactour__share"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={share}
                        alt="Share"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Share" />
                  </ListItem>
                </List>
              </Box>
            )}
            {setupOption === 1 && (
              <Box height={500} overflow="scroll">
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel1`}
                  onChange={handleAccordionChange(`panel1`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Front Cover</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accord}>
                    <InnerAccordion className={classes.autoWidth}>
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Background</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {coverImageUrl === "" ? (
                          <>
                            <input
                              name="frontBgImage"
                              id="cover-image"
                              type="file"
                              onChange={(event) =>
                                handleUploadCoverImage(event)
                              }
                              className={classes.input}
                            />
                            <label htmlFor="cover-image">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={coverImageUrl} alt="coverimage" />
                            <IconButton
                              onClick={handleClearCoverImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                className={classes.cancell}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box className={classes.autoWidth}>
                          <Box my={2}>
                            <CustomTextfield
                              name="frontHeadlineTxt"
                              label="Headline Text"
                              value={coverHeadline}
                              onChange={(e) => setCoverHeadline(e.target.value)}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="frontSublineTxt"
                              label="Subline Text"
                              value={coverSubline}
                              onChange={(e) => setCoverSubline(e.target.value)}
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="frontRegularTxt"
                              label="Regular Text"
                              value={coverRregularText}
                              onChange={(e) =>
                                setCoverRregularText(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add logo</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {coverLogoUrl === "" ? (
                          <>
                            <input
                              name="frontLogoImage"
                              id="cover-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadCoverLogoImage(event)
                              }
                              className={classes.input}
                            />
                            <label htmlFor="cover-logo">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={coverLogoUrl} alt="coverlogo" />
                            <IconButton
                              onClick={handleClearCoverLogoImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                className={classes.cancell}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel2`}
                  onChange={handleAccordionChange(`panel2`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Back Cover</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className={classes.accord}
                    style={{ display: "felx", flexDirection: "column" }}
                  >
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Background</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {backCoverImageUrl === "" ? (
                          <>
                            <input
                              name="backBgImage"
                              id="back-cover-image"
                              type="file"
                              onChange={(event) =>
                                handleUploadBackCoverImage(event)
                              }
                              className={classes.input}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="back-cover-image">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={backCoverImageUrl} alt="coverimage" />
                            <IconButton
                              onClick={handleClearBackCoverImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                className={classes.cancell}
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box className={classes.autoWidth}>
                          <Box my={2}>
                            <CustomTextfield
                              name="backHeadlineTxt"
                              label="Headline Text"
                              value={coverBackHeadline}
                              onChange={(e) =>
                                setCoverBackHeadline(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="backSublineTxt"
                              label="Subline Text"
                              value={coverBackSubline}
                              onChange={(e) =>
                                setCoverBackSubline(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="backRegularTxt"
                              label="Regular Text"
                              value={coverBackRegular}
                              onChange={(e) =>
                                setCoverBackRegular(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add logo</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {backCoverLogoUrl === "" ? (
                          <>
                            <input
                              name="backLogoImage"
                              id="back-cover-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadBackCoverLogoImage(event)
                              }
                              className={classes.input}
                            />
                            <label htmlFor="back-cover-logo">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={backCoverLogoUrl} alt="coverlogo" />
                            <IconButton
                              onClick={handleClearBackCoverLogoImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                className={classes.cancell}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>
                <FontSelector
                  handleAccordioChange={handleAccordioChangeColor}
                  expanded={expanded}
                  font={sustainabilityReport.font}
                  handleFontComplete={handleFontComplete}
                />
                <ColorGradient
                  handleAccordioChange={handleAccordioChangeColor}
                  expanded={expanded}
                  image={imageLogo}
                  color={sustainabilityReport.color}
                  handleColorComplete={handleColorComplete}
                  preColor={
                    sustainabilityReport.preSelectedColor
                      ? sustainabilityReport.preSelectedColor
                      : []
                  }
                />
              </Box>
            )}
            {setupOption === 2 && (
              <Box height={520} overflow="scroll">
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel3`}
                  onChange={handleAccordionChange(`panel3`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Header</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accord}>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box className={classes.autoWidth}>
                          <Box my={2}>
                            <CustomTextfield
                              name="addHeaderTxt"
                              label="Header Text"
                              value={headerText}
                              onChange={(e) => setHeaderText(e.target.value)}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add logo</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {headerLogoUrl === "" ? (
                          <>
                            <input
                              name="headerLogo"
                              id="header-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadHeaderLogoImage(event)
                              }
                              className={classes.input}
                            />
                            <label htmlFor="header-logo">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={headerLogoUrl} alt="coverlogo" />
                            <IconButton
                              onClick={handleClearHeaderLogoImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                className={classes.cancell}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel4`}
                  onChange={handleAccordionChange(`panel4`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Footer</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accord}>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box className={classes.autoWidth}>
                          <Box my={2}>
                            <CustomTextfield
                              name="addFooterTxt"
                              label="Footer Text"
                              value={footerText}
                              onChange={(e) => setFooterText(e.target.value)}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      className={classes.autoWidth}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Page Number</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box className={classes.autoWidth}>
                          <Box my={2}>
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  name="pgNumCheckbox"
                                  color="secondary"
                                  disableRipple
                                  id="agreement"
                                  checked={isPageNumberVisible}
                                  onChange={() =>
                                    setIsPageNumberVisible(!isPageNumberVisible)
                                  }
                                />
                              }
                              label="Show page number"
                            />
                          </Box>
                        </Box>
                        <Box className={classes.autoWidth}>
                          <Box my={2}>
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  name="pgNumCheckbox"
                                  color="secondary"
                                  disableRipple
                                  id="agreement"
                                  checked={isPNumberDefaultPosition}
                                  onChange={() =>
                                    setIsPNumberDefaultPosition(
                                      !isPNumberDefaultPosition
                                    )
                                  }
                                />
                              }
                              label="Default position (Left)"
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {setupOption === 3 && (
              <>
                <CustomTextfield
                  name="cmtSearch"
                  label="Search By Table Of Content"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className={classes.CustomTextfield}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                          className={classes.search}
                          fontSize="large"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box height={460} overflow="scroll">
                  {filteredComments.length > 0 ? (
                    commentsDisplay(filteredComments)
                  ) : searchText.length > 0 ? (
                    <Typography className={classes.typo4}>
                      No Data Found
                    </Typography>
                  ) : searchText === "" ? (
                    commentsDisplay(allComment)
                  ) : (
                    commentsDisplay(filteredComments)
                  )}
                </Box>
              </>
            )}
            {setupOption === 4 && (
              <Box className={classes.box3}>
                <Grid container className={classes.grid7}>
                  <Grid item xs={8} className={classes.tocTitle}>
                    Title
                  </Grid>
                  <Grid item xs={4} className={classes.tocTitle}>
                    Pg No
                  </Grid>
                </Grid>
                <Box height={420} overflow="scroll">
                  {localSustainablityReport.map((toc, i) => {
                    return (
                      <>
                        <Box display="flex">
                          <Grid
                            container
                            key={toc.toc_id + i}
                            className={classes.tocContentGrid}
                          >
                            <Grid item xs={8}>
                              {toc.visible &&
                              showTocInput &&
                              (idToShowInput === toc.toc_id ||
                                idToShowInput === toc.assessment_topic_id) ? (
                                <CustomTextfield
                                  name={toc.name.substring(0, 4)}
                                  autoFocus
                                  variant="outlined"
                                  value={editTocObj.name}
                                  InputProps={{
                                    disableUnderline: true,
                                    className: classes.inputBox,
                                  }}
                                  className={classes.tocInput}
                                  fullWidth
                                  onChange={(e) => {
                                    handleEditToc(e, i);
                                    setTocEyeDisabler(true);
                                  }}
                                  disabled={!toc.visible}
                                  size="small"
                                  inputRef={textFieldRef}
                                  onBlur={() => {
                                    api(toc);
                                    setShowTocInput(false);
                                  }}
                                ></CustomTextfield>
                              ) : (
                                <Typography
                                  variant="body1"
                                  className={classes.tocContent}
                                  id={
                                    toc.toc_id === ""
                                      ? toc.assessment_topic_id
                                      : toc.toc_id
                                  }
                                  onClick={(e) => {
                                    if (toc.visible) {
                                      handleShowInputToc(e, toc);
                                    }
                                  }}
                                >
                                  {toc.name}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant="body1"
                                className={classes.typo5}
                                align="center"
                              >
                                {toc.visible &&
                                  toc.page_no &&
                                  toc.page_no.pageNo}
                              </Typography>
                            </Grid>
                          </Grid>
                          {true && (
                            <Box key={toc.toc_id + i}>
                              <IconButton>
                                {toc.visible ? (
                                  <VisibilityOutlinedIcon
                                    name={toc.name.substring(0, 4)}
                                    style={{ color: "#9FA1AF" }}
                                    onClick={() => {
                                      toc.assessment_topic_id !== "" &&
                                        setEditTocAssessmentId(
                                          toc.assessment_topic_id
                                        );
                                      handleTocVisibility(
                                        toc.name,
                                        toc.toc_id === "" ? "" : toc.toc_id,
                                        false,
                                        toc.order,
                                        toc.assessment_topic_id === ""
                                          ? ""
                                          : toc.assessment_topic_id
                                      );
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    name={toc.name.substring(0, 4)}
                                    style={{ color: "#9FA1AF" }}
                                    onClick={() => {
                                      toc.assessment_topic_id !== "" &&
                                        setEditTocAssessmentId(
                                          toc.assessment_topic_id
                                        );
                                      handleTocVisibility(
                                        toc.name,
                                        toc.toc_id === "" ? "" : toc.toc_id,
                                        true,
                                        toc.order,
                                        toc.assessment_topic_id === ""
                                          ? ""
                                          : toc.assessment_topic_id
                                      );
                                    }}
                                  />
                                )}
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Box>
            )}
            {setupOption === 5 && (
              <Box height={520} overflow="scroll">
                {searchArr.length > 0
                  ? searchArr.map((user, index) => (
                      <div className={classes.demo} key={index}>
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar className={classes.avatar1}>
                                {user.user_data.name.substring(0, 2)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={user.user_data.name}
                              secondary={user.user_data.email}
                            />
                          </ListItem>
                        </List>
                      </div>
                    ))
                  : sustainabilityReport.approversReviewersList.length > 0 && (
                      <>
                        <CommentsAccordion variant="elevation" elevation={0}>
                          <CommentsAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ marginTop: 10 }}
                          >
                            <Box className={classes.box1}>
                              <Typography>Approvers</Typography>
                              <Box
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "50%",
                                  width: 32,
                                  height: 32,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: 16,
                                  lineHeight: 24,
                                }}
                              >
                                {approversArr.length}
                              </Box>
                            </Box>
                          </CommentsAccordionSummary>

                          {approversArr.map((user, index) => (
                            <div
                              style={{ wordBreak: "break-word" }}
                              className={classes.demo}
                              key={index}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "8px",
                                  overflow: "scroll",
                                }}
                                id={user.stakeholder_id}
                                onMouseEnter={(e) => {
                                  setShowRevomeUserIcon(true);
                                  setUserId(e.target.id);
                                }}
                                onMouseLeave={(e) => {
                                  setShowRevomeUserIcon(false);
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexGrow: "4",
                                  }}
                                >
                                  {user.profile_img ? (
                                    <Avatar
                                      alt={user.name}
                                      src={user.profile_img.url}
                                    />
                                  ) : (
                                    <Avatar
                                      style={{
                                        backgroundColor: " #DDE9F6",
                                      }}
                                    >
                                      {user.name.substring(0, 2)}
                                    </Avatar>
                                  )}
                                  <Box>
                                    <Typography style={{ fontSize: 16 }}>
                                      {user.name}
                                    </Typography>
                                    <Typography style={{ fontSize: 14 }}>
                                      {user.email}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box flexGrow="4">
                                  <Select
                                    variant="outlined"
                                    displayEmpty
                                    size="small"
                                    select
                                    value={approver}
                                    name={user.stakeholder_id}
                                    onChange={(e) => {
                                      e.target.name === user.stakeholder_id &&
                                        handleApproverChange(e);
                                    }}
                                  >
                                    {["To Approve", "To comment"].map(
                                      (item) => (
                                        <MenuItem value={item} key={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </Box>
                                <Box flexGrow="2">
                                  {showRevomeUserIcon &&
                                    userId === user.stakeholder_id && (
                                      <IconButton
                                        name="rmvAproover"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                          setRemoveUserEmail(user.email);
                                          setShowDeleteUserDailogue(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </div>
                          ))}
                        </CommentsAccordion>
                        <CommentsAccordion variant="elevation">
                          <CommentsAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ marginTop: 10 }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Reviewers</Typography>
                              <Box
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "50%",
                                  width: 32,
                                  height: 32,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: 16,
                                  lineHeight: 24,
                                }}
                              >
                                {reviewersArr.length}
                              </Box>
                            </Box>
                          </CommentsAccordionSummary>

                          {reviewersArr.map((user, index) => (
                            <div className={classes.div1} key={index}>
                              <Box
                                className={classes.box4}
                                id={user.stakeholder_id}
                                onMouseEnter={(e) => {
                                  setShowRevomeUserIcon(true);
                                  setUserId(e.target.id);
                                }}
                                onMouseLeave={(e) => {
                                  setShowRevomeUserIcon(false);
                                }}
                              >
                                <Box className={classes.box5}>
                                  {user.profile_img ? (
                                    <Avatar
                                      alt={user.name}
                                      src={user.profile_img.url}
                                    />
                                  ) : (
                                    <Avatar className={classes.avatar1}>
                                      {user.name.substring(0, 2)}
                                    </Avatar>
                                  )}
                                  <Box>
                                    <Typography className={classes.typo6}>
                                      {user.name}
                                    </Typography>
                                    <Typography className={classes.typo6}>
                                      {user.email}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box flexGrow="4">
                                  <Select
                                    variant="outlined"
                                    displayEmpty
                                    size="small"
                                    select
                                    value={reviewer}
                                    name={user.stakeholder_id}
                                    onChange={(e) => handleReviewerChange(e)}
                                  >
                                    {["To Comment", "To Approve"].map(
                                      (item) => (
                                        <MenuItem value={item} key={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </Box>
                                <Box flexGrow="2">
                                  {showRevomeUserIcon &&
                                    userId === user.stakeholder_id && (
                                      <IconButton
                                        name="rmvReviewers"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                          setRemoveUserEmail(user.email);
                                          setShowDeleteUserDailogue(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </div>
                          ))}
                        </CommentsAccordion>
                      </>
                    )}
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SustainabilityReportPDF;
