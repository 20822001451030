import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import { ReportListingUseStyles } from "./reportListingStyle";

export default function ReportlistingPasswordDialog({
  surveyListing,
  changePasswordValidation,
  setLogin,
  login
}) {
  const classes = ReportListingUseStyles();
  // Update Password
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  // update password function
  const handleChangePassword = () => {
    changePasswordValidation(
      login.token,
      password,
      name,
      login.current_organisation
    );
    setLogin({
      name: name
    });
  };

  return (
    <Dialog open={surveyListing.changePasswordDialog} maxWidth="xs">
      <DialogContent>
        <Typography variant="h6">Change Your Password</Typography>
      </DialogContent>
      <DialogContent>
        <CustomTextfield
          id="name"
          name="name"
          type="text"
          margin="dense"
          variant="outlined"
          size="small"
          label="Name"
          className={classes.passwordTextField}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <CustomTextfield
          id="paswword"
          name="paswword"
          type="password"
          margin="dense"
          variant="outlined"
          size="small"
          label="Password"
          className={classes.passwordTextField}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <CustomTextfield
          id="paswwordConf"
          name="paswwordConf"
          type="password"
          margin="dense"
          variant="outlined"
          size="small"
          label="Confirm Password"
          className={classes.passwordTextField}
          value={confPassword}
          onChange={(e) => {
            setConfPassword(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton
          disabled={password !== "" && password === confPassword ? false : true}
          variant="contained"
          color="primary"
          className={classes.passwordButton}
          onClick={handleChangePassword}
        >
          Change Password
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
