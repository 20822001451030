import UNIVERSAL from "../../config/config";
import {
  REDIRECT_VALUE,
  SET_ASSESSMENT,
  SET_KEYWORD,
  SET_CHANGE_PASSWORD_DIALOG,
  SET_ACTIVITY_LOG,
  SET_CURRENT_STEP,
  SET_ORGANIZATION_DETAILS,
  // SET_ASSESSMENT_STATUS,
  // SET_ASSIGNED_ASSESSMENTS,
  // SET_ASSIGNED_ASSESSMENT_STATUS,
  SET_NOTIFICATIONS,
  SET_ASSESSMENTS_TOTAL_COUNT,
  SET_ASSIGNED_ASSESSMENTS_TOTAL_COUNT,
  SET_TOTAL_NOTIFICATIONS,
  SET_ASSESSMENTS_PAGINATION_PAGE,
  SET_COMPLETED_QUESTIONS,
  SET_DEFAULT_SURVEY_DATA,
  SET_QUESTION_STATUS,
  SET_ACTIVE_STEP,
  SET_SURVEY_COMPLETED,
  SET_TABLE_TYPE_DOCS,
} from "../../constants/surveyListing/surveyListingConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import encrypt from "../shared/sharedActions";
import { passwordValidation } from "../../actions/regexValidation/regexValidation";
import {
  setLogin,
  setCurrentOrganisationUserType,
  setHelperDailogue,
} from "../../actions/login/loginActions";
import { setClearDashboardChart } from "../datahubActions/datahubActions";

export function setRedirectValue(value) {
  return {
    type: "REDIRECT_VALUE1",
    payload: value,
  };
}

export function setAssessmentStatus(value) {
  return {
    type: "SET_ASSESSMENT_STATUS",
    payload: value,
  };
}

export function setAssignedAssessmentStatus(value) {
  return {
    type: "SET_ASSIGNED_ASSESSMENT_STATUS",
    payload: value,
  };
}

export function setAssignedAssessments(value) {
  return {
    type: "SET_ASSIGNED_ASSESSMENTS",
    payload: value,
  };
}

export function setAssessments(payload) {
  return {
    type: SET_ASSESSMENT,
    payload: payload,
  };
}

export function setRedirect(value) {
  return {
    type: REDIRECT_VALUE,
    payload: value,
  };
}

export function setKeyword(value) {
  return {
    type: SET_KEYWORD,
    payload: value,
  };
}

export function setOrganizationDetails(value) {
  return {
    type: SET_ORGANIZATION_DETAILS,
    payload: value,
  };
}

export function setChangePasswordDialog(payload) {
  return {
    type: SET_CHANGE_PASSWORD_DIALOG,
    payload: payload,
  };
}

export function setActivityLog(payload) {
  return {
    type: SET_ACTIVITY_LOG,
    payload: payload,
  };
}

export function setCurrentStep(payload) {
  return {
    type: SET_CURRENT_STEP,
    payload: payload,
  };
}

export function setNotification(payload) {
  return {
    type: SET_NOTIFICATIONS,
    payload: payload,
  };
}

export function setTotalNotification(payload) {
  return {
    type: SET_TOTAL_NOTIFICATIONS,
    payload: payload,
  };
}

export function setAssessmentsTotalCount(payload) {
  return {
    type: SET_ASSESSMENTS_TOTAL_COUNT,
    payload: payload,
  };
}

export function setAssignedAssessmentsTotalCount(payload) {
  return {
    type: SET_ASSIGNED_ASSESSMENTS_TOTAL_COUNT,
    payload: payload,
  };
}

export function setAssessmentsPaginationPage(payload) {
  return {
    type: SET_ASSESSMENTS_PAGINATION_PAGE,
    payload: payload,
  };
}

export function setQuestionStatus(payload) {
  return {
    type: SET_QUESTION_STATUS,
    payload: payload,
  };
}

export function createAssessment(token, organisation_id) {
  var data = encrypt({
    "user-token": token,
    organisation_id: organisation_id,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/assessments/create_assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setRedirect(responseJson.result.assessment_id));
          dispatch(setHelperDailogue(responseJson.result.helper));
        }
        dispatch(unset_loader());
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewAssessments(
  token,
  limit,
  startingAfter,
  surveyListing,
  current_organisation,
  keyword,
  is_ascending
) {
  var data = encrypt({
    "user-token": token ? token : localStorage.getItem("user_token"),
    limit: limit,
    starting_after: startingAfter,
    keyword: keyword,
    organisation_id: current_organisation
      ? current_organisation
      : localStorage.getItem("current_organisation"),
    is_ascending,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/assessment/view_assessments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(setAssessmentStatus(true));
        if (responseJson.status) {
          dispatch(setAssessments(responseJson.result));
          dispatch(setAssessmentsTotalCount(responseJson.total));
        }

        dispatch(unset_loader());

        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewAssignedAssessments(
  token,
  limit,
  startingAfter,
  surveyListing,
  current_organisation,
  keyword,
  is_ascending
) {
  var data = encrypt({
    "user-token": token ? token : localStorage.getItem("user_token"),
    limit: surveyListing.limit,
    starting_after: startingAfter,
    keyword: keyword,
    organisation_id: current_organisation
      ? current_organisation
      : localStorage.getItem("current_organisation"),
    is_ascending,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/assessments/view_assigned_assessments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(setAssignedAssessmentStatus(true));
        if (responseJson.status) {
          let total = responseJson.hasOwnProperty("total")
            ? responseJson.total
            : 0;
          dispatch(setAssignedAssessments(responseJson.result));
          dispatch(setAssignedAssessmentsTotalCount(total));
        }
        dispatch(unset_loader());
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function changePasswordValidation(token, password, name) {
  return (dispatch) => {
    if (name === "") {
      dispatch(set_snack_bar(true, "Please Enter Your Name"));
    } else if (passwordValidation(password) === false) {
      dispatch(
        set_snack_bar(
          true,
          "Please Enter 8 character Password(at least 1 uppercase, 1 lowercase, 1 special character and 1 number)"
        )
      );
      // } else if (password !== confPassword) {
      //   dispatch(set_snack_bar(true, "Password Does Not Match"));
    } else {
      dispatch(changePassword(token, password, name));
    }
  };
}

export function changePassword(token, password, name, organisation_id) {
  let url = window.origin;
  let domain_name = window.location.hostname;
  var data = encrypt({
    "user-token": token,
    password: password,
    name: name,
    organisation_id: localStorage.getItem("current_organisation"),
    url,
    domain_name,
  });

  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/userDetails/change_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(setChangePasswordDialog(false));
        dispatch(
          setLogin({
            user_token: localStorage.getItem("user_token"),
            user_type: localStorage.getItem("user_type"),
            userName: name,
            userEmail: localStorage.getItem("userEmail"),
            name: name,
            email: localStorage.getItem("userEmail"),
            parent_organisation: JSON.parse(
              localStorage.getItem("parent_organisation")
            ),
            current_organisation: localStorage.getItem("current_organisation"),
            onBoarding: true,
          })
        );
        localStorage.setItem("userName", name);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateAssessmentName(
  token,
  assessment_id,
  assessment_name,
  surveyListing,
  limit,
  startingAfter,
  is_ascending,
  current_organisation
) {
  var data = encrypt({
    "user-token": token,
    assessment_id: assessment_id,
    assessment_name: assessment_name,
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/assessment/update_assessment_name", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(
          viewAssessments(
            token,
            limit,
            startingAfter,
            surveyListing,
            current_organisation,
            surveyListing.keyword,
            is_ascending
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewActivityLog(user_token, assessment_id, step_no) {
  return (dispatch) => {
    // dispatch(set_loader());

    const data = encrypt({
      "user-token": user_token,
      assessment_id: assessment_id,
      step_no: step_no,
    });

    return fetch(UNIVERSAL.BASEURL + "/activityLog/view_activity_log", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.result) {
          dispatch(setActivityLog(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function getOrganizations(token, domain_name) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      domain_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/businessUnit/get_organisations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganizationDetails(responseJson.result));
          dispatch(
            setCurrentOrganisationUserType(responseJson.result[0].user_type)
          );
          dispatch(setClearDashboardChart());
          // localStorage.setItem(
          //   'current_organisation_user_type',
          //   responseJson.result[0].user_type
          // );
          if (responseJson.result.length === 1) {
            localStorage.setItem(
              "parent_organisation",
              JSON.stringify([responseJson.result[0]._id])
            );
            localStorage.setItem(
              "current_organisation",
              responseJson.result[0]._id
            );
            localStorage.setItem(
              "status",
              responseJson.result[0]?.payment_status
            );
            localStorage.setItem(
              "status_message",
              responseJson.result[0]?.payment_message
            );
            // setIsSubscriptionActive(
            //   selectedORG?.payment_status === "EXPIRED" ? false : true
            // );
            // localStorage.setItem(
            //   "current_organisation_user_type",
            //   responseJson.result[0].user_type
            // );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function deleteAssessments(
  token,
  assessment_id,
  surveyListing,
  current_organisation,
  limit,
  startingAfter,
  is_ascending
) {
  var data = encrypt({
    "user-token": token ? token : localStorage.getItem("user_token"),
    assessment_id: assessment_id,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/assessment/delete_assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(setAssessmentStatus(true));
        if (responseJson.status) {
          dispatch(
            viewAssessments(
              token,
              limit,
              startingAfter,
              surveyListing,
              current_organisation,
              surveyListing.keyword,
              is_ascending
            )
          );
        }
        dispatch(unset_loader());
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getNotifications(
  token,
  organisation_id,
  limit,
  starting_after
) {
  var data = encrypt({
    "user-token": token ? token : localStorage.getItem("user_token"),
    organisation_id: organisation_id,
    limit: limit,
    starting_after: starting_after,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/notification/view_notification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(setAssessmentStatus(true));
        if (responseJson.status) {
          dispatch(setNotification(responseJson.result));
          dispatch(setTotalNotification(responseJson.total));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const duplicateReport = (
  token,
  assessment_id,
  report_selected,
  surveyListing,
  assessment_name,
  limit,
  startingAfter,
  is_ascending,
  current_organisation
) => {
  const data = encrypt({
    "user-token": token,
    assessment_id: assessment_id,
    report_selected: report_selected,
    assessment_name: assessment_name,
  });

  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/assessments/duplicate_assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            viewAssessments(
              token,
              limit,
              startingAfter,
              surveyListing,
              current_organisation,
              surveyListing.keyword,
              is_ascending
            )
          );
        }
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
};

// export const getVersionNo = () => {
//   return (dispatch) => {
//     fetch(UNIVERSAL.BASEURL + "/userDetails/get_version_no", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "content-type": "application/json"
//       }
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//         }
//       })
//       .catch((err) => console.log(err));
//   };
// };

// ------------------ Get Completed Questions ------------------
export function setCompletedQuestions(payload) {
  return {
    type: SET_COMPLETED_QUESTIONS,
    payload: payload,
  };
}

export const getCompletedQuestions = (
  token,
  survey_id,
  organization_id,
  frequency_id,
  supplier_id,
  version
) => {
  // console.log(survey_id, organization_id, frequency_id, supplier_id, version);

  const data = encrypt({
    "user-token": token,
    survey_id,
    organization_id,
    frequency_id,
    supplier_id,
    version,
  });

  return (dispatch) => {
    dispatch(set_loader());
    fetch(
      UNIVERSAL.BASEURL + "/survey/get_survey_response_for_completed_questions",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCompletedQuestions(responseJson?.result));
          dispatch(
            setQuestionStatus({
              no_of_completed_questions:
                responseJson?.no_of_completed_questions,
              total_no_of_questions: responseJson?.total_no_of_questions,
            })
          );
        }
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
};

// ---------------------------------------------------------

// ------------------ Get Default Survey Data ------------------
export function setDefaultSurveyData(payload) {
  return {
    type: SET_DEFAULT_SURVEY_DATA,
    payload: payload,
  };
}

export const getDefaultSurveyData = (token, supplier_id, organization_id) => {
  const data = encrypt({
    "user-token": token,
    supplier_id,
    organization_id,
  });

  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/survey/get_defatult_survey_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setDefaultSurveyData(responseJson?.result));
        }
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
};

// ---------------------------------------------------------

// ------------------ set active survey step ------------------
export function setActiveStep(payload) {
  return {
    type: SET_ACTIVE_STEP,
    payload: payload,
  };
}

export function setSurveyCompleted(payload) {
  return {
    type: SET_SURVEY_COMPLETED,
    payload: payload,
  };
}

// ---------------------------------------------------------

// ------------------ survey type table actions ------------------
export function setSurveyTableDocs(payload) {
  return {
    type: SET_TABLE_TYPE_DOCS,
    payload: payload,
  };
}

// ---------------------------------------------------------
