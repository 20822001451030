import { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  MuiThemeProvider,
  Box,
  createTheme,
} from "@material-ui/core";

import { MainHeader } from "../../../screen/vendorAdminScreen";
import SearchTextField from "../../../UI/textfield/searchTextField";
import AssignedTab from "./surveyMain/AssignedTab";
import CompletedTab from "./surveyMain/CompletedTab";
import { useDispatch, useSelector } from "react-redux";
import { getCorporatesShareSurveys } from "../../../../actions/supplierAdmin/supplierAdminActions";

export const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});

const SurveyMain = () => {
  const dispatch = useDispatch();
  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );
  const { assignSurveyList, completedSurveyList, selectedCorporate } =
    useSelector((state) => state?.supplierAdmin);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchClick = (value) => {
    console.log(value);
  };

  useEffect(() => {
    if (selectedCorporate?._id) {
      dispatch(
        getCorporatesShareSurveys(token, supplier_id, selectedCorporate?._id)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {/* Header with Breadcrumbs */}
      <MainHeader>
        <SearchTextField searchClick={handleSearchClick} />
      </MainHeader>

      {/* Main Section Tabs */}
      <Box p={2} display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
        <MuiThemeProvider theme={appTheme}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              disableRipple
              label={<span>Assigned</span>}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              disableRipple
              label={<span>Completed</span>}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </MuiThemeProvider>

        {/* Render content for tabs*/}
        <Box style={{ overflow: "scroll", height: "70vh" }}>
          {tabValue === 0 && <AssignedTab />}
          {tabValue === 1 && <CompletedTab />}
        </Box>
      </Box>
    </Box>
  );
};
export default SurveyMain;
