import React from "react";
import { connect } from "react-redux";
import DataHubSurveyScreen from "../../components/screen/dataHubSurvey/dataHubSurveyScreen";
import {
  addDataHubChart,
  getDataHubChart,
  updateDashboardLayout,
  deleteDataHubChart
} from "../../actions/datahubActions/datahubActions";
import DashBoardScreen from "../../components/screen/dashboard/dashboard";

export class DashBoardCont extends React.PureComponent {
  render() {
    return <DashBoardScreen {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    dataHub: store.dataHub,
    login: store.login,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    getDataHubChart: (token, organisation_id) => {
      dispatch(getDataHubChart(token, organisation_id));
    },
    addDataHubChart: (token, organisation_id, new_chart_config) => {
      dispatch(addDataHubChart(token, organisation_id, new_chart_config));
    },
    updateDashboardLayout: (token, organisation_id, new_layout) => {
      dispatch(updateDashboardLayout(token, organisation_id, new_layout));
    },
    deleteDataHubChart: (token, organisation_id, chart_id) => {
      dispatch(deleteDataHubChart(token, organisation_id, chart_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardCont);
