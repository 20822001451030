import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import DrawerListItem from "../listItem/drawerListItem";
import "../../../styles/drawer.css";
import logo from "../../../images/ImpactGrows Black corrected.svg";
import { Link, useHistory } from "react-router-dom";
import DrawerUseStyles from "./drawerStyles";
import vector from "../../../images/vector.png";
import settings from "../../../images/settings.png";
import reportListing from "../../../images/reportListing.png";
import surveyListing from "../../../images/surveyListing.png";
import getStarted from "../../../images/getStarted.png";
import dashboardIcon from "../../../images/dashboardIcon.png";
import { BsPassFill } from "react-icons/bs";

//removed.
function NavCustomDrawer({
  isOpen,
  setIsOpen,
  logOut,
  token,
  redirect,
  setRedirectValue,
  login,
  current_organisation,
}) {
  let history = useHistory();

  const classes = DrawerUseStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    setIsOpen(!isOpen);
  };
  const [tnc, setTnc] = useState(false);
  const [selectedIndex, setSelectedId] = React.useState(null);
  const setSelectedIndex = (index) => {
    setSelectedId(index);
  };
  const userType = localStorage.getItem("current_organisation_user_type");

  useEffect(() => {
    if (history.location.pathname === "/onboarding_tour") {
      setOpen(true);
    }
    if (history.location.pathname === "/dashbord") {
      setSelectedId(2);
    }
    if (history.location.pathname === "/dashbord_static") {
      setSelectedId(5);
    }
    if (history.location.pathname === "/survey_listing") {
      setSelectedId(6);
    }
    if (history.location.pathname === "/get_started") {
      setSelectedId(12);
    }
    if (history.location.pathname === "/report_listing") {
      setSelectedId(3);
    }
    if (history.location.pathname === "/general_settings") {
      setSelectedIndex(8);
    }
    if (history.location.pathname === "/data_hub/survey") {
      setSelectedId(1);
    }
    if (history.location.pathname === "/supplier_admin") {
      setSelectedId(21);
    }
    if (history.location.pathname === "/supplier_task") {
      setSelectedId(31);
    }
    if (
      history.location.pathname === "/privacy_policy" ||
      history.location.pathname === "/tnc" ||
      history.location.pathname === "/faq"
    ) {
      setTnc(true);
    } else {
      setTnc(false);
    }
  }, [history.location.pathname, current_organisation]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!tnc && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List className={classes.head}>
            <ListItem className={classes.head}>
              <ListItemIcon>
                {open ? (
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleDrawerToggle}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                ) : (
                  <IconButton name="expandPanel" onClick={handleDrawerToggle}>
                    <Icon className={classes.iconButton}>menu</Icon>
                  </IconButton>
                )}
              </ListItemIcon>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemIcon>
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  login.selectedOrganization.domain_logo.after_login.logo && (
                    <div
                      style={
                        login.selectedOrganization &&
                        login.selectedOrganization.domain_logo.after_login.logo
                          ? login.selectedOrganization.domain_logo.after_login
                              .logo_styles
                          : JSON.parse(localStorage.getItem("logo_styles"))
                          ? JSON.parse(localStorage.getItem("logo_styles"))
                          : {}
                      }
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          login.selectedOrganization &&
                          login.selectedOrganization.domain_logo.after_login
                            .logo
                            ? login.selectedOrganization.domain_logo.after_login
                                .logo
                            : localStorage.getItem("logo") &&
                              localStorage.getItem("logo")
                        }
                        alt="logo"
                      />
                    </div>
                  )}
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  !login.selectedOrganization.domain_logo.after_login.logo && (
                    <div>
                      <img
                        style={{
                          width: "70%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={logo}
                        alt="logo"
                      />
                    </div>
                  )}
                {/* {open && isOpen && (
                <img src={localStorage.getItem('logo') && localStorage.getItem('logo')!== 'undefined' && localStorage.getItem('logo')!== 'null' ? localStorage.getItem('logo') : login.selectedOrganization && login.selectedOrganization.domain_logo.after_login.logo ? login.selectedOrganization.domain_logo.after_login.logo : logo } className={classes.img} alt="log" />
              )} */}
              </ListItemIcon>
            </ListItem>
          </List>
          <List className={classes.list2}>
            {(userType === "A" || userType === "SA") && (
              <Link
                to="/get_started"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 12}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(12)}
                  button
                  data-tut="reactour__get_started"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={getStarted}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary={"Get Started"} />
                </DrawerListItem>
              </Link>
            )}

            {/* {(userType === "A" || userType === "SA") && (
              <Link
                to="/dashboard"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 2}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(2)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Dashboard" />
                </DrawerListItem>
              </Link>
            )}  */}
            {(userType === "A" || userType === "SA") && (
              <Link
                to="/dashboard_static"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 5}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(5)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={dashboardIcon}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Dashboard Static" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "SA" && (
              <Link
                to="/survey_listing"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 6}
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(6);
                    if (redirect) {
                      setRedirectValue(false);
                    }
                  }}
                  button
                  data-tut="reactour__survery_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={surveyListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Assessments" />
                </DrawerListItem>
              </Link>
            )}
            {(userType === "A" || userType === "SA") && (
              <Link
                to="/report_listing"
                disabled
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 3}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(3)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Tasks" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "SA" && (
              <Link
                to="/data_hub/survey"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 1}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(1)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Data Hub" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "SA" && (
              <Link
                to="/supplier"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 11}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(11)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      {/* <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon> */}
                      <BsPassFill size={20} style={{ color: "#9A9A9A" }} />
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Supplier" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "SADMIN" && (
              <Link
                to="/supplier_admin"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 21}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(21)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Supplier" />
                </DrawerListItem>
              </Link>
            )}
            {(userType === "SADMIN" || userType === "SG") && (
              <Link
                to="/supplier_task"
                className={
                  login.isSubscriptionActive &&
                  localStorage.getItem("status") !== "EXPIRED"
                    ? classes.link
                    : classes.disabledLink
                }
              >
                <DrawerListItem
                  disabled={
                    !login.isSubscriptionActive &&
                    localStorage.getItem("status") === "EXPIRED"
                  }
                  selected={selectedIndex === 31}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(31)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Supplier" />
                </DrawerListItem>
              </Link>
            )}
            {
              <Link to="/general_settings" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 8}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(8)}
                  button
                  data-tut="reactour__general_settings"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={settings}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="General Settings" />
                </DrawerListItem>
              </Link>
            }
          </List>
          <Divider style={{ marginTop: "auto" }} />
          <List className={classes.list}>
            <Link to="/login">
              <DrawerListItem
                selected={selectedIndex === 10}
                className={classes.listitems}
                onClick={() => {
                  setSelectedIndex(10);
                  logOut(token);
                }}
                button
                name="logoutBtn"
              >
                <ListItemIcon>
                  <>
                    {open && <Icon fontSize="small">chevron_right</Icon>}
                    <Icon fontSize="small">logout</Icon>
                  </>
                </ListItemIcon>
                <ListItemText secondary="Logout" />
              </DrawerListItem>
            </Link>
          </List>
        </Drawer>
      )}
    </div>
  );
}
export default React.memo(NavCustomDrawer);
