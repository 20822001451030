import {
  SET_COMPANY_NAME,
  SET_COMPANY_ADDRESS,
  SET_COMPANY_ADDRESS_ARRAY,
  SET_BUSINESS_TYPE,
  SET_START_DATE,
  SET_COMPANY_DESCRIPTION,
  SET_SECTOR_TYPE,
  SET_ASSESSMENT_DATE,
  SET_CMP_NAME,
  SET_CMP_ADDRESS,
  SET_CMP_PINCODE,
  SET_INDUSTRY_TYPE,
  SET_NUMBER_OF_PERMANENT_EMPLOYEE,
  SET_NUMBER_OF_CONTRACT_EMPLOYEE,
  SET_CITY,
  SET_COUNTRY,
  SET_DEFINE_MATERIALITY,
  SET_DEFINE_OBJECTIVE,
  SET_DEFINE_MATERIALITY_ARRAY,
  SET_DEFINE_OBJECTIVE_ARRAY,
  SET_SEARCH_METRIC,
  SET_FILTER,
  SET_CHIP,
  SET_SELECT_BOX,
  SET_SHOW_ASSEST,
  SET_METRICS,
  SET_ASSIGN,
  SET_TOPIC_RELEVANCE,
  SET_RISKS,
  SET_OPPORTUNITY,
  SET_FILE,
  SET_TOPICS,
  SET_MAIN_TOPICS,
  SET_TOPICS_ARRAY,
  SET_FILES_ARRAY,
  SET_CURRENT_TOPIC_ID,
  RESET_TOPICS,
  SET_SEARCH_KEYWORD,
  SET_MEMBERS_ARRAY,
  SET_ADD_EMAIL,
  RESET_ADD_EMAIL,
  BASIC_FILE,
  BASIC_COMPANY_FILE,
  FRAMEWORK,
  VALUE,
  SET_ACTIVE_BUTTON,
  SET_ADDRESS,
  UNSET_ADDRESS,
  SET_UPDATE_DEFINE_MATERIALITY,
  UNSET_DEFINE_MATERIALITY,
  SET_UPDATE_DEFINE_OBJECTIVE,
  UNSET_UPDATE_DEFINE_OBJECTIVE,
  // SET_ACTIVITY_LOG,
  SET_ASSIGNED_MEMBERS,
  SET_ASSESSMENT_DATA,
  RESET_BASIC_INFORMATION,
  RESET_MATERIALITY_ASSESSMENT,
  SET_WEIGHTAGE,
  SET_RELEVANCE,
  SET_SELECTED_METRICS,
  UPLOAD_LOGO,
  SET_TOPIC_NAME,
  SET_ASSESSMENT_REPORT,
  SET_SELECTED_TOPICS,
  SET_IS_MATERIALITY_COMPLETED,
  SET_FRAMEWORD_TEST,
  SET_COMPANY_STATUS,
  SET_SECTORS,
  SET_INDUSTRIES,
  SET_LOCATIONS,
  UNSET_LOCATION,
  SET_LOCATION_INDUSTRY_TYPE,
  SET_VOTE_DATA,
  SET_CURRENT_TOPIC_OBJECT,
  SET_CURRENT_MAIN_TOPIC_OBJECT,
  SET_IS_UPSERT,
  SET_APPROVERS_REVIEWERS,
  SET_BLOB_URL,
  SET_ASSESSMENT_SKELETON,
  SET_JWT_HAS,
  SET_SUSTAINABILITY_PDF_PROGRESS,
  SET_SUSTAINABILITY_TOC,
  SET_SUSTAINABILITY_PROGRESS_COUNTER
} from "../../constants/sustainabilityReport/sustainabilityReportConst";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";
import { resetApprovalData } from "../../actions/assessmentApproval/assessmentApproval";
import { resetBusinessUnit } from "../../actions/businessUnit/businessUnitAction";
import { resetESGStrategy } from "../../actions/esgStrategy/esgStrategyAction";
import { resetFieldData } from "../../actions/fielddata/fieldDataAction";
import { resetFramework } from "../../actions/frameworks/frameworkAction";
import { resetTopic } from "../../actions/topics/topicActions";
import UNIVERSAL from "../../config/config";
import encrypt from "../shared/sharedActions";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { viewActivityLog } from "../../actions/surveyListing/surveyListingAction";
import { getCommentsToc } from "./sustainabilityReportPdfAction";
// import { Redirect } from "react-router-dom";

export const setFrameWorkTest = (payload) => ({
  type: SET_FRAMEWORD_TEST,
  payload: payload
});

export const setCompanyStatus = (payload) => ({
  type: SET_COMPANY_STATUS,
  payload: payload
});

export const setTopicName = (payload) => ({
  type: SET_TOPIC_NAME,
  payload: payload
});

export const setUpdateDefineObject = (payload) => ({
  type: SET_UPDATE_DEFINE_OBJECTIVE,
  payload: payload
});

export const unsetDefineObject = (payload) => ({
  type: UNSET_UPDATE_DEFINE_OBJECTIVE,
  payload: payload
});

export const setUpdateDefineMateriality = (payload) => ({
  type: SET_UPDATE_DEFINE_MATERIALITY,
  payload: payload
});

export const unsetDefineMateriality = (payload) => ({
  type: UNSET_DEFINE_MATERIALITY,
  payload: payload
});

export const setApproversReviewers = (payload) => ({
  type: SET_APPROVERS_REVIEWERS,
  payload: payload
});

export const setAddress = (payload) => ({
  type: SET_ADDRESS,
  payload: payload
});

export const setLocations = (payload) => ({
  type: SET_LOCATIONS,
  payload: payload
});

export const unsetLocation = (payload) => ({
  type: UNSET_LOCATION,
  payload: payload
});

export const unsetAddress = (payload) => ({
  type: UNSET_ADDRESS,
  payload: payload
});

export const setWeightage = (payload) => ({
  type: SET_WEIGHTAGE,
  payload: payload
});

export const setRelevance = (payload) => ({
  type: SET_RELEVANCE,
  payload: payload
});

export function setActiveStep(step) {
  return {
    type: SET_ACTIVE_BUTTON,
    payload: step
  };
}

export function setCompanyName(payload) {
  return {
    type: SET_COMPANY_NAME,
    payload: payload
  };
}
export function setCompanyAddress(payload) {
  return {
    type: SET_COMPANY_ADDRESS,
    payload: payload
  };
}
export function setCompanyAddressArray(payload) {
  return {
    type: SET_COMPANY_ADDRESS_ARRAY,
    payload: payload
  };
}
export function setBusinessType(payload) {
  return {
    type: SET_BUSINESS_TYPE,
    payload: payload
  };
}
export function setStartDate(payload) {
  return {
    type: SET_START_DATE,
    payload: payload
  };
}
export function setCompanyDescription(payload) {
  return {
    type: SET_COMPANY_DESCRIPTION,
    payload: payload
  };
}
export function setSectorType(payload) {
  return {
    type: SET_SECTOR_TYPE,
    payload: payload
  };
}
export function setAssessmentDate(payload) {
  return {
    type: SET_ASSESSMENT_DATE,
    payload: payload
  };
}
export function setCMPName(payload) {
  return {
    type: SET_CMP_NAME,
    payload: payload
  };
}
export function setCMPAddress(payload) {
  return {
    type: SET_CMP_ADDRESS,
    payload: payload
  };
}
export function setCMPPincode(payload) {
  return {
    type: SET_CMP_PINCODE,
    payload: payload
  };
}
export function setIndustryType(payload) {
  return {
    type: SET_INDUSTRY_TYPE,
    payload: payload
  };
}
export function setLocationIndustryType(payload) {
  return {
    type: SET_LOCATION_INDUSTRY_TYPE,
    payload: payload
  };
}
export function setNumberOfPermanentEmployee(payload) {
  return {
    type: SET_NUMBER_OF_PERMANENT_EMPLOYEE,
    payload: payload
  };
}
export function setNumberOfContractEmployee(payload) {
  return {
    type: SET_NUMBER_OF_CONTRACT_EMPLOYEE,
    payload: payload
  };
}
export function setCity(payload) {
  return {
    type: SET_CITY,
    payload: payload
  };
}
export function setCountry(payload) {
  return {
    type: SET_COUNTRY,
    payload: payload
  };
}
export function setDefineMateriality(payload) {
  return {
    type: SET_DEFINE_MATERIALITY,
    payload: payload
  };
}
export function setDefineObjective(payload) {
  return {
    type: SET_DEFINE_OBJECTIVE,
    payload: payload
  };
}
export function setDefineMaterialityArray(payload) {
  return {
    type: SET_DEFINE_MATERIALITY_ARRAY,
    payload: payload
  };
}
export function setDefineObjectiveArray(payload) {
  return {
    type: SET_DEFINE_OBJECTIVE_ARRAY,
    payload: payload
  };
}
export function setMainTopics(payload) {
  return {
    type: SET_MAIN_TOPICS,
    payload: payload
  };
}
export function setTopics(payload) {
  return {
    type: SET_TOPICS,
    payload: payload
  };
}
export function setSearchMetric(payload) {
  return {
    type: SET_SEARCH_METRIC,
    payload: payload
  };
}
export function setFilter(payload) {
  return {
    type: SET_FILTER,
    payload: payload
  };
}
export function setChip(payload) {
  return {
    type: SET_CHIP,
    payload: payload
  };
}
export function setSelectBox(payload) {
  return {
    type: SET_SELECT_BOX,
    payload: payload
  };
}
export function setShowAssest(payload) {
  return {
    type: SET_SHOW_ASSEST,
    payload: payload
  };
}
export const setMetrics = (payload) => {
  return {
    type: SET_METRICS,
    payload: payload
  };
};
export function setSelectedMetrics(payload) {
  return {
    type: SET_SELECTED_METRICS,
    payload: payload
  };
}
export function setAssign(payload) {
  return {
    type: SET_ASSIGN,
    payload: payload
  };
}
export function setTopicRelevance(payload) {
  return {
    type: SET_TOPIC_RELEVANCE,
    payload: payload
  };
}

export function setRisks(payload) {
  return {
    type: SET_RISKS,
    payload: payload
  };
}
export function setOpportunity(payload) {
  return {
    type: SET_OPPORTUNITY,
    payload: payload
  };
}
export function setFile(payload) {
  return {
    type: SET_FILE,
    payload: payload
  };
}
export function setTopicsArray(payload) {
  return {
    type: SET_TOPICS_ARRAY,
    payload: payload
  };
}
export function setFilesArray(payload) {
  return {
    type: SET_FILES_ARRAY,
    payload: payload
  };
}
export function setCurrentTopicId(payload) {
  return {
    type: SET_CURRENT_TOPIC_ID,
    payload: payload
  };
}
export function resetTopics() {
  return {
    type: RESET_TOPICS
  };
}
export function setSearchKeyword(payload) {
  return {
    type: SET_SEARCH_KEYWORD,
    payload: payload
  };
}
export function setMembersArray(payload) {
  return {
    type: SET_MEMBERS_ARRAY,
    payload: payload
  };
}
export function setAddEmail(payload) {
  return {
    type: SET_ADD_EMAIL,
    payload: payload
  };
}
export function resetAddEmail() {
  return {
    type: RESET_ADD_EMAIL
  };
}

export function setbasicFile(payload) {
  return {
    type: BASIC_FILE,
    payload: payload
  };
}

export function setbasicCompanyFile(payload) {
  return {
    type: BASIC_COMPANY_FILE,
    payload: payload
  };
}

export function setFrameWork(payload) {
  return {
    type: FRAMEWORK,
    payload: payload
  };
}

export function setFrameValue(payload) {
  return {
    type: VALUE,
    payload: payload
  };
}

export function setAssignedMembers(payload) {
  return {
    type: SET_ASSIGNED_MEMBERS,
    payload: payload
  };
}
export function resetBasicInformation() {
  return {
    type: RESET_BASIC_INFORMATION
  };
}
export function resetMaterialityAssessment() {
  return {
    type: RESET_MATERIALITY_ASSESSMENT
  };
}

export function setlogo(payload) {
  return {
    type: UPLOAD_LOGO,
    payload: payload
  };
}

export function setAssessmentReport(payload) {
  return {
    type: SET_ASSESSMENT_REPORT,
    payload: payload
  };
}

export function setSelectedTopics(payload) {
  return {
    type: SET_SELECTED_TOPICS,
    payload: payload
  };
}

export function setIsMaterialityCompleted(payload) {
  return {
    type: SET_IS_MATERIALITY_COMPLETED,
    payload: payload
  };
}

export function setSectors(payload) {
  return {
    type: SET_SECTORS,
    payload: payload
  };
}

export function setIndustries(payload) {
  return {
    type: SET_INDUSTRIES,
    payload: payload
  };
}

export function setVoteData(payload) {
  return {
    type: SET_VOTE_DATA,
    payload: payload
  };
}

export function setCurrentMainTopicObject(payload) {
  return {
    type: SET_CURRENT_MAIN_TOPIC_OBJECT,
    payload: payload
  };
}

export function setCurrentTopicObject(payload) {
  return {
    type: SET_CURRENT_TOPIC_OBJECT,
    payload: payload
  };
}

export function setIsUpsert(payload) {
  return {
    type: SET_IS_UPSERT,
    payload: payload
  };
}

export const getIndustries = (sector_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      sector_id: sector_id
    });

    return fetch(UNIVERSAL.BASEURL + "/assessment/get_industry_type", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setIndustries(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later ...."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getAssessmentReport = (token, assessmentId) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id: assessmentId
    });

    return fetch(UNIVERSAL.BASEURL + "/assessment/get_assessment_report", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAssessmentReport(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later ...."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const setPdfBlobs = (payload) => {
  return {
    type: SET_BLOB_URL,
    payload
  };
};

export const setAssessmentSkeleton = (payload) => ({
  type: SET_ASSESSMENT_SKELETON,
  payload: payload
});

export const setJwt_has = (payload) => ({
  type: SET_JWT_HAS,
  payload
});

export const setSustainabilityPdfProgress = (payload) => ({
  type: SET_SUSTAINABILITY_PDF_PROGRESS,
  payload
});

export const setSustainabilityToc = (payload) => ({
  type: SET_SUSTAINABILITY_TOC,
  payload
});

export const setSustainabilityProgressCounter = (payload) => ({
  type: SET_SUSTAINABILITY_PROGRESS_COUNTER,
  payload
});

export const getSustainabilityPdfProgress = (
  token,
  assessment_id,
  step_no,
  counter = 0
) => {
  return (dispatch) => {
    dispatch(
      setSustainabilityProgressCounter({
        id: assessment_id,
        counter: counter
      })
    );
    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id
    });

    return fetch(
      UNIVERSAL.BASEURL + "/assessments/get_sustainability_report_progress",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: data })
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setSustainabilityPdfProgress({
              id: assessment_id,
              progress: responseJson.result
            })
          );
          if (responseJson.link) {
            const url = responseJson.link;
            dispatch(
              setPdfBlobs({
                id: assessment_id,
                pdfBlob: url + `?${Date.now()}`
              })
            );
            dispatch(
              setSustainabilityPdfProgress({
                id: assessment_id,
                progress: responseJson.result
              })
            );
            dispatch(hideLoading());
            dispatch(setAssessmentSkeleton(false));
          }
          if (responseJson.result === 100) {
            dispatch(getCommentsToc(token, assessment_id, step_no));
          }
          if (responseJson.link === null || responseJson.link === undefined) {
            counter++;
            setTimeout(() => {
              dispatch(
                getSustainabilityPdfProgress(
                  token,
                  assessment_id,
                  step_no,
                  counter
                )
              );
            }, 5000);
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getSustainabilityReportPdf = (
  token,
  assessmentId,
  jwt_hash,
  step_no,
) => {
  return (dispatch) => {
    dispatch(setAssessmentSkeleton(true));
    dispatch(showLoading("sectionBar"));
    //setting the progress value to null to prevent the dulpicate api on the component
    dispatch(
      setSustainabilityPdfProgress({
        id: assessmentId,
        progress: null
      })
    );

    const data = encrypt({
      "user-token": token,
      assessment_id: assessmentId,
      jwt_hash: jwt_hash,
      payment_status:localStorage.getItem('status') || ''
    });

    return fetch(
      UNIVERSAL.BASEURL + "/assessments/get_sustainability_report_pdf",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: data })
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.result !== null) {
            dispatch(
              setPdfBlobs({
                id: assessmentId,
                pdfBlob: responseJson.result + `?${Date.now()}`
              })
            );
            dispatch(
              setSustainabilityPdfProgress({
                id: assessmentId,
                progress: 100
              })
            );
            dispatch(setJwt_has(responseJson.jwt_hash));
            dispatch(hideLoading());
            dispatch(setAssessmentSkeleton(false));
            dispatch(hideLoading("sectionBar"));
            dispatch(getCommentsToc(token, assessmentId, step_no));
          }
          if (responseJson.result === null) {
            setTimeout(() => {
              dispatch(
                getSustainabilityPdfProgress(token, assessmentId, step_no)
              );
            }, 2000);
          } else {
            dispatch(hideLoading());
            dispatch(setAssessmentSkeleton(false));
          }
        } else {
          dispatch(hideLoading("sectionBar"));
          dispatch(setAssessmentSkeleton(false));
          // dispatch(setSustainabilityPdfProgress(100));
          // clearInterval(interval);
        }
      })
      .catch((error) => {
        console.error(error);
        // clearInterval(interval);
      })
      .finally(() => {
        // dispatch(unset_loader());
      });
  };
};
export function resetAllAssessmentData() {
  return (dispatch) => {
    dispatch(resetBasicInformation());
    dispatch(resetMaterialityAssessment());
    dispatch(resetApprovalData());
    dispatch(resetBusinessUnit());
    dispatch(resetESGStrategy());
    dispatch(resetFieldData());
    dispatch(resetFramework());
    dispatch(resetTopic());
  };
}

export const getMainTopics = (token, frameworkId) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      framework_id: frameworkId
    });

    return fetch(UNIVERSAL.BASEURL + "/topicsMasterRoute/get_main_topics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setMainTopics(responseJson.result));
        } else {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later ...."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getTopics = (
  token,
  frameworkId,
  topicId,
  assessment_id,
  topicName
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      framework_id: frameworkId,
      topic_id: topicId,
      assessment_id: assessment_id
    });

    return fetch(UNIVERSAL.BASEURL + "/topicsMasterRoute/get_topics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setTopics(responseJson.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          // if (topicName !== undefined) {
          //   dispatch(getMetrics(token, responseJson.result[0]._id, assessment_id, topicName));
          // }
          // dispatch(setTopicName(responseJson.result[0].topic_name + ':' + responseJson.result[0].description));
          // } else {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later ...."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getFrameworks = (token) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token
    });

    return fetch(UNIVERSAL.BASEURL + "/frameworks/view_frameworks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setFrameWork(responseJson.result));
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getMetrics = (token, topicId, assessment_id, topicName) => {
  return (dispatch) => {
    dispatch(set_loader());
    dispatch(setSelectedMetrics([]));
    dispatch(setTopicRelevance(""));
    dispatch(setWeightage(""));
    dispatch(setRisks(""));
    dispatch(setOpportunity(""));
    dispatch(setIsMaterialityCompleted(false));
    dispatch(setFile(null));
    // if (topicName !== undefined) {
    //   dispatch(setTopicName(topicName));
    // }
    const data = encrypt({
      "user-token": token,
      topic_id: topicId,
      assessment_id: assessment_id
    });

    return fetch(UNIVERSAL.BASEURL + "/metrics/get_metrics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
        alert(2)
          dispatch(setMetrics(responseJson.result.metric_data));
          dispatch(setCurrentTopicId(topicId));
          if (responseJson.result.selected_data !== null) {
            dispatch(setIsMaterialityCompleted(true));
            // if (topicName !== undefined) {
            //   dispatch(setTopicName(topicName));
            // }
            dispatch(
              setSelectedMetrics(responseJson.result.selected_data.metrics)
            );
            dispatch(
              setTopicRelevance(responseJson.result.selected_data.relevance)
            );
            dispatch(setWeightage(responseJson.result.selected_data.weightage));
            dispatch(
              setRisks(responseJson.result.selected_data.additional_params.risk)
            );
            dispatch(
              setOpportunity(
                responseJson.result.selected_data.additional_params.opportunity
              )
            );
            if (!responseJson.result.selected_data.file.name) {
              dispatch(setFile(null));
            }
            if (responseJson.result.selected_data.file.name) {
              dispatch(setFile(responseJson.result.selected_data.file));
            }
          } else {
            dispatch(setFile(null));
          }
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later...."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const viewAssessmentTopic = (token, assessment_id, topic_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    dispatch(setMetrics([]));

    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      topic_id: topic_id
    });

    return fetch(
      UNIVERSAL.BASEURL + "/assessmentTopics/view_assessment_topics",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: data })
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setTopicsArray([
              {
                metrics: responseJson.result[0].metrics,
                relevance: responseJson.result[0].relevance,
                additional_params: {
                  risk: responseJson.result[0].additional_params.risk,
                  opportunity:
                    responseJson.result[0].additional_params.opportunity
                },
                topics_id: responseJson.result[0].topic_id
              }
            ])
          );
          dispatch(setRelevance(responseJson.result[0].relevance));
          dispatch(
            setOpportunity(responseJson.result[0].additional_params.opportunity)
          );
          dispatch(setRisks(responseJson.result[0].additional_params.risk));
          dispatch(setWeightage(responseJson.result[0].weightage));
          dispatch(
            setFile(
              responseJson.result[0].file === ""
                ? null
                : responseJson.result[0].file
            )
          );
        } else {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later..."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const updateMaterialityAssessmentTopic = (
  user_token,
  assessment_id,
  topic_id,
  metrics,
  relevance,
  weightage,
  additional_params,
  file,
  organisation_id
) => {
  return (dispatch) => {
    dispatch(set_loader());
    var formdata = new FormData();

    const data = encrypt({
      "user-token": user_token,
      assessment_id: assessment_id,
      topic_id: topic_id,
      metrics: metrics,
      relevance: relevance,
      weightage: weightage,
      additional_params: additional_params,
      organisation_id: organisation_id
    });

    formdata.append("data", data);
    formdata.append("file", file);

    return fetch(
      UNIVERSAL.BASEURL + "/assessmentTopics/upsert_assessment_topic",
      {
        method: "POST",
        body: formdata
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(viewAssessmentTopic(user_token, assessment_id, topicsArray[0].topics_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(viewActivityLog(user_token, assessment_id, 2));
          // dispatch(setSelectedMetrics([]));
          // dispatch(setTopicRelevance(""));
          // dispatch(setWeightage(""));
          // dispatch(setRisks(""));
          // dispatch(setOpportunity(""));
          // dispatch(setFile(null));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(
        //   set_snack_bar(
        //     500,
        //     "Please try again later can't upsert assessment topic..."
        //   )
        // );
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function updateMaterialityAssessment(
  token,
  assessmentId,
  topics,
  files,
  isSave,
  activeStep
) {
  var formData = new FormData();
  let data = encrypt({
    "user-token": token,
    assessment_id: assessmentId,
    topics: JSON.stringify(topics),
    save: isSave
  });
  formData.append("data", data);
  files.map((file, i) => formData.append(`${i}`, file));

  return (dispatch) => {
    dispatch(set_loader());
    return fetch(
      UNIVERSAL.BASEURL + "/assessment/update_materiality_assessment",
      {
        method: "POST",
        body: formData
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (isSave) {
            dispatch(unset_loader());
            window.location = "./survey_listing";
          }
          if (!isSave) {
            dispatch(setActiveStep(activeStep + 1));
          }
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

//FUNCTION TO BE USED WHEN IN SEARCH FUNCTIONALITY
export function searchMembers({ keyword, limit, startingAfter }) {
  let data = encrypt({
    "user-token": "user123",
    keyword: keyword,
    limit: limit,
    starting_after: startingAfter
  });
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/userDetails/search_members", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.result) {
          dispatch(setMembersArray(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

//FUNCTION TO BE USED TO GET MEMBERS THAT ARE ALREADY ASSIGNED
export const getAssignedMembers = (
  token,
  assessment_id,
  step_no,
  topic_id,
  metric_id,
  survey_id
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      step_no: parseInt(step_no),
      topic_id: topic_id,
      metric_id: metric_id,
      survey_id: survey_id
    });

    return fetch(UNIVERSAL.BASEURL + "/assignMember/view_member_assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAssignedMembers(responseJson.result));
        }
        if (!responseJson.status && responseJson.result === null) {
          dispatch(setAssignedMembers([]));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(500, "Please try again later..."));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const addMember = (
  token,
  email,
  assessment_id,
  step_no,
  topic_id,
  metric_id,
  report_section_id,
  role,
  organisation_id
) => {
  return (dispatch) => {
    dispatch(set_loader());

    if (email.length > 1) {
      dispatch(set_snack_bar(404, "Please Enter only one email address.."));
      dispatch(unset_loader());
    } else {
      let url = window.origin;
      const data = encrypt({
        "user-token": token,
        email: email,
        assessment_id: assessment_id,
        step_no: step_no,
        topic_id: topic_id,
        metric_id: metric_id,
        report_section_id: report_section_id,
        role: role,
        organisation_id: organisation_id,
        url
      });

      return fetch(UNIVERSAL.BASEURL + "/assignMember/add_member_assign", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: data })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else {
            // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
        })
        .catch((error) => {
          console.error(error);
          // dispatch(set_snack_bar(500, "Please try again later ..."));
        })
        .finally(() => {
          dispatch(unset_loader());
        });
    }
  };
};

export function setAssessmentStepData(payload) {
  return {
    type: SET_ASSESSMENT_DATA,
    payload: payload
  };
}

export const getAssessmentStepData = (token, assessment_id, step_no) => {
  return (dispatch) => {
    dispatch(set_loader());
    if (step_no === "1") {
      dispatch(setFrameWorkTest(false));
    }
    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      step_no: step_no
    });

    return fetch(UNIVERSAL.BASEURL + "/assessment/get_assessment_step_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.result) {
          dispatch(setCompanyStatus(responseJson.status));
          dispatch(setAssessmentStepData(responseJson.result));
          dispatch(setSectors(responseJson.result.sector));
          if (step_no === "1") {
            dispatch(setFrameWorkTest(responseJson.status));
          }
        } else {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function upsertAssessmentTopic(
  user_token,
  assessment_id,
  topic_id,
  metrics,
  relevance,
  weightage,
  additional_params,
  file,
  frameworkId,
  mainTopicId,
  organisation_id
) {
  return (dispatch) => {
    // dispatch(set_loader());

    var formData = new FormData();

    const data = encrypt({
      "user-token": user_token,
      assessment_id,
      topic_id,
      metrics,
      relevance,
      weightage,
      additional_params,
      organisation_id: organisation_id,
      
    });

    formData.append("data", data);
    formData.append("file", file);

    return fetch(
      UNIVERSAL.BASEURL + "/assessmentTopics/upsert_assessment_topic",
      {
        method: "POST",
        body: formData
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getSelectedTopics(user_token, assessment_id));
          // dispatch(getTopics(user_token, frameworkId, mainTopicId, assessment_id, topicName));
          // dispatch(getMetrics(user_token, topic_id, assessment_id));
          dispatch(viewActivityLog(user_token, assessment_id, 2));
        } else {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later"));
      })
      .finally(() => {
        // dispatch(unset_loader());
      });
  };
}

export function upsertVoteData(token, topic_id, assessment_id, relevance) {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
      topic_id,
      relevance
    });

    return fetch(UNIVERSAL.BASEURL + "/assessmentTopics/upsert_vode_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setVoteData(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later"));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function getSelectedTopics(user_token, assessment_id) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": user_token,
      assessment_id
    });

    return fetch(UNIVERSAL.BASEURL + "/assessmentTopics/view_selected_topics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSelectedTopics(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later"));
      });
  };
}

export function finishAssessment(user_token, assessment_id) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": user_token,
      assessment_id
    });

    return fetch(UNIVERSAL.BASEURL + "/assessment/finish_assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ data: data })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
      })
      .catch((error) => {
        console.error(error);
        // dispatch(set_snack_bar(true, "Please try again later"));
      });
  };
}
