import React, { useEffect, useCallback, useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../UI/button/button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import CustomTextfield from "../../UI/textfield/textfield";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";
import { getTimeStamp } from "../../../utils/reportData/reportDataRightUtils";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		"& .MuiBackdrop-root": {
			backdropFilter: "blur(2px)",
			backgroundColor: "#63b6a921",
		},
		"& .MuiDrawer-paper": {
			width: "80%",
		},
	},
	Menu: { marginTop: 35, marginRight: 40 },
	companyLogo: {
		border: "2px solid #DADBE6",
		width: 120,
		height: 120,
		borderRadius: 5,
		zIndex: 1,
		"& img": {
			width: "100%",
			height: "100%",
		},
		position: "relative",
	},
	ClearImage: {
		position: "absolute",
		right: 0,
		top: 0,
		padding: 2,
	},
	fileDisplayContainer: {
		background: "#FEFEFE",
		borderRadius: "8px",
		border: "1px solid #E9E9E9",
		display: "flex",
		flexDirection: "row",

		alignItems: " center",
		maxWidth: "350px",
	},
	fileName: {
		fontSize: "12px",
		lineHeight: "16px",
		fontWeight: 500,
		color: "#15314E",
		letterSpacing: "0.005em",
		paddingLeft: "8px",
	},
	mainGrid: { borderBottom: "1px solid #DADBE6" },
	Typography2: {
		paddingBottom: 5,
		borderBottom: "3px solid green",
		display: "flex",
		justifyContent: "center",
		fontWeight: "bold",
	},
	div1: { width: 300 },
	Typography3: { fontSize: "small", marginTop: 5 },
	CustomButton: { padding: "2px 10px", textTransform: "none" },
	versionButton: {
		textTransform: "none",
		float: "right",
		marginRight: "10px",
		color: "#3374B9",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "white",
			boxShadow: "1px 1px 3px #c8c0c0a1",
		},
	},
	headerIcon: {
		paddingLeft: "5px",
	},
}));

function ViewSubmissionChatThread({
	setThread,
	socket,
	login,
	metric,
	assessment_id,
	fieldData,
	selectedSubmissionId,
	approveSubmission,
	user_data,
	getAssignedMetric,
	ownSbmission,
	task,
	main,
	getSubmissionDetails,
	...props
}) {
	const classes = useStyles();
	const [comment, setComment] = useState("");
	const [disclosureFile, setDisclosureFile] = useState("");
	const [disclosureFileUrl, setDisclosureFileUrl] = useState("");
	const [dataUrl, setDataUrl] = useState("");
	const [showFileContainer, setShowFileContainer] = useState(false);
	const dataUrlRef = useRef(dataUrl);

	const handleSendCommentCallback = useCallback((comment, file) => {
		if (file) {
			let finalFileObj = {
				file: file.file,
				fileName: file.name,
				fileType: file.type,
			};
			return {
				user_token: login.token,
				organisation_id: login.current_organisation,
				comment,
				assessment_id,
				metric_id: metric._id,
				files: finalFileObj,
				chat_id: selectedSubmissionId,
			};
		} else {
			return {
				user_token: login.token,
				organisation_id: login.current_organisation,
				comment,
				assessment_id,
				metric_id: metric._id,
				chat_id: selectedSubmissionId,
			};
		}
	}, []);

	const handleApproveCallBack = useCallback(() => {
		return {
			user_token: login.token,
			organisation_id: login.current_organisation,
			assessment_id,
			metric_id: metric._id,
			chat_id: selectedSubmissionId,
		};
	}, []);

	useEffect(() => {
		dataUrlRef.current = dataUrl;
	}, [dataUrl]);
	const handleSendComment = () => {
		if (!comment && !disclosureFile) return;
		if (comment || disclosureFile) {
			socket.emit(
				"sendComment",
				handleSendCommentCallback(comment, dataUrlRef.current)
			);
			setComment("");
		} else alert("empty comment");
		setDisclosureFile("");
		setShowFileContainer(false);
		setDataUrl("");
		setDisclosureFileUrl("");
	};

	const handleApprove = () => {
		socket.emit("approve", handleApproveCallBack());
	};
	const reader = (file) => {
		let finalResult = {};
		finalResult.name = file.name;
		finalResult.type = file.type;
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.onload = () => {
				finalResult.file = fileReader.result;
				resolve(finalResult);
			};
			fileReader.readAsDataURL(file);
		});
	};
	const handleFileClear = () => {
		setDisclosureFile("");
		setDisclosureFileUrl("");
		setShowFileContainer(false);
	};

	const handleFileUpload = async (e) => {
		e.preventDefault();
		setDisclosureFile(e.target.files[0]);
		setDisclosureFileUrl(URL.createObjectURL(e.target.files[0]));
		setShowFileContainer(true);
		reader(e.target.files[0]).then((result) => setDataUrl(result));
	};
	const [confirmationDialog, setConfirmationDialog] = useState(false);
	const [showSubmissionVersion, setShowSubmissionVersion] = useState([]);
	const [currentVersion, setCurrentVersion] = useState({
		data: [],
		comment: "",
	});
	const [anchorEl, setAnchorEl] = useState(null);
	const [selected, setSelected] = useState("");

	const confirmation = () => {
		return (
			<>
				<Dialog
					open={confirmationDialog}
					maxWidth="md"
					onClose={() => setConfirmationDialog(false)}
				>
					<DialogTitle>
						<Grid
							container
							justifyContent="center"
							className={classes.mainGrid}
						>
							<Grid item xs={10}>
								<Typography className={classes.Typography2}>
									Approve this Contribution
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent>
						<Grid>
							{/* {!fieldData.currentSubmission?.approved_data ? ( */}
							{!fieldData.currentSubmission?.approved_data ? (
								<div className={classes.div1}>
									<Grid container style={{ alignItems: "center" }}>
										<ListAltIcon
											size="large"
											style={{
												fontSize: 28,
												backgroundColor: "#3374B9",
												color: "#fff",
												// padding: 1,
											}}
										/>
										<Avatar
											style={{
												marginLeft: 10,
											}}
											src={
												fieldData &&
												fieldData.currentSubmission &&
												fieldData.currentSubmission.user &&
												fieldData.currentSubmission?.user?.user_data
													?.profile_img?.url
											}
											alt="user"
										/>
									</Grid>
									<Grid
										container
										style={{ alignItems: "center", marginTop: 5 }}
									>
										<Typography className={classes.Typography3}>
											{`Approve ${fieldData.currentSubmission?.user?.user_data.name}'s Version on disclosure`}
										</Typography>
									</Grid>
								</div>
							) : (
								<div className={classes.div1}>
									<Grid container style={{ alignItems: "center" }}>
										<ListAltIcon
											size="large"
											style={{
												fontSize: 28,
												backgroundColor: "#3374B9",
												color: "#fff",
												// padding: 1,
											}}
										/>{" "}
										<Avatar
											style={{
												marginLeft: 10,
											}}
											src={
												fieldData.currentSubmission?.approved_data &&
												fieldData.currentSubmission?.approved_data?.contributor
													?.profile_img?.url
											}
										/>
									</Grid>
									<Grid
										container
										style={{ alignItems: "center", marginTop: 5 }}
									>
										<DoneIcon
											varient="filled"
											style={{
												backgroundColor: "#63b6a921",
												color: "#3374B9",
												alignItems: "center",
												padding: 1,
											}}
										/>
										<Avatar
											style={{
												marginLeft: 15,
											}}
											src={
												fieldData.currentSubmission?.approved_data &&
												fieldData.currentSubmission?.approved_data?.approver
													?.profile_img?.url
											}
										/>
										<Typography className={classes.Typography3}>
											{`Looks like ${
												fieldData.currentSubmission?.approved_data &&
												fieldData.currentSubmission?.approved_data?.approver
													?.name
											} has approved ${
												fieldData.currentSubmission?.approved_data &&
												fieldData.currentSubmission?.approved_data?.contributor
													?.name
											}'s Version on disclosure `}
										</Typography>
									</Grid>
									<Grid
										container
										style={{ alignItems: "center", marginTop: 5 }}
									>
										<ListAltIcon
											size="large"
											style={{
												fontSize: 28,
												backgroundColor: "#3374B9",
												color: "#fff",
												// padding: 1,
											}}
										/>{" "}
										<Avatar
											style={{
												marginLeft: 10,
											}}
											src={
												fieldData.currentSubmission &&
												fieldData.currentSubmission.user &&
												fieldData.currentSubmission?.user?.user_data
													?.profile_img?.url
											}
										/>
									</Grid>
								</div>
							)}
						</Grid>
					</DialogContent>
					<DialogActions className={classes.DialogActions}>
						<CustomButton
							variant="outlined"
							color="primary"
							className={classes.CustomButton}
							onClick={() => {
								setConfirmationDialog(false);
							}}
						>
							Cancel
						</CustomButton>
						<CustomButton
							variant="contained"
							color="primary"
							className={classes.CustomButton}
							onClick={() => {
								setConfirmationDialog(false);
								approveSubmission(
									login.token,
									metric._id,
									assessment_id,
									fieldData.currentSubmission.user.user_data._id,
									login.current_organisation,
									selectedSubmissionId,
									currentVersion && currentVersion?.selected_index,
									metric.parent_metric_id,
									props.is_approver
								);
								//call update api
								setTimeout(() => {
									getAssignedMetric(
										login.token,
										assessment_id,
										metric.parent_metric_id,
										props.is_approver
									);
								}, [800]);
								handleApprove();
							}}
						>
							Continue to Approve
						</CustomButton>
					</DialogActions>
				</Dialog>
			</>
		);
	};

	useEffect(() => {
		if (
			fieldData &&
			fieldData?.currentSubmission &&
			fieldData.currentSubmission?.submissions
		) {
			setShowSubmissionVersion(fieldData.currentSubmission?.submissions);
			setCurrentVersion(
				fieldData.currentSubmission.submissions[
					fieldData.currentSubmission?.submissions.length - 1
				]
			);
			setSelected(`Version ${fieldData.currentSubmission.submissions.length}`);
		}
	}, [fieldData.currentSubmission]);
	useEffect(() => {
		return () => {
			setShowSubmissionVersion([]);
			setCurrentVersion({ data: [], comment: "" });
			setSelected(`Version`);
		};
	}, []);

	const handleDownloadSelect = (e, sub, i) => {
		setCurrentVersion(sub);
		setSelected(`Version ${i}`);
		// getSubmissionDetails(
		//   login.token,
		//   sub.submission_id,
		//   sub.user_id,
		//   // filtered.length>0&& filtered[0],
		// );
		setAnchorEl(null);
	};

	return (
		<>
			{confirmationDialog && confirmation()}
			<div>
				<Divider />
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Box display="flex" alignItems="center">
						<Box>
							{!ownSbmission && (
								<IconButton
									onClick={() => {
										setShowSubmissionVersion([]);
										setCurrentVersion({ data: [], comment: "" });
										setSelected(`Version`);
										setThread(false);
									}}
									size="small"
								>
									<ArrowBackIcon />
								</IconButton>
							)}
						</Box>
						<Box>
							<ListAltIcon
								style={{
									backgroundColor: "#3374B9",
									color: "#fff",
									padding: 2,
								}}
							/>
						</Box>
						<Box>
							<List disablePadding dense>
								<ListItem>
									<ListItemAvatar>
										<Avatar
											src={
												fieldData.currentSubmission &&
												fieldData.currentSubmission.user &&
												fieldData.currentSubmission.user.user_data?.profile_img
													?.url
											}
										/>
									</ListItemAvatar>
									<ListItemText
										style={{ color: "#000" }}
										primary={
											fieldData.currentSubmission &&
											fieldData.currentSubmission.user &&
											fieldData.currentSubmission.user.user_data.name
										}
									/>
								</ListItem>
							</List>
						</Box>
						<Box>
							<Typography variant="caption" color="textSecondary">
								{getTimeStamp(currentVersion?.added_at)}
							</Typography>
						</Box>
					</Box>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Button
							// style={{ float: 'right' }}
							className={classes.versionButton}
							onClick={(e) => setAnchorEl(e.currentTarget)}
						>
							{selected} <ExpandMoreIcon className={classes.headerIcon} />
						</Button>

						<Menu
							id="simple-menu"
							variant="selectedMenu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
							style={{ top: "30px" }}
						>
							{fieldData?.currentSubmission &&
								fieldData.currentSubmission?.submissions.map((sub, i) => {
									return (
										<div
											style={{
												display: "felx",
												justifyContent: "space-between",
												borderRadius: "4px",
												margin: " 0px 5px",
												backgroundColor:
													selected === `Version ${i + 1}` && "#daefe2",
											}}
										>
											<MenuItem
												value="Completed"
												style={{
													width: "140px",
													justifyContent: "space-between",
												}}
												onClick={(e) => handleDownloadSelect(e, sub, i + 1)}
											>
												<div>
													<Typography
														style={{
															fontSize: "14px",
															fontWeight:
																selected === `Version ${i + 1}` && 500,
														}}
													>
														{" "}
														{`Version ${i + 1}`}
													</Typography>
												</div>
												<div>
													{selected === `Version ${i + 1}` && (
														<DoneIcon
															style={{
																color: "#5bb37f",
																fontSize: "25px",
																padding: " 5px 0px 0px 5px",
															}}
														/>
													)}
												</div>
											</MenuItem>
										</div>
									);
								})}
						</Menu>
						{/* <ChatBubbleOutlineIcon className={classes.versionButton} /> */}
					</Box>
				</Box>
				<Divider />
				<div
					className="scroll"
					style={{
						height: "80vh",
						maxWidth: "100%",
						overflowX: "scroll",
						backgroundColor: "#F6F6F6",
					}}
				>
					<Box px={2} pb={2}>
						{fieldData &&
							fieldData?.currentSubmission &&
							fieldData?.currentSubmission?.data_type === "Type 1" && (
								<>
									<Grid
										container
										alignItems="center"
										flexDirection="row"
										display="flex"
										spacing={2}
										className={classes.Grid}
									>
										<Grid item xs={12}>
											<Typography className={classes.Typography}>
												{currentVersion?.data && currentVersion?.data[0]?.row
													? currentVersion?.data[0]?.row
													: "Data"}
											</Typography>

											<CustomTextfield
												// label={
												//   currentVersion?.data && currentVersion?.data[0]?.row
												// }
												margin="dense"
												variant="outlined"
												className={classes.CustomTextfield}
												value={
													currentVersion?.data && currentVersion?.data[0]?.data
												}
												fullWidth
												rows={3}
												multiline
											/>
										</Grid>
									</Grid>
									<Box>
										<Box my={2}>
											<Typography className={classes.Typography}>
												Comments
											</Typography>
											<CustomTextfield
												style={{ marginTop: "10px" }}
												label="Additional Comment"
												fullWidth
												multiline
												rows={3}
												size="small"
												variant="outlined"
												value={currentVersion?.comment}
											/>
										</Box>
									</Box>
									<Typography
										className={classes.Typography}
										style={{ marginBottom: 5 }}
									>
										Files
									</Typography>
									{currentVersion?.file && (
										<Box className={classes.fileDisplayContainer} px={1} py={1}>
											<AttachFileIcon fontSize="small" color="primary" />
											<Typography className={classes.fileName}>
												{currentVersion?.file?.name}
											</Typography>
										</Box>
									)}
								</>
							)}

						{fieldData &&
							fieldData?.currentSubmission &&
							fieldData?.currentSubmission?.data_type === "Type 1.1" && (
								<>
									<Grid
										container
										alignItems="center"
										flexDirection="row"
										display="flex"
										spacing={2}
										className={classes.Grid}
									>
										<Grid item xs={12}>
											<Typography className={classes.Typography}>
												{currentVersion?.data && currentVersion?.data[0]?.row
													? currentVersion?.data[0]?.row
													: "Data"}
											</Typography>

											<CustomTextfield
												margin="dense"
												variant="outlined"
												className={classes.CustomTextfield}
												value={
													currentVersion?.data && currentVersion?.data[0]?.data
												}
												fullWidth
											/>
										</Grid>
									</Grid>
									<Box>
										<Box my={2}>
											<Typography className={classes.Typography}>
												Comments
											</Typography>
											<CustomTextfield
												style={{ marginTop: "10px" }}
												label="Additional Comment"
												fullWidth
												multiline
												rows={3}
												size="small"
												variant="outlined"
												value={currentVersion?.comment}
											/>
										</Box>
									</Box>
									{currentVersion?.file && (
										<>
											<Typography
												className={classes.Typography}
												style={{ marginBottom: 5 }}
											>
												Files
											</Typography>
											<Box
												className={classes.fileDisplayContainer}
												px={1}
												py={1}
											>
												<AttachFileIcon fontSize="small" color="primary" />
												<Typography className={classes.fileName}>
													{currentVersion?.file?.name}
												</Typography>
											</Box>
										</>
									)}
								</>
							)}
						{fieldData &&
							fieldData?.currentSubmission &&
							fieldData?.currentSubmission?.data_type === "Type 2" && (
								<>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell></TableCell>
												{fieldData &&
													fieldData.currentSubmission &&
													fieldData.currentSubmission?.columns.map((header) => {
														return (
															<TableCell style={{ textAlign: "center" }}>
																{header}
															</TableCell>
														);
													})}
											</TableRow>
										</TableHead>
										<TableBody>
											{currentVersion?.data &&
												currentVersion?.data.map((row) => {
													return (
														<>
															<TableRow>
																<TableCell>{row?.row}</TableCell>
																{Array.isArray(row?.columns) &&
																	row?.columns.map((column) => (
																		<TableCell style={{ textAlign: "center" }}>
																			{column.data}
																		</TableCell>
																	))}
															</TableRow>
														</>
													);
												})}
										</TableBody>
									</Table>
									<Box>
										<Box my={2}>
											<Typography className={classes.Typography}>
												Comments
											</Typography>
											<CustomTextfield
												style={{ marginTop: "10px" }}
												label="Additional Comment"
												fullWidth
												multiline
												rows={3}
												size="small"
												variant="outlined"
												value={currentVersion?.comment}
											/>
										</Box>
									</Box>
								</>
							)}
						{fieldData &&
							fieldData?.currentSubmission &&
							fieldData?.currentSubmission?.data_type === "Type 3" && (
								<>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell></TableCell>
												{fieldData &&
													fieldData.currentSubmission &&
													fieldData.currentSubmission.columns.map((header) => {
														return (
															<TableCell style={{ textAlign: "center" }}>
																{header}
															</TableCell>
														);
													})}
											</TableRow>
										</TableHead>
										<TableBody>
											{currentVersion?.data &&
												currentVersion?.data.map((row) => {
													return (
														<>
															<TableRow>
																<TableCell>{row.row}</TableCell>
																{Array.isArray(row?.columns) &&
																	row?.columns.map((column) => (
																		<TableCell style={{ textAlign: "center" }}>
																			{column.data}
																		</TableCell>
																	))}
															</TableRow>
														</>
													);
												})}
										</TableBody>
									</Table>
									<Box>
										<Box my={2}>
											<Typography className={classes.Typography}>
												Comments
											</Typography>
											<CustomTextfield
												style={{ marginTop: "10px" }}
												label="Additional Comment"
												fullWidth
												multiline
												rows={3}
												size="small"
												variant="outlined"
												value={currentVersion?.comment}
											/>
										</Box>
									</Box>
								</>
							)}
					</Box>
					<div style={{ background: "#FCFCFC" }}>
						{showFileContainer && (
							<Box
								width="90.50%"
								mx={1}
								px={1}
								py={1}
								style={{
									border: "1px solid #c4c4c4",
									borderRadius: "8px",
									boxShadow: "0px 0px 8px rgba(51, 116, 185, 0.25);",
									background: "#FCFCFC",
								}}
							>
								{disclosureFile !== "" && (
									<>
										{disclosureFile.type === "image/jpeg" ? (
											<div className={classes.companyLogo}>
												<img src={disclosureFileUrl} alt="company-logo" />
												<IconButton
													onClick={handleFileClear}
													className={classes.ClearImage}
												>
													<CancelIcon
														style={{ color: "gray" }}
														fontSize="small"
													/>
												</IconButton>
											</div>
										) : (
											<div>
												<Chip
													label={disclosureFile.name}
													variant="outlined"
													size="medium"
													onDelete={handleFileClear}
												/>
											</div>
										)}
									</>
								)}
							</Box>
						)}
					</div>
					<div style={{}}>
						<Box>
							{(task || main) && (
								<Grid
									container
									alignItems="center"
									style={{
										position: "absolute",
										bottom: 0,
										padding: "15px",
									}}
								>
									<Grid item md={task && !props.is_approver ? 10 : 8}>
										<TextField
											fullWidth
											value={comment}
											onChange={(e) => setComment(e.target.value)}
											variant="outlined"
											placeholder="If not approved comment the changes required to be complete"
											InputProps={{
												endAdornment: (
													<>
														<input
															id="contained-button-disclosureFile"
															type="file"
															onClick={(e) => (e.target.value = null)}
															onChange={handleFileUpload}
														/>
														<label htmlFor="contained-button-disclosureFile">
															<Box
																display="flex"
																component="span"
																alignItems="center"
																style={{ cursor: "pointer" }}
															>
																<AttachFileIcon />
															</Box>
														</label>
													</>
												),
											}}
										/>
									</Grid>
									<Grid item md={2}>
										<Button
											color="primary"
											fullWidth
											onClick={() => handleSendComment()}
										>
											Send comment
										</Button>
									</Grid>
									<Grid item md={2}>
										{props.is_approver && (
											<Button
												color="primary"
												variant="contained"
												size="small"
												fullWidth
												onClick={() => {
													setConfirmationDialog(true);
												}}
											>
												Approve version
											</Button>
										)}
									</Grid>
								</Grid>
							)}
						</Box>
					</div>
				</div>
			</div>
		</>
	);
}

export default ViewSubmissionChatThread;
