import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Textfield from "./textfield";
import { FiSearch } from "react-icons/fi";
import debounce from "lodash.debounce";

function SearchTextField({ searchClick }) {
  const [searchChange, setSearchChange] = React.useState("");

  // Debounce the searchClick function
  const debouncedSearchClick = React.useMemo(
    () => debounce((query) => searchClick(query), 800), // Debounce delay set to 500 milliseconds
    [searchClick]
  );

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchChange(newValue);
    debouncedSearchClick(newValue);
  };

  return (
    <Textfield
      variant={"outlined"}
      color={"primary"}
      label={"Search"}
      value={searchChange}
      style={{ width: "400px" }}
      size={"small"}
      onChange={handleSearchChange}
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={!searchChange}
            onClick={() => searchClick(searchChange)}
          >
            <FiSearch
              style={{ color: "#3374B9", cursor: "pointer" }}
              size={18}
            />
          </IconButton>
        ),
        style: {
          borderRadius: 10,
        },
      }}
      InputLabelProps={{
        style: {
          color: "#3374B9",
        },
      }}
    />
  );
}

export default React.memo(SearchTextField);
