import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

function Guidelines({ guidelines, setViewGuideLines }) {
	const useStyles = makeStyles({
		blueText: {
			fontSize: 16,
			lineHeight: "24px",
			fontWeight: "600",
			color: "#3374B9",
			padding: "16px 0px",
		},
		grayText: {
			fontSize: 14,
			lineHeight: "21px",
			color: "#9FA1AF",
		},
	});
	const classes = useStyles();
	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography className={classes.blueText}>Guidelines</Typography>
				<IconButton size="small">
					<CloseIcon
						fontSize="small"
						onClick={() => setViewGuideLines(false)}
					/>
				</IconButton>
			</div>
			<div
				className="scroll"
				style={{
					height: "63vh",
				}}
			>
				{guidelines.map((guideline, index) => (
					<Grid md={12} style={{ display: "flex", marginTop: "10px" }}>
						<Grid
							md={0.5}
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "35px",
								height: "30px",
								padding: "5px",
								border: "none",
								backgroundColor: "#EBF1F8",
								color: "#3374B9",
								borderRadius: "5px",
							}}
						>
							<div
								style={{
									border: "1px solid #3374B9",
									backgroundColor: "#3374B9",
									margin: "2px",
									borderRadius: "5px",
									width: "3px",
									height: "18px",
									fontWeight: "bold",
								}}
							></div>
							<Grid md={6}>
								<Typography
									style={{
										padding: "2px 5px 2px 2px",
									}}
								>
									{index + 1}
								</Typography>
							</Grid>
						</Grid>
						<Grid md={11} style={{ paddingLeft: "10px" }}>
							<Typography className={classes.grayText}>
								{`${guideline}`}
							</Typography>
						</Grid>
					</Grid>
				))}
			</div>
		</>
	);
}

export default Guidelines;
