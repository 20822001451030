import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import '../../../styles/hidescroll.css';
import Typography from '@material-ui/core/Typography';
import framework from '../../../images/framework.png';
import Slide from '@material-ui/core/Slide';
import tick from '../../../images/tick.png';

const useStyles = makeStyles((theme) => ({
  hover_card: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '&:hover': {
      backgroundImage: 'linear-gradient(to right, #47BB76, #3374B9)',
      padding: 2,
    },
  },
  hover_cards: {
    boxShadow:
      '0px 1px 2px rgba(92, 144, 199, 0.3), 0px 2px 6px 2px rgba(92, 144, 199, 0.15)',
    background: '#FFFFFF',
    borderRadius: '10px',
    //     width: "560px",
    // height: "152px",
    // "& .MuiList-padding": {
    //   paddingTop: 0,
    //   paddingBottom: 0,
    // },
    '&:hover': {
      backgroundColor: '#D6E3F173',
      // padding: 2,
    },
  },
  hover_cardSelected: {
    backgroundColor: '#E0EEE8',
    borderRadius: '10px',
    boxShadow:
      '0px 1px 2px rgba(92, 144, 199, 0.3), 0px 2px 6px 2px rgba(92, 144, 199, 0.15)',
  },
  ListItem: {
    background: 'white',

    height: '85px',
    cursor: 'pointer',
  },
  ListItems: {
    background: 'white',
    '&:hover': {
      backgroundColor: '#D6E3F173',
      // backgroundColor: '#E0EEE8',
      // padding: 2,
    },

    height: '85px',
    cursor: 'pointer',
  },
  ListItemSelected: {
    background: 'white',
    backgroundColor: '#E0EEE8',
    height: '85px',
    cursor: 'pointer',
  },
  Avatar: { width: 70, height: 70, marginRight: 15 },
  Typography: { fontSize: 12, fontWeight: '600' },
  tickImg: {
    position: 'absolute',
    top: '5px',
    right: '15px',
    float: 'right',
  },
  primText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#15314E',
  },
  secondText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

function FrameworkContent(props) {
  const classes = useStyles();
  const {
    subContentData,
    subContent,
    setSubContent,
    template,
    setTemplate,
    contentId,
    setContentId,
    selectedIndex,
    setSelectedIndex,
    defaultFilter,
    setDefaultFilter,
  } = props.states;
  const handleListItemClick = (event, index) => {
    if (index === 0) {
      setTemplate('TEMPLATE_1');
    } else if (index === 1) {
      setTemplate('TEMPLATE_2');
    }
    if (index === selectedIndex) {
      // setSelectedIndex(index);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleReportFormat = (event, data, id) => {
    handleListItemClick(event, id);
  };

  const handleFrameSelect = (content, id) => {
    if (
      content.template_type !== undefined &&
      content.template_type.template_2
    ) {
      setSubContent(subContentData);
      props.handleTitle(content, id);
      setContentId(id);
      setDefaultFilter(content.default);
    } else {
      setSubContent([subContentData[0]]);
      props.handleTitle(content, id);
      setContentId(id);
      setDefaultFilter(content.default);
    }
  };

  const handleFlipImage = (id) => {
    setSubContent(
      [...subContent].map((object) => {
        if (object.id === id) {
          return {
            ...object,
            flipImage: !object.flipImage,
          };
        } else return object;
      })
    );
  };

  const handleButtonClick = (id, template, defaultFilter) => {
    props.onClose(id, template, defaultFilter);
  };
  return (
    <>
      <Grid container>
        {!props.title && (
          <Grid container item xs={12} spacing={2}>
            {props.framework.map((content, id) => (
              <Grid item xs={12} sm={6} key={id}>
                <Slide
                  direction="right"
                  in={props.title === false}
                  // timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <Card className={classes.hover_card} variant="outlined">
                    <CardActionArea>
                      <List>
                        <ListItem
                          key={id}
                          onClick={() =>
                            handleFrameSelect(content, content._id)
                          }
                          className={classes.ListItem}
                        >
                          <ListItemAvatar>
                            <img
                              alt="frameworkpic"
                              className={classes.Avatar}
                              src={
                                content.image !== '' ? content.image : framework
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              content.framework_name !== '' ? (
                                <Typography className={classes.Typography}>
                                  {' '}
                                  {content.framework_name}{' '}
                                </Typography>
                              ) : (
                                <Typography>
                                  sustainability Accounting Standards Board
                                </Typography>
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </CardActionArea>
                  </Card>
                </Slide>
              </Grid>
            ))}
          </Grid>
        )}
        {props.title && (
          <>
            {subContent.map((data, index) => (
              <Grid item xs={12} sm={12} style={{ margin: '20px 0px' }}>
                <Slide
                  direction="right"
                  in={props.title === true}
                  // timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <Card
                    className={
                      selectedIndex === index
                        ? classes.hover_cardSelected
                        : classes.hover_cards
                    }
                    variant="outlined"
                  >
                    <CardActionArea>
                      <List>
                        <ListItem
                          // selected={selectedIndex}
                          className={
                            selectedIndex === index
                              ? classes.ListItemSelected
                              : classes.ListItems
                          }
                          onClick={(event) => {
                            handleReportFormat(event, data, index);
                          }}
                          onMouseEnter={() => handleFlipImage(index)}
                          onMouseLeave={() => handleFlipImage(index)}
                        >
                          <ListItemAvatar>
                            <img
                              alt="frameworkpic"
                              className={classes.Avatar}
                              src={data.flipImage ? data.image1 : data.image2}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography className={classes.primText}>
                                {data.topic}
                              </Typography>
                            }
                            secondary={
                              <Typography className={classes.secondText}>
                                {data.content}
                              </Typography>
                            }
                          />
                        </ListItem>

                        {selectedIndex === index && (
                          <img
                            src={tick}
                            alt="tick"
                            className={classes.tickImg}
                          />
                        )}
                      </List>
                    </CardActionArea>
                  </Card>
                </Slide>
              </Grid>
            ))}
            
          </>
        )}

        {props.title && <Grid
              item
              // xs={12}
              // sm={12}
              style={{
                display: 'flex',
                paddingTop:'15px',
                justifyContent: 'space-between',
                position: 'relative',
                bottom: 5,
                left: '65%',
                width: '35%',
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                Cancel
              </Button>
            
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleButtonClick(contentId, template, defaultFilter)
                }
                // disabled={contentId !== '' && confirmButton ? false : true}
                disabled={props.title === false}
              >
                Confirm
              </Button>
            </Grid> }
      </Grid>
    </>
  );
}

export default React.memo(FrameworkContent);
