import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";
import UNIVERSAL from "../../config/config";
import encrypt from "../shared/sharedActions";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getSustainabilityReportPdf,
  setSustainabilityPdfProgress,
} from "../sustainabilityReport/sustainabilityReportAction";
import { getSustainabilityReport } from "../sustainabilityReport/sustainabilityReportPdfAction";
import {
  SET_ALL_ASSIGNMENT_DATA,
  UPDATE_DEFINE_OBJECTIVE,
  UPDATE_DEFINE_MATERIALITY,
  SET_COMPANY_ADDRESS,
  SET_COMPANY_PINCODE,
  SET_COMPANY_LOCATION,
  SET_COMPANY_GEOGRAPHIC,
  SET_COMPANY_CAPACITY,
  SET_COMPANY_START_DATE,
  SET_COMPANY_WEBSITE,
  SET_COMPANY_NAME,
  RESET_APPROVAL_DATA,
  SET_COMPANY_ALL_ADDRESS,
  SET_BUSINESS_WEBSITE_ADDRESS,
  SET_NUMBER_OF_EMPLOYEE,
  SET_BUSINESS_GEOGRAPHIC,
  SET_BUSINESS_CAPACITY_TYPE,
  SET_ASSESSMENT_SKELETONS,
  SET_COMPANY_EMAIL,
  SET_COMPANY_ADDRESS_WEBSITE,
  SET_CMP_NAME,
  SET_CMP_ADDRESS,
  SET_CMP_PINCODE,
  SET_CMP_INDUSTRY,
  SET_CPM_PERMANENT_EMPLOYEE,
  SET_CPM_CONTRACT_EMPLOYEE,
  SET_CPM_CITY,
  SET_CPM_COUNTRY,
  SET_COMPANY_ALL_LOCATION,
  SET_COMPANY_DESCRIPTION,
  SET_SECTOR,
  SET_INDUSTRY,
  SET_TOC,
  SET_COMMENTS,
  SET_LOADING_COMPLETED,
  SET_APPROVERS_REVIEWERS,
  SET_PDF_BLOB,
  SET_JWT_HASH,
  SET_PDF_PROGRESS,
  SET_PDF_PROGRESS_COUNTER,
} from "../../constants/assessmentApproval/assessmentApprovalConst";

export const setCompanyEmail = (payload) => ({
  type: SET_COMPANY_EMAIL,
  payload: payload,
});

export const setCompanyAddressWebsite = (payload) => ({
  type: SET_COMPANY_ADDRESS_WEBSITE,
  payload: payload,
});

export const setAssessmentSkeletons = (payload) => ({
  type: SET_ASSESSMENT_SKELETONS,
  payload: payload,
});

export const setBusinessWebsiteAddress = (payload) => ({
  type: SET_BUSINESS_WEBSITE_ADDRESS,
  payload: payload,
});

export const setNumberOfEmployee = (payload) => ({
  type: SET_NUMBER_OF_EMPLOYEE,
  payload: payload,
});

export const setBusinessGeographic = (payload) => ({
  type: SET_BUSINESS_GEOGRAPHIC,
  payload: payload,
});

export const setBusinessCapacityType = (payload) => ({
  type: SET_BUSINESS_CAPACITY_TYPE,
  payload: payload,
});

export const setCompanyName = (payload) => ({
  type: SET_COMPANY_NAME,
  payload: payload,
});

export const setCompanyGeographic = (payload) => ({
  type: SET_COMPANY_GEOGRAPHIC,
  payload: payload,
});

export const setCompanyCapacity = (payload) => ({
  type: SET_COMPANY_CAPACITY,
  payload: payload,
});

export const setCompanyStartDate = (payload) => ({
  type: SET_COMPANY_START_DATE,
  payload: payload,
});

export const setCompanyWebsite = (payload) => ({
  type: SET_COMPANY_WEBSITE,
  payload: payload,
});

export const setCompanyLocation = (payload) => ({
  type: SET_COMPANY_LOCATION,
  payload: payload,
});

export const setCompanyPincode = (payload) => ({
  type: SET_COMPANY_PINCODE,
  payload: payload,
});

export const setCompanyAddress = (payload) => ({
  type: SET_COMPANY_ADDRESS,
  payload: payload,
});

export const updateDefineMateriality = (payload) => ({
  type: UPDATE_DEFINE_MATERIALITY,
  payload: payload,
});

export const updateDefineObjective = (payload) => ({
  type: UPDATE_DEFINE_OBJECTIVE,
  payload: payload,
});

export const setAllAssignmentData = (payload) => {
  return {
    type: SET_ALL_ASSIGNMENT_DATA,
    payload: payload,
  };
};

export const resetApprovalData = () => ({
  type: RESET_APPROVAL_DATA,
});

export const setAllCompanyAddress = (payload) => ({
  type: SET_COMPANY_ALL_ADDRESS,
  payload: payload,
});

export const setAllLocation = (payload) => ({
  type: SET_COMPANY_ALL_LOCATION,
  payload: payload,
});

export const setCMPName = (payload) => ({
  type: SET_CMP_NAME,
  payload: payload,
});

export const setCMPAddress = (payload) => ({
  type: SET_CMP_ADDRESS,
  payload: payload,
});

export const setCMPPincode = (payload) => ({
  type: SET_CMP_PINCODE,
  payload: payload,
});

export const setCMPIndustry = (payload) => ({
  type: SET_CMP_INDUSTRY,
  payload: payload,
});

export const setCMPPermanentEmployee = (payload) => ({
  type: SET_CPM_PERMANENT_EMPLOYEE,
  payload: payload,
});

export const setCMPContractEmployee = (payload) => ({
  type: SET_CPM_CONTRACT_EMPLOYEE,
  payload: payload,
});

export const setCMPCity = (payload) => ({
  type: SET_CPM_CITY,
  payload: payload,
});

export const setCMPCountry = (payload) => ({
  type: SET_CPM_COUNTRY,
  payload: payload,
});

export const setCompanyDescription = (payload) => ({
  type: SET_COMPANY_DESCRIPTION,
  payload: payload,
});

export const setSector = (payload) => ({
  type: SET_SECTOR,
  payload: payload,
});

export const setIndustry = (payload) => ({
  type: SET_INDUSTRY,
  payload: payload,
});

export const setToc = (payload) => ({
  type: SET_TOC,
  payload: payload,
});

export const setComments = (payload) => ({
  type: SET_COMMENTS,
  payload: payload,
});

export const setLoadingCompleted = (payload) => ({
  type: SET_LOADING_COMPLETED,
  payload: payload,
});

export const setApproversReviewers = (payload) => ({
  type: SET_APPROVERS_REVIEWERS,
  payload: payload,
});

export const setPdfProgressCounter = (payload) => ({
  type: SET_PDF_PROGRESS_COUNTER,
  payload,
});

export const getAssessmentData = (token, assessment_id) => {
  return (dispatch) => {
    // dispatch(setAssessmentSkeletons(true));

    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/assessments/get_assessment_report", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAllAssignmentData(responseJson.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setPdfBlob = (payload) => ({
  type: SET_PDF_BLOB,
  payload,
});

export const setJwt_hash = (payload) => ({
  type: SET_JWT_HASH,
  payload,
});

export const setPdfProgress = (payload) => ({
  type: SET_PDF_PROGRESS,
  payload,
});

export const getAssessmentPdf = (token, assessment_id, jwt_hash, step_no) => {
  return (dispatch) => {
    dispatch(showLoading());
    dispatch(setAssessmentSkeletons(true));
    //setting the progress value to null initinally to avoid duplicate api call on the component
    dispatch(setPdfProgress({ id: assessment_id, progress: null }));

    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      jwt_hash: jwt_hash,
      payment_status:localStorage.getItem('status') || ''
    });

    return (
      fetch(UNIVERSAL.BASEURL + "/assessments/get_assessment_report_pdf", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      })
        // .then((response) => response.arrayBuffer())
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            if (responseJson.result !== null) {
              dispatch(
                setPdfBlob({
                  id: assessment_id,
                  pdfBlob: responseJson.result + `?${Date.now()}`,
                })
              );
              dispatch(
                setPdfProgress({
                  id: assessment_id,
                  progress: 100,
                })
              );
              dispatch(setJwt_hash(responseJson.jwt_hash));
              dispatch(hideLoading());
              dispatch(setAssessmentSkeletons(false));
              dispatch(getCommentsToc(token, assessment_id, step_no));
            }
            if (responseJson.result === null) {
              setTimeout(() => {
                dispatch(getPdfProgress(token, assessment_id, step_no));
              }, 2000);
            }
          } else {
            dispatch(hideLoading());
            dispatch(setAssessmentSkeletons(false));
          }
        })
        .catch((error) => {
          console.log(error);
          // clearInterval(interval);
        })
    );
  };
};

export const getPdfProgress = (token, assessment_id, step_no, counter = 0) => {
  return (dispatch) => {
    dispatch(setPdfProgressCounter({ id: assessment_id, counter: counter }));
    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/assessments/get_assessment_report_progress",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setPdfProgress({ id: assessment_id, progress: responseJson.result })
          );
          if (responseJson.link) {
            const url = responseJson.link;
            dispatch(
              setPdfBlob({ id: assessment_id, pdfBlob: url + `?${Date.now()}` })
            );
            dispatch(
              setPdfProgress({
                id: assessment_id,
                progress: responseJson.result,
              })
            );
            dispatch(hideLoading());
            dispatch(setAssessmentSkeletons(false));
          }
          if (responseJson.result === 100) {
            dispatch(getCommentsToc(token, assessment_id, step_no));
          }
          if (
            responseJson.link === null ||
            responseJson.link === undefined ||
            responseJson.result !== 100
          ) {
            counter++;
            setTimeout(() => {
              dispatch(getPdfProgress(token, assessment_id, step_no, counter));
            }, 5000);
          }
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const resetPdfProgress = (token, assessment_id, step_no) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      assessment_id,
      step_no,
    });
    return fetch(UNIVERSAL.BASEURL + "/assessments/reset_pdf_progress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const updateAssessmentApproval = (
  assessment_id,
  assesmentApproval,
  token
) => {
  return (dispatch) => {
    var formData = new FormData();

    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      step_no: 4,
      basic_company_info: {
        company_name: assesmentApproval.companyName,
        registered_address: assesmentApproval.companyAddress,
        industrial_sector_details: {
          company_description: assesmentApproval.company_description,
          sector: assesmentApproval.sector,
          industry: assesmentApproval.companyIndustry,
          year_of_assessment: assesmentApproval.start_date,
        },
        locations_and_employees: assesmentApproval.locations_and_employees,
      },
      basic_materiality_info: {
        framework_id: assesmentApproval.frameWorkId,
        define_materiality: assesmentApproval.defineMateriality,
        define_objective: assesmentApproval.defineObjective,
      },
      organisation_id: localStorage.getItem("current_organisation"),
    });

    formData.append("data", data);
    formData.append(
      "company_info_legal_document",
      assesmentApproval.basicCompanyFile
    );
    formData.append("logo", assesmentApproval.logo);
    formData.append(
      "materiality_info_legal_document",
      assesmentApproval.basicFile
    );

    if (!assesmentApproval.companyName) {
      dispatch(set_snack_bar(400, "Enter Company Name !"));
    } else if (!assesmentApproval.frameWorkId === "") {
      dispatch(set_snack_bar(400, "Select a Framework !"));
    } else if (assesmentApproval.defineMateriality === "") {
      dispatch(set_snack_bar(400, "Define Materiality!"));
    } else if (assesmentApproval.defineObjective === "") {
      dispatch(set_snack_bar(400, "Define Objectives !"));
    } else {
      dispatch(set_loader());

      return fetch(UNIVERSAL.BASEURL + "/assessment/update_basic_info", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(getAssessmentData(token, assessment_id));
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else {
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          dispatch(unset_loader());
        });
    }
  };
};

export const updateReportName = (
  token,
  assessment_id,
  step_no,
  report_name,
  jwt_hash
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      step_no: step_no,
      report_name: report_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/assessment/set_report_name", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          if (step_no === 4) {
            dispatch(getAssessmentData(token, assessment_id));
            dispatch(
              setPdfProgress({
                id: assessment_id,
                progress: 0,
              })
            );
          }
          if (step_no === 6) {
            dispatch(
              setSustainabilityPdfProgress({
                id: assessment_id,
                progress: 0,
              })
            );
            dispatch(getSustainabilityReport(token, assessment_id));
          }
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function getCommentsToc(token, assessment_id, step_no) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token ? token : localStorage.getItem("user_token"),
      assessment_id: assessment_id,
      step_no: step_no,
    });
    return fetch(UNIVERSAL.BASEURL + "/comment/get_toc", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setToc(responseJson.result));
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
}

export function editToc(
  token,
  name,
  toc_id,
  assessment_topic_id,
  step_no,
  visible,
  order,
  assessment_id,
  is_text_changed
) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      name,
      toc_id,
      assessment_topic_id,
      step_no,
      visible,
      order,
      assessment_id,
      is_text_changed,
    });

    return fetch(UNIVERSAL.BASEURL + "/comment/edit_toc", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(getCommentsToc(token, assessment_id, step_no));
        }else{
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

        }

        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
}

export function addCover(
  token,
  assessment_id,
  step_no,
  front_text,
  back_text,
  back_img_front,
  logo_front,
  back_img_back,
  logo_back,
  jwt_hash,
  font,
  color,
  previously_selected_colors
) {
  let formData = new FormData();
  return (dispatch) => {
    dispatch(setAssessmentSkeletons(true));
    const data = encrypt({
      "user-token": token,
      assessment_id,
      step_no,
      front_text,
      back_text,
      jwt_hash: jwt_hash,
      font,
      color,
      previously_selected_colors,
    });
    formData.append("back_img_front", back_img_front);
    formData.append("logo_front", logo_front);
    formData.append("back_img_back", back_img_back);
    formData.append("logo_back", logo_back);
    formData.append("data", data);
    return fetch(UNIVERSAL.BASEURL + "/assessments/add_cover", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (step_no === 6) {
            setTimeout(() => {
              dispatch(getSustainabilityReport(token, assessment_id));
              dispatch(
                getSustainabilityReportPdf(token, assessment_id, jwt_hash)
              );
            }, 6000);
          }
          if (step_no === 4) {
            setTimeout(() => {
              dispatch(getAssessmentData(token, assessment_id));
              dispatch(getAssessmentPdf(token, assessment_id, jwt_hash));
            }, 6000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addHeaderFooter(
  token,
  assessment_id,
  step_no,
  header_text,
  footer_text,
  logo,
  jwt_hash,
  page_no,
  is_page_no_position_left
) {
  let formData = new FormData();
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      assessment_id,
      step_no,
      header_text,
      footer_text,
      logo,
      jwt_hash: jwt_hash,
      page_no,
      is_page_no_position_left
    });
    formData.append("header_text", header_text);
    formData.append("footer_text", footer_text);
    formData.append("logo", logo);
    formData.append("data", data);
    return fetch(UNIVERSAL.BASEURL + "/assessments/add_header_footer", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (step_no === 6) {
            setTimeout(() => {
              dispatch(getSustainabilityReport(token, assessment_id));
              dispatch(
                getSustainabilityReportPdf(token, assessment_id, jwt_hash)
              );
            }, 6000);
          }
          if (step_no === 4) {
            setTimeout(() => {
              dispatch(getAssessmentData(token, assessment_id));
              dispatch(getAssessmentPdf(token, assessment_id, jwt_hash));
            }, 4000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewAssignedMembers(token, assessment_id, step_no) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      assessment_id,
      step_no,
    });
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/view_assigned_members", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setApproversReviewers(responseJson.result));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function deleteAssignedMember(
  token,
  stakeholder_id,
  assessment_id,
  step_no
) {
  return (dispatch) => {
    let organisation_id = localStorage.getItem("current_organisation");
    const data = encrypt({
      "user-token": token,
      stakeholder_id,
      organisation_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/delete_stakeholder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewAssignedMembers(token, assessment_id, step_no));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function updateUserRole(
  token,
  stakeholder_id,
  role,
  assessment_id,
  step_no
) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      stakeholder_id,
      role,
    });
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/update_user_role", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewAssignedMembers(token, assessment_id, step_no));
        }
      })
      .catch((err) => console.log(err));
  };
}
