import React, { useState, useEffect, useRef, useCallback } from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import { Select } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomCheckBox from "./../../UI/checkBox/checkBox";
import addcover from "../../../images/add_cover.png";
import headerfooter from "../../../images/headerfooter.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import ReportSkeleton from "../skeletons/reportSkeleton";
import viewcomments from "../../../images/view_comments.png";
import share from "../../../images/share.png";
import MoreIcon from "@material-ui/icons/MoreVert";
import useDebounce from "../../../customHooks/useDebouncec";
import { AssessmentApprovalUseStyles } from "./assessmentApprovalStyle";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import tablecontent from "../../../images/table_of_content.png";

// color gradient and font selector
import ColorGradient from "./colorGradient";
import FontSelector from "./fontSelector";
import Image from "../../../images/bcd.png";

const InnerAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const InnerAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const InnerAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CommentsAccordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
  },
})(MuiAccordion);

const CommentsAccordionSummary = withStyles({
  root: {
    backgroundColor: "#DDE9F6",
    minHeight: "64px",
    borderRadius: "5px",
    borderTop: "none",
  },
})(MuiAccordionSummary);

export default function AssessmentApprovalTest(props) {
  const {
    assesmentApproval,
    assessment_id,
    materialityAssessment,
    getCommentsToc,
    addCommentToc,
    login,
    viewAllUsers,
    generalSettings,
    esgPlan,
    assignMembers,
    getStakeholder,
    addCover,
    editToc,
    viewAllComments,
    viewComments,
    comments,
    addHeaderFooter,
    viewAssignedMembers,
    deleteAssignedMember,
    updateUserRole,
    removeFile,
    getAssessmentPdf,
    setPdfProgress,
    resetPdfProgress,
    setPdfProgressCounter,
  } = props;

  const classes = AssessmentApprovalUseStyles();

  const LinearProgressWithLabel = (props) => {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const [expanded, setExpanded] = useState(false);
  const [commentsAccordionExpanded, setCommentsAccordionExpanded] =
    useState(false);
  const [setupOption, setSetupOption] = useState(0);

  const [coverImage, setCoverImage] = useState("");

  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [coverImageUrlPdf, setCoverImageUrlPdf] = useState("");
  const [coverLogo, setCoverLogo] = useState("");

  const [coverLogoUrl, setCoverLogoUrl] = useState("");
  const [coverLogoUrlPdf, setCoverLogoUrlPdf] = useState("");
  const [coverHeadline, setCoverHeadline] = useState("");

  const [coverSubline, setCoverSubline] = useState("");

  const [coverRregularText, setCoverRregularText] = useState("");

  const [backCoverImage, setBackCoverImage] = useState("");

  const [backCoverImageUrl, setBackCoverImageUrl] = useState("");
  const [backCoverImageUrlPdf, setBackCoverImageUrlPdf] = useState("");
  const [backCoverLogo, setBackCoverLogo] = useState("");

  const [backCoverLogoUrl, setBackCoverLogoUrl] = useState("");
  const [backCoverLogoUrlPdf, setBackCoverLogoUrlPdf] = useState("");
  const [coverBackHeadline, setCoverBackHeadline] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [headerTextPdf, setHeaderTextPdf] = useState("");
  const [headerLogo, setHeaderLogo] = useState("");
  const [headerLogoPdf, setHeaderLogoPdf] = useState("");
  const [headerLogoUrl, setHeaderLogoUrl] = useState("");
  const [headerLogoUrlPdf, setHeaderLogoUrlPdf] = useState("");
  const [footerText, setFooterText] = useState("");
  const [footerTextPdf, setFooterTextPdf] = useState("");
  const [isPageNumberVisible, setIsPageNumberVisible] = useState(true);
  const [isPNumberDefaultPosition, setIsPNumberDefaultPosition] = useState(true);

  const [coverBackSubline, setCoverBackSubline] = useState("");

  const [coverBackRegular, setCoverBackRegular] = useState("");

  const [showSendButton, setShowSendButton] = useState(false);
  const [showTocInput, setShowTocInput] = useState(false);
  const [idToShowInput, setIdToShowInput] = useState("");

  const [comment, setComment] = useState("");
  const [focusedTocInputId, setFocusedTocInputId] = useState(null);
  const [commentTocId, setCommentTocId] = useState("");
  const initial = {
    name: null,
    id: "",
    visible: true,
    order: "",
    assessment_topic_id: "",
  };
  const [editTocObj, setEditTocObj] = useState(initial);
  const [editTocName, setEditTocName] = useState("");
  const [editTocAssessmentId, setEditTocAssessmentId] = useState("");

  // share dialog state
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [userTypes, setUserTypes] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState([]);
  const [topicId, setTopicId] = useState([]);
  const [showRevomeUserIcon, setShowRevomeUserIcon] = useState(false);
  const [userId, setUserId] = useState("");
  const [showDeleteUserDailogue, setShowDeleteUserDailogue] = useState(false);
  const [removeUserEmail, setRemoveUserEmail] = useState("");
  const [hideLoader, setHideLoader] = useState(false);
  const [toCallPdfApi, setToCallPdfApi] = useState(true);
  const [showResetPdfProgressDialogue, setShowResetPdfProgressDialogue] =
    useState(false);
  // share search bar
  const [search, setSearch] = useState("");

  // logo state

  const [blob, setBlob] = useState("");

  // share state
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At ${
      login.selectedOrganization && login.selectedOrganization.company_name
    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });
  const [localAssess, setLocalAssess] = useState([]);

  // font state
  const [selectedFont, setSelectedFont] = useState("");

  const [selectedColor, setSelectedColor] = useState({});

  const [imageLogo, setImageLogo] = useState("");

  const handleFontComplete = (font) => {
    setSelectedFont(font);
  };

  const handleColorComplete = (color) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    setSelectedFont(assesmentApproval.font);
    setSelectedColor(assesmentApproval.color);
    setImageLogo(
      assesmentApproval.basicData && assesmentApproval.basicData?.url
    );
  }, [
    assesmentApproval.font,
    assesmentApproval.color,
    assesmentApproval.basicData,
  ]);

  useEffect(() => {
    if (
      (assesmentApproval.pdfProgress[assessment_id] === 0 ||
        !assesmentApproval.pdfProgress.hasOwnProperty(assessment_id)) &&
      toCallPdfApi
    ) {
      getAssessmentPdf(
        login.token,
        assessment_id,
        assesmentApproval.jwt_hash,
        4
      );
    }
  }, [assesmentApproval.pdfProgress[assessment_id], toCallPdfApi]);

  useEffect(() => {
    return () => {
      if (assesmentApproval.pdfProgress[assessment_id] === 100) {
        setPdfProgress({ id: assessment_id, progress: 0 });
      }
    };
  }, [assesmentApproval.pdfProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLocalAssess(assesmentApproval.tableOfContent);
  }, [assesmentApproval]);

  useEffect(() => {
    if (
      assesmentApproval.pdfProgress[assessment_id] === 0 ||
      assesmentApproval.pdfProgress[assessment_id] === null ||
      !assesmentApproval.pdfProgress.hasOwnProperty(assessment_id)
    ) {
      hideLoader && setHideLoader(false);
    }
    if (assesmentApproval.pdfProgress[assessment_id] === 100) {
      setTimeout(() => {
        setHideLoader(true);
      }, 500);
    }
  }, [assesmentApproval.pdfProgress[assessment_id]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBlob(assesmentApproval.pdfBlob[assessment_id]);
  }, [login.token, assessment_id, assesmentApproval.pdfBlob[assessment_id]]);

  const handleAccordioChange = (panel, id) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordioChangeColor = useCallback(
    (panel, id) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const handleCommentsAccordionChange =
    (panel, id, assessment_topic_id) => (event, isExpanded) => {
      let assessmentTopicparam;
      setCommentsAccordionExpanded(isExpanded ? panel : false);
      if (assessment_topic_id) {
        assessmentTopicparam = assessment_topic_id;
      } else {
        assessmentTopicparam = "";
      }
      if (isExpanded) {
        viewComments(props.login.token, id, assessmentTopicparam, 4);
      }
    };

  const handleEditTableOfContentClick = () => {
    setSetupOption(4);
  };

  const handleUploadCoverImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setCoverImage(file);
      reader.onloadend = () => {
        setCoverImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearCoverImage = () => {
    coverImageUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        4,
        coverImageUrlPdf,
        "front_cover_img"
      );

    setCoverImage("");
    setCoverImageUrl("");
  };

  const handleUploadCoverLogoImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setCoverLogo(file);
      reader.onloadend = () => {
        setCoverLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearCoverLogoImage = () => {
    coverLogoUrlPdf &&
      removeFile(login.token, assessment_id, 4, coverLogoUrlPdf, "front_logo");
    setCoverLogo("");
    setCoverLogoUrl("");
  };

  const handleUploadBackCoverImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setBackCoverImage(file);
      reader.onloadend = () => {
        setBackCoverImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearBackCoverImage = () => {
    backCoverImageUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        4,
        backCoverImageUrlPdf,
        "back_cover_img"
      );
    setBackCoverImage("");
    setBackCoverImageUrl("");
  };

  const handleUploadBackCoverLogoImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setBackCoverLogo(file);
      reader.onloadend = () => {
        setBackCoverLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearBackCoverLogoImage = () => {
    backCoverLogoUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        4,
        backCoverLogoUrlPdf,
        "back_logo"
      );
    setBackCoverLogo("");
    setBackCoverLogoUrl("");
  };

  const handleUploadHeaderLogoImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      alert("Please select images less than 5MB");
    } else {
      setHeaderLogo(file);
      reader.onloadend = () => {
        setHeaderLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearHeaderLogoImage = () => {
    headerLogoUrlPdf &&
      removeFile(
        login.token,
        assessment_id,
        4,
        headerLogoUrlPdf,
        "header_logo"
      );
    setHeaderLogo("");
    setHeaderLogoUrl("");
  };

  const handleViewCommentsClick = () => {
    setSetupOption(3);
  };

  const handleEditToc = (e) => {
    setEditTocName(e.target.value);
    setEditTocObj((preValue) => ({ ...preValue, name: e.target.value }));
    // if (e.target.value !== '' && e.target.value !== editTocObj.name) {
    //   setTimeout(() => setEditTocName(e.target.value), 2000);
    // }
  };

  const handleNewCommentSubmit = (id, assessment_topic_id, toc_name) => {
    comment.length !== 0 && setCommentTocId(id);
    const assessmentTopicParams = assessment_topic_id
      ? assessment_topic_id
      : "";
    const tocParam = assessment_topic_id ? "" : id;
    addCommentToc(
      props.login.token,
      id,
      comment,
      assessmentTopicParams,
      4,
      toc_name,
      assessment_id
    );
    setShowSendButton(false);
    setTimeout(() => {
      viewComments(props.login.token, tocParam, assessmentTopicParams, 4);
      viewAllComments(login.token, assessment_id, 4);
    }, [200]);
    setComment("");
    setFocusedTocInputId(null);
  };

  const handleShowInputToc = (e, topic) => {
    setShowTocInput(true);

    setIdToShowInput(e.target.id);

    setEditTocObj({
      name: e.target.innerText,
      id: topic.toc_id,
      visible: topic.visible,
      order: topic.order,
      assessment_topic_id: topic.assessment_topic_id,
    });

    setEditTocAssessmentId(topic.assessment_topic_id);
  };
  // Toc visiblity (eye button handler)
  const handleTocVisibility = (
    name,
    id,
    visibility,
    order,
    assessment_topic_id
  ) => {
    // setEditTocName(name);
    setEditTocObj({
      name: name,
      id: id,
      visible: visibility,
      order: order,
      assessment_topic_id: assessment_topic_id,
    });
    let newLocalAscess = localAssess.map((toc, i) => {
      if (
        (toc.toc_id && toc.toc_id === id) ||
        (toc.assessment_topic_id &&
          toc.assessment_topic_id === assessment_topic_id)
      ) {
        editToc(
          login.token,
          name,
          id,
          assessment_topic_id,
          4,
          visibility,
          order,
          assessment_id,
          false
        );
        return { ...toc, visible: visibility };
      }
      return toc;
    });
    setLocalAssess(newLocalAscess);
  };
  const [tocApplyDisabler, setTocEyeDisabler] = useState(false);
  const textFieldRef = useRef();

  useDebounce(
    () => {
      textFieldRef.current && textFieldRef.current.blur();
    },
    2000,
    [editTocName]
  );

  const api = (toc) => {
    if (editTocObj.name !== "" && editTocObj.name !== toc.name) {
      editToc(
        login.token,
        editTocObj.name,
        editTocObj.id,
        editTocObj.assessment_topic_id,
        4,
        editTocObj.visible,
        editTocObj.order,
        assessment_id,
        true
      );

      let newLocalAscess = localAssess.map((toc, i) => {
        if (
          toc.toc_id === editTocObj.id &&
          toc.assessment_topic_id === editTocObj.assessment_topic_id
        ) {
          return { ...toc, name: editTocObj.name };
        }
        return toc;
      });
      setLocalAssess(newLocalAscess);
      setTocEyeDisabler(false);
      setEditTocObj(initial);
    }
  };
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (assesmentApproval.pdfProgressCounter[assessment_id] === 120) {
      setShowResetPdfProgressDialogue(true);
    }
  }, [assesmentApproval.pdfProgressCounter[assessment_id]]);

  useEffect(() => {
    viewAssignedMembers(login.token, assessment_id, 4);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assesmentApproval.coverData !== null) {
      if (
        assesmentApproval.coverData.front &&
        assesmentApproval.coverData.front.text
      ) {
        setCoverHeadline(assesmentApproval.coverData.front.text.heading);

        setCoverSubline(assesmentApproval.coverData.front.text.subText);

        setCoverRregularText(
          assesmentApproval.coverData.front.text.regularText
        );
      }
      if (
        assesmentApproval.coverData.front &&
        assesmentApproval.coverData.front.back_img &&
        assesmentApproval.coverData.front.back_img.url
      ) {
        setCoverImageUrl(assesmentApproval.coverData.front.back_img.url);
        setCoverImageUrlPdf(assesmentApproval.coverData.front.back_img.url);
      }
      if (
        assesmentApproval.coverData.front &&
        assesmentApproval.coverData.front.logo &&
        assesmentApproval.coverData.front.logo.url
      ) {
        setCoverLogoUrl(assesmentApproval.coverData.front.logo.url);
        setCoverLogoUrlPdf(assesmentApproval.coverData.front.logo.url);
      }
      if (
        assesmentApproval.coverData.back &&
        assesmentApproval.coverData.back.text
      ) {
        setCoverBackHeadline(assesmentApproval.coverData.back.text.heading);

        setCoverBackSubline(assesmentApproval.coverData.back.text.subText);

        setCoverBackRegular(assesmentApproval.coverData.back.text.regularText);
      }
      if (
        assesmentApproval.coverData.back &&
        assesmentApproval.coverData.back.back_img &&
        assesmentApproval.coverData.back.back_img.url
      ) {
        setBackCoverImageUrl(assesmentApproval.coverData.back.back_img.url);
        setBackCoverImageUrlPdf(assesmentApproval.coverData.back.back_img.url);
      }
      if (
        assesmentApproval.coverData.back &&
        assesmentApproval.coverData.back.logo &&
        assesmentApproval.coverData.back.logo.url
      ) {
        setBackCoverLogoUrl(assesmentApproval.coverData.back.logo.url);
        setBackCoverLogoUrlPdf(assesmentApproval.coverData.back.logo.url);
      }
    }
  }, [assesmentApproval.coverData, assessment_id]);

  //reseting the state value of cover data on unmounting
  useEffect(() => {
    return () => {
      setCoverHeadline("");
      setCoverSubline("");
      setCoverRregularText("");
      setCoverImageUrl("");
      setCoverLogoUrl("");
      setCoverBackHeadline("");
      setCoverBackSubline("");
      setCoverBackRegular("");
      setBackCoverImageUrl("");
      setBackCoverLogoUrl("");
    };
  }, [assesmentApproval.coverData, assessment_id]);

  useEffect(() => {
    if (Object.keys(assesmentApproval.headerFooterData).length > 0) {
      if (assesmentApproval.headerFooterData.footer_text) {
        setFooterText(assesmentApproval.headerFooterData.footer_text);
      }
      if (assesmentApproval.headerFooterData.header_text) {
        setHeaderText(assesmentApproval.headerFooterData.header_text);
      }
      if (assesmentApproval.headerFooterData.logo) {
        setHeaderLogoUrl(assesmentApproval.headerFooterData.logo.url);
        setHeaderLogoUrlPdf(assesmentApproval.headerFooterData.logo.url);
      }
      if (assesmentApproval.headerFooterData.hasOwnProperty("page_no")) {
        setIsPageNumberVisible(assesmentApproval.headerFooterData.page_no);
      }
      if (assesmentApproval.headerFooterData.hasOwnProperty("is_page_no_position_left")) {
        setIsPNumberDefaultPosition(assesmentApproval.headerFooterData.is_page_no_position_left);
      }
    }
  }, [assesmentApproval.headerFooterData, assessment_id]);

  //resetting the state for headerfooterdata on unmounting
  useEffect(() => {
    return () => {
      setFooterText("");
      setHeaderText("");
      setHeaderLogoUrl("");
      setIsPageNumberVisible(true);
      setIsPNumberDefaultPosition(true)
    };
  }, [assesmentApproval.headerFooterData, assessment_id]);

  const handleAddCoverComplete = () => {
    const preColor = [
      ...assesmentApproval.preSelectedColor,
      selectedColor.subline,
    ];
    setCoverImageUrlPdf(coverImageUrl);

    setCoverLogoUrlPdf(coverLogoUrl);

    setBackCoverImageUrlPdf(backCoverImageUrl);
    setBackCoverLogoUrlPdf(backCoverLogoUrl);
    setToCallPdfApi(false);
    setPdfProgress({ id: assessment_id, progress: 0 });
    addCover(
      login.token,
      assessment_id,
      4,
      {
        heading: coverHeadline,
        subText: coverSubline,
        regularText: coverRregularText,
      },
      {
        heading: coverBackHeadline,
        subText: coverBackSubline,
        regularText: coverBackRegular,
      },
      coverImage,
      coverLogo,
      backCoverImage,
      backCoverLogo,
      assesmentApproval.jwt_hash,
      selectedFont,
      selectedColor,
      [...new Set(preColor)]
    );
  };

  const handleHeaderFooterComplete = () => {
    setHeaderLogoUrlPdf(headerLogoUrl);
    setHeaderTextPdf(headerText);
    setFooterTextPdf(footerText);
    setToCallPdfApi(false);
    setPdfProgress({ id: assessment_id, progress: 0 });
    addHeaderFooter(
      login.token,
      assessment_id,
      4,
      headerText,
      footerText,
      headerLogo,
      assesmentApproval.jwt_hash,
      isPageNumberVisible,
      isPNumberDefaultPosition,
    );
  };
  // share search bar const

  const searchArr = materialityAssessment.assignedStakeholdersData.filter(
    (user) => user.user_data.email.includes(search === "" ? undefined : search)
  );
  // share dialog functions and API callings

  const option = ["Approvers", "Reviewers"];
  const role = userTypes === "Approvers" ? 1 : 2;
  const approversArr = assesmentApproval.approversReviewersList.filter(
    (data) => data.role === 1
  );
  const reviewersArr = assesmentApproval.approversReviewersList.filter(
    (data) => data.role === 2
  );

  const option1 =
    generalSettings.allUsers &&
    generalSettings.allUsers.length > 0 &&
    generalSettings.allUsers.filter(
      (user) => typeof user.name === "string" && user.name.includes(inputValue)
    );

  const option2 =
    generalSettings.allUsers &&
    generalSettings.allUsers.length > 0 &&
    generalSettings.allUsers.filter(
      (user) =>
        typeof user.email === "string" && user.email.includes(inputValue)
    );

  useEffect(() => {
    viewAllUsers(login.token, login.current_organisation, keyword);
    getStakeholder(login.token, assessment_id, 4);
  }, [login.current_organisation, shareDialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const stakeHolderContentChange = (e) => {
    const { name, value, defaultValue } = e.target;
    setStakeholderContent((prev) => {
      return { ...prev, [name]: value };
    });
    if (stakeholderContent.content === "") {
      setStakeholderContent((prev) => {
        return { ...prev, [name]: defaultValue };
      });
    }
  };

  const handleAssignShareDialog = () => {
    setShareDialogOpen(false);
    assignMembers(
      login.token,
      emails,
      4,
      assessment_id,
      login.current_organisation,
      topicId,
      role,
      stakeholderContent.subject,
      stakeholderContent.content
    );
    setTopicId([]);
    setTimeout(() => {
      viewAssignedMembers(login.token, assessment_id, 4);
    }, 500);
  };

  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
    setTopicId([esgPlan.currentTopic.topic_id]);
  };

  const handleDeleteUser = (id) => {
    deleteAssignedMember(login.token, id, assessment_id, 4);
    setShowDeleteUserDailogue(false);
  };

  const handleApproverChange = (e) => {
    let id = e.target.name;
    updateUserRole(login.token, id, 2, assessment_id, 4);
  };

  const handleReviewerChange = (e) => {
    let id = e.target.name;
    updateUserRole(login.token, id, 1, assessment_id, 4);
  };

  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.shareGrid}>
            <Grid item xs={3}>
              <Typography className={classes.shareTypography}>
                Share List
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            style={{}}
          >
            <Grid item md={12} className={classes.shareGritItem}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                multiple
                options={
                  option1.length > 0
                    ? option1.map((option) => option.email)
                    : option2.map((option) => option.email)
                }
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                onChange={(event, newValue) => {
                  setEmails(newValue);
                }}
                renderInput={(params) => (
                  <CustomTextfield
                    {...params}
                    name="asgEmailStep4"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Search By Emai ID or Name"
                  />
                )}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue == null ? " " : newInputValue);
                  setKeyword(newInputValue == null ? " " : newInputValue);
                }}
              />
            </Grid>
            <Grid item className={classes.shareGritItem1}>
              <CustomTextfield
                label="Approver / Reviewers"
                fullWidth
                InputLabelProps={{
                  style: { color: "#3374B9" },
                }}
                select
                size="small"
                variant="outlined"
                className={classes.shareTextField}
                value={userTypes}
                onChange={(e) => {
                  setUserTypes(e.target.value);
                }}
              >
                {option.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            {true && (
              <>
                <Grid item className={classes.shareGritItem}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.shareTextField}
                    onChange={stakeHolderContentChange}
                    helperText="hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.shareGritItem}>
                  <Typography className={classes.shareContent}>
                    Content
                  </Typography>
                </Grid>
                <Grid item className={classes.shareGritItem}>
                  <CustomTextfield
                    name="content"
                    multiline="true"
                    onChange={stakeHolderContentChange}
                    defaultValue={`At ${
                      login.selectedOrganization &&
                      login.selectedOrganization.company_name
                    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`}
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            spacing={2}
            className={classes.shareDialogActionContainer}
          >
            <Grid item xs={3} md={6} className={classes.shareDialogActionItem}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setShareDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.shareButtonGrid}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.shareButton}
                disabled={!userTypes || !emails.length > 0}
                onClick={() => {
                  handleAssignShareDialog();
                }}
              >
                {emails.length > 0 ? "Share Report" : "Add New User"}
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const removeUserDailogue = () => {
    return (
      <Dialog open={showDeleteUserDailogue} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={5}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Delete User from list
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ paddingBottom: "50px" }}>
            The user will only be deleted from the list and can still be found
            on the company profile. Are you sure want to delete the user ?
          </Typography>
          <Box
            style={{
              border: "1px solid #D9DADB",
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: "16px",
              borderRadius: "5px",
            }}
          >
            <Chip variant="outlined" label={removeUserEmail} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={3}
              md={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setShowDeleteUserDailogue(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} style={{ paddingRight: 10 }}>
              <CustomButton
                color="primary"
                variant="contained"
                style={{ paddingLeft: 45, paddingRight: 45 }}
                onClick={() => handleDeleteUser(userId)}
              >
                Delete User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const resetPdfProgressDialog = () => {
    return (
      <Dialog open={showResetPdfProgressDialogue} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={5}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Reset PDF rendering
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ paddingBottom: "50px" }}>
            PDF is taking too much time than usual. Do you want to wait or
            restart the process?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={3}
              md={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShowResetPdfProgressDialogue(false);
                }}
              >
                Wait
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} style={{ paddingRight: 10 }}>
              <CustomButton
                color="primary"
                variant="contained"
                style={{ paddingLeft: 45, paddingRight: 45 }}
                onClick={() => {
                  resetPdfProgress(login.token, assessment_id, 4);
                  setPdfProgress({ id: assessment_id, progress: 0 });
                  setShowResetPdfProgressDialogue(false);
                }}
              >
                Reload
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    // getCommentsToc(login.token, assessment_id, 4);
    viewAllComments(login.token, assessment_id, 4);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const commentsDisplay = (toMap) => {
    return toMap
      .filter((toc) => toc.visible)
      .map((toc, i) => (
        <CommentsAccordion
          variant="elevation"
          elevation={0}
          key={toc.toc_id + i}
          expanded={commentsAccordionExpanded === toc.name}
          onChange={handleCommentsAccordionChange(
            toc.name,
            toc.toc_id,
            toc.assessment_topic_id
          )}
        >
          <CommentsAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ marginTop: 10 }}
          >
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{toc.name}</Typography>
              <Box
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  width: 32,
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 16,
                  lineHeight: 24,
                }}
              >
                {toc.comments}
              </Box>
            </Box>
          </CommentsAccordionSummary>

          <AccordionDetails style={{ marginTop: 8 }}>
            <Box display="flex" flexDirection="column" width="100%">
              <Box>
                {comments.comments.map((comment, id) => {
                  return (
                    comment.toc_id === toc.toc_id && (
                      <Box key={id}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          p="16px 16px 0 16px"
                        >
                          <Box display="flex" alignItems="center">
                            <Avatar
                              style={{
                                color: "#3374B9",
                                backgroundColor: "#3374B9",
                                height: "8px",
                                width: "8px",
                                marginRight: "8px",
                              }}
                            ></Avatar>
                            <Typography
                              style={{
                                color: "#9FA1AF",
                                fontSize: "14px",
                                lineHeight: "21px",
                              }}
                            >
                              {comment.user_name}
                            </Typography>
                          </Box>

                          <Typography
                            style={{
                              color: "#9FA1AF",
                              fontSize: "14px",
                              lineHeight: "21px",
                            }}
                          >
                            {formatDate(comment.created_on)}
                          </Typography>
                        </Box>
                        <Box p="16px">
                          <Box pb="10px">{comment.comment}</Box>
                        </Box>
                      </Box>
                    )
                  );
                })}
              </Box>
              <Box>
                <CustomTextfield
                  name={toc.name.substring(0, 4)}
                  fullWidth
                  size="small"
                  id="show-reply"
                  variant="outlined"
                  label="Add Reply"
                  value={focusedTocInputId === toc.toc_id ? comment : ""}
                  onFocus={() => {
                    setFocusedTocInputId(toc.toc_id);
                    setShowSendButton(true);
                  }}
                  onChange={(e) =>
                    e.target.value !== " " && setComment(e.target.value)
                  }
                />
                {showSendButton && comment && (
                  <CustomButton
                    name="commentButton"
                    endIcon={<Icon>send</Icon>}
                    variant="outlined"
                    className={classes.sendBtn}
                    color="primary"
                    onClick={() =>
                      handleNewCommentSubmit(
                        toc.toc_id,
                        toc.assessment_topic_id,
                        toc.name
                      )
                    }
                  >
                    Send
                  </CustomButton>
                )}
              </Box>
            </Box>
          </AccordionDetails>
        </CommentsAccordion>
      ));
  };

  const [filteredComments, setFilteredComments] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    let filtered = comments.commentToc.filter((obj) => {
      return (
        typeof obj.name === "string" &&
        obj.name.toUpperCase().includes(searchText.toUpperCase())
      );
    });
    setFilteredComments(filtered);
  }, [searchText, comments.commentToc]);

  return (
    <>
      {shareDialogOpen && shareDialog()}
      {showDeleteUserDailogue && removeUserDailogue()}
      {showResetPdfProgressDialogue && resetPdfProgressDialog()}

      <Grid container spacing={2} style={{ height: "100%", marginLeft: 5 }}>
        <Grid item md={7}>
          <Box
            height={550}
            paddingLeft="20px"
            width="100%"
            data-tut="reactour__assessmentLeft"
          >
            {!hideLoader && (
              <LinearProgressWithLabel
                value={
                  assesmentApproval.pdfProgress[assessment_id]
                    ? assesmentApproval.pdfProgress[assessment_id]
                    : 0
                }
              />
            )}
            {assesmentApproval.assessmentSkeletons ||
            blob === "" ||
            !hideLoader ? (
              <ReportSkeleton />
            ) : (
              <iframe
                src={blob}
                title="pdf"
                width="100%"
                height="100%"
                name={Date.now()}
              />
            )}
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ marginTop: -80 }}
          data-tut="reactour__assessmentRight"
        >
          {/* <Box height={645} marginLeft={12} mt="-90px" p="10px"> */}
          <Card variant="outlined" className={classes.setupCard}>
            <div style={{ display: "flex" }}>
              {setupOption !== 0 && (
                <ArrowBackIcon
                  name="backBtn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSetupOption(0);
                    setSearch("");
                    setSearchText("");
                  }}
                />
              )}
              {setupOption === 0 && (
                <Typography variant="body1" className={classes.cardTitle}>
                  Setup Document
                </Typography>
              )}
              {setupOption === 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" className={classes.cardTitle}>
                    Customise layout
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "auto" }}
                    onClick={handleAddCoverComplete}
                    disabled={
                      assesmentApproval.pdfProgress[assessment_id] !== 100
                        ? true
                        : false
                    }
                  >
                    Apply
                  </CustomButton>
                </div>
              )}
              {setupOption === 2 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" className={classes.cardTitle}>
                    Add Header/Footer
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "auto" }}
                    onClick={handleHeaderFooterComplete}
                    disabled={
                      assesmentApproval.pdfProgress[assessment_id] !== 100
                        ? true
                        : false
                    }
                  >
                    Apply
                  </CustomButton>
                </div>
              )}
              {setupOption === 3 && (
                <Typography variant="body1" className={classes.cardTitle}>
                  Add Comment
                </Typography>
              )}
              {setupOption === 4 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" className={classes.cardTitle}>
                    Table Of Content
                  </Typography>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setPdfProgress({ id: assessment_id, progress: 0 });
                      !toCallPdfApi && setToCallPdfApi(true);
                      viewAllComments(login.token, assessment_id, 4);
                    }}
                    disabled={
                      assesmentApproval.pdfProgress[assessment_id] !== 100 ||
                      tocApplyDisabler
                    }
                  >
                    Apply
                  </CustomButton>
                </div>
              )}
              {setupOption === 5 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -10,
                    }}
                  >
                    <Typography variant="body1" className={classes.cardTitle}>
                      Stakeholder List
                    </Typography>
                    <CustomButton
                      name="shareBtn"
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: "auto", zIndex: 1 }}
                      onClick={() => handleShareDialogOpen()}
                    >
                      Share
                    </CustomButton>
                    <IconButton
                      aria-label="display more actions"
                      edge="end"
                      color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>
                  </div>
                  <Divider
                    variant="middle"
                    style={{
                      width: "150%",
                      position: "relative",
                      right: 70,
                    }}
                  />
                </div>
              )}
            </div>
            {setupOption === 0 && (
              <Box height={500} overflow="scroll">
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem
                    onClick={() => setSetupOption(1)}
                    button
                    data-tut="reactour__addCover"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={addcover}
                        alt="cover"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Customise layout" />
                  </ListItem>
                  <ListItem
                    onClick={() => setSetupOption(2)}
                    button
                    data-tut="reactour__addHeaderFooter"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={headerfooter}
                        alt="headerfooter"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Add Header/Footer" />
                  </ListItem>
                  <ListItem
                    onClick={() => handleViewCommentsClick()}
                    button
                    data-tut="reactour__viewComments"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={viewcomments}
                        alt="comments"
                      />
                    </ListItemIcon>
                    <ListItemText primary="View Comments" />
                  </ListItem>
                  <ListItem
                    onClick={() => handleEditTableOfContentClick()}
                    button
                    disabled={
                      !hideLoader && assesmentApproval.assessmentSkeletons
                    }
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={tablecontent}
                        alt="table of content"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Edit Table Of Content" />
                  </ListItem>
                  <ListItem
                    onClick={() => setSetupOption(5)}
                    button
                    data-tut="reactour__share"
                  >
                    <ListItemIcon>
                      <img
                        className={classes.setupIcocn}
                        src={share}
                        alt="share"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Share" />
                  </ListItem>
                </List>
              </Box>
            )}

            {setupOption === 1 && (
              <Box height={500} overflow="scroll">
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel1`}
                  onChange={handleAccordioChange(`panel1`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Front Cover</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "felx", flexDirection: "column" }}
                  >
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Background</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {coverImageUrl === "" ? (
                          <>
                            <input
                              name="frontBgImage"
                              id="cover-image"
                              type="file"
                              onChange={(event) =>
                                handleUploadCoverImage(event)
                              }
                              style={{ display: "none" }}
                            />
                            <label htmlFor="cover-image">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={coverImageUrl} alt="coverimage" />
                            <IconButton
                              onClick={handleClearCoverImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box style={{ width: "100%" }}>
                          <Box my={2}>
                            <CustomTextfield
                              name="frontHeadlineTxt"
                              label="Headline Text"
                              value={coverHeadline}
                              onChange={(e) => setCoverHeadline(e.target.value)}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="frontSublineTxt"
                              label="Subline Text"
                              value={coverSubline}
                              onChange={(e) => setCoverSubline(e.target.value)}
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="frontRegularTxt"
                              label="Regular Text"
                              value={coverRregularText}
                              onChange={(e) =>
                                setCoverRregularText(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add logo</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {coverLogoUrl === "" ? (
                          <>
                            <input
                              name="frontLogoImage"
                              id="cover-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadCoverLogoImage(event)
                              }
                              style={{ display: "none" }}
                            />
                            <label htmlFor="cover-logo">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={coverLogoUrl} alt="coverlogo" />
                            <IconButton
                              onClick={handleClearCoverLogoImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel2`}
                  onChange={handleAccordioChange(`panel2`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Back cover</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "felx", flexDirection: "column" }}
                  >
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Background</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {backCoverImageUrl === "" ? (
                          <>
                            <input
                              name="backBgImage"
                              id="back-cover-image"
                              type="file"
                              onChange={(event) =>
                                handleUploadBackCoverImage(event)
                              }
                              style={{ display: "none" }}
                            />
                            <label htmlFor="back-cover-image">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={backCoverImageUrl} alt="coverimage" />
                            <IconButton
                              onClick={handleClearBackCoverImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box style={{ width: "100%" }}>
                          <Box my={2}>
                            <CustomTextfield
                              name="backHeadlineTxt"
                              label="Headline Text"
                              value={coverBackHeadline}
                              onChange={(e) =>
                                setCoverBackHeadline(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="backSublineTxt"
                              label="Subline Text"
                              value={coverBackSubline}
                              onChange={(e) =>
                                setCoverBackSubline(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <Box my={2}>
                            <CustomTextfield
                              name="backRegularTxt"
                              label="Regular Text"
                              value={coverBackRegular}
                              onChange={(e) =>
                                setCoverBackRegular(e.target.value)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add logo</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {backCoverLogoUrl === "" ? (
                          <>
                            <input
                              name="backLogoImage"
                              id="back-cover-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadBackCoverLogoImage(event)
                              }
                              style={{ display: "none" }}
                            />
                            <label htmlFor="back-cover-logo">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={backCoverLogoUrl} alt="coverlogo" />
                            <IconButton
                              onClick={handleClearBackCoverLogoImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>

                <FontSelector
                  handleAccordioChange={handleAccordioChangeColor}
                  expanded={expanded}
                  font={assesmentApproval.font}
                  handleFontComplete={handleFontComplete}
                />
                <ColorGradient
                  handleAccordioChange={handleAccordioChangeColor}
                  expanded={expanded}
                  image={imageLogo}
                  color={assesmentApproval.color}
                  handleColorComplete={handleColorComplete}
                  preColor={
                    assesmentApproval.preSelectedColor
                      ? assesmentApproval.preSelectedColor
                      : []
                  }
                />
              </Box>
            )}
            {setupOption === 2 && (
              <Box height={500} overflow="scroll">
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel3`}
                  onChange={handleAccordioChange(`panel3`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Header</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "felx", flexDirection: "column" }}
                  >
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box style={{ width: "100%" }}>
                          <Box my={2}>
                            <CustomTextfield
                              name="addHeaderTxt"
                              label="Header Text"
                              value={headerText}
                              onChange={(e) => setHeaderText(e.target.value)}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add logo</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        {headerLogoUrl === "" ? (
                          <>
                            <input
                              name="headerLogo"
                              id="header-logo"
                              type="file"
                              onChange={(event) =>
                                handleUploadHeaderLogoImage(event)
                              }
                              style={{ display: "none" }}
                            />
                            <label htmlFor="header-logo">
                              <div className={classes.addCoverImage}>
                                <AddCircleOutlineIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </div>
                            </label>
                          </>
                        ) : (
                          <div className={classes.coverImage}>
                            <img src={headerLogoUrl} alt="coverlogo" />
                            <IconButton
                              onClick={handleClearHeaderLogoImage}
                              className={classes.ClearImage}
                            >
                              <CancelIcon
                                style={{ color: "white" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </div>
                        )}
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  variant="elevation"
                  elevation={0}
                  expanded={expanded === `panel4`}
                  onChange={handleAccordioChange(`panel4`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>Footer</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "felx", flexDirection: "column" }}
                  >
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Add text</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box style={{ width: "100%" }}>
                          <Box my={2}>
                            <CustomTextfield
                              name="addFooterTxt"
                              label="Footer Text"
                              value={footerText}
                              onChange={(e) => setFooterText(e.target.value)}
                              variant="outlined"
                              fullWidth
                              multiline
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                    <InnerAccordion
                      style={{ width: "100%" }}
                      variant="elevation"
                      elevation={0}
                    >
                      <InnerAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>Page number</Typography>
                      </InnerAccordionSummary>
                      <InnerAccordionDetails>
                        <Box style={{ width: "100%" }}>
                          <Box my={2}>
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  name="pgNumCheckbox"
                                  color="secondary"
                                  disableRipple
                                  id="agreement"
                                  checked={isPageNumberVisible}
                                  onChange={() => {
                                    setIsPageNumberVisible(
                                      !isPageNumberVisible
                                    );
                                  }}
                                />
                              }
                              label="Show page number"
                            />
                          </Box>
                        </Box>
                        <Box style={{ width: "100%" }}>
                          <Box my={2}>
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  name="pgNumCheckboxposition"
                                  color="secondary"
                                  disableRipple
                                  id="agreementPosition"
                                  checked={isPNumberDefaultPosition}
                                  onChange={() => {
                                    setIsPNumberDefaultPosition(
                                      !isPNumberDefaultPosition
                                    );
                                  }}
                                />
                              }
                              label="Default position (Left)"
                            />
                          </Box>
                        </Box>
                      </InnerAccordionDetails>
                    </InnerAccordion>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {setupOption === 3 && (
              <>
                <Box mb={1}>
                  <CustomTextfield
                    name="cmtSearch"
                    label="Search By Table Of Content"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    style={{ marginTop: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon
                            style={{ color: "#3374B9" }}
                            fontSize="large"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box height={480} overflow="scroll">
                  {filteredComments.length > 0 ? (
                    commentsDisplay(filteredComments)
                  ) : searchText.length > 0 ? (
                    <Typography
                      style={{
                        position: "relative",
                        top: "7px",
                        left: "4px",
                      }}
                    >
                      No Data Found
                    </Typography>
                  ) : searchText === "" ? (
                    commentsDisplay(comments.commentToc)
                  ) : (
                    commentsDisplay(filteredComments)
                  )}
                </Box>
              </>
            )}
            {setupOption === 4 && (
              <Box style={{ paddingTop: 24, paddingLeft: 17 }}>
                <Grid container style={{ paddingBottom: 20 }}>
                  <Grid
                    item
                    xs={8}
                    className={classes.tocTitle}
                    style={{ paddingLeft: 16 }}
                  >
                    Title
                  </Grid>
                  <Grid item xs={4} className={classes.tocTitle}>
                    Pg No
                  </Grid>
                </Grid>
                <Box height={450} overflow="scroll">
                  {localAssess.map((toc, index) => {
                    return (
                      <Box display="flex">
                        <Grid
                          container
                          key={toc.toc_id + index}
                          className={classes.tocContentGrid}
                        >
                          <Grid item xs={8}>
                            {toc.visible &&
                            showTocInput &&
                            (idToShowInput === toc.toc_id ||
                              idToShowInput === toc.assessment_topic_id) ? (
                              <CustomTextfield
                                autoFocus
                                variant="outlined"
                                value={editTocObj.name}
                                InputProps={{
                                  disableUnderline: true,
                                  className: classes.input,
                                }}
                                className={classes.tocInput}
                                fullWidth
                                onChange={(e) => {
                                  handleEditToc(e);
                                  setTocEyeDisabler(true);
                                }}
                                disabled={!toc.visible}
                                size="small"
                                inputRef={textFieldRef}
                                onBlur={() => {
                                  api(toc);
                                  setShowTocInput(false);
                                }}
                              ></CustomTextfield>
                            ) : (
                              <Typography
                                name={toc.name.substring(0, 4)}
                                variant="body1"
                                className={classes.tocContent}
                                id={
                                  toc.toc_id === ""
                                    ? toc.assessment_topic_id
                                    : toc.toc_id
                                }
                                onClick={(e) => {
                                  if (toc.visible) {
                                    handleShowInputToc(e, toc);
                                  }
                                }}
                              >
                                {toc.name}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant="body1"
                              style={{ color: "#9FA1AF" }}
                              align="center"
                            >
                              {toc.visible && toc.page_no && toc.page_no.pageNo}
                            </Typography>
                          </Grid>
                        </Grid>
                        {true && (
                          <Box key={toc.toc_id + index}>
                            <IconButton>
                              {toc.visible ? (
                                <VisibilityOutlinedIcon
                                  name={toc.name.substring(0, 4)}
                                  style={{ color: "#9FA1AF" }}
                                  onClick={() => {
                                    toc.assessment_topic_id !== "" &&
                                      setEditTocAssessmentId(
                                        toc.assessment_topic_id
                                      );
                                    handleTocVisibility(
                                      toc.name,
                                      toc.toc_id === "" ? "" : toc.toc_id,
                                      false,
                                      toc.order,
                                      toc.assessment_topic_id === ""
                                        ? ""
                                        : toc.assessment_topic_id
                                    );
                                  }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  style={{ color: "#9FA1AF" }}
                                  onClick={() => {
                                    toc.assessment_topic_id !== "" &&
                                      setEditTocAssessmentId(
                                        toc.assessment_topic_id
                                      );
                                    handleTocVisibility(
                                      toc.name,
                                      toc.toc_id === "" ? "" : toc.toc_id,
                                      true,
                                      toc.order,
                                      toc.assessment_topic_id === ""
                                        ? ""
                                        : toc.assessment_topic_id
                                    );
                                  }}
                                />
                              )}
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            {setupOption === 5 && (
              <Box height={500} overflow="scroll">
                {searchArr.length > 0
                  ? searchArr.map((user, index) => (
                      <div className={classes.demo} key={index}>
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar style={{ backgroundColor: " #DDE9F6" }}>
                                {user.user_data.name.substring(0, 2)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={user.user_data.name}
                              secondary={user.user_data.email}
                            />
                          </ListItem>
                        </List>
                      </div>
                    ))
                  : assesmentApproval.approversReviewersList.length > 0 && (
                      <>
                        <CommentsAccordion variant="elevation" elevation={0}>
                          <CommentsAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ marginTop: 10 }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Approvers</Typography>
                              <Box
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "50%",
                                  width: 32,
                                  height: 32,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: 16,
                                  lineHeight: 24,
                                }}
                              >
                                {approversArr.length}
                              </Box>
                            </Box>
                          </CommentsAccordionSummary>

                          {approversArr.map((user, index) => (
                            <div
                              className={classes.demo}
                              key={index}
                              style={{ wordBreak: "break-word" }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "8px",
                                  overflow: "scroll",
                                }}
                                id={user.stakeholder_id}
                                onMouseEnter={(e) => {
                                  setShowRevomeUserIcon(true);
                                  setUserId(e.target.id);
                                }}
                                onMouseLeave={(e) => {
                                  setShowRevomeUserIcon(false);
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexGrow: "4",
                                  }}
                                >
                                  {user.profile_img ? (
                                    <Avatar
                                      alt={user.name}
                                      src={user.profile_img.url}
                                    />
                                  ) : (
                                    <Avatar
                                      style={{
                                        backgroundColor: " #DDE9F6",
                                      }}
                                    >
                                      {user.name.substring(0, 2)}
                                    </Avatar>
                                  )}
                                  <Box>
                                    <Typography style={{ fontSize: "16px" }}>
                                      {user.name}
                                    </Typography>
                                    <Typography style={{ fontSize: "14px" }}>
                                      {user.email}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box flexGrow="4">
                                  <Select
                                    variant="outlined"
                                    displayEmpty
                                    size="small"
                                    select
                                    value={"To Approve"}
                                    name={user.stakeholder_id}
                                    onChange={(e) => {
                                      e.target.name === user.stakeholder_id &&
                                        handleApproverChange(e);
                                    }}
                                  >
                                    {["To Approve", "To comment"].map(
                                      (item) => (
                                        <MenuItem value={item} key={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </Box>
                                <Box flexGrow="2">
                                  {showRevomeUserIcon &&
                                    userId === user.stakeholder_id && (
                                      <IconButton
                                        name="rmvAproover"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                          setRemoveUserEmail(user.email);
                                          setShowDeleteUserDailogue(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </div>
                          ))}
                        </CommentsAccordion>
                        <CommentsAccordion variant="elevation" elevation={0}>
                          <CommentsAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ marginTop: 10 }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Reviewers</Typography>
                              <Box
                                style={{
                                  backgroundColor: "#fff",
                                  borderRadius: "50%",
                                  width: 32,
                                  height: 32,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: 16,
                                  lineHeight: 24,
                                }}
                              >
                                {reviewersArr.length}
                              </Box>
                            </Box>
                          </CommentsAccordionSummary>

                          {reviewersArr.map((user, index) => (
                            <div
                              key={index}
                              style={{ wordBreak: "break-word" }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "8px",
                                  overflow: "scroll",
                                }}
                                id={user.stakeholder_id}
                                onMouseEnter={(e) => {
                                  setShowRevomeUserIcon(true);
                                  setUserId(e.target.id);
                                }}
                                onMouseLeave={(e) => {
                                  setShowRevomeUserIcon(false);
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexGrow: "4",
                                  }}
                                >
                                  {user.profile_img ? (
                                    <Avatar
                                      alt={user.name}
                                      src={user.profile_img.url}
                                    />
                                  ) : (
                                    <Avatar
                                      style={{
                                        backgroundColor: " #DDE9F6",
                                      }}
                                    >
                                      {user.name.substring(0, 2)}
                                    </Avatar>
                                  )}
                                  <Box>
                                    <Typography style={{ fontSize: "16px" }}>
                                      {user.name}
                                    </Typography>
                                    <Typography style={{ fontSize: "14px" }}>
                                      {user.email}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box flexGrow="4">
                                  <Select
                                    variant="outlined"
                                    displayEmpty
                                    size="small"
                                    select
                                    value={"To Comment"}
                                    name={user.stakeholder_id}
                                    onChange={(e) => handleReviewerChange(e)}
                                  >
                                    {["To Comment", "To Approve"].map(
                                      (item) => (
                                        <MenuItem value={item} key={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </Box>
                                <Box flexGrow="2">
                                  {showRevomeUserIcon &&
                                    userId === user.stakeholder_id && (
                                      <IconButton
                                        name="rmvReviewers"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                          setRemoveUserEmail(user.email);
                                          setShowDeleteUserDailogue(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </div>
                          ))}
                        </CommentsAccordion>
                      </>
                    )}
              </Box>
            )}
          </Card>
          {/* </Box> */}
        </Grid>
      </Grid>
    </>
  );
}
