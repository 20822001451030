import { Icon, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../UI/textfield/textfield";
// import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../UI/button/button";
import { submitEsgRating } from "../../../actions/brm/BrmActions";
import { setEsgRating } from "actions/supplierAdmin/supplierAdminActions";
// import {
//   getRiskRating,
//   setRiskRating,
//   setRiskRatingData,
// } from "../../../redux/actions/riskEvaluator/riskEvaluatorActions";
// import useDebounce from "../../../customHooks/useDebounce";
function EsgRiskRating() {
  const { companyDocuments, supplierOrganizationDetails, esgRating } =
    useSelector((state) => state.brmReducer);
  console.log(esgRating);
  const { token, current_organisation } = useSelector((state) => state.login);
  // const { login, riskEvaluator, brmReducer } = useSelector((state) => state);
  // const { rating, comment } = riskEvaluator;
 
  const [risk, setRisk] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    setRisk(esgRating?.risk_score);
    setComments(esgRating?.comment);
  }, [esgRating]);


  const useChipStyles = makeStyles(() => ({
    medium: {
      color: "#8C7038",
      backgroundColor: "#FFEFD0",
      borderRadius: 12,
      padding: "5px 8px",
      fontSize: 13,
      width: "fit-content",
      margin: "5px 8px",
      cursor: "pointer",
    },
    low: {
      color: "#32A05F",
      backgroundColor: "#EBF6EF",
      borderRadius: 12,
      padding: "5px 8px",
      fontSize: 13,
      width: "fit-content",
      margin: "5px 8px",
      cursor: "pointer",
    },
    high: {
      color: "#52231D",
      backgroundColor: "#EDCAC6",
      borderRadius: 12,
      padding: "5px 8px",
      fontSize: 13,
      width: "fit-content",
      margin: "5px 8px",
      cursor: "pointer",
    },
    chip: {
      color: "#6C6C6C",
      backgroundColor: "#F6F6F6",
      borderRadius: 12,
      padding: "5px 8px",
      fontSize: 13,
      width: "fit-content",
      margin: "5px 8px",
      cursor: "pointer",
    },
  }));
  // console.log(risk);
  const classes = useChipStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getRiskRating(login.token, brmReducer.organizationDetails[0]._id));
    return () => {
      dispatch(setEsgRating({}))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRating = ({ target }) => {
    setRisk(target.innerText);
    // dispatch(
    //   setRiskRating(
    //     login.token,
    //     brmReducer.organizationDetails[0]?._id,
    //     target.innerText,
    //     comment
    //   )
    // );
  };

  const handleSaveRating = () => {
    dispatch(
      submitEsgRating(
        token,
        supplierOrganizationDetails?._id,
        current_organisation,
        risk,
        comments
      )
    );
  };

  // useDebounce(
  //   () => {
  //     dispatch(
  //       setRiskRating(
  //         login.token,
  //         brmReducer.organizationDetails[0]?._id,
  //         rating,
  //         comment
  //       )
  //     );
  //   },
  //   2000,
  //   [comment]
  // );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          ESG Risk Rating
        </Typography>
        <CustomButton onClick={handleSaveRating} variant="text" color="primary">
          save change
        </CustomButton>
        {/* <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
          more_vert
        </Icon> */}
      </div>
      <div className="scroll" style={{ marginTop: 18, height: "57vh" }}>
        <Typography style={{ fontSize: 13, fontWeight: 500, color: "#6C6C6C" }}>
          Assign the Risk score
        </Typography>

        <div style={{ display: "flex" }}>
          <Typography
            className={risk === "Low" ? classes.low : classes.chip}
            onClick={handleRating}
          >
            Low
          </Typography>
          <Typography
            className={risk === "Medium" ? classes.medium : classes.chip}
            onClick={handleRating}
          >
            Medium
          </Typography>
          <Typography
            className={risk === "High" ? classes.high : classes.chip}
            onClick={handleRating}
          >
            High
          </Typography>
        </div>

        <div style={{ padding: "5px 8px" }}>
          <Typography
            style={{
              color: "#6C6C6C",
              fontSize: 13,
              width: "fit-content",
              padding: "5px 0px",
              cursor: "pointer",
            }}
          >
            Comments
          </Typography>
          <CustomTextField
            variant="outlined"
            multiline
            size="large"
            value={comments}
            onChange={
              (e) => setComments(e.target.value)
              // dispatch(
              //   // setRiskRatingData({ rating, comment: e.target.value || "" })
              // )
            }
            inputProps={{
              style: {
                height: 80,
                padding: "0 14px",
              },
            }}
            style={{ width: "100%", height: 70 }}
          />
        </div>
      </div>
    </div>
  );
}

export default EsgRiskRating;
