import UNIVERSAL from "../../config/config";
import {
	REPORT,
	REPORT_VALUE,
	RESET_FIELD_DATA,
	SET_VIEW_SURVEY,
	SET_SURVEY_TYPE,
	SET_SURVEY_RESULT,
	SET_SURVEY_ID,
	SET_SURVEY_DATA,
	SET_CURRENT_METRIC_ID,
	SET_UPSERT,
	SET_ALL_STEP_5_USERS,
	// new
	SET_TOPIC_METRICS,
	SET_REPORT_METRICS,
	SET_REPORT_TAB,
	SET_REVIEW_DATA,
	SET_UPSER_METRIC_DATA,
	SET_CURRENT_METRIC,
	SET_REPORT_DATA_TASK,
	SET_ASSIGNED_METRIC,
	SET_ASSIGNED_MEMBERS_FIELD,
	SET_FREEZE_LOADING,
	SET_FREEZE_UPLOADED,
	SET_COLLECT_METRICS,

	// update dialog review tap
	SET_METRIC_RESULT,
	SET_BLOCKCHAIN_HASHLINKS,
	SET_CURRENT_REVIEW_METRICS,
	SET_REVIEW_METRICS,
	SET_ASSIGNED_METRIC_USERS,
	SET_FRAMEWORK_DEFAULT_FILTER,
	RESET_TEMPLATE_TYPE,
	SET_METRIC_CHATS,
	SET_CURRENT_DETAILED_METRIC,
	SET_DISCLOSURE_SETTINGS_RESPONSES,
	FILTER,
	SET_DISCLOSURE_SETTINGS_FILES,
	SET_DISCLOSURE_SETTINGS_VIEW_SUBMISSION,
	SET_DISCLOSURE_SETTINGS_DATA,
} from "../../constants/fielddata/fielddataConst";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";
import { viewActivityLog } from "../../actions/surveyListing/surveyListingAction";
import { setTemplateType } from "../basicinfoAction/basicinfoAction";
import { viewAssignedMatrixUsers } from "../maturityMatrix/maturityMatrixActions";

export function setGetReport(payload) {
	return {
		type: REPORT,
		payload: payload,
	};
}

export function setReportValue(payload) {
	return {
		type: REPORT_VALUE,
		payload: payload,
	};
}

export function setViewSurvey(payload) {
	return {
		type: SET_VIEW_SURVEY,
		payload: payload,
	};
}

export function setSurveyType(payload) {
	return {
		type: SET_SURVEY_TYPE,
		payload: payload,
	};
}

export function setSurveyResult(payload) {
	return {
		type: SET_SURVEY_RESULT,
		payload: payload,
	};
}

export function setSurveyData(payload) {
	return {
		type: SET_SURVEY_DATA,
		payload: payload,
	};
}

export function setSurveyId(payload) {
	return {
		type: SET_SURVEY_ID,
		payload: payload,
	};
}

export function setCurrentMetricId(payload) {
	return {
		type: SET_CURRENT_METRIC_ID,
		payload: payload,
	};
}

export function resetFieldData() {
	return {
		type: RESET_FIELD_DATA,
	};
}

export function setUpsert(payload) {
	return {
		type: SET_UPSERT,
		payload: payload,
	};
}

export function setFreezeLoading(payload) {
	return {
		type: SET_FREEZE_LOADING,
		payload: payload,
	};
}

export function setFreezeUploaded(payload) {
	return {
		type: SET_FREEZE_UPLOADED,
		payload: payload,
	};
}

export function setBlockchainHashlinks(payload) {
	return {
		type: SET_BLOCKCHAIN_HASHLINKS,
		payload: payload,
	};
}

export function setCurrentReviewMetrics(payload) {
	return {
		type: SET_CURRENT_REVIEW_METRICS,
		payload: payload,
	};
}

export function setReviewMetrics(payload) {
	return {
		type: SET_REVIEW_METRICS,
		payload: payload,
	};
}

export function setAssignedMetricUsers(payload) {
	return {
		type: SET_ASSIGNED_METRIC_USERS,
		payload: payload,
	};
}
export const setFrameworkDefaultFilter = (payload) => ({
	type: SET_FRAMEWORK_DEFAULT_FILTER,
	payload: payload,
});

export const resetTemplateType = (payload) => ({
	type: RESET_TEMPLATE_TYPE,
	payload: payload,
});

export function getReports(token) {
	const data = encrypt({
		"user-token": token,
	});
	return (dispatch) => {
		fetch(UNIVERSAL.BASEURL + "/reports/get_reports", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(setGetReport(responseJson.result));
			})
			.catch((error) => {
				console.error(error);
			});
	};
}

// export function getViewSurvey(token, report_id) {
// 	const data = encrypt({
// 		"user-token": token,
// 		report_id: report_id,
// 	});
// 	return (dispatch) => {
// 		dispatch(setSurveyResult([]));
// 		fetch(UNIVERSAL.BASEURL + "/survey/view_survey", {
// 			method: "POST",
// 			headers: {
// 				Accept: "application/json",
// 				"content-type": "application/json",
// 			},
// 			body: JSON.stringify({
// 				data: data,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((responseJson) => {
// 				dispatch(setViewSurvey(responseJson.result));
// 			})
// 			.catch((error) => {
// 				console.error(error);
// 			});
// 	};
// }

export function getSurveyResult(token, metric_id, assessment_id) {
	const data = encrypt({
		"user-token": token,
		metric_id: metric_id,
		assessment_id: assessment_id,
	});
	return (dispatch) => {
		dispatch(set_loader());
		dispatch(setSurveyResult([]));
		fetch(UNIVERSAL.BASEURL + "/survey/get_survey_result", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setSurveyResult(responseJson.result));
					dispatch(setSurveyType(responseJson.result.survey_data.survey_type));
					dispatch(setCurrentMetricId(metric_id));
				}
				dispatch(unset_loader());
			})
			.catch((error) => {
				console.error(error);
			});
	};
}

export function upsetSurveyResult(
	token,
	survey_id,
	assessment_id,
	data,
	organisation_id
) {
	const surveyData = encrypt({
		"user-token": token,
		survey_id: survey_id,
		assessment_id: assessment_id,
		data: data,
		organisation_id: organisation_id,
	});
	return (dispatch) => {
		fetch(UNIVERSAL.BASEURL + "/survey/upsert_survey_result", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: surveyData,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(set_snack_bar(responseJson.status, responseJson.message));
					dispatch(viewActivityLog(token, assessment_id, 5));
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
}

// New

export function setTopicMetric(payload) {
	return {
		type: SET_TOPIC_METRICS,
		payload: payload,
	};
}

export function setMetrics(payload) {
	return {
		type: SET_REPORT_METRICS,
		payload: payload,
	};
}

export function setFilteredMetrics(payload) {
	return {
		type: FILTER,
		payload: payload,
	};
}

export function setCollectMetrics(payload) {
	return {
		type: SET_COLLECT_METRICS,
		payload: payload,
	};
}

export function setReportTab(payload) {
	return {
		type: SET_REPORT_TAB,
		payload: payload,
	};
}

export function setReviewData(payload) {
	return {
		type: SET_REVIEW_DATA,
		payload: payload,
	};
}

export function setIsUpsertMetricData(payload) {
	return {
		type: SET_UPSER_METRIC_DATA,
		payload: payload,
	};
}

export function setCurrentMetric(payload) {
	return {
		type: SET_CURRENT_METRIC,
		payload: payload,
	};
}

export function setAssignedMembers(payload) {
	return {
		type: SET_ASSIGNED_MEMBERS_FIELD,
		payload: payload,
	};
}

export function setAllStep5Users(payload) {
	return {
		type: SET_ALL_STEP_5_USERS,
		payload: payload,
	};
}

// update dialog review tap

export function setMetricResult(payload) {
	return {
		type: SET_METRIC_RESULT,
		payload: payload,
	};
}



// export function getBrsrMetric(token, framework_id) {
// 	const data = encrypt({
// 		"user-token": token,

// 		framework_id,
// 	});
// 	return (dispatch) => {
// 		dispatch(set_loader());
// 		fetch(UNIVERSAL.BASEURL + "/topic/get_brsr_metrics", {
// 			method: "POST",
// 			headers: {
// 				Accept: "application/json",
// 				"content-type": "application/json",
// 			},
// 			body: JSON.stringify({
// 				data: data,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((responseJson) => {
// 				dispatch(setTopicMetric(responseJson.result));
// 			})
// 			.catch((error) => {
// 				console.error(error);
// 			})
// 			.finally(() => {
// 				dispatch(unset_loader());
// 			});
// 	};
// }

export function getMetrics(
	token,
	assessment_id,
	metric_id,
	review_data,
	template_type
) {
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		review_data,
	});
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/metric/get_metrics", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					review_data
						? dispatch(setReviewMetrics(responseJson.result))
						: dispatch(
								getCollectMetrics(
									token,
									assessment_id,
									metric_id,
									// main_topic_id,
									template_type
								)
						  );
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
}

export function getAllMetrics(
	token,
	framework_id,
	template_type,
	assessment_id
) {
	const data = encrypt({
		"user-token": token,
		framework_id,
		template_type,
		assessment_id,
	});
	return (dispatch) => {
		// dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/topic/get_all_metrics", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setTopicMetric(responseJson.result));
					// dispatch(setAllMetrics(responseJson.result))
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				// dispatch(unset_loader());
			});
	};
}

export function getTopicMetric(
	token,
	assessment_id,
	framework_id,
	template_type
) {
	const data = encrypt({
		"user-token": token,
		assessment_id,
		framework_id,
		template_type,
	});
	return (dispatch) => {
		// dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/topic/get_topic_metrics", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(setTopicMetric(responseJson.result));
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				// dispatch(unset_loader());
			});
	};
}
// new metric API for collecting data

export function getCollectMetrics(
	token,
	assessment_id,
	metric_id,
	main_topic_id,
	template_type,
	organisation_id
) {
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		main_topic_id,
		template_type,
		organisation_id,
	});
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/metric/get_metrics_collect_data", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setMetrics(responseJson.result));
				} else {
					dispatch(setMetrics([]));
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
}
export function viewAllUsers_step5(
	token,
	assessment_id,
	metric_id,
	organisation_id
) {
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		organisation_id,
	});
	return (dispatch) => {
		fetch(UNIVERSAL.BASEURL + "/userDetails/view_users_step_5", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(unset_loader());
					dispatch(setAllStep5Users(responseJson.result));
					// console.log('viewAllUsers_step5',responseJson.result)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
}

export function changeStatus(
	token,
	assessment_id,
	metric_id,
	status,
	parent_id,
	review_data
) {
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		status,
	});
	return (dispatch) => {
		console.log(assessment_id, metric_id, parent_id);
		fetch(UNIVERSAL.BASEURL + "/metric/change_status", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					status === "awaiting_approval" &&
						dispatch(getdetailedMetrics(token, metric_id, assessment_id));
					dispatch(getMetrics(token, assessment_id, parent_id, review_data));
					dispatch(unset_loader());
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
}

export const setCurrentDetailedMetric = (payload) => {
	return {
		type: SET_CURRENT_DETAILED_METRIC,
		payload,
	};
};
export function getdetailedMetrics(token, metric_id, assessment_id) {
	const data = encrypt({
		"user-token": token,
		metric_id,
		assessment_id,
		organisation_id: localStorage.getItem("current_organisation"),
	});
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/metric/get_detailed_metric_data", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(setCurrentDetailedMetric(responseJson.result));
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
}

export function getReviewData(token, assessment_id) {
	const data = encrypt({
		"user-token": token,
		assessment_id,
	});
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/metric/get_review_data", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(setReviewData(responseJson.result));
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
}
// export function getReviewData(token, assessment_id) {
//   const data = encrypt({
//     "user-token": token,
//     assessment_id
//   });
//   return (dispatch) => {
//     dispatch(set_loader());
//     fetch(UNIVERSAL.BASEURL + "/topic/get_review_data", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "content-type": "application/json"
//       },
//       body: JSON.stringify({
//         data: data
//       })
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         dispatch(setReviewData(responseJson.result));
//       })
//       .catch((error) => {
//         console.error(error);
//       })
//       .finally(() => {
//         dispatch(unset_loader());
//       });
//   };
// }

export function upsertMetricsData(
	token,
	assessment_id,
	metric_id,
	comment,
	file
) {
	let formData = new FormData();
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		comment,
	});
	formData.append("data", data);
	formData.append("file", file);
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/topic/upsert_metrics", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(set_snack_bar(responseJson.status, responseJson.message));
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
}

export function addMetricsData(
	token,
	assessment_id,
	metric_id,
	parent_id,
	metric_data,
	comment,
	file,
	main_topic_ids,
	parent_metric_id,
	metric_name,
	metric_desc,
	p_id,
	is_approver,
	setRedo,
	setExplore
) {
	let formData = new FormData();
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		parent_id,
		data: metric_data,
		comment: comment ? comment : "",
		organisation_id: localStorage.getItem("current_organisation"),
		main_topic_ids,
		parent_metric_id,
		metric_name,
		metric_desc,
	});

	formData.append("data", data);
	formData.append("file", file);
	return (dispatch) => {
		fetch(UNIVERSAL.BASEURL + "/topic/add_metrics_data", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(
					getAssignedMetric(
						token,
						assessment_id,
						p_id,
						is_approver,
						setRedo,
						setExplore
					)
				);
				dispatch(set_snack_bar(responseJson.status, responseJson.message));
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {});
	};
}

// export function updateOmission(
//   token,
//   assessment_id,
//   metric_id,
//   omit,
//   parent_id,
//   main_topic_id,
//   review_data,
//   file,
//   omission_reason,
//   omission_explanation
// ) {
//   console.log(
//     'update api',
//     assessment_id,
//     metric_id,
//     omit,
//     parent_id,
//     main_topic_id,
//     review_data,
//     file,
//     omission_reason,
//     omission_explanation
//   );
//   let formData = new FormData();

//   const data = encrypt({
//     'user-token': token,
//     assessment_id,
//     metric_id,
//     omit,
//     omission_reason,
//     omission_explanation,
//   });
//   formData.append('data', data);
//   formData.append('file', file);

//   console.log('formData', formData);
//   return (dispatch) => {
//     dispatch(set_loader());
//     fetch(UNIVERSAL.BASEURL + '/metric/update_omission', {
//       method: 'POST',
//       // headers: {
//       // Accept: "application/json",
//       // "content-type": "application/json"
//       // },
//       body: JSON.stringify({
//         data: formData,
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         dispatch(set_snack_bar(responseJson.status, responseJson.message));
//         dispatch(getMetrics(token, assessment_id, parent_id, review_data));
//       })
//       .catch((error) => {
//         console.error(error);
//       })
//       .finally(() => {
//         dispatch(unset_loader());
//       });
//   };
// }

export function updateOmission(
	token,
	assessment_id,
	metric_id,
	omit,
	parent_id,
	main_topic_id,
	review_data,
	file,
	omission_reason,
	omission_explanation
) {
	let formData = new FormData();
	const data = encrypt({
		"user-token": token,
		assessment_id,
		metric_id,
		omit,
		omission_reason,
		omission_explanation,
	});
	formData.append("data", data);
	formData.append("file", file);
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/metric/update_omission", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(set_snack_bar(responseJson.status, responseJson.message));
				dispatch(getMetrics(token, assessment_id, parent_id, review_data));
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
}
export function assignMembers(
	token,
	email_ids,
	step_no,
	assessment_id,
	organisation_id,
	metrics_ids,
	parent_metric_id,
	main_topic_ids,
	role,
	app,
	template_type
) {
	let url = window.origin;
	let domain_name = window.location.hostname;
	const data = encrypt({
		"user-token": token ? token : localStorage.getItem("user_token"),
		email_ids,
		step_no,
		assessment_id,
		organisation_id,
		metrics_ids,
		url,
		parent_metric_id,
		main_topic_ids,
		role,
		domain_name,
	});
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/assignMembers/assign_members", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					app &&
						app.length &&
						dispatch(
							assignMembers(
								token,
								app,
								step_no,
								assessment_id,
								organisation_id,
								metrics_ids,
								parent_metric_id,
								main_topic_ids,
								1
							)
						);
					dispatch(set_snack_bar(responseJson.status, responseJson.message));
					// dispatch(
					// 	getCollectMetrics(
					// 		token,
					// 		assessment_id,
					// 		parent_metric_id,
					// 		main_topic_ids,
					// 		template_type,
					// 		organisation_id
					// 	)
					// );
					step_no === 1 &&
						dispatch(
							viewAssignedMatrixUsers(token, assessment_id, organisation_id)
						);
					dispatch(unset_loader());
				}
			})
			.catch((err) => console.log(err));
	};
}

export function assignMembers_step5(
	token,
	approvers,
	contributors,
	metrics_ids,
	assessment_id,
	organisation_id,
	parent_metric_id,
	main_topic_ids,
	template_type
) {
	let url = window.origin;
	let domain_name = window.location.hostname;
	const data = encrypt({
		"user-token": token ? token : localStorage.getItem("user_token"),
		approvers,
		contributors,
		metrics_ids,
		assessment_id,
		organisation_id,
		parent_metric_id,
		main_topic_ids,
		domain_name,
		url,
	});
	return (dispatch) => {
		dispatch(set_loader());
		fetch(UNIVERSAL.BASEURL + "/assignMembers/assign_members_step_5", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(set_snack_bar(responseJson.status, responseJson.message));
					dispatch(
						getCollectMetrics(
							token,
							assessment_id,
							parent_metric_id,
							main_topic_ids,
							template_type,
							organisation_id
						)
					);

					dispatch(unset_loader());
				}
			})
			.catch((err) => console.log(err));
	};
}

export function viewAssignMembers(token, assessment_id, metric_ids) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token ? token : localStorage.getItem("user_token"),
			assessment_id,
			metric_ids,
		});
		fetch(UNIVERSAL.BASEURL + "/topic/get_assigned_metric_user_data", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify({
				data: data,
			}),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setAssignedMetricUsers(responseJson.result));
				}
			})
			.catch((err) => console.log(err));
	};
}

export const updateFramework = (
	token,
	assessment_id,
	framework_id,
	template_type
) => {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
			framework_id,
			template_type,
		});

		return fetch(UNIVERSAL.BASEURL + "/framework/update_frameworks", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				dispatch(setTemplateType(responseJson.result.template_type));
				dispatch(setTopicMetric([]));

				if (responseJson.status) {
					// dispatch(setFrameworkDefaultFilter(responseJson.result.default_filter))
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
};

//getting assigned metric and sub metric for mytask section

export const setReportDataTask = (payload) => {
	return {
		type: SET_REPORT_DATA_TASK,
		payload: payload,
	};
};

export const getReportDataTask = (token, assessment_id, is_approval) => {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			assessment_id,
			is_approval,
		});
		//assessments
		return fetch(UNIVERSAL.BASEURL + "/metric/get_reporting_data_task", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setReportDataTask(responseJson.result));
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {});
	};
};

export const setAssignedMetric = (payload) => {
	return {
		type: SET_ASSIGNED_METRIC,
		payload: payload,
	};
};

export const getAssignedMetric = (
	token,
	assessment_id,
	metric_id,
	is_approval,
	setRedo,
	setExplore
) => {
	//  ass 63c0ea11f62ff1f33a59ba2a metr 634d024e076373bbca259671
	// console.log('api', token, assessment_id, metric_id, is_approval,setRedo,setExplore);
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
			metric_id,
			is_approval,
		});
		// assessments
		return fetch(UNIVERSAL.BASEURL + "/metric/get_assigned_metrics", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					setRedo && setRedo(true);
					setExplore && setExplore(false);
					dispatch(setAssignedMetric(responseJson.result));
				} else {
					dispatch(setAssignedMetric({}));
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
};

export const viewAssignedMembers = (
	token,
	assessment_id,
	step_no,
	metric_id
) => {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
			step_no,
			metric_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/assignMembers/view_assigned_members", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setAssignedMembers(responseJson.result));
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
};

// update dialog review tap

// export const metricResult = (token, assessment_id, metric_id) => {
// 	return (dispatch) => {
// 		dispatch(set_loader());
// 		const data = encrypt({
// 			"user-token": token ? token : localStorage.getItem("user_token"),
// 			assessment_id,
// 			metric_id,
// 		});
// 		return fetch(UNIVERSAL.BASEURL + "/topic/get_assessment_metrics_results", {
// 			method: "POST",
// 			headers: {
// 				Accept: "application/json",
// 				"Content-Type": "application/json",
// 			},
// 			body: JSON.stringify({ data: data }),
// 		})
// 			.then((response) => response.json())
// 			.then((responseJson) => {
// 				if (responseJson.status) {
// 					dispatch(setMetricResult(responseJson.result));
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(error);
// 			})
// 			.finally(() => {
// 				dispatch(unset_loader());
// 			});
// 	};
// };

export const updateMetricResult = (
	token,
	assessment_id,
	metric_id,
	index_no,
	parent_metric_id,
	main_topic_id,
	review_data
) => {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token ? token : localStorage.getItem("user_token"),
			assessment_id,
			metric_id,
			index_no,
		});
		return fetch(UNIVERSAL.BASEURL + "/topic/update_selected_data", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(
						getMetrics(token, assessment_id, parent_metric_id, review_data)
					);
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				dispatch(unset_loader());
			});
	};
};

export const addBlockchainHashlink = (token, assessment_id, hash_link) => {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			assessment_id,
			hash_link,
		});
		return fetch(UNIVERSAL.BASEURL + "/topic/blockchain_hashlink", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(set_snack_bar(responseJson.status, responseJson.message));
				}
			})
			.catch((err) => console.log(err));
	};
};

export const getBlockchainHashlinks = (token, assessment_id) => {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/blockchain/get_hashlinks", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setBlockchainHashlinks(responseJson.result));
				}
				dispatch(unset_loader());
			})
			.catch((err) => console.log(err));
	};
};

export const freezeData = (token, assessment_id) => {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/blockchain/freeze_data", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(getBlockchainHashlinks(token, assessment_id));
				}
				dispatch(unset_loader());
			})
			.catch((err) => console.log(err));
	};
};

export function deleteAssignedMember(
	token,
	stakeholder_id,
	assessment_id,
	metric_ids
) {
	return (dispatch) => {
		// console.log(stakeholder_id, assessment_id, metric_ids);
		let organisation_id = localStorage.getItem("current_organisation");
		const data = encrypt({
			"user-token": token,
			stakeholder_id,
			organisation_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/assignMembers/delete_stakeholder", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(viewAssignMembers(token, assessment_id, metric_ids));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function setMetricChats(payload) {
	return {
		type: SET_METRIC_CHATS,
		payload: payload,
	};
}
export function setDisclosureSettingsResponses(payload) {
	return {
		type: SET_DISCLOSURE_SETTINGS_RESPONSES,
		payload: payload,
	};
}
export function setDisclosureSettingsFiles(payload) {
	return {
		type: SET_DISCLOSURE_SETTINGS_FILES,
		payload: payload,
	};
}
export function setDisclosureSettingsData(payload) {
	return {
		type: SET_DISCLOSURE_SETTINGS_DATA,
		payload: payload,
	};
}
export function setDisclosureSettingsViewSubmission(payload) {
	return {
		type: SET_DISCLOSURE_SETTINGS_VIEW_SUBMISSION,
		payload: payload,
	};
}
export function viewChats(token, assessment_id, metric_id) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			assessment_id,
			metric_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/chat/view_chats", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setMetricChats(responseJson.result));
				}
			})
			.catch((err) => console.log(err));
	};
}
export function viewChatsInTask(token, assessment_id, metric_id, is_approval) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			assessment_id,
			metric_id,
			is_approval,
		});
		return fetch(UNIVERSAL.BASEURL + "/chat/view_chats_in_task", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setMetricChats(responseJson.result));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getDisclosureSettingsResponses(
	token,
	metric_id,
	assessment_id
) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			metric_id,
			assessment_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/metric/view_submissions", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setDisclosureSettingsResponses(responseJson.result));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getDisclosureSettingsFiles(token, metric_id, assessment_id) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			metric_id,
			assessment_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/chat/get_files", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setDisclosureSettingsFiles(responseJson.result));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getSettingsData(token, metric_id, assessment_id) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			metric_id,
			assessment_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/metric/get_settings_data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(setDisclosureSettingsData(responseJson.result));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function updateSettings(
	token,
	metric_id,
	assessment_id,
	is_approval,
	settings_value,
	approvers_list
) {
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			metric_id,
			assessment_id,
			is_approval,
			settings_value,
			approvers_list,
		});
		return fetch(UNIVERSAL.BASEURL + "/metric/update_settings", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(getSettingsData(token, metric_id, assessment_id));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function deleteFile(token, chat_id, reply_id, assessment_id, metric_id) {
	// console.log({ assessment_id, metric_id });
	return (dispatch) => {
		const data = encrypt({
			"user-token": token,
			chat_id,
			reply_id,
			assessment_id,
			metric_id,
		});
		return fetch(UNIVERSAL.BASEURL + "/chat/delete_file", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					console.log("image deleted");
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getSubmissionDetails(token, submission_id, user_id, user) {
	console.log("api", token, submission_id, user_id, user);
	return (dispatch) => {
		const data = encrypt({ "user-token": token, submission_id, user_id });
		return fetch(UNIVERSAL.BASEURL + "/metric/view_submission_details", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(
						setDisclosureSettingsViewSubmission({
							...responseJson.result,
							user,
						})
					);
				}
			})
			.catch((err) => console.log(err));
	};
}

export function approveSubmission(
	token,
	metric_id,
	assessment_id,
	user_id,
	organisation_id,
	chat_id,
	selected_index,
	parent_metric_id,
	is_approver
) {
	return (dispatch) => {
		console.log("selected_index", selected_index);
		const data = encrypt({
			"user-token": token,
			metric_id,
			assessment_id,
			user_id,
			organisation_id,
			chat_id,
			selected_index,
		});

		return fetch(UNIVERSAL.BASEURL + "/metric/approve_submission", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(set_snack_bar(true, responseJson.message));
					dispatch();
					// getAssignedMetric(
					//   token,
					//   assessment_id,
					//   parent_metric_id,
					//   is_approver
					// )
				} else {
					dispatch(set_snack_bar(false, responseJson.message));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getSustainabilityDocx(token, assessment_id) {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
		});

		return fetch(UNIVERSAL.BASEURL + "/assessments/get_sustainability_docx", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					console.log(responseJson);
					dispatch(unset_loader());
					let url = responseJson.result;
					let a = document.createElement("a");
					a.href = url;
					a.download = "test.docx";
					a.click();
				} else {
					dispatch(unset_loader());
					dispatch(set_snack_bar(false, responseJson.message));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getSustainabilityXBRL(token, assessment_id) {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
		});

		return fetch(UNIVERSAL.BASEURL + "/xbrl/get_xbrl_data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(unset_loader());
					let url = responseJson.data; 
					let a = document.createElement("a");
					a.href = url;
					a.download = "test.xml";
					a.click();
				} else {
					dispatch(unset_loader());
					dispatch(set_snack_bar(false, responseJson.message));
				}
			})
			.catch((err) => console.log(err));
	};
}

export function getSustainabilityExcel(token, assessment_id) {
	return (dispatch) => {
		dispatch(set_loader());
		const data = encrypt({
			"user-token": token,
			assessment_id,
		});

		return fetch(UNIVERSAL.BASEURL + "/excel/get_excel_export", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: data }),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status) {
					dispatch(unset_loader());
					let url = responseJson.data; 
					let a = document.createElement("a");
					a.href = url;
					a.download = "test.xlsx";
					a.click();
				} else {
					dispatch(unset_loader());
					dispatch(set_snack_bar(false, responseJson.message));
				}
			})
			.catch((err) => console.log(err));
	};
}
