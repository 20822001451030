import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../UI/button/button";
import SurveyUp from "../../UI/maturityMatrix/SurveyUp";
import SyncIcon from "@material-ui/icons/Sync";
import CustomTextfield from "../../UI/textfield/textfield";
import { Data } from "../basicInformationRight/basicInformationRight";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import IconButton from "@material-ui/core/IconButton";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import { assignMembers } from "../../../actions/maturityMatrix/maturityMatrixActions";

const MaturityMatrixLeftStyles = makeStyles(() => ({
  mainGrid: {
    justifyContent: "space-between",
    padding: "0px 5px",
    margin: "8px 0px",
  },
  Typo: {
    marginTop: "6px",
    fontWeight: 600,
  },
  mainGrid1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typography1: {
    fontSize: "16px",
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  Typography2: { fontWeight: "600" },
  grid1: { marginLeft: "20px" },
  Typography3: {
    marginLeft: 10,
    fontSize: "14px",
    marginRight: 0,
    textTransform: "none",
    fontFamily: "Poppins",
    lineHeight: "24px",
  },
  search: {
    width: "100%",
  },
  Helper: {
    fontSize: "small",
    color: "grey",
  },
  table: {
    width: "850px",
  },
  sent: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9A9A9A",
  },
  Dialog: {
    // minWidth:600,
  },
}));

function MaturityMatrixLeft(props) {
  const classes = MaturityMatrixLeftStyles();
  const [shareSurvey, setShareSurvey] = useState(false);
  const [selectedToShareSurvey, setSelectedToShareSurvey] = useState([]);
  const {
    assessment_id,
    login,
    basicInfo,
    tabno,
    viewAllUsers,
    generalSettings,
    assignMembers,
    viewAssignedMembers,
    maturityMatrix,
    viewAssignedMatrixUsers,
  } = props;
  const [filteredStakeholders, setFilteredStakeholders] = useState([]);
  const [searchText, setSearchText] = useState("");
  // console.log(maturityMatrix)
  // useEffect(()=>{
  //   console.log('useEffect')
  //   basicInfo.basicInfoTab===1 && viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
  //  },[maturityMatrix.notAssignedUsers])
  useEffect(() => {
    let filtered = maturityMatrix.notAssignedUsers.filter((holder) => {
      if (
        (holder.name &&
          typeof holder.name === "string" &&
          holder.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (holder.email &&
          typeof holder.email === "string" &&
          holder.email.toLowerCase().includes(searchText.toLowerCase())) ||
        (holder.designation &&
          typeof holder.designation === "string" &&
          holder.designation.toLowerCase().includes(searchText.toLowerCase()))
      ) {
        return holder;
        console.log(holder);
      }
    });
    setFilteredStakeholders(filtered);
  }, [searchText]);

  const toMapSearched = (toMap) => {
    // console.log(toMap);
    return (
      <>
        {maturityMatrix.notAssignedUsers.length > 0 &&
          toMap.map((contributor, id) => (
            <TableRow key={id}>
              <TableCell style={{ padding: "0px", margin: "0px" }}>
                <CustomCheckBox
                  checked={selectedToShareSurvey.includes(contributor.email)}
                  value={contributor.email}
                  onChange={(e) => {
                    if (selectedToShareSurvey.includes(e.target.value)) {
                      let selectedIndex = selectedToShareSurvey.indexOf(
                        e.target.value
                      );
                      setSelectedToShareSurvey((prev) => {
                        prev.splice(selectedIndex, 1);
                        return [...prev];
                      });
                    } else {
                      setSelectedToShareSurvey((prev) => [
                        ...prev,
                        e.target.value,
                      ]);
                    }
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography
                  style={{ paddingTop: "4px" }}
                  className={classes.boldText}
                >
                  {contributor.name}
                </Typography>
                <Typography style={{ fontSize: "small" }}>
                  {contributor.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{contributor.designation}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{contributor.type}</Typography>
              </TableCell>
              <TableCell className={classes.sent}>
                {contributor.sent && "Sent"}
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  const shareSurveyDialoge = () => {
    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        className={classes.Dialog}
        open={shareSurvey}
      >
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid1}>
            <Grid item>
              <Typography className={classes.Typography1}>
                Share Maturity Matrix
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Typography className={classes.Helper}>
              Select a stakeholder to assign to
            </Typography>
            <Grid item xs={12}>
              <Box mx={1}>
                <CustomTextfield
                  label="Search by Name/Email ID/Position"
                  // variant="outlined"
                  size="small"
                  className={classes.search}
                  // value={}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mx={1}>
                <Typography className={classes.Typography2}>
                  Category related to the topic
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <div className="scroll" style={{ marginTop: 18 }}>
              <TableContainer>
                {searchText !== "" && filteredStakeholders.length === 0 ? (
                  <Typography style={{ fontWeight: 400 }}>
                    Stakeholder Not Found
                  </Typography>
                ) : (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ padding: "0px", margin: "0px" }}
                        ></TableCell>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Name/Email
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Position
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Type
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {/* <h1>hhf f kyfkug</h1> */}
                    <TableBody md={12} lg={12} sm={12}>
                      {filteredStakeholders.length > 0
                        ? toMapSearched(filteredStakeholders)
                        : toMapSearched(maturityMatrix.notAssignedUsers)}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container lg={5} md={5} sm={5} xs={5}>
            <Grid item className={classes.grid1}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShareSurvey(!shareSurvey);
                  setSelectedToShareSurvey([]);
                  setSearchText("");
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                className={classes.grid1}
                color="primary"
                variant="contained"
                onClick={() => {
                  assignMembers(
                    login.token,
                    selectedToShareSurvey,
                    1,
                    assessment_id,
                    login.current_organisation
                  );
                  setShareSurvey(!shareSurvey);
                  setSelectedToShareSurvey([]);
                  setSearchText("");
                  // viewAssignedMatrixUsers(
                  //   login.token,
                  //   assessment_id,
                  //   login.current_organisation
                  // );
                }}
                disabled={selectedToShareSurvey.length === 0}
              >
                Share To Stakeholders
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const handleShareSurvey = () => {
    setShareSurvey(true);
    //  viewAssignedMatrixUsers(login.token, assessment_id,login.current_organisation)
  };
  // console.log('shareSurvey,',shareSurvey)
  return (
    <>
      {shareSurvey && shareSurveyDialoge()}
      <div className={classes.mainContainer}>
        <Grid>
          <Grid container className={classes.mainGrid}>
            <Grid item>
              <Typography className={classes.Typo}>Survey Section</Typography>
            </Grid>
            <Grid item>
              <CustomButton
                size="small"
                variant="contained"
                color="primary"
                className={classes.Typography3}
                onClick={handleShareSurvey}
              >
                <SyncIcon /> Share Survey
              </CustomButton>
            </Grid>
          </Grid>
          <div className="scroll" style={{ height: "60vh" }}>
            <Grid>
              <SurveyUp {...props} />
            </Grid>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default MaturityMatrixLeft;
