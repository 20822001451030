import React, { useState } from 'react';
import CustomButton from '../../UI/button/button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import CustomTextfield from '../../UI/textfield/textfield';
import contributorButtonStyles from './contributorButtonStyles';

function ContributorButton({
  setAssignApproved,
  token,
  id,
  assessment_id,
  stepNo,
}) {
  const [approve, setApprove] = useState('Approved');
  const classes = contributorButtonStyles();
  return (
    <Card className={classes.card} >
      <CardContent>
        <Grid container justifyContent="space-around">
          <Grid item xs={1}>
            <CustomButton
              color="primary"
              variant="outlined"
              className={classes.customButton}
            >
              <Link to="/report_listing" className={classes.link}>
                Exit
              </Link>
            </CustomButton>
          </Grid>
          <Grid item xs={5}>
            <CustomTextfield
            name="selectOrReject"
              select
              color="primary"
              variant="outlined"
              size="small"
              value={approve}
              onChange={(e) => {
                setApprove(e.target.value);
              }}
            >
              {['Approved', 'Rejected '].map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </CustomTextfield>
            <CustomButton
              color="primary"
              variant="contained"
              className={classes.customButton}
              onClick={() => {
                setAssignApproved(
                  token,
                  assessment_id,
                  approve === 'Approved' ? true : false,
                  stepNo
                );
              }}
            >
              Send
            </CustomButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default React.memo(ContributorButton);
