import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@material-ui/core/Icon";
import calendar from "../../../images/calendar.png";
import AddIcon from "@material-ui/icons/Add";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CustomButton from "../../UI/button/button";
import { Avatar, Grid, Switch, withStyles } from "@material-ui/core";
import Data from "./data";
import LineChart from "../../UI/dataHubCalculatedCharts/lineChart";
import DataSetsCardTitle from "../dataHubDataSets/DataSetsCardTitle";
import { Line } from "react-chartjs-2";
import { rawData } from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";

import ChartRenderedDashb from "../../screen/dashboardStatic/ChartRenderedDashboard";
import { setAutoFillSuggestions } from "../../../actions/datahubActions/datahubActions";

function ChatBoatSuggestionDrawer(props) {
  const { autoFill } = useSelector((state) => state.dataHub);

  const dispatch = useDispatch();

  const {
    isDrawerOpen,
    handleDrawer,
    children,
    chartSchema,
    currentOrganisation,
    isHide = true,
  } = props;

  const [isAutoFillChecked, setAutoFillChecked] = useState(false);

  useEffect(() => {
    setAutoFillChecked(autoFill);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    // cutout: d === 4 && '80%',
    // rotation: d === 4 && 270,
    // circumference: d === 4 && 180,
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          display: true,
          align: "center",
        },
        grid: {
          display: true,
          drawOnChartArea: false,
        },
      },

      y: {
        grid: {
          display: true,
          drawBorder: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      title: {
        font: {
          weight: 700,
        },
        display: true,
        position: "top",
        // text: title[0],
      },
    },

    elements: {
      point: {
        radius: 5,
      },
    },
  };
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [50, 50, 80, 70, 60, 40, 30, 8, 90],
        borderColor: "#3374B9",
        backgroundColor: "#3374B9",
      },
    ],
  };

  const item = {
    id: 16,
    name: "ground water",
    width: "32%",
    baseline: "#32A05F",
    targetline: "#FFCC66",
    chartY_AxisCustomize: "Cubic meters",

    vizState: {
      query: {
        limit: 5000,
        measures: ["Env_water_wtd_from_gwater_src.total_water"],
        order: {
          "Env_water_wtd_from_gwater_src.total_ewaste": "desc",
        },
        dimensions: ["Env_water_wtd_from_gwater_src.total_gwater_wtd"],
        filters: [
          {
            member: "Env_water_wtd_from_gwater_src.organisationid",
            operator: "equals",
            values: ["62da2d4fd884ca873d88da59"],
          },
        ],
        timeDimensions: [
          {
            dimension: "Env_water_wtd_from_gwater_src.date",
            granularity: "month",
          },
        ],
      },
      chartType: "line",
    },

    pivotConfig: {
      x: ["Env_water_wtd_from_gwater_src.total_gwater_wtd"],
      y: ["measures"],
      fillMissingDates: true,
      joinDateRange: false,
    },

    label: ["Maharashtra", "Karnataka", "Gujarat", "Kerala"],
  };

  const API_URL = "http://localhost:4000";

  const CUBEJS_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzExMDY1NDMsImV4cCI6MTY3MTE5Mjk0M30.-9_171MOu0Vs8rfl2f_siqdys9SYph6xfZDvFL81ilI";

  const cubejsApi = cubejs(CUBEJS_TOKEN, {
    apiUrl: `${API_URL}/cubejs-api/v1`,
  });

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const handleAutoFilled = (event) => {
    const autoFillValue = event.target.checked;
    setAutoFillChecked(autoFillValue);
    console.log("Autofill Value:", autoFillValue);
  };

  const handleCloseDrawer = (isClose, checked) => {
    isClose && dispatch(setAutoFillSuggestions(checked));
    !isClose && setAutoFillChecked(checked);
    handleDrawer();
  };

  const cYearStart = "2023-04-01";
  const cYearEnd = "2024-03-31";

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => handleCloseDrawer(false, autoFill)}
        PaperProps={{ style: { background: "#fff" } }}
      >
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#FFFFFF",
            boxShadow: "none",
            borderRadius: "5px",
            padding: "5px",
            height: "72px",
          }}
        >
          <CardContent
            style={{
              marginRight: "12px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Avatar
              src="https://imgrows.s3.us-east-2.amazonaws.com/publicfiles/Black_logo.png"
              variant="rounded"
              style={{ backgroundColor: "#fff" }}
            />
            <Box pl="8px">
              <Typography variant="caption">ImGrows Bot Suggestion</Typography>
            </Box>
          </CardContent>
          <CardActions onClick={() => handleCloseDrawer(false, autoFill)}>
            <CloseIcon style={{ color: "#3374B9", cursor: "pointer" }} />
          </CardActions>
        </Card>
        <div className="scroll" style={{ height: "77vh" }}>
          {/* raw table */}
          {/* <div
            style={{
              width: "50vw",
              overflowY: "scroll",
              zIndex: "-1",
              padding: "0px 10px",
            }}
          >
            <Box
              style={{
                backgroundColor: "#EBF1F8",
                borderRadius: "5px",
              }}
            >
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Raw Data Table
              </Typography>
            </Box>
            {children}
          </div> */}
          <Data />
          {/* <Data description="Calculated Data Table" data={rawData} /> */}
          <div
            style={{
              // width: "50vw",
              overflowY: "scroll",
              zIndex: "-1",
              padding: "10px",
            }}
          >
            <Box
              style={{
                backgroundColor: "#EBF1F8",
                borderRadius: "5px",
              }}
            >
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Charts
              </Typography>
            </Box>
            {/* <Box
              style={{
                border: "1px solid #EBF1F8",
                borderRadius: "8px",
                margin: "10px 20px 10px 20px",
                height: "50px",
              }}
            >
              <DataSetsCardTitle
                fontSize={"14px"}
                hasLine={true}
                title={"NOx (excluding N2O)"}
              />
            </Box> */}
            {chartSchema?.length > 0 &&
              chartSchema?.map((item) => {
                const vizStateOrganization = {
                  ...item.vizState,
                  query: {
                    ...item?.vizState?.query,
                    filters: [
                      {
                        ...item?.vizState?.query?.filters[0],
                        values: [currentOrganisation],
                      },
                    ],
                    timeDimensions: [
                      {
                        ...item?.vizState?.query?.timeDimensions[0],
                        dateRange: [cYearStart, cYearEnd],
                      },
                    ],
                  },
                };
                return (
                  <Box
                    style={{ height: "450px", width: "100%", padding: "10px" }}
                  >
                    <ChartRenderedDashb
                      vizState={vizStateOrganization}
                      pivotConfig={item?.pivotConfig}
                      backgroundColor={item?.chartColors?.backgroundColors}
                      borderColor={item?.chartColors?.borderColor}
                      pieChartCustomize={item?.label}
                      chartY_AxisCustomize={item?.chartY_AxisCustomize}
                      baseline={item?.baseline}
                      targetline={item?.targetline}
                    />
                  </Box>
                );
              })}
          </div>

          {/* switch */}
          {isHide && (
            <div
              style={{
                // width: "50vw",
                overflowY: "scroll",
                zIndex: "-1",
                padding: "10px",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    padding: "5px 4px",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Allow Imgrows Bot To Automatically Fill In Data To Fields
                </Typography>
                <AntSwitch
                  checked={isAutoFillChecked}
                  onChange={handleAutoFilled}
                  name="checkedC"
                />
              </Box>
              <div
                style={{
                  width: "50vw",
                  overflowY: "scroll",
                  zIndex: "-1",
                  padding: "10px",
                }}
              >
                <Box
                  style={{
                    backgroundColor: "#EBF1F8",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "13px",
                      fontWeight: 400,
                    }}
                  >
                    By Allowing imgrows bot to automatically fill in data to any
                    fields which has its legend. you can always cross verify
                    with your current data & edit the prefil.
                  </Typography>
                </Box>
              </div>
            </div>
          )}
          {/* store doc */}
          <div
            style={{
              width: "50vw",
              overflowY: "scroll",
              zIndex: "-1",
              padding: "10px",
            }}
          >
            <Box
              style={{
                borderRadius: "5px",
              }}
            >
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Stored Documents
              </Typography>
              <Typography
                style={{
                  color: "grey",
                  padding: "5px 4px",
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                These are all the updated documents that imgrows bot can learn
                from
              </Typography>
            </Box>
          </div>
          <Box
            style={{ display: "flex", gap: "8px", backgroundColor: "#EBF1F8" }}
          >
            <div style={{ width: "5%" }}></div>
            <div style={{ width: "40%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                Document Name
              </Typography>
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Last Updated
              </Typography>
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Created on
              </Typography>
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Created By
              </Typography>
            </div>
          </Box>

          <Box style={{ display: "flex", gap: "8px" }}>
            <div style={{ width: "5%" }}></div>
            <div style={{ width: "40%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                Air Quality Conversion
              </Typography>
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                06 Feb 2023
              </Typography>
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                06 Feb 2023
              </Typography>
            </div>
            <div style={{ width: "20%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Harikrishna V
              </Typography>
            </div>
          </Box>
        </div>

        {isHide && (
          <Card
            style={{
              display: "flex",
              background: "#FFFFFF",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "72px",
              padding: "16px",
              // border: "1px solid red",
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "100%",
              marginTop: "20px",
            }}
          >
            <CardActions>
              <CustomButton
                variant="contained"
                color="primary"
                style={{
                  // width: "178px",
                  height: "40px",
                  borderRadius: "8px",
                  textAlign: "center",
                  fontWeight: 500,
                  fontSize: "14px",

                  textTransform: "capitalize",
                  color: "#EBF1F8",
                }}
                disabled={false}
                onClick={() => handleCloseDrawer(true, isAutoFillChecked)}
              >
                Map Data fields to task section
              </CustomButton>
            </CardActions>
          </Card>
        )}
      </Drawer>
    </CubeProvider>
  );
}

export default React.memo(ChatBoatSuggestionDrawer);
