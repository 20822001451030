import React, { useState, useRef, useCallback, useEffect } from "react";

import { Avatar, Box, Grid, makeStyles, Typography } from "@material-ui/core";
import CustomTextfield from "../../../UI/textfield/textfield";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import VendorLogo from "./DrawerComps/VendorLogo";
import VendorSector from "./DrawerComps/VendorSector";
import { RevenueData } from "../brmDataRight.js/CompanyDetails";
import { TurnoverYearPickerDialog } from "../TurnoverYearPickerDialog";
import debounce from "lodash.debounce";

import { useDispatch, useSelector } from "react-redux";
import {
  searchSuppliersbyISIN,
  setISINData,
} from "actions/supplier/supplierAction";

function CreatePortfolioDrawer({ companyDetails, setCompanyDetails }) {
  const classes = useStyles();

  // from redux
  const dispatch = useDispatch();
  const { brmReducer } = useSelector((state) => state);
  const { token } = useSelector((state) => state.login);
  const { isinData } = useSelector((state) => state.supplierAdmin);

  // states
  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const [yearRange, setyearRange] = useState([]);
  const [options, setOptions] = useState([]); // Initial options
  const [selectedIsin, setSelectedIsin] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedISINData, setSelectedISINData] = useState(null);
  const [disableFields, setDisableField] = useState(false);

  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    const data = [];

    for (let i = 0; i < 9; i++) {
      data.push(currentYear - 5 + i);
    }
    setyearRange(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTurnover = (i, revenue) => {
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  const handleSetYear = () => {
    const handleYear = (prev) => {
      let copy = [...prev];
      copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
      return copy;
    };
    !currentIndex.current.revenue &&
      setCompanyDetails((prev) => {
        return { ...prev, turnover: handleYear(prev?.turnover) };
      });
    currentIndex.current.revenue &&
      setCompanyDetails((prev) => {
        return { ...prev, revenue: handleYear(prev?.revenue) };
      });
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      setTStartYear(year);
      setTEndYear("");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };

  const debouncedDispatch = useCallback(
    debounce((token, value) => {
      // fetch isin data
      dispatch(searchSuppliersbyISIN(token, value));

      // add new value to options
      const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      if (!filteredOptions.includes(value)) {
        setOptions([...options, value]);
      }
    }, 1000),
    []
  );

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (value) {
      debouncedDispatch(token, value);
    } else {
      dispatch(setISINData([]));
      setOptions([]);
    }
  };

  const handleOptionSelect = (event, value) => {
    setSelectedIsin(value);

    // isin data if found
    const selectedIsin = isinData?.find((obj) => obj?.isin === value);

    setSelectedISINData(selectedIsin);
    // console.log("Selected ISIN:", value);
    // console.log("Selected ISIN DATA:", selectedIsin);
  };

  // ------------ useEffects ---------------

  useEffect(() => {
    if (inputValue && isinData?.length > 0) {
      const newOptions = isinData.map((data) => data.isin);
      setOptions(newOptions);
    }
  }, [isinData]);

  useEffect(() => {
    if (selectedISINData) {
      setCompanyDetails((prev) => ({
        ...prev,
        isin: selectedISINData?.isin,
        supplierName: selectedISINData?.name,
        supplierWebsite: selectedISINData?.website,
        revenue: selectedISINData?.revenue,
        turnover: selectedISINData?.turnover,
        country_name: selectedISINData?.country_name,
        country_code: selectedISINData?.country,
        operation: selectedISINData?.operation,
        no_of_employees: selectedISINData?.no_of_employees,
        sector_id: selectedISINData?.sector_id,
        industry_id: selectedISINData?.industry_id,
        address: [
          {
            ...prev?.address[0],
            companyAddress:
              selectedISINData?.company_address[0]?.companyAddress,
            landMark:
              selectedISINData?.company_address[0]?.landMark ||
              selectedISINData?.company_address[0]?.landmark,
            pinCode: selectedISINData?.company_address[0]?.pinCode,
            state: selectedISINData?.company_address[0]?.state,
            country: selectedISINData?.company_address[0]?.country,
          },
        ],
      }));
      setDisableField(true);
    } else {
      setCompanyDetails((prev) => ({
        ...prev,
        isin: selectedIsin,
        supplierName: "",
        supplierWebsite: "",
        revenue: [],
        turnover: [],
        country_name: "",
        country_code: "",
        operation: "",
        no_of_employees: "",
        sector_id: "",
        industry_id: "",
        address: [],
      }));
      setDisableField(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedISINData]);

  // ---------------------------------------

  return (
    <>
      {turnoverPicker && (
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />
      )}
      <Grid container>
        {/* ISIN */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <label htmlFor="isinNumber">ISIN Number</label>
            {!companyDetails?.isin && (
              <span
                style={{
                  color: "red",
                  padding: "0px 3px",
                  display: "inline",
                }}
              >
                *
              </span>
            )}
            <Autocomplete
              id="isinNumber"
              options={options}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              value={selectedIsin}
              onChange={handleOptionSelect}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="text"
                />
              )}
              renderOption={(option) => (
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>{option}</Typography>
                  {isinData.map((data) => {
                    if (data.isin === option) {
                      return (
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Typography variant="body2" style={{ color: "#999" }}>
                            {data?.name}
                          </Typography>
                          <Avatar
                            src={data?.logo?.url}
                            alt={data?.name}
                            style={{ height: 20, width: 20 }}
                          >
                            {!data?.logo && data?.name && data.name.charAt(0)}
                          </Avatar>
                        </Typography>
                      );
                    }
                    return null;
                  })}
                </Typography>
              )}
            />
          </Box>
        </Grid>

        {/* Name */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <label htmlFor="supplierName">Supplier Name</label>
            {!companyDetails?.supplierName && (
              <span
                style={{
                  color: "red",
                  padding: "0px 3px",
                  display: "inline",
                }}
              >
                *
              </span>
            )}
            <CustomTextfield
              id="supplierName"
              name="supplierName"
              variant="outlined"
              fullWidth
              size="small"
              value={companyDetails?.supplierName}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  supplierName: e.target.value,
                }));
              }}
              disabled={disableFields}
            />
          </Box>
        </Grid>

        {/* Website */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <label htmlFor="supplierWebsite">Supplier Website</label>
            <CustomTextfield
              id="supplierWebsite"
              name="supplierWebsite"
              placeholder="URL"
              variant="outlined"
              value={companyDetails?.supplierWebsite}
              fullWidth
              size="small"
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  supplierWebsite: e.target.value,
                }));
              }}
              disabled={disableFields}
            />
          </Box>
        </Grid>

        {/* Logo */}
        <Grid item xs={12} md={8}>
          <VendorLogo
            imageData={selectedISINData?.logo}
            disableInputs={disableFields}
            setVendorDetails={setCompanyDetails}
          />
        </Grid>

        {/* Sector */}
        <Grid item xs={12} md={12}>
          <VendorSector
            sectorId={selectedISINData?.sector_id}
            industryId={selectedISINData?.industry_id}
            disableInputs={disableFields}
            setCompanyDetails={setCompanyDetails}
          />
        </Grid>

        {/* Revenue */}
        <Grid item xs={12} md={12}>
          <RevenueData
            revenueData={companyDetails?.revenue}
            setRevenueData={setCompanyDetails}
            handleTurnover={handleTurnover}
            disableInputs={disableFields}
            dataType={"Revenue"}
          />
        </Grid>

        {/* Turnover */}
        <Grid item xs={12} md={12}>
          <RevenueData
            revenueData={companyDetails?.turnover}
            setRevenueData={setCompanyDetails}
            handleTurnover={handleTurnover}
            disableInputs={disableFields}
            dataType={"TurnOver"}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <label htmlFor="country-select-demo">Choose a country</label>
            {!companyDetails?.country_name && (
              <span
                style={{
                  color: "red",
                  padding: "0px 3px",
                  display: "inline",
                }}
              >
                *
              </span>
            )}
            <Autocomplete
              id="country-select-demo"
              size="small"
              // style={{ width: 300 }}
              options={brmReducer.countries}
              classes={{
                option: classes.option,
              }}
              value={{ country_name: companyDetails.country_name }}
              autoHighlight
              getOptionLabel={(option) => option.country_name}
              renderOption={(option) => (
                <React.Fragment>{option.country_name}</React.Fragment>
              )}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  variant="outlined"
                  // value={{country_name:'Dominica'}}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCompanyDetails((prev) => ({
                    ...prev,
                    country_name: newValue?.country_name,
                    country_code: newValue?.country_code,
                    // country: { country_code: newValue?.country_code, name: newValue?.country_name },
                  }));
                  // setBranchCountry(newValue);
                }
              }}
              disabled={disableFields}
            />
          </Box>
        </Grid>

        {/* Operation */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <label htmlFor="compOperation">Company Operation</label>
            <CustomTextfield
              id="compOperation"
              name="compOperation"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              size="small"
              value={companyDetails?.operation}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  operation: e.target.value,
                }));
              }}
              disabled={disableFields}
            />
          </Box>
        </Grid>

        {/* Number of Employees */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <label htmlFor="NumOfEmployee">Number of Employees</label>
            <CustomTextfield
              id="NumOfEmployee"
              name="NumOfEmployee"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              value={companyDetails?.no_of_employees}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  no_of_employees: e.target.value,
                }));
              }}
              disabled={disableFields}
            />
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={12}>
          <Box my={2}>
            <CustomTextfield
              name="website"
              variant="outlined"
              fullWidth
              size="small"
              label="Website"
            />
          </Box>
        </Grid> */}

        {/* Company Address */}
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <Typography className={classes.headerText}>
              Company Address
            </Typography>
          </Box>

          {/* Street/Location */}
          <Box my={2}>
            <CustomTextfield
              name="compAddress"
              variant="outlined"
              fullWidth
              size="small"
              label="Street/Location"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RoomOutlinedIcon fontSize="small" color="disabled" />
                  </InputAdornment>
                ),
              }}
              value={companyDetails?.address[0]?.companyAddress}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      companyAddress: e.target.value,
                    },
                  ],
                }));
              }}
              disabled={disableFields}
            />
          </Box>

          {/* Landmark */}
          <Box my={2}>
            <CustomTextfield
              variant="outlined"
              name="compLandmark"
              fullWidth
              size="small"
              label="Landmark"
              value={companyDetails?.address[0]?.landMark}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      landMark: e.target.value,
                    },
                  ],
                }));
              }}
              disabled={disableFields}
            />
          </Box>

          {/* Pincode */}
          <Box my={2}>
            <CustomTextfield
              name="compPincode"
              variant="outlined"
              fullWidth
              size="small"
              label="Pincode"
              type="number"
              value={companyDetails?.address[0]?.pinCode}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      pinCode: e.target.value,
                    },
                  ],
                }));
              }}
              disabled={disableFields}
            />
          </Box>

          {/* State */}
          <Box my={2}>
            <CustomTextfield
              name="compState"
              variant="outlined"
              fullWidth
              size="small"
              label="State"
              value={companyDetails?.address[0]?.state}
              onChange={(e) => {
                setCompanyDetails((prev) => ({
                  ...prev,
                  address: [
                    {
                      ...prev?.address[0],
                      state: e.target.value,
                    },
                  ],
                }));
              }}
              disabled={disableFields}
            />
          </Box>

          {/* Country */}
          <Box my={2}>
            <Autocomplete
              name="compCountry"
              id="countryCode"
              options={brmReducer.countries.map((c) => c?.country_name)}
              getOptionLabel={(option) => option}
              renderOption={(option) => option}
              size="small"
              value={companyDetails?.address[0]?.country}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Country"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  // setCompanyCountry(newValue);

                  setCompanyDetails((prev) => ({
                    ...prev,
                    address: [
                      {
                        ...prev?.address[0],
                        country: newValue,
                      },
                    ],
                  }));
                }
              }}
              disabled={disableFields}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default CreatePortfolioDrawer;

// ------- styles ---------
const useStyles = makeStyles(() => ({
  mainDiv: {
    padding: "5px 20px",
    minWidth: 700,
    maxWidth: 700,
    overflowY: "hidden",
  },
  icon: {
    color: "red",
    marginLeft: 10,
  },
  headerText: {
    fontWeight: "600",
  },
  cardTypography: {
    fontWeight: "600",
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  removeChipIcon: {
    display: "none",
  },
  tableHeader: {
    marginTop: "10px",
    backgroundColor: "#EBF1F8",
    padding: "0px 5px",
    borderRadius: "8px",
    height: "15px",
  },
  tableData: {
    padding: "6px",
  },
  tableheadData: {
    padding: "0px 5px",
  },
  statusOrange: {
    padding: "3px",
    borderRadius: "50%",
    backgroundColor: "orange",
    width: "8px",
    height: "8px",
  },
  downloadButton: {
    textTransform: "none",
    float: "right",
    marginRight: "10px",
    color: "#3374B9",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  createButton: {
    textTransform: "none",
    float: "right",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  searchCont: {
    marginRight: 10,
  },
  headerIcon: { color: "#fff", paddingLeft: "5px" },
  box: {
    border: "1px solid #00000050",
    padding: 12,
    borderRadius: 5,
    cursor: "pointer",
    minHeight: 45,
  },
}));
