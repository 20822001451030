import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined';
import { AppBar, createTheme, MuiThemeProvider } from '@material-ui/core';

function Files(props) {
  const { fieldData } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#47BB76',
      },
    },
    topicname: {
      fontFamily: ['poppins', 'sans-serif'].join(','),
    },
    selectTopicText: {
      color: '#9FA1AF',
      fontSize: 13,
      padding: '10px 0px',
      fontFamily: ['poppins', 'sans-serif'].join(','),
    },
    containerSelectTopicText: {
      marginBottom: 7,
    },
    emptyTopicBar: {
      width: '100%',
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#DADBE655',
      borderRadius: 10,
    },
  });
  const documents = [
    {
      file: {
        name: 'a',
        type: 'img',
        url: 'http:/www.w.com',
      },
      user_data: {
        name: 'user name',
      },
    },
    {
      file: {
        name: 'a',
        type: 'img',
        url: 'http:/www.w.com',
      },
      user_data: {
        name: 'user name',
      },
    },
  ];

  const links = [
    {
      link: ['Sustainability report Changes'],
      report: 'Report name',
      user_data: {
        name: 'user name',
      },
    },

    {
      link: ['Sustainability report Changes', 'Sustainability report Changes'],
      report: 'Report name',
      user_data: {
        name: 'user name 2',
      },
    },
  ];

  return (
    <>
      <Box m={2}>
        <Box mt={4} mb={1}>
          <Typography variant="body2">Files</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            The responses tab has the control of how the responses can be seen
            and by whom.
          </Typography>
        </Box>
        <Divider />
        <Box mb={2} />
        <Box>
          <MuiThemeProvider theme={theme}>
            <AppBar
              position="static"
              color="inherit"
              style={{ boxShadow: 'none' }}
            >
              <Tabs
                value={value}
                style={{
                  // color: '#EBF1F8',
                  boxShadow: 'none',
                  textTransform: 'none',
                }}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
              >
                <Tab
                  disableRipple
                  style={{
                    fontSize: 12,
                    textTransform: 'none',
                    // minWidth: '100px',
                  }}
                  label="Documents"
                />
                <Tab
                  disableRipple
                  style={{
                    fontSize: 12,
                    textTransform: 'none',
                    // minWidth: '100px',
                  }}
                  label="Links"
                />
              </Tabs>
            </AppBar>
          </MuiThemeProvider>
        </Box>

        {console.log('files', fieldData.disclosureFiles)}
        {value === 0 ? (
          <List>
            {fieldData.disclosureFiles &&
            fieldData.disclosureFiles.files.length > 0 ? (
              fieldData.disclosureFiles.files.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Box bgcolor="#EBF1F8" p={1} m={1}>
                      <InsertDriveFileOutlinedIcon />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.file.name}
                    secondary={item.user_data.name}
                  />
                </ListItem>
              ))
            ) : (
              <Typography style={{}}>No Files Availavle.</Typography>
            )}
          </List>
        ) : (
          <List>
            {fieldData.disclosureFiles &&
              fieldData.disclosureFiles.links.length > 0 ?
              fieldData.disclosureFiles.links.map((item, index) => (
                <>
                  {console.log('item', item)}
                  {item.link.map((link) => {
                    return (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <Box bgcolor="#EBF1F8" p={1} m={1}>
                            <InsertLinkOutlinedIcon />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={item.report}
                          secondary={item.user_data.name}
                        />
                      </ListItem>
                    );
                  })}
                </>
              ))
              : (
                <Typography style={{}}>No Links Availavle.</Typography>
              )
            }
          </List>
        )}
      </Box>
    </>
  );
}

export default Files;
