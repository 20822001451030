import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CustomTextfield from "../../UI/textfield/textfield";
import useDebounce from "../../../customHooks/useDebouncec";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import ValidSurveyTypeOnePointOne from "./validation/surveyTypeOnePointOne";

const useStyles = makeStyles({
	blueText: {
		fontWeight: "600",
		color: "#3374B9",
		padding: "10px 0px",
	},
	Grid: { marginTop: 20 },
	CustomTextfield: { backgroundColor: "white" },
	Typography: {
		fontWeight: "600",
		color: "#3374B9",
	},
	input: { display: "none" },
	Box: {
		borderBottom: "2px solid gray",
		cursor: "pointer",
	},
	Box1: { borderBottom: "2px solid #3374B9" },
});

function SurveyTypeOnePointOne(props) {
	const {
		surveyData,
		surveyResultData,
		isUpsertMetricData,
		setIsUpsertMetricData,
		addMetricsData,
		fieldData,
		login,
		assessment_id,
		metricId,
		index,
		parentId,
		removeFile,
		metric_name,
		metric_desc,
		metric,
		setOnSubmitObject,
		validation,
	} = props;

	const classes = useStyles();

	const [typeOnePointOneData, setTypeOnePointOneData] = useState([]);

	const [metricFile, setMetricFile] = useState(null);
	const [metricComment, setMetricComment] = useState("");

	useEffect(() => {
		if (props.metric.hasOwnProperty("comment")) {
			setMetricComment(props.metric.comment);
		}

		if (props.metric.hasOwnProperty("file") && props.metric.file !== "") {
			setMetricFile(props.metric.file);
		}
	}, [fieldData.currentMetric]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (surveyResultData !== null && surveyResultData !== "") {
			setTypeOnePointOneData(surveyResultData);
		} else {
			const data = surveyData.rows.map((col, idx) => {
				return {
					row: col,
					data: "0:0",
				};
			});
			setTypeOnePointOneData(data);
		}
	}, [fieldData.currentMetric, surveyResultData, parentId]); // eslint-disable-line react-hooks/exhaustive-deps

	useDebounce(
		() => {
			setOnSubmitObject({
				token: login.token,
				assessment_id,
				metricId,
				currentMetric_id: fieldData.currentMetric._id,
				data_type: typeOnePointOneData,
				metricComment,
				metricFile,
				metric_name,
				metric_desc,
				parent_id: parentId,
				parent_metric_id: metric.parent_metric_id,
			});
		},
		100,
		[metricComment, metricFile]
	);

	return (
		<>
			{typeOnePointOneData.map((data, i) => {
				return (
					<Grid
						container
						alignItems="center"
						spacing={2}
						display="flex"
						flexDirection="row"
						className={classes.Grid}
					>
						<Grid item xs={12}>
							<Typography className={classes.Typography}>{data.row}</Typography>
							<ValidSurveyTypeOnePointOne
								isUpsertMetricData={isUpsertMetricData}
								setIsUpsertMetricData={setIsUpsertMetricData}
								setOnSubmitObject={setOnSubmitObject}
								parent_metric_id={metric.parent_metric_id}
								fieldData={fieldData}
								login={login}
								assessment_id={assessment_id}
								metricId={metricId}
								index={i}
								validation={validation}
								typeOnePointOneData={typeOnePointOneData}
								setTypeOnePointOneData={setTypeOnePointOneData}
								metricComment={metricComment}
								metricFile={metricFile}
								classes={classes}
								metric_name={metric_name}
								metric_desc={metric_desc}
								parentId={parentId}
							/>
						</Grid>
					</Grid>
				);
			})}
			<Box>
				<Box my={2}>
					<Typography className={classes.blueText}>Comments</Typography>
					<CustomTextfield
						name={`Comment_type1${metricId}`}
						label="Additional Comment"
						fullWidth
						multiline
						rows={3}
						size="small"
						variant="outlined"
						value={metricComment}
						onChange={(e) => {
							if (!isUpsertMetricData) {
								setIsUpsertMetricData(true);
							}
							setMetricComment(e.target.value);
						}}
					/>
				</Box>
				<Box my={2}>
					{metricFile === null || metricFile === "" ? (
						<>
							<input
								id={metricId}
								type="file"
								index={index}
								onChange={(event) => {
									setMetricFile(event.target.files[0]);

									if (!isUpsertMetricData) {
										setIsUpsertMetricData(true);
									}
								}}
								className={classes.input}
							/>
							<label htmlFor={metricId}>
								<Box display="flex" component="span" alignItems="center">
									<AttachFileIcon color="disabled" />
									<Box mx={2} className={classes.Box} width="100%">
										<Typography variant="caption" color="textSecondary">
											Upload document
										</Typography>
									</Box>
								</Box>
							</label>
						</>
					) : (
						<>
							<Box display="flex" alignItems="center">
								<AttachFileIcon color="disabled" />
								<Box mx={2} className={classes.Box1} width="100%">
									<Typography variant="caption" color="primary">
										{metricFile.name}
									</Typography>
								</Box>
								<ClearIcon
									color="primary"
									style={{ cursor: "pointer" }}
									onClick={() => {
										removeFile(
											login.token,
											assessment_id,
											5,
											metricFile.url,
											"file",
											null,
											null,
											null,
											metricId
										);
										setMetricFile(null);
									}}
								/>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	);
}

export default SurveyTypeOnePointOne;
