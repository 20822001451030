import { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import SingleMetricReport from "../reportListing/fieldDataSurvey/singleMetricReport";
import SurveySuccess from "../../../images/SurveySuccess.svg";
import CustomButton from "../../UI/button/button";
import Threads from "../viewThreads/viewThreads";
import ChatBotSuggestionDrawer from "../chatBotSuggestion/chatBotSuggestionDrawer";

import {
  getViewSuggestions,
  setAutoFillSuggestions,
  setKpiSuggestions,
} from "../../../actions/datahubActions/datahubActions";

const ExploreData = (props) => {
  const dispatch = useDispatch();

  const useStyles = makeStyles({
    viewMetricThread: {
      backgroundColor: "white",
      color: "#3374B9",
      fontSize: "small",
      marginRight: "10px",
      "&:hover": {
        backgroundColor: "white",
        boxShadow: "1px 1px 3px #c8c0c0a1",
      },
    },
  });
  const classes = useStyles();
  const {
    fieldData,
    finalData,
    login,
    setExplore,
    setIsUpsertMetricData,
    addMetricsData,
    open,
    setOpen,
  } = props;
  const { current_organisation } = login;
  const [onsubmitObject, setOnSubmitObject] = useState({});
  const [redo, setRedo] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseChatBoatDrawer = () => {
    setIsDrawerOpen(false);
  };
  // console.log(props)
  const handleclick = () => {
    if (fieldData.isUpsertMetricData) {
      addMetricsData(
        login.token,
        onsubmitObject.assessment_id,
        onsubmitObject.metricId,
        onsubmitObject.currentMetric_id,
        onsubmitObject.data_type,
        onsubmitObject.metricComment,
        onsubmitObject.metricFile,
        "",
        onsubmitObject.currentMetric_id,
        onsubmitObject.metric_name,
        onsubmitObject.metric_desc,
        onsubmitObject.parent_metric_id,
        props.is_approver,
        setRedo,
        setExplore
      );

      //   setTimeout(() => {
      // getAssignedMetric(
      //   login.token,
      //   onsubmitObject.assessment_id,
      //   onsubmitObject.parent_metric_id,
      //   props.is_approver
      // );
      // setRedo(true);
      // setExplore(false);
      //   }, [2000]);
    }
    setIsUpsertMetricData(false);
  };

  const handleSuggestionDrawer = () => {
    dispatch(
      getViewSuggestions(
        props?.login?.token,
        props?.login?.current_organisation,
        // "61e53decda306d000b0eddf9",
        finalData?.metric?.kpi_name,
        finalData?.metric?.framework_id,
        "2023-04-01",
        "2024-03-31"
        // "section_c_principle_6_e_8_a"
        // "section_c_principle_6_e_3_a"
      )
    );
    setIsDrawerOpen((preValue) => !preValue);
  };

  // clear suggestion redux state

  useEffect(() => {
    dispatch(setKpiSuggestions([]));
    dispatch(setAutoFillSuggestions(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return finalData && finalData.metric.data && !redo ? (
    <div>
      <ArrowBackIcon
        style={{ cursor: "pointer" }}
        onClick={() => setExplore(false)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "14%",
        }}
      >
        <img
          style={{ maxWidth: "400px", maxHeight: "441px" }}
          src={SurveySuccess}
          alt="survey_success_vector"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "30px",
          }}
        >
          <Typography
            style={{
              color: "#0F0F0F",
              paddingBottom: "13px",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "500",
              letterSpacing: "0.0015rem",
              textTransform: "capitalize",
            }}
          >
            The survey has been Saved.
          </Typography>
          <Typography
            style={{
              color: "#0F0F0F",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "400",
              letterSpacing: "0.0025em",
              textTransform: "capitalize",
            }}
          >
            If you wish to redo the survey please use the redo button. Or go
            back to the task section.
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: "30px",
        }}
      >
        <Button
          onClick={() => setOpen(true)}
          className={classes.viewMetricThread}
        >
          View Metric Thread
        </Button>
        <CustomButton
          style={{ textTransform: "none", fontFamily: "Poppins" }}
          variant="contained"
          color="primary"
          onClick={() => setRedo(true)}
        >
          Redo Survey
        </CustomButton>
      </div>
      {open && (
        <Threads
          task={true}
          main={false}
          is_approver={props.location.state.role[0] === 0 ? false : true}
          {...props}
          open={open}
          setOpen={setOpen}
          metric={{ ...finalData.metric }}
          onClose={() => setOpen(false)}
          isTaskSection={true}
        />
      )}
    </div>
  ) : (
    <>
      {isDrawerOpen && (
        <ChatBotSuggestionDrawer
          isDrawerOpen={isDrawerOpen}
          handleDrawer={handleCloseChatBoatDrawer}
          chartSchema={finalData?.metric?.cubejs_schema2}
          currentOrganisation={current_organisation}
        />
      )}

      <div>
        {/* <Card
       variant="elevation"
       id="drawer-container"
       elevation={1}
       className={classes.cardContainer}
       data-tut="reactour__basicRight"
     > */}
        <CardContent style={{ padding: 0 }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "16px" }}
          >
            <Grid item>
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={() => setExplore(false)}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => setOpen(true)}
                style={{
                  backgroundColor: "white",
                  color: "darkblue",
                  fontSize: "small",
                }}
              >
                View Metric Thread
              </Button>
              <Button
                // disabled={!fieldData.isUpsertMetricData}
                style={{
                  backgroundColor: "darkblue",
                  color: "white",
                  fontSize: "small",
                  width: "100px",
                }}
                onClick={handleclick}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "16px" }}
            alignItems="center"
          >
            <Grid item md={10}>
              <Box
                width="100%"
                p={1}
                bgcolor="#EBF1F8"
                display="flex"
                alignItems="center"
                borderRadius={8}
                marginBottom="10px"
              >
                <Avatar
                  src="https://imgrows.s3.us-east-2.amazonaws.com/publicfiles/Black_logo.png"
                  variant="rounded"
                  style={{ backgroundColor: "#fff" }}
                />
                <Box pl="8px">
                  <Typography variant="caption">
                    Impact grows bot is a handy suggestion tool based on the raw
                    data from the exchanged hub it can give you the data to be
                    entered in a spectific field. This data can also be edited
                    before submitting.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={handleSuggestionDrawer}
                style={{
                  backgroundColor: "white",
                  color: "#3374B9",
                  fontSize: "11px",
                  textAlign: "right",
                  marginLeft: "auto",
                  fontWeight: 600,
                }}
              >
                View Suggestion
              </Button>
            </Grid>
          </Grid>

          <div className="scroll" style={{ height: "70vh" }}>
            <SingleMetricReport
              {...props}
              onsubmitObject={onsubmitObject}
              setOnSubmitObject={setOnSubmitObject}
              open={open}
              setOpen={setOpen}
              metric={finalData.metric}
              index={finalData.index}
            />
          </div>
        </CardContent>

        {/* </Card> */}
      </div>
    </>
  );
};

export default ExploreData;
