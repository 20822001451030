import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";

function DataSetsCardTitle({
  title,
  fontSize = "16px",
  hasLine = false,
  handleOpenMenu,
}) {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      <Typography
        style={{
          fontSize: fontSize,
          color: "#242424",
          fontWeight: "bolder",
          display: "flex",
        }}
      >
        {hasLine && (
          <Box
            style={{
              border: "4px solid #FF8600",
              borderRadius: "8px",
              marginRight: "10px",
            }}
          />
        )}
        {title}
      </Typography>
      <MoreVertIcon
        style={{ color: "#3374B9", cursor: "pointer" }}
        onClick={(e) => handleOpenMenu(e, title)}
      />
    </Box>
  );
}

export default DataSetsCardTitle;
