import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import DataType2 from "./dataType2";

function Data() {
  const { suggestedData } = useSelector((state) => state.dataHub);
  console.log("Suggested Data: ", suggestedData);
  // const [column_name, data] = suggestedData
  if (
    suggestedData &&
    suggestedData.length > 0 &&
    suggestedData[0].column_name
  ) {
    return suggestedData.map((tableData, index) => {
      return (
        <div
          key={index}
          style={{
            // width: "50vw",
            overflowY: "scroll",
            zIndex: "-1",
            padding: "10px",
          }}
        >
          <Box
            style={{
              backgroundColor: "#EBF1F8",
              borderRadius: "5px",
            }}
          >
            <Typography
              style={{ padding: "5px 4px", fontSize: "16px", fontWeight: 600 }}
            >
              {tableData?.column_name}
            </Typography>
          </Box>
          <Box style={{ display: "flex", gap: "8px" }}>
            <div style={{ width: "40%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                Time Period
              </Typography>
            </div>
            <div style={{ width: "15%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Q1
              </Typography>
            </div>
            <div style={{ width: "15%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Q2
              </Typography>
            </div>
            <div style={{ width: "15%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Q3
              </Typography>
            </div>
            <div style={{ width: "15%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Q4
              </Typography>
            </div>
            <div style={{ width: "15%" }}>
              <Typography
                style={{
                  padding: "5px 4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Total
              </Typography>
            </div>
          </Box>
          {tableData?.data?.length > 0 &&
            tableData?.data.map((rData, rIndex) => (
              <Box style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                <div style={{ width: "40%" }}>
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {rData.row}
                  </Typography>
                </div>

                <div
                  style={{
                    width: "15%",
                    borderRadius: "8px",
                    backgroundColor: "#FBFBFB",
                  }}
                >
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {rData?.q1}
                  </Typography>
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRadius: "8px",
                    backgroundColor: "#FBFBFB",
                  }}
                >
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    {rData?.q2}
                  </Typography>
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRadius: "8px",
                    backgroundColor: "#FBFBFB",
                  }}
                >
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    {rData?.q3}
                  </Typography>
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRadius: "8px",
                    backgroundColor: "#FBFBFB",
                  }}
                >
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    {rData?.q4}
                  </Typography>
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRadius: "8px",
                    backgroundColor: "#FBFBFB",
                  }}
                >
                  <Typography
                    style={{
                      padding: "5px 4px",
                      fontSize: "16px",
                      fontWeight: 400,
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    {rData?.total}
                  </Typography>
                </div>
              </Box>
            ))}
        </div>
      );
    });
  } else {
    return <DataType2 suggestedData={suggestedData} />;
  }
}

export default Data;
