import React, { useEffect, useState } from "react";
import { Box, Icon, IconButton, Typography } from "@material-ui/core";

import StepRight from "../../stepLayout/right/StepRight";

import CustomButton from "../../../../../UI/button/button";
import SurveyQuestionTable from "./SurveyQuestionTable";
import AssignDrawer from "../assignDrawer/AssignDrawer";
import ExploreQuestion from "./ExploreQuestion";
import { FaListUl } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { FiActivity } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";
import NoData from "../../../../NoData/NoData";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovedAnswer,
  getQuestionWithStatus,
} from "../../../../../../actions/supplierAdmin/supplierAdminActions";

function Step2Right({
  contributors,
  approvers,
  handleContributers,
  handleApprovers,
  handleDeleteApprovers,
  handleDeleteContributers,
  toggleSingleItemUser,
  handleReset,
  users,
  sectionQuestions,
  status,
  handleStatus,
  surveyList,
  // selectedSection,
}) {
  const dispatch = useDispatch();
  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );
  const { selectedCorporate } = useSelector((state) => state?.supplierAdmin);

  const {
    selectedAssignSurvey,
    questionWithStatus,
    selectedSection,
    questionApproved,
    questionAssigned,
    totalQuestion,
    questionStatus,
    approvedAnswerData,
  } = useSelector((state) => state?.supplierAdmin);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hasExplore, setHasExplore] = useState(false);

  const handleExploreQuestion = (data) => {
    setHasExplore(true);
    dispatch(
      getApprovedAnswer(
        token,
        selectedCorporate?._id,
        supplier_id,
        data?.question_id,
        selectedAssignSurvey?.frequency_id,
        selectedAssignSurvey?.version
      )
    );
  };

  useEffect(() => {
    selectedSection &&
      dispatch(
        getQuestionWithStatus(
          token,
          supplier_id,
          selectedCorporate?._id,
          selectedSection?._id,
          selectedAssignSurvey?.version,
          selectedAssignSurvey?.frequency_id
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  return (
    <StepRight>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasExplore && (
            <IconButton size="small">
              <Icon
                style={{ color: "#3374B9" }}
                onClick={() => setHasExplore(false)}
                fontSize="small"
              >
                arrow_back
              </Icon>
            </IconButton>
          )}
          Company Profile
        </Typography>
        {hasExplore ? (
          <>
            {/* <CustomButton
            variant="contained"
            color="primary"
            onClick={() => {
              setIsDrawerOpen(true);
            }}
            startIcon={
              <Icon style={{ transform: "scaleX(-1)" }}>keyboard_return</Icon>
            }
            style={{ textTransform: "capitalize" }}
          >
            View Thread
          </CustomButton> */}
          </>
        ) : (
          selectedSection && (
            <CustomButton
              variant="text"
              color="primary"
              onClick={() => setIsDrawerOpen(true)}
              style={{ textTransform: "capitalize" }}
            >
              Assign Question
            </CustomButton>
          )
        )}
      </Box>
      {!hasExplore && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 20,
            alignItems: "center",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaListUl size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {`${totalQuestion} Questions`}
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FiUserPlus size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {`${questionAssigned} Questions Assigned`}
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaCheck size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {`${questionApproved} Questions Approved`}
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FiActivity size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {questionStatus}
            </Typography>
          </Box>
        </Box>
      )}
      {hasExplore && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <HiOutlineUsers size={"16px"} style={{ color: "#5C90C7" }} />
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {`${approvedAnswerData?.contributors?.length} contributors`}
          </Typography>
          <FiUserCheck size={"16px"} style={{ color: "#5C90C7" }} />
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {`${approvedAnswerData?.approvers?.length} Approver`}
          </Typography>
        </Box>
      )}
      {hasExplore && (
        <Box style={{ overflow: "scroll", height: "80%" }}>
          <ExploreQuestion />
        </Box>
      )}
      {!hasExplore &&
        (questionWithStatus?.length > 0 ? (
          <Box style={{ overflow: "scroll", height: "80%" }}>
            <SurveyQuestionTable
              status={status}
              handleExploreQuestion={handleExploreQuestion}
              sectionQuestions={questionWithStatus}
            />
          </Box>
        ) : (
          <Box style={{ height: "50%" }}>
            <NoData message={"Please Select Section"} />
          </Box>
        ))}

      {isDrawerOpen && (
        <AssignDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={() => {
            setIsDrawerOpen(false);
            handleStatus("In Progress");
            handleReset();
          }}
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={handleReset}
          toggleSingleItemUser={toggleSingleItemUser}
          surveyList={questionWithStatus}
          resource={"question"}
        />
      )}
    </StepRight>
  );
}

export default Step2Right;
