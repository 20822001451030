import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import dataSurvey from "../../../images/dataSurvey.png";
import DataHubSurveySheetTable from "./dataHubSurveySheetTable";
import DataHubSurveyDrawer from "./dataHubSurveyDrawer";

function DataHubSurvey(props) {
  const {
    isDrawerOpen,
    handleDrawer,
    login,
    addTags,
    dataHub,
    uploadImportFile,
    getImportFileList,
    deleteTags,
    getImportFileData,
    getMatatikaJobStatus,
    restartMatatikaJob,
  } = props;
  const [file, setFile] = React.useState([]);
  const TAB_NAME = "survey_tab";
  React.useEffect(() => {
    getImportFileList(login.token, login.current_organisation, TAB_NAME);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFile = (e) => {
    const uploadFile = e.target.files[0];
    uploadFile !== undefined && setFile([uploadFile]);
  };
  const handleFileRemove = (value) => {
    setFile(value);
  };
  return (
    <>
      <DataHubSurveyDrawer
        login={login}
        addTags={addTags}
        dataHub={dataHub}
        file={file}
        isDrawerOpen={isDrawerOpen}
        handleDrawer={handleDrawer}
        uploadHandler={handleFile}
        handleFileRemove={handleFileRemove}
        uploadImportFile={uploadImportFile}
        deleteTags={deleteTags}
      />
      {dataHub.importFileList.length === 0 && (
        <div>
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
              }}
            >
              <img
                src={dataSurvey}
                alt=""
                style={{
                  marginTop: "6%",
                  width: "560.96px",
                  height: "360px",
                }}
              />
            </Grid>
          </Grid>

          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "32px",
              textAlign: "center",
              textTransform: "capitalize",
              color: "#242424",
              padding: "1px 15px",
            }}
          >
            Import sheet files to procees the data.
          </Typography>
        </div>
      )}
      {dataHub.importFileList.length > 0 && (
        <DataHubSurveySheetTable
          file={dataHub.importFileList}
          status={dataHub.dataProcessingStatus}
          progress={dataHub.fileProcessingProgress}
          getImportFileData={getImportFileData}
          token={login.token}
          getMatatikaJobStatus={getMatatikaJobStatus}
          restartMatatikaJob={restartMatatikaJob}
          currentOrganisation={login.current_organisation}
        />
      )}
    </>
  );
}

export default React.memo(DataHubSurvey);
