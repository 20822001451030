import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@material-ui/core/Icon";
import calendar from "../../../images/calendar.png";
import AddIcon from "@material-ui/icons/Add";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import DataHubSurveyDatePicker from "./dataHubSurveyDatePicker";
import DataHubSurveyLabelDialog from "./dataHubSurveyLabelDialog";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CustomButton from "../../UI/button/button";
import {
  setFileValidation,
  setFileError,
  setErrorMessage,
  getAllFrameworks,
} from "../../../actions/datahubActions/datahubActions";

function DataHubSurveyDrawer(props) {
  const dispatch = useDispatch();
  const {
    isDrawerOpen,
    handleDrawer,
    uploadHandler,
    file,
    handleFileRemove,
    login,
    addTags,
    dataHub,
    uploadImportFile,
    // deleteTags,
  } = props;

  const { token, selectedOrganization } = login;
  const {
    functionalTags,
    locationTags,
    valueChainTags,
    isFileValid,
    hasFileError,
    errorMessage,
  } = dataHub;

  const FUNCTIONAL = "functional";
  const LOCATION = "location";
  const VALUECHAIN = "value_chain";

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] =
    React.useState(false);

  const [isLabelDialogOpen, setIsLabelDialogOpen] = React.useState(false);

  const [nameOfDataStream, setNameOfDataStream] = React.useState("");
  const [descriptionOfDataStream, setDescriptionOfDtaStream] =
    React.useState("");
  const [yearOfAssesment, setYearOfAssessment] = React.useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const [functionLabels, setFunctionLabels] = React.useState([]);
  const [locationLabels, setLocationLabels] = React.useState([]);
  const [valueChainLabels, setValueChainLabels] = React.useState([]);
  const [labelIndex, setLabelIndex] = React.useState("");
  const [tagType, setTagType] = React.useState("");
  const functionalIds = functionLabels.map((tag) => tag.tag_id);
  const locationIds = locationLabels.map((tag) => tag.tag_id);
  const valueChainIds = valueChainLabels.map((tag) => tag.tag_id);

  const { frameworks } = useSelector((state) => state.dataHub);

  const [selectedFramework, setSelectedFramework] = React.useState("");
  const [selectedFrameworkId, setselectedFrameworkId] = React.useState("");

  // fetching all the frameworks
  useEffect(() => {
    dispatch(getAllFrameworks(token));
  }, [dispatch, token]);

  let disableButton =
    file.length > 0 &&
    // functionLabels.length > 0 &&
    // locationLabels.length > 0 &&
    // valueChainLabels.length > 0 &&
    selectedFrameworkId !== "" &&
    nameOfDataStream !== "" &&
    descriptionOfDataStream !== "" &&
    yearOfAssesment[0].startDate !== ""
      ? false
      : true;

  const handleDatePickerClose = (value) => {
    setIsDatePickerDialogOpen(value);
  };

  const handleDates = (value) => {
    setYearOfAssessment(value);
  };
  const handleLabelDialogClose = (value, labels, tagType) => {
    setIsLabelDialogOpen(value);
    if (tagType === FUNCTIONAL) {
      labels !== undefined &&
        // !Array.isArray(labels) &&
        setFunctionLabels(labels);
    } else if (tagType === LOCATION) {
      labels !== undefined &&
        // !Array.isArray(labels) &&
        setLocationLabels(labels);
    } else {
      labels !== undefined &&
        // !Array.isArray(labels) &&
        setValueChainLabels(labels);
    }
  };
  const handleAddTag = (isOpen, type) => {
    setIsLabelDialogOpen(isOpen);
    setTagType(type);
  };

  const TAB_NAME = "survey_tab";

  const handleEmptyState = () => {
    handleDrawer();
    setNameOfDataStream("");
    setDescriptionOfDtaStream("");
    setYearOfAssessment([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    setFunctionLabels([]);
    setLocationLabels([]);
    setValueChainLabels([]);
    handleFileRemove([]);
    dispatch(setFileValidation(false));
    dispatch(setFileError(false));
    dispatch(setErrorMessage(""));
  };

  const handleDataUpload = () => {
    uploadImportFile(
      token,
      selectedOrganization._id,
      "first_template",
      TAB_NAME,
      file[0],
      nameOfDataStream,
      descriptionOfDataStream,
      yearOfAssesment,
      functionalIds,
      locationIds,
      valueChainIds,
      selectedFrameworkId
    );
  };

  const handleCloseDrawer = () => {
    handleEmptyState();
  };

  const handleTagRemove = (index, id) => {
    index === 0
      ? setFunctionLabels((preValue) =>
          preValue.filter((tag) => tag.tag_id !== id)
        )
      : index === 1
      ? setLocationLabels((preValue) =>
          preValue.filter((tag) => tag.tag_id !== id)
        )
      : setValueChainLabels((preValue) =>
          preValue.filter((tag) => tag.tag_id !== id)
        );
  };

  useEffect(() => {
    isFileValid && handleEmptyState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileValid]);

  const handleFrameworkChange = (event) => {
    setSelectedFramework(event.target.value);

    const selectedFramework = frameworks.find(
      (framework) => framework.framework_name === event.target.value
    );

    if (selectedFramework) {
      setselectedFrameworkId(selectedFramework._id);
    }
  };

  return (
    <>
      <DataHubSurveyDatePicker
        handleDatePickerClose={handleDatePickerClose}
        handleDates={handleDates}
        isDatePickerDialogOpen={isDatePickerDialogOpen}
      />
      <DataHubSurveyLabelDialog
        handleLabelDialogClose={handleLabelDialogClose}
        isLabelDialogOpen={isLabelDialogOpen}
        tagType={tagType}
        token={token}
        organization={selectedOrganization}
        addTags={addTags}
        functionalTags={functionalTags}
        locationTags={locationTags}
        valueChainTags={valueChainTags}
        functional={FUNCTIONAL}
        location={LOCATION}
        valueChain={VALUECHAIN}
        // deleteTags={deleteTags}
      />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => handleCloseDrawer()}
        PaperProps={{ style: { background: "#F6F6F6" } }}
      >
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#FFFFFF",
            boxShadow: "none",
            borderRadius: "5px",
            padding: "16px",
            height: "72px",
          }}
        >
          <CardContent style={{ marginRight: "12px" }}>
            <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
              Update Configuration
            </Typography>
          </CardContent>
          <CardActions onClick={() => handleCloseDrawer()}>
            <CloseIcon style={{ color: "#3374B9", cursor: "pointer" }} />
          </CardActions>
        </Card>
        <div
          style={{
            width: "50vw",
            overflowY: "scroll",
            zIndex: "-1",
            height: "80vh",
          }}
        >
          <TextField
            id="outlined-basic"
            margin="normal"
            label="Name of the Data Stream"
            variant="outlined"
            style={{
              borderRadius: " 5px",
              background: " #FFFFFF",
              width: "95%",
              marginLeft: "2%",
            }}
            value={nameOfDataStream.trimStart()}
            onChange={(e) => setNameOfDataStream(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            multiline
            rows={4}
            label="Description of the Data Stream"
            variant="outlined"
            style={{
              borderRadius: " 5px",
              background: " #FFFFFF",
              width: "95%",
              marginLeft: "2%",
            }}
            value={descriptionOfDataStream.trimStart()}
            onChange={(e) => setDescriptionOfDtaStream(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              width: "95%",
              marginLeft: "2%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box my={2} style={{ width: "50%" }}>
              {file.length === 0 ? (
                <>
                  <input
                    name="actUpload"
                    id="contained-button-activityFile"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadHandler(e)}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="contained-button-activityFile">
                    <Box
                      display="flex"
                      component="span"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                    >
                      <AttachFileIcon color="disabled" />
                      <Box
                        mx={2}
                        style={{ borderBottom: "2px solid gray" }}
                        width="100%"
                      >
                        <Typography
                          style={{ fontWeight: 400, fontSize: "11px" }}
                          variant="caption"
                          color="textSecondary"
                        >
                          Upload document (Acceptable Formats: xls,csv)
                        </Typography>
                      </Box>
                    </Box>
                  </label>
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    component="span"
                    alignItems="center"
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <AttachFileIcon color="disabled" />
                    <TextField
                      style={{ width: "100%" }}
                      error={hasFileError}
                      id="standard-text"
                      label={hasFileError && "Error"}
                      value={file[0].name}
                      helperText={hasFileError && errorMessage}
                      color=""
                    />

                    <ClearIcon
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleFileRemove([]);
                        dispatch(setFileValidation(false));
                        dispatch(setFileError(false));
                        dispatch(setErrorMessage(""));
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box
              my={2}
              onClick={() => setIsDatePickerDialogOpen(true)}
              style={{
                border: "1px solid #9A9A9A",
                background: "#FFFFFF",
                padding: "12px",
                borderRadius: "5px",
                cursor: "pointer",
                // height: 45,
                width: "45%",
              }}
            >
              <Typography
                variant="body1"
                name="yearOfAssesment"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {yearOfAssesment[0].startDate !== "" &&
                yearOfAssesment[0].endDate !== ""
                  ? `${moment(new Date(yearOfAssesment[0].startDate)).format(
                      "DD MMM yy"
                    )} - ${moment(new Date(yearOfAssesment[0].endDate)).format(
                      "DD MMM yy"
                    )}`
                  : "Duration of Data"}
                <Icon component={"img"} src={calendar} />
              </Typography>
            </Box>
          </div>

          {/* Select Framework Start */}
          <div
            style={{
              display: "flex",
              width: "95%",
              marginLeft: "2%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                textTransform: "capitalize",
                color: "#242424",
              }}
            >
              Select Framework
            </Typography>
            <TextField
              select
              variant="outlined"
              label="Framework"
              value={selectedFramework}
              onChange={handleFrameworkChange}
              style={{
                borderRadius: "5px",
                background: "#FFFFFF",
                width: "60%",
              }}
            >
              {frameworks?.length > 0 ? (
                frameworks.map((framework) => (
                  <MenuItem
                    key={framework._id}
                    value={framework.framework_name}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={
                          framework.image && framework.image[0]
                            ? framework.image[0]
                            : ""
                        }
                        alt={framework.framework_name}
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      {framework.framework_name}
                    </div>
                  </MenuItem>
                ))
              ) : (
                <Typography>Loading frameworks...</Typography>
              )}
            </TextField>
          </div>
          {/* Select Framework End */}

          {["Functional", "Location", "Value chain"].map((tags, index) => {
            let tagsArray =
              index === 0
                ? functionLabels
                : index === 1
                ? locationLabels
                : valueChainLabels;

            let tagsType =
              index === 0 ? FUNCTIONAL : index === 1 ? LOCATION : VALUECHAIN;
            let defaultThemeBackground =
              index === 0 ? "#EBF1F8" : index === 1 ? "#EBF6EF" : "#FFF9EE";

            let defaultIconTheme =
              index === 0 ? "#3374B9" : index === 1 ? "#32A05F" : "#FFC250";
            return (
              <>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "95%",
                    marginLeft: "2%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                      textTransform: "capitalize",
                      color: "#242424",
                    }}
                  >
                    Add {tags} Tag
                  </Typography>
                  <Button
                    variant="contained"
                    color="default"
                    // className={classes.button}
                    startIcon={<AddIcon color="primary" />}
                    style={{
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                      textTransform: "capitalize",
                      color: "#3374B9",
                      background: " #FFFFFF",
                      borderRadius: "8px",
                      padding: " 8px 16px",
                      width: "30%",
                    }}
                    onClick={() => handleAddTag(true, tagsType)}
                  >
                    Add Tags
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    marginLeft: "2%",
                    padding: "16px 8px 16px 8px",
                    background: "#E9E9E9",
                    borderRadius: "8px",
                    minHeight: "88px",
                    marginTop: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {tagsArray.length > 0 ? (
                    tagsArray.map((data, indexArray) => {
                      return (
                        <div
                          key={indexArray}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "16px",
                            minWidth: "10%",
                            hight: "56%",
                            background: defaultThemeBackground,
                            border: "1px solid #9A9A9A",
                            borderRadius: "8px",
                            marginRight: "7px",
                            marginTop: "7px",
                            // justifyContent: "space-evenly",
                          }}
                          onMouseEnter={() => setLabelIndex(data.tag_id)}
                          onMouseLeave={() => setLabelIndex("")}
                        >
                          <Icon>
                            <Brightness1Icon
                              style={{
                                color: defaultIconTheme,
                              }}
                            />
                          </Icon>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "16px",
                              // lineHeight: "24px",
                              textAlign: "center",
                              textTransform: "capitalize",
                              color: "#15314E",
                              marginLeft: "15px",
                              marginRight: "15px",
                            }}
                          >
                            {data.tag_name}
                          </Typography>
                          <Icon
                            style={{
                              marginLeft: "auto",
                              visibility:
                                data.tag_id === labelIndex
                                  ? "visible"
                                  : "hidden",
                              // visibility: "hidden",
                              transitionDelay: "0.2s",
                              cursor: "pointer",
                            }}
                            onClick={() => handleTagRemove(index, data.tag_id)}
                          >
                            <RemoveCircleIcon
                              style={{
                                color: defaultIconTheme,
                              }}
                            />
                          </Icon>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // padding: "16px",
                        width: "192px",
                        hight: "56%",
                        background: "#E9E9E9",
                        border: "1px solid #9A9A9A",
                        borderRadius: "8px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Icon>
                        <Brightness1Icon color="disabled" />
                      </Icon>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "16px",
                          // lineHeight: "24px",
                          textAlign: "center",
                          textTransform: "capitalize",
                          color: "#9A9A9A",
                        }}
                      >
                        No tags added
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>

        <Card
          style={{
            display: "flex",
            background: "#FFFFFF",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "72px",
            padding: "16px",
            // border: "1px solid red",
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "100%",
            marginTop: "20px",
          }}
        >
          <CardActions>
            <CustomButton
              variant="contained"
              style={{
                boxShadow: "none",
                width: "83px",
                height: "40px",
                borderRadius: "8px",
                background: " #FFFFFF",
                color: "#3374B9",
                textAlign: "center",
                fontWeight: 500,
                fontSize: "14px",
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              onClick={() => handleCloseDrawer()}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{
                width: "178px",
                height: "40px",
                borderRadius: "8px",
                textAlign: "center",
                fontWeight: 500,
                fontSize: "14px",

                textTransform: "capitalize",
                color: "#EBF1F8",
              }}
              disabled={disableButton}
              onClick={() => handleDataUpload()}
            >
              Update Data Stream
            </CustomButton>
          </CardActions>
        </Card>
      </Drawer>
    </>
  );
}

export default React.memo(DataHubSurveyDrawer);
