import {
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RadioButton from '../radioButton/radioButton';
import RadarChart from './RadarChart';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ScrollToTop from '../scrollto/ScrollToTop';
import ScrollToBottm from '../scrollto/ScrollToBottom';

const checkBoxStyles = makeStyles((theme) => ({
  root: {
    '&.Mui-checked': {
      color: '#3374B9',
    },
  },
  checked: {},
}));

function Survey() {
  const classes = checkBoxStyles();
  const [questions, setQuestions] = useState([
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Experience Level',
      id: 1,
      name: 'When do you expect our sector to be significantly impacted by ESG issues?',
      ans: [
        ['Never', 1],
        ['Occurring now', 2],
        ['<1 years', 3],
        ['1-2 years', 4],
        ['3+ years', 5],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Experience Level',
      id: 2,
      name: 'How are new products and services designed and develop within your organization?',
      ans: [
        ['Not in place', 1],
        ['Recently launched', 2],
        ['Partially integrated with business operations', 3],
        ['Well integrated with business operations', 4],
        ['Perceived as industry leading', 5],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Experience Level',
      id: 3,
      name: 'Which of the following best describes how your organisation aligns to recognised frameworks and standards? (e.g. Global Reporting Initiative, Sustainability Accounting Standards Board, UN Sustainable Development Goals (SDGs), etc.)? Please select one…',
      ans: [
        ['Organisation intents to align to these frameworks in the future ', 2],
        [
          'Organisation has started to align in part with reporting frameworks and guidelines',
          3,
        ],
        [
          'Organisation is fully aligned with leading reporting frameworks and guidelines ',
          5,
        ],
        ["Don't know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Materiality',
      id: 4,
      name: 'Does your organisation perform materiality assessment to identify material issues/ topics of importance',
      ans: [
        ['Yes ', 5],
        ['No', 3],
        ["Don't Know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Stakeholder Engagement ',
      catagory2: 'Materiality',
      id: 5,
      name: 'Which of the following best describles how your organisation conducts materiality assessment? Please select one. ',
      ans: [
        [
          'There is an advanced material topic assessment process in place, involving management and other key personnel, atleast annually ',
          5,
        ],
        [
          'There is high level assessment of some material topics undertaken every few years. ',
          4,
        ],
        [
          'A materiality process is undertaken atleast annually with selected stakeholders. ',
          3,
        ],
        ['None of the above ', 2],
        ["Don't know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Stakeholder Engagement ',
      id: 6,
      name: 'Which of the following stakeholders do you engage and consult with on sustainability issues to shape strategy and drive organisational focus? Please select each of the main stakeholders that apply to your organisation. ',
      ans: [
        ['Community ', 1],
        ['Shareholders', 1],
        ['Customers  ', 1],
        ['Suppliers  ', 1],
        ['Investors ', 1],
        ['Employees  ', 1],
        ['Regulators, Government, Policy Makers   ', 1],
        ['NGOs  ', 1],
        ['Others, please specify   ', 1],
        ['None of the above   ', 0],
        ["Don't know", 0],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Stakeholder Engagement',
      id: 7,
      name: 'Which of the following would describe your organisation’s stakeholder engagement? Please select one. ',
      ans: [
        ['There are limited stakeholder engegement processes in place ', 2],
        [
          'Stakeholders are engaged actively (i.e. on a annual basis) and recorded by the organisation on a stakeholder engagement register. ',
          3,
        ],
        ['There are formal stakeholder engagement processes in place ', 4],
        [
          'There is a. firm-wide stakeholder engagement plan in place and implemented. ',
          5,
        ],
        ["Don't Know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Materiality',
      id: 8,
      name: 'Please rate the following statement: Enhancing ESG performance will significantly enhance our brand image and reputation in your sector',
      ans: [
        ['Strongly Disagree ', 1],
        ['Disagree', 2],
        ['Neutral', 3],
        ['Agree', 4],
        ['Strongly Agree', 5],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Materiality',
      id: 9,
      name: 'Please rate the following statement: Our customers have expressed explicit interest in our ESG strategy and our ability to enhance our ESG performance',
      ans: [
        ['Strongly Disagree ', 1],
        ['Disagree', 2],
        ['Neutral', 3],
        ['Agree', 4],
        ['Strongly Agree', 5],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Materiality',
      id: 10,
      name: 'Please rate the following statement: Our customers have expressed explicit interest in our ESG strategy and our ability to enhance our ESG performance',
      ans: [
        ['Strongly Disagree ', 1],
        ['Disagree', 2],
        ['Neutral', 3],
        ['Agree', 4],
        ['Strongly Agree', 5],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'Materiality',
      id: 11,
      name: 'Is there opportunity for the sector to increase collaboration with customers to shape industry-wide ESG initiatives?',
      ans: [
        ['Yes – already occurring ', 5],
        ['Yes – not yet occurring', 3],
        ['No', 1],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'ESG Strategy',
      id: 12,
      name: 'Which of the following statements do you think your organisation’s vision most aligns to? Please select one. ',
      ans: [
        [
          "Organisation's vision considers the vision and interest of a broad range of stakeholders and focuses of the organisation's long tern value (10+ years)",
          5,
        ],
        [
          "Organisation's vision includes intent to deliver positive societal outcomes ",
          4,
        ],
        [
          "Organisation's vision does not consider positive societal outcomes ",
          3,
        ],
        [
          "Organisation's vision has limited intent to deliver positive societal outcomes",
          2,
        ],
        ["Don't Know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'ESG Strategy',
      id: 13,
      name: 'Has your organisation developed a sustainability strategy with clear commitments, targets and objectives related to sustainability?',
      ans: [
        ['Yes ', 5],
        ['No', 3],
        ["Don't Know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: '',
      catagory2: 'ESG Strategy',
      id: 14,
      name: 'Please select the statement that best describes the current level of focus within your organization on improving the sustainability performance of current products and services:',
      ans: [
        [
          'Sustainability is typically only considered on an ad-hoc basis, for specific attributes and / or mostly as per compliance requirements e.g. meeting minimum HSE for products ',
          1,
        ],
        [
          'There is consistent focus on understanding and enhancing sustainability performance (e.g. less carbon intensity, water usage) for key products and services developed',
          3,
        ],
        [
          '‘Sustainability’ is considered one of the key value propositions of the product portfolio. There is relentless focus on improving the sustainability performance of existing product portfolio.',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Environment',
      catagory2: 'Innovation and Growth',
      id: 15,
      name: 'How are new products and services designed and develop within your organization?',
      ans: [
        [
          'No established process to design and develop new sustainable / environmentally-friendly products and services ',
          1,
        ],
        [
          'A well-defined process to generate ideas for new sustainable products and services; to screen, analyse and test ideas, and prototype nradiostainable products which are commercially viable',
          3,
        ],
        [
          'Well-established process and evidence that new products and services are designed to strive towards more sustainable design inline/beyond customer demand',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Environment',
      catagory2: 'Innovation and Growth',
      id: 16,
      name: 'Please select the statement that best describes the current approach within your organization to improve the resource efficiency of core operations (e.g., fuel, power, water, raw materials)',
      ans: [
        [
          'Some programs are in place to reduce the consumption of resources to drive cost savings, these are primarily based on quick wins ',
          1,
        ],
        [
          'There is strategy to systematically reduce consumption of all resources (energy, water and materials)',
          3,
        ],
        [
          'The organization is recognized for its strong focus on reducing resource consumption throughout the value chain and leaders within the organization have remuneration targets linked to smart improvements in resource efficiency',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Environment',
      catagory2: 'Data Management',
      id: 17,
      name: 'Please select Environmental metrics that your company is tracking currently:',
      ans: [
        ['Annual Scope 1 and 2 emissions intensity (CO2 Eq / Revenue) ', 1],
        ['Annual Scope 1, 2 and 3 emissions intensity (CO2 Eq / Revenue)', 1],
        [
          'Energy-Consumption Mix (% of renewables in overall energy consumed)',
          1,
        ],
        ['Renewable energy generation and usage ', 1],
        ['Water Use (Annual Water Consumption in mm3 / revenue)', 1],
        ['Unintended fluids releases (# of incidents)', 1],
        ['Solid Waste Generation (pound / revenue)', 1],
        ['Other (please specify)', 1],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Social',
      catagory2: 'Stakeholder Engagement ',
      id: 18,
      name: 'Please select the statement that best describes the way you identify and engage the different stakeholder groups (communities, governments, employees, contractors etc.) in the areas where you operate:',
      ans: [
        [
          'Stakeholder engagement is typically reactive and in the form of communications in response to specific stakeholder concerns ',
          1,
        ],
        [
          'A good understanding has been developed of stakeholder needs and communication channels have been established for a multilateral, ongoing dialogue that enhances trust amongst different groups',
          3,
        ],
        [
          'There is proactive engagement with stakeholders customised to their needs and expectations. Initiatives are planned and delivered to maximize stakeholder benefits',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Social',
      catagory2: 'Stakeholder Engagement ',
      id: 19,
      name: 'Please select the statement that best describes the extent to which your organization engage with employees and community to enhance credibility and strengthen its social license to grow:',
      ans: [
        [
          'ESG initiatives are basic (e.g. reducing waste to landfill), mostly focused on donations with no active employee engagement and our firm has earned enough credibility in the field to tap into new markets and geographies ',
          1,
        ],
        [
          'ESG initiatives include tracking of I&D metrics and promoting of volunteering and community engagement activities but lack a strategic framework',
          3,
        ],
        [
          'ESG initiatives are included under a strategic program for I&D and volunteering/community activities; this has earned our firm the social license-to-operate and grow in new geographies and markets',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Environment',
      catagory2: 'Supplychain Compliance',
      id: 20,
      name: "To minimise the negative impacts of upstream and downstream business activities, which of the following best describes your organisation's approach? Please select one…",
      ans: [
        [
          'Supplychain/ procurement policy is developed and made available to the suppliers. There is a preference to source from suppliers with certail credentials and meeting other sustainability priorities (eg local suppliers). This is tracked and reported internally.  ',
          3,
        ],
        [
          'Suppliers are selected based on price and ability to meet other operational requirements. ',
          1,
        ],
        [
          'Upstream and downstream impacts are well understood and integrated into business operations and decision making. Supplychain sourcing is aligned with preferred sustainability criteria and is tracked and reported both interbally and externally. ',
          5,
        ],
        ["Don't Know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Social',
      catagory2: 'Supplychain Compliance',
      id: 21,
      name: 'Please select the statement that best describes the focus on supplier engagement programs:',
      ans: [
        [
          'The training / capacity building programs and supply chain related collaborations are only temporary and implemented on an ad-hoc basis ',
          1,
        ],
        [
          'There are planned regular programs & initiatives for suppliers to help improve their ESG performance and overall continuity',
          3,
        ],
        [
          'The focus is on empowering suppliers at the grassroots levels (e.g. sponsoring training / higher education qualifications) to accelerate growth and social development of supplier’s employees leading to enhanced business value',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Social',
      catagory2: 'Supplychain Compliance',
      id: 22,
      name: 'Please select the statement that best describes your practices for building a transparent, human rights compliant value chain:',
      ans: [
        [
          'There is very basic visibility on labor, and environmental standards across suppliers’ value chains (the organization performs supplier audits only to meet regulatory requirements) ',
          1,
        ],
        [
          'There is significant effort to collaborate and engage with suppliers on material disclosure of labor and environmental standards',
          3,
        ],
        [
          'The company works closely with its suppliers to set industry-leading standards for labor and environmental aspects, including material usage (majority of the company’s product portfolio follows full disclosure standards)',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Social',
      catagory2: 'Data Management',
      id: 23,
      name: 'Please select Social metrics that your company is tracking currently:',
      ans: [
        ['% of revenue spent on community programs ', 1],
        ['Workforce diversity ratio (%)', 1],
        ['Training hours per employee', 1],
        ['% of local community grievances resolved within a year', 1],
        ['Annual attrition rate (%)', 1],
        ['Other (please specify)', 1],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Policy and Risk Management',
      id: 24,
      name: 'Please rate the following statement: Enhancing ESG performance will significantly enhance our brand image and reputation in your sector',
      ans: [
        [
          'The Third party undertakes limited level assurance on atleast one indicator annually ',
          2,
        ],
        [
          'The Third party undertakes limited level assurance on two or more indicators and assurance on alignment to reporting with best practice guidelines annually',
          3,
        ],
        [
          'The Third party undertakes reasonable level assurance on selected sustainability performance information. ',
          5,
        ],
        ["Don't know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Stakeholder Engagement ',
      id: 25,
      name: 'Please select the statement that best describes how ESG reporting and performance disclosure is leveraged to build trust across stakeholders:',
      ans: [
        [
          'Focus on reporting and performance disclosure is primarily reactive and to comply with laws and regulations',
          1,
        ],
        [
          'There is a well-defined strategy to use this information to communicate to all relevant stakeholders and build trust aligned with organization’s stated corporate purpose',
          3,
        ],
        [
          'Monitors and tracks ESG performance across industry recognised disclosure standards (e.g., SASB) and is recognized as a leader in advancing the transparency agenda in the OFES sector',
          5,
        ],
        ['Agree', 4],
        ['Strongly Agree', 5],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Policy and Risk Management',
      id: 26,
      name: 'Please rate the following statement: Enhancing ESG performance will significantly enhance our brand image and reputation in your sector',
      ans: [
        [
          'Internal risk mgmt. processes do not have specific ESG risks/risk categories leading to a reactive approach to track non-regulated issues. There is some focus on the identification of new regulations that could impact business, but tracking of non-regulated issues is less formal and reactive',
          1,
        ],

        [
          'The organization has structured processes for identifying and evaluating key risks including those that are not regulated along with structured processes to address these risks and mitigate exposure across multiple scenarios',
          3,
        ],

        [
          'There is a systematic way to scan emerging and latent ESG risks via engagement with intra- and inter-industry peers and partners (e.g., PESA). The organization quantifies value at stake from identified risks and links ESG performance to financial metrics',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Policy and Risk Management',
      id: 27,
      name: 'Please select the statement that best describes your organizations approach to ethics compliance and following a code of conduct:',
      ans: [
        [
          'Employee conduct is governed through rules and systems in a reactive manner based on a written statement of business principles ',
          1,
        ],
        [
          'The statement of business principles on business ethics and culture is embedded and reinforced in leadership decision making',
          3,
        ],
        [
          'All employees are proactively and mandatorily trained on well-defined domains of individual and organizational responsibility encouraging them to make it a way of working',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Innovation and Growth',
      id: 28,
      name: 'How do you enable your leaders to stay up to date with the latest ESG information and trends?',
      ans: [
        [
          'Minimal opportunities to learn and discuss ESG related topics (e.g., ESG aspects not addressed in leadership development programs) ',
          1,
        ],
        [
          'Opportunities to learn and discuss about ESG are offered through conferences and ad-hoc events',
          3,
        ],
        [
          'Regular, dedicated leadership development programs around ESG are offered for C-level and/or top management, e.g. once/twice per year',
          5,
        ],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Incentives',
      id: 29,
      name: 'Please select the statement that best illustrates the level of ESG responsibility within your organization:',
      ans: [
        [
          'C-suite or Management responsible for ESG with limited visibility of topic on board-level ',
          3,
        ],
        [
          'NBoard member(s) responsible for strategic ESG initiatives but plays passive role; CEO and C-Suite responsible for ESG but have no ESG objectives tied to financial compensationo',
          4,
        ],
        [
          'Board members have I&D representation and are responsible to actively drive strategic ESG initiatives; CEO and C-Suite have one or more ESG objectives tied to financial compensation',
          5,
        ],
        ['None of the Above', 2],
        ["Don't Know", 1],
      ],
    },
    {
      type: 'radio',
      catagory1: 'Governance',
      catagory2: 'Incentives',
      id: 30,
      name: 'Please select Governance metrics that your company is tracking currently:',
      ans: [
        ['Percentage of leadership compensation linked to ESG performance ', 1],
        ['Percentage of independent board members', 1],
        ['Inclusion & Diversity mix within board of directors', 1],
        [
          'Frequency of ESG risks discussed in board meetings (Need Basis, Quarterly, Every board Meeting)',
          1,
        ],
        ['Number of ESG risks highlighted by independent audits last year', 1],
        ['Other (please specify)', 1],
      ],
    },
  ]);

  const [collect, setCollect] = useState({});
  const [chartEsgData, setChartEsgData] = useState([]);
  const [chart2EsgData, setChart2EsgData] = useState([]);
  const [displayChart, setDisplayChart] = useState(false);
  const [helperText, setHelperText] = useState('');


  const checkBoxChange = (e, i, j) => {
    const { name, id, checked } = e.target;
    if (checked) {
      if (collect[name]) {
        setCollect({ ...collect, [name]: collect[name] + 1 });
      } else setCollect({ ...collect, [name]: 1 });
    } else {
      if (collect[name]) {
        setCollect({ ...collect, [name]: collect[name] - 1 });
      } else alert('NEGATIVE');
    }
  };

  const addSurvey = (e, i) => {
    const { name, id, checked } = e.target;

    if (questions[i].type === 'radio') {
      setCollect({ ...collect, [name]: id });
    }
  };

  const charting = () => {
    let cat1 = { e: [], s: [], g: [] };
    let cat2 = {
      Experience: [],
      Materiality: [],
      stake: [],
      esg: [],
      innovation: [],
      data: [],
      supply: [],
      policy: [],
      incentives: [],
    };
    if (Object.keys(collect).length > 0) {
      for (let key in collect) {
        if (questions[key - 1].catagory1 === 'Environment') {
          cat1.e.push(collect[key]);
        }

        if (questions[key - 1].catagory1 === 'Social') {
          cat1.s.push(collect[key]);
        }
        if (questions[key - 1].catagory1 === 'Governance') {
          cat1.g.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Experience Level') {
          cat2.Experience.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Materiality') {
          cat2.Materiality.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Stakeholder Engagement') {
          cat2.stake.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'ESG Strategy') {
          cat2.esg.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Innovation and Growth') {
          cat2.innovation.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Data Management') {
          cat2.data.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Supplychain Compliance') {
          cat2.supply.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Policy and Risk Management') {
          cat2.policy.push(collect[key]);
        }
        if (questions[key - 1].catagory2 === 'Incentives') {
          cat2.incentives.push(collect[key]);
        }
      }
      let chartData = [
        cat1.e.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 20,
        cat1.s.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 25,
        cat1.g.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 35,
      ];

      let chart2data = [
        cat2.Experience.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.Materiality.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 30,
        cat2.stake.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 25,
        cat2.esg.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.innovation.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.data.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 10,
        cat2.supply.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.policy.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.incentives.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 10,
      ];
      console.log(chartData);
      console.log(chartData);
      setChartEsgData(chartData);
      setChart2EsgData(chart2data);
      if (Object.keys(collect).length === 30) {
        setDisplayChart(true);
        setHelperText('');
        scrollDown();
      } else if (Object.keys(collect).length < 30) {
        setHelperText('Please complete Answering all the Questions');
      }
    } else {
      setHelperText('Please complete answering all the questions');
    }
  };
  // Scroll 
  useEffect(()=>{
    window.scrollTo({top:0})
  },[])
  const scrollDown = () => {
    setTimeout(() => {
      window.scrollTo({
        top:( document.documentElement.scrollHeight - window.innerHeight)-850,
        behavior: 'smooth',
      });
    }, 700);
  };
  
 
  return (
    <div>
      <Card style={{ padding: '15px', margin: '25px' }}>
        {questions.map((que, i) => {
          return (
            <div key={i}>
              <hr></hr>
              <br />
              <Typography>
                {i + 1}) {que.name}
              </Typography>
              <FormControl on component="fieldset">
                <FormLabel component="legend">
                  {que.catagory1 === '' ? null : (
                    <Chip label={que.catagory1} size="small" />
                  )}{' '}
                  <Chip label={que.catagory2} size="small" />
                </FormLabel>
                <RadioGroup
                  name={que.id + ''}
                  // value={value}
                  // onChange={(e) => {
                  //   addSurvey(e, i);
                  // }}
                >
                  {que.ans.map((ans, j) => {
                    return (
                      <div key={j}>
                        <FormControlLabel
                          value={j + ''}
                          control={
                            que.type === 'check' ? (
                              <Checkbox
                                className={clsx(classes.root, classes.checked)}
                                onChange={(e) => {
                                  checkBoxChange(e, i, j);
                                }}
                                name={que.id + ''}
                                id={ans[1] + ''}
                              />
                            ) : (
                              <RadioButton id={ans[1] + ''} />
                            )
                          }
                          label={ans[0]}
                          onChange={(e) => {
                            addSurvey(e, i);
                          }}
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
              </FormControl>
              <br />
              <br />
            </div>
          );
        })}
        <span
          style={{ color: 'red', fontSize: 'large', paddingBottom: '25px' }}
        >
          {helperText}
        </span>
        <br /> <br />
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{charting()}}>Submit</Button>
        <ScrollToTop />
        <ScrollToBottm />
      </Card>
      <div>
        {displayChart && (
          <>
            <Paper>
              <RadarChart data1={chartEsgData} data2={chart2EsgData}  /> 
            </Paper>
          </>
        )}
      </div>
    </div>
  );
}

export default Survey;
