import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextfield from "../../UI/textfield/textfield";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  Grid: { marginTop: 20 },
  Typography: {
    fontWeight: "600",
    color: "#3374B9",
  },
  CustomTextfield: { backgroundColor: "white" },
  input: { display: "none" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});

export default function ReportTypeOne(props) {
  const {
    surveyData,
    surveyResultData,
    fieldData,
    metricId,
    index,
    currentDetailedMetric,
  } = props;

  const classes = useStyles();
  const [typeOneData, setTypeOneData] = useState([]);
  const refer = useRef(null);
  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");
  useEffect(() => {
    if (currentDetailedMetric.metrics_data.hasOwnProperty("comment")) {
      setMetricComment(currentDetailedMetric.metrics_data.comment);
    }
    if (
      currentDetailedMetric.metrics_data.hasOwnProperty("file") &&
      currentDetailedMetric.metrics_data.file !== ""
    ) {
      setMetricFile(currentDetailedMetric.metrics_data.file);
    }
  }, [currentDetailedMetric]);

  useEffect(() => {
    return () => {
      setMetricFile(null);
    };
  }, [fieldData.currentMetric]);
  useEffect(() => {
    if (surveyResultData) {
      setTypeOneData(surveyResultData);
    } else {
      const data = surveyData.rows.map((col, idx) => {
        return {
          row: col,
          data: "",
        };
      });
      setTypeOneData(data);
    }
  }, [fieldData.currentMetric, surveyResultData, surveyData]);

  return (
    <>
      {typeOneData.map((data, i) => (
        <Grid
          container
          alignItems="center"
          flexDirection="row"
          display="flex"
          spacing={2}
          key={i}
          className={classes.Grid}
        >
          <Grid item xs={12}>
            <Typography className={classes.Typography}>{data.row}</Typography>
            <CustomTextfield
              name={`Text_type1${i}`}
              margin="dense"
              variant="outlined"
              className={classes.CustomTextfield}
              value={typeOneData[i].data}
              fullWidth
              rows={3}
              multiline
              inputRef={refer}
              disabled
            />
          </Grid>
        </Grid>
      ))}
      <Box>
        <Box my={2}>
          <Typography className={classes.blueText}>Comments</Typography>
          <CustomTextfield
            name={`Comment_type1${metricId}`}
            label="Additional Comment"
            fullWidth
            multiline
            rows={3}
            size="small"
            variant="outlined"
            value={metricComment}
            disabled
          />
        </Box>
        <Box my={2}>
          {metricFile === null ? (
            <>
              <input
                id={metricId}
                type="file"
                index={index}
                disabled
                className={classes.input}
              />
              <label htmlFor={metricId}>
                <Box display="flex" component="span" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box1} width="100%">
                    <Typography variant="caption" color="textSecondary">
                      Upload document
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <AttachFileIcon color="disabled" />
                <Box mx={2} className={classes.Box2} width="100%">
                  <Typography variant="caption" color="primary">
                    {metricFile.name}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
