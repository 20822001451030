import { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";

// icons
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import CalendarIcon from "@material-ui/icons/DateRange";
import AttachFileIcon from "@material-ui/icons/AttachFile";

// comps
import { appTheme } from "../../riskAssessment/brmDetaails";
import CustomTextfield from "components/UI/textfield/textfield";
import { UserCard } from "components/widgets/riskAssessment/riskUtilComponents";
import TabPanel from "../../../UI/tabPannel/tabPannel";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  requestFileSupplier,
  viewSupplierStakeHolder,
} from "actions/brm/BrmActions";
import { BlueBackgroundCard } from "components/widgets/surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import CustomButton from "components/UI/button/button";
import YearRangePicker from "./YearRangePicker";
import YearPickerDialog from "./YearPickerDialog";

const FileUploadDrawer = ({
  classes,
  setIsRequestUpload,
  setIsUploadDrawerOpen,
  isUploadDrawerOpen,
  isrequestUpload,
}) => {
  // from redux
  const dispatch = useDispatch();
  const { token, selectedOrganization, supplier_id, current_organisation } =
    useSelector((state) => state.login);
  const { members, supplierOrganizationDetails } = useSelector(
    (state) => state.brmReducer
  );

  // refs
  const inputRef = useRef(null);

  // states
  const [requestTabNo, setRequestTabNo] = useState(0);
  const [userIds, setUserIds] = useState([]);
  // States for categories
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [yearPicker, setYearPicker] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState({});

  const [searchText, setSearchText] = useState("");
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [fileData, setFileData] = useState({});

  // -------------- inner functions --------------
  const handleAssignUser = () => {
    const outputArray = Object.keys(categoryData).map((key) => {
      return {
        category: categoryData[key].category,
        year: categoryData[key].year,
      };
    });

    dispatch(
      requestFileSupplier(
        token,
        supplierOrganizationDetails?._id,
        selectedOrganization?._id,
        userIds,
        outputArray
      )
    );
    setCategoryData({});
    setCategories([]);
    setIsRequestUpload(false);
    setIsUploadDrawerOpen(false);
    // setUserIds([]);
  };

  const handleRangeData = (rangeData) => {
    handleCategoryChange(selectedCategoryIndex, "year", rangeData);
  };

  const handleCheckboxChange = (id) => {
    const isSelected = userIds.includes(id);

    if (!isSelected) {
      setUserIds([...userIds, id]);
    } else {
      setUserIds(userIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleAddCategory = () => {
    setCategories((prevCategories) => [...prevCategories, {}]);
  };

  const handleCategoryChange = (index, key, value) => {
    setCategoryData((prevData) => ({
      ...prevData,
      [index]: {
        ...prevData[index],
        [key]: value,
      },
    }));
  };

  const toggleYearPicker = () => {
    setYearPicker(!yearPicker);
  };

  const handleFileUpload = (e) => {
    const uploadFile = Object.values(e.target.files);
    // console.log(uploadFile);
    uploadFile !== undefined &&
      setFileData((prev) => ({
        ...prev,
        file: uploadFile[0],
      }));
  };
  // -------------------------------------------------

  useEffect(() => {
    if (isUploadDrawerOpen) {
      dispatch(
        viewSupplierStakeHolder(
          token,
          supplierOrganizationDetails?._id || supplier_id,
          current_organisation
        )
      );

      setRequestTabNo(0);
      setUserIds([]);
      setCategories([]);
    }
  }, [isUploadDrawerOpen]);

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      onClose={() => {
        setIsRequestUpload(false);
        setIsUploadDrawerOpen(false);
      }}
      // variant="persistent"
      width="lg"
      open={isUploadDrawerOpen}
    >
      <div style={{ width: "500px", padding: 10 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
          >
            {isrequestUpload ? "Request File" : "Upload File"}
          </Typography>
          <Typography
            onClick={() => {
              setIsRequestUpload(false);
              setIsUploadDrawerOpen(false);
            }}
            style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
          >
            <CloseIcon style={{ cursor: "pointer" }} />
          </Typography>
        </div>
        {isrequestUpload ? (
          <div>
            <MuiThemeProvider theme={appTheme}>
              <AppBar
                position="static"
                color="inherit"
                className={classes.AppBar}
              >
                <Tabs
                  value={requestTabNo}
                  onChange={(e, newVal) => {
                    setRequestTabNo(newVal);
                    // setReportsTab(0);
                  }}
                >
                  <Tab
                    disableRipple
                    className={classes.tab}
                    label={
                      <span className={classes.tabLabel}>Participants</span>
                    }
                  />
                  <Tab
                    disableRipple
                    disabled={userIds.length === 0 ? true : false}
                    className={classes.tab}
                    label={
                      <span className={classes.tabLabel}>Document Type</span>
                    }
                  />
                </Tabs>
              </AppBar>
            </MuiThemeProvider>
            <TabPanel value={requestTabNo} index={0}>
              <div style={{ marginTop: 40 }}>
                <CustomTextfield
                  name="Search"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="text"
                  label="Search user by name or email Id or employee id"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{ color: "#3374B9" }} />
                      </InputAdornment>
                    ),
                  }}
                  // value={searchCompanyText}
                />

                {members
                  .filter(
                    (m) =>
                      m.name.includes(searchText) ||
                      m.email.includes(searchText)
                  )
                  .map((member) => (
                    <UserCard
                      key={member?._id}
                      member={{
                        name: member?.name,
                        email: member?.email,
                        role: member?.role,
                        surveySentStatus: member?.survey_sent,
                      }}
                      isChecked={userIds.includes(member?._id)}
                      onClick={() => handleCheckboxChange(member?._id)}
                    />
                  ))}
              </div>
            </TabPanel>
            <TabPanel value={requestTabNo} index={1}>
              <div style={{ marginTop: 40 }}>
                <BlueBackgroundCard heading="Click on add categories and select the required document type and the year." />

                {categories.map((_, index) => (
                  <Grid container key={index} style={{ margin: "1.5em 0" }}>
                    <Grid md={6} style={{}}>
                      <CustomTextfield
                        name={`category-${index}`}
                        value={categoryData[index]?.category}
                        label="Select Type of Document"
                        variant="outlined"
                        size="small"
                        fullWidth
                        select
                        onChange={(e) =>
                          handleCategoryChange(
                            index,
                            "category",
                            e.target.value
                          )
                        }
                      >
                        {[
                          "Annual Report",
                          "ESG Report",
                          "GRI Report",
                          "XBRL",
                          "Other",
                        ].map((cat) => (
                          <MenuItem key={cat} value={cat}>
                            {cat}
                          </MenuItem>
                        ))}
                      </CustomTextfield>
                    </Grid>
                    <Grid md={6}>
                      <CustomTextfield
                        name={`selectYear-${index}`}
                        value={categoryData[index]?.year}
                        label={!categoryData[index]?.year && "Select Year"}
                        variant="outlined"
                        style={{ marginLeft: "1em", cursor: "pointer" }}
                        InputProps={{
                          style: {
                            pointerEvents: "none",
                          },
                          endAdornment: (
                            <CalendarIcon style={{ color: "#3374B9" }} />
                          ),
                        }}
                        size="small"
                        onClick={() => {
                          setSelectedCategory(categoryData[index]);
                          setSelectedCategoryIndex(index);
                          toggleYearPicker();
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                <CustomButton
                  color="primary"
                  style={{ textTransform: "none" }}
                  onClick={handleAddCategory}
                >
                  Add Category
                </CustomButton>
              </div>
            </TabPanel>

            {/* submit button for Request File */}
            <CustomButton
              color={"primary"}
              variant="text"
              style={{
                position: "fixed",
                bottom: 10,
                right: 160,
                cursor: "pointer",
                textTransform: "capitalize",
                fontSize: "14px",
                padding: 10,
              }}
              onClick={() => {
                setIsRequestUpload(false);
                setIsUploadDrawerOpen(false);
              }}
            >
              Back
            </CustomButton>
            <Button
              variant={"contained"}
              color="primary"
              className={classes.endButton}
              disabled={
                !categoryData ||
                JSON.stringify(categoryData).length === 2 ||
                !categories ||
                categories.length === 0
              }
              onClick={() => {
                handleAssignUser();
              }}
            >
              Assign to Users
            </Button>
          </div>
        ) : (
          <div>
            <FormControl
              variant="outlined"
              style={{ width: "100%", margin: "15px 0px" }}
            >
              <InputLabel htmlFor="age-native-helper">
                Select File Type
              </InputLabel>{" "}
              <Select
                fullWidth
                size="small"
                label="Select Type Of Report"
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setFileData((prev) => ({
                    ...prev,
                    type: value,
                  }));
                }}
                // input={<OutlinedInput />}
              >
                {[
                  "Annual Report",
                  "ESG Report",
                  "GRI Report",
                  "XBRL",
                  "Other",
                ].map((item) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                      style={{ backgroundColor: "white" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box
              my={2}
              onClick={() => setIsDatePickerDialogOpen(true)}
              style={{
                border: "1px solid #00000050",
                padding: 12,
                borderRadius: 5,
                cursor: "pointer",
                minHeight: 45,
              }}
            >
              {fileData.year ? (
                <Typography variant="body1" name="yearOfAssesment">
                  {`${fileData.year.split("-")[0]} - ${
                    fileData.year.split("-")[1]
                  }`}
                </Typography>
              ) : (
                <Typography
                  style={{ color: "#878282e6", fontFamily: "Roboto" }}
                >
                  Year Of Assessment
                </Typography>
              )}
            </Box>
            {fileData?.type === "XBRL" ? (
              <TextField
                style={{ margin: "15px 0px" }}
                fullWidth
                variant="outlined"
                placeholder="XBRL URL"
                onChange={(e) => {
                  setFileData((prev) => ({
                    ...prev,
                    file: e.target.value,
                  }));
                }}
              />
            ) : (
              <Box
                width="100%"
                style={{
                  margin: "26px 5px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                <AttachFileIcon
                  style={{ backgroundColor: "#ced4da", padding: 4 }}
                  color="disabled"
                />
                <input
                  name="actUpload"
                  id="contained-button-activityFile"
                  type="file"
                  accept=".pdf"
                  ref={inputRef}
                  onChange={(e) => handleFileUpload(e)}
                  style={{ display: "none" }}
                  multiple
                />
                <div
                  style={{
                    borderBottom: "2px solid #ced4da",
                    marginLeft: 20,
                    width: "100%",
                  }}
                >
                  {fileData?.file?.name ? fileData.file.name : "Upload file"}
                </div>
              </Box>
            )}
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Source URL"
              style={{ margin: "15px 0px" }}
              onChange={(e) => {
                setFileData((prev) => ({
                  ...prev,
                  sourceUrl: e.target.value,
                }));
              }}
            />

            <Typography
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                cursor: "pointer",
                textTransform: "none",
                color: "#3374b9",
                marginRight: "5px",
                fontSize: "14px",
                padding: 10,
              }}
              onClick={() => {
                setFile((prev) => {
                  let data = [...prev];
                  data.push(fileData?.file);
                  return data;
                });

                setIsUploadDrawerOpen(false);
                setFileData("");
              }}
            >
              Upload file
            </Typography>
          </div>
        )}
      </div>

      <YearRangePicker
        open={yearPicker}
        onClose={toggleYearPicker}
        currentRange={selectedCategory?.year}
        handleRangeData={handleRangeData}
        singleYear={true}
      />

      <YearPickerDialog
        isDatePickerDialogOpen={isDatePickerDialogOpen}
        setIsDatePickerDialogOpen={setIsDatePickerDialogOpen}
        fileData={fileData}
        setFileData={setFileData}
      />
    </Drawer>
  );
};

export default FileUploadDrawer;
