import {
  Typography,
  Select,
  withStyles,
  InputBase,
  MenuItem,
  TextField,
  styled,
  Card,
  Button,
  Chip,
} from "@material-ui/core";
import React from "react";
import CustomTextfield from "../../../UI/textfield/textfield";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { OutlinedInput } from "@material-ui/core";
import { CubeProvider } from "@cubejs-client/react";
import ChartRenderer from "../ChartRenderedDashboard";
import { useSelector } from "react-redux";

export const CustomInput = withStyles((theme) => ({
  root: {
    "label + &": {
      // marginTop: theme.spacing(3),
      color: "red",
    },
  },
  input: {
    height: 20,
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "8px 20px 8px 10px",
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Poppins"].join(","),
    // '&:focus': {
    //   borderRadius: 4,
    //   borderColor: '#80bdff',
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    // },
  },
}))(InputBase);
const ChartCard = styled(Card)`
  border-radius: 4px;
  border: none;
`;
// const Empty = styled.div`
//   text-align: center;
//   margin-top: 105px;
//   margin-bottom: 155px;
// `;
function PreviewCharts({
  vizState,
  selectedTab,
  setAddChart,
  isQueryPresent,
  query,
  cubejsApi,
  chartType,
  pivotConfig,
  props,
  backgroundColor,
  borderColor,
  barChartCustomize,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
  lineChartCustomize,
  pieChartCustomize,
  measures,
  dimensions,
  orderMembers,
  timeDimensions,
}) {
  const BasicPreview = () => {
    return (
      <div>
        <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Preview with the selected configuration.
        </Typography>
      </div>
    );
  };

  const MeasurePreview = () => {
    // console.log('measure',vizState, query);
    return (
      <div>
        <Typography
          style={{
            fontSize: 13,
            paddingBottom: 5,
            fontWeight: 400,
            color: "#AFAFAF",
          }}
        >
          Selected Measures
        </Typography>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
          {measures.map((m, i) => (
            <Chip key={i} label={m.shortTitle} />
            // <div
            //   style={{
            //     fontSize: 13,
            //     padding: 3,
            //     borderRadius: 8,
            //     fontWeight: 400,
            //     backgroundColor: "#d5d5d5",
            //   }}
            // >
            //   {m.title}
            // </div>
          ))}
          {/* <CustomTextfield
          name="SusTeamMsg"
          variant="outlined"
          fullWidth
          rows={3}
          size="small"
          label="Measure value"
          value={measures[0].shortTitle}
          // onChange={(e) =>
          //   setSustainibilityTeamMessage(e.target.value)
          // }
        /> */}
        </div>
      </div>
    );
  };

  const DimentionPreview = () => {
    // console.log('dimensiuon',vizState, query, timeDimensions);
    return (
      <div>
        <Typography
          style={{
            fontSize: 13,
            paddingBottom: 5,
            fontWeight: 400,
            color: "#AFAFAF",
          }}
        >
          Selected Dimentions
        </Typography>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
          {dimensions.map((m) => (
            <Chip label={m.shortTitle} />
            // <div
            //   style={{
            //     fontSize: 13,
            //     padding: 3,
            //     borderRadius: 8,
            //     fontWeight: 400,
            //     backgroundColor: "#d5d5d5",
            //   }}
            // >
            //   {m.title}
            // </div>
          ))}
          {/* <CustomTextfield
        name="SusTeamMsg"
        variant="outlined"
        fullWidth
        rows={3}
        size="small"
        label="Measure value"
        value={measures[0].shortTitle}
        // onChange={(e) =>
        //   setSustainibilityTeamMessage(e.target.value)
        // }
      /> */}
        </div>
      </div>
    );
  };

  const ChartTypePreview = () => {
    const { current_organisation } = useSelector((state) => state.login);
    // const [runQuery, setRunQuery] = React.useState(false);

    // const handleRunQuery = (isQueryPresent) => {
    //   if (!isQueryPresent) {
    //     setRunQuery(isQueryPresent);
    //   }

    //   setAddChart(isQueryPresent);

    //   return isQueryPresent;
    // };

    // const handleRunQueryButton = (isQueryPresent) => {
    //   if (isQueryPresent) {
    //     setRunQuery(true);
    //   }
    // };

    // console.log(vizState, query);

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {/* <Typography style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}>
          Measure Value is the chart’s Measure based on the Etc Etc... Select
          from the given list.
        </Typography> */}

        <div>
          <ChartCard style={{ minHeight: 380 }}>
            <ChartRenderer
              vizState={{
                query: {
                  ...query,
                  // timeDimensions: query.hasOwnProperty("timeDimensions")
                  //   ? query?.timeDimensions.length > 0
                  //     ? [
                  //         {
                  //           dimension: `${
                  //             query?.measures[0].split(".")[0]
                  //           }.date`,
                  //           granularity: "quarter",
                  //         },
                  //       ]
                  //     : []
                  //   : [],
                  filters: [
                    {
                      member: `${
                        query?.measures[0].split(".")[0]
                      }.organisationid`,
                      operator: "equals",
                      values: [current_organisation],
                    },
                  ],
                },
                chartType,
              }}
              // cubejsApi={cubejsApi}
              // chartHeight={380}
              // isQueryPresent={isQueryPresent}
              // validatedQuery={props}
              pivotConfig={pivotConfig}
              backgroundColor={backgroundColor}
              borderColor={borderColor}
              // barChartCustomize={barChartCustomize}
              // chartX_AxisCustomize={chartX_AxisCustomize}
              // chartY_AxisCustomize={chartY_AxisCustomize}
              lineChartCustomize={lineChartCustomize}
              // pieChartCustomize={pieChartCustomize}
              // run="run"
              // redraw={chartType}
            />
          </ChartCard>

          {/* {handleRunQuery(isQueryPresent) && runQuery ? (
            <>
              <ChartCard style={{ minHeight: 440 }}>
                <CubeProvider cubejsApi={cubejsApi}>
                  <ChartRenderer
                    vizState={{ query, chartType }}
                    cubejsApi={cubejsApi}
                    chartHeight={400}
                    isQueryPresent={isQueryPresent}
                    validatedQuery={props}
                    pivotConfig={pivotConfig}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    barChartCustomize={barChartCustomize}
                    chartX_AxisCustomize={chartX_AxisCustomize}
                    chartY_AxisCustomize={chartY_AxisCustomize}
                    lineChartCustomize={lineChartCustomize}
                    pieChartCustomize={pieChartCustomize}
                    run="run"
                  />
                </CubeProvider>
              </ChartCard>
            </>
          ) : (
            <div>
              <h3>Choose a measure or dimension to get started</h3>
              {isQueryPresent && (
                <Button
                  style={{ marginTop: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleRunQueryButton(isQueryPresent)}
                >
                  Run Chart
                </Button>
              )}
            </div>
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        border: "1px solid #EBF1F8",
        width: "40%",
        backgroundColor: "#FBFBFB",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px",
          borderBottom: "1px solid #EBF1F8",
        }}
      >
        <Typography style={{ fontSize: 13, fontWeight: 600 }}>
          Create charts
        </Typography>
      </div>
      <div
        className="scroll"
        style={{
          height: "82vh",
          display: "flex",
          flexDirection: "column",
          gap: 8,
          padding: 8,
        }}
      >
        <BasicPreview />
        {selectedTab >= 2 && <MeasurePreview />}
        {selectedTab >= 3 && <DimentionPreview />}
        {selectedTab >= 4 && <ChartTypePreview />}
        {/* <Select
          fullWidth
          input={<CustomInput />}
          IconComponent={ExpandMoreIcon}
          label="Search Measure Value"
        >
       <MenuItem disabled value="">
            <em>Placeholder</em>
          </MenuItem> 
          {["Assending", "Descending"].map((numb, i) => (
            <MenuItem value={numb} key={i}>
              {numb}
            </MenuItem>
          ))}
        </Select> */}
      </div>
    </div>
  );
}

export default PreviewCharts;
