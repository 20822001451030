import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import CustomTextfield from "./../../UI/textfield/textfield";
import ProfilePlaceholder from "../../../images/ProfilePlaceholder.png";
import "../../../styles/userProfileImage.css";
import "../../../styles/settingScroll.css";
import CustomButton from "../../UI/button/button";
import profileDetailsFormStyles from "./profileDetailsFormStyles";

export default function ProfileDetailsForm(props) {
  const classes = profileDetailsFormStyles();

  const {
    generalSettings,
    login,
    viewUserProfile,
    setUserName,
    setUserPosition,
    setUserImage,
    setUserContact,
    setUserDistrict,
    setUserLocation,
    setUserPincode,
    setUserState,
    upodateUserProfile,
  } = props;

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 40,
    height: 40,
    backgroundColor: "#fff",
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (generalSettings.userImage) {
      setImageURL(generalSettings.userImage.url);
    } else {
      setImageURL(ProfilePlaceholder);
    }
  }, [generalSettings.userImage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    viewUserProfile(login.token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ImageUpload = ({ onChange, src }) => {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <SmallAvatar>
            <label htmlFor="photo-upload" className={classes.fileUpload}>
              <Icon name="editProfileImg" style={{ color: "#000" }}>
                edit
              </Icon>
              <input id="photo-upload" type="file" onChange={onChange} />
            </label>
          </SmallAvatar>
        }
      >
        <Avatar className={classes.Avatar} alt="user profile" src={src} />
      </Badge>
    );
  };

  const handleUploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    setUserImage(file);

    reader.onloadend = () => {
      setImageURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Card variant="outlined" className={classes.Card}>
        <CardContent
          className="settingScroll"
          style={{ padding: 100, paddingTop: 50, height: "80vh" }}
        >
          {/* Username and Image */}
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.Typography}>
                {generalSettings.userName}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={5}>
                <ImageUpload src={imageURL} onChange={handleUploadImage} />
              </Grid>
            </Grid>
          </Grid>

          {/* Name and Position */}
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Box my={2}>
                <CustomTextfield
                  name="fullName"
                  variant="outlined"
                  fullWidth
                  label="Full Name"
                  size="small"
                  value={generalSettings.userName}
                  error={generalSettings.userName === ""}
                  helperText={
                    generalSettings.userName === "" &&
                    "Name should not be empty"
                  }
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  name="position"
                  variant="outlined"
                  fullWidth
                  label="Position"
                  size="small"
                  value={generalSettings.userPosition}
                  onChange={(e) => {
                    setUserPosition(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          {/* Contact Info*/}
          <Grid container className={classes.Grid}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.Typography1}>
                Contact Info
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Box my={2}>
                <CustomTextfield
                  variant="outlined"
                  fullWidth
                  label="Location"
                  name="location"
                  size="small"
                  value={generalSettings.userLocation}
                  onChange={(e) => {
                    setUserLocation(e.target.value);
                  }}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  variant="outlined"
                  fullWidth
                  label="District"
                  name="district"
                  size="small"
                  value={generalSettings.userDistrict}
                  onChange={(e) => {
                    setUserDistrict(e.target.value);
                  }}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  variant="outlined"
                  fullWidth
                  label="Contact No."
                  name="contactNumber"
                  size="small"
                  type="number"
                  value={generalSettings.userContact}
                  onChange={(e) => {
                    setUserContact(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box my={2}>
                <CustomTextfield
                  variant="outlined"
                  fullWidth
                  label="State"
                  name="state"
                  size="small"
                  value={generalSettings.userState}
                  onChange={(e) => {
                    setUserState(e.target.value);
                  }}
                />
              </Box>
              <Box my={2}>
                <CustomTextfield
                  variant="outlined"
                  fullWidth
                  label="Pin Code"
                  name="pincode"
                  size="small"
                  type="number"
                  value={generalSettings.userPincode}
                  onChange={(e) => {
                    setUserPincode(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.Card1}>
        <CardContent className={classes.CardContent}>
          <CustomButton
            name="saveBtn"
            variant="contained"
            color="primary"
            className={classes.CustomButton}
            disabled={generalSettings.userName === ""}
            onClick={() => {
              upodateUserProfile(
                login.token,
                generalSettings.userName,
                generalSettings.userPosition,
                {
                  location: generalSettings.userLocation,
                  state: generalSettings.userState,
                  district: generalSettings.userDistrict,
                  pincode: generalSettings.userPincode,
                  contact_no: generalSettings.userContact,
                },
                generalSettings.userImage
              );
            }}
          >
            Save
          </CustomButton>
        </CardContent>
      </Card>
    </>
  );
}
