export const SET_IMPORT_FILE_LIST = "SET_IMPORT_FILE_LIST";
export const SET_FILE_PROCESSING_PROGRESS = "SET_FILE_PROCESSING_PROGRESS";
export const SET_FUNCTIONAL_TAGS = "SET_FUNCTIONAL_TAGS";
export const SET_LOCATION_TAGS = "SET_LOCATION_TAGS";
export const SET_VALUE_CHAIN_TAGS = "SET_VALUE_CHAIN_TAGS";
export const SET_IMPORT_FILE_DATA = "SET_IMPORT_FILE_DATA";
export const SET_CALCULATED_DATA = "SET_CALCULATED_DATA";
export const SET_DATA_HUB_CHARTS = "SET_DATA_HUB_CHARTS";
export const SET_DATA_PROCESSING_STATUS = "SET_DATA_PROCESSING_STATUS";
export const SET_ALL_FILE_PROCESSING_STATUS = "SET_ALL_FILE_PROCESSING_STATUS";
export const SET_CLEAR_CHARTS = "SET_CLEAR_CHARTS";
export const SET_SELECTED_TOPIC = "SET_SELECTED_TOPIC";
export const SET_SELECTED_KPI_TYPE = "SET_SELECTED_KPI_TYPE";
export const SET_BASELINE_VALUE = "SET_BASELINE_VALUE";
export const SET_TARGETLINE_VALUE = "SET_TARGETLINE_VALUE";
export const SET_KPI_DRAWER_STATE = "SET_KPI_DRAWER_STATE";
export const SET_SUGGESTED_DATA = "SET_SUGGESTED_DATA";
export const SET_AUTOFILL = "SET_AUTOFILL";
export const SET_FILE_VALIDATION = "SET_FILE_VALIDATION";
export const SET_FILE_ERROR = "SET_FILE_ERROR";
export const SET_FILE_ERROR_MESSAGE = "SET_FILE_ERROR_MESSAGE";
export const HAS_PUBLIS_ERROR = "HAS_PUBLIS_ERROR";
export const SET_OVERLAPPING_FILES = "SET_OVERLAPPING_FILES";
export const SET_PUBLISH_STATUS = "SET_PUBLISH_STATUS";
export const SET_GENERAL_TAB_DATA = "SET_GENERAL_TAB_DATA";
export const SET_GENERAL_TAB_REPORT_DATA = "SET_GENERAL_TAB_REPORT_DATA";
export const SET_SELECTED_ASSESSMENT = "SET_SELECTED_ASSESSMENT";
export const SET_GENERAL_TAB_TASK_DATA = "SET_GENERAL_TAB_TASK_DATA";
export const SET_CHART_DATA = "SET_CHART_DATA";
export const SET_SELECTED_TAB_CHART_DATA = "SET_SELECTED_TAB_CHART_DATA";
export const SET_INACTIVE_CHART_DATA = "SET_INACTIVE_CHART_DATA";
export const SET_NEW_LAYOUT = "SET_NEW_LAYOUT";

export const SET_FRAMEWORKS = "SET_FRAMEWORKS";
