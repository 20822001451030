import React, { useEffect } from "react";

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Question } from "../../../../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import { useDispatch, useSelector } from "react-redux";
import { setApprovedAnswerData } from "../../../../../../actions/supplierAdmin/supplierAdminActions";
import QuestionBody from "components/widgets/surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

function UserDetailsWithRole({ role, userList = [] }) {
  return (
    <Box style={{ display: "flex", gap: 30 }}>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          width: "20%",
        }}
      >
        {role}
      </Typography>
      <List
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        {userList.map((user) => (
          <ListItem
            key={user?.id}
            role={undefined}
            dense={true}
            button
            //   selected={value?.isCheck}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              flex: 1,
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt={user?.profile_img?.name || `Avatar n°${1}`}
                src={user?.profile_img?.url}
              />
            </ListItemAvatar>
            <ListItemText
              // id={labelId}
              primary={user?.name}
              secondary={user?.email}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function ExploreQuestion() {
  const { approvedAnswerData } = useSelector((state) => state?.supplierAdmin);
  const dispatch = useDispatch();

  const {
    approved_data,
    admins,
    approvers,
    contributors,
    question,
    question_type,
    answers,
    comment,
    comment_value,
  } = approvedAnswerData || {};
  useEffect(() => {
    return () => {
      dispatch(setApprovedAnswerData({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UserDetailsWithRole role={"Admin"} userList={admins} />
      <UserDetailsWithRole role={"Approver"} userList={approvers} />
      <UserDetailsWithRole role={"Contributer"} userList={contributors} />
      <Question
        question={question}
        options={answers}
        type={question_type}
        submittedAnswer={approved_data?.answer}
        hideWeightage
      />

      {comment && (
        <QuestionBody
          questionType={"Comment box"}
          questionId={question?.question_id}
          answers={approved_data?.comment_value}
          isFrom={"questionComment"}
          comment_value={approved_data?.comment_value}
          removeEditOption
        />
      )}
    </>
  );
}

export default ExploreQuestion;
