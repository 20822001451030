import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ActivityLog from "../../widgets/reportListing/activityLog";
import ESGReport from "../../widgets/reportListing/esgReport";
import { Link, useHistory } from "react-router-dom";
import { useSelector ,useDispatch} from "react-redux";
import { getAssignedMaterialityTopics } from "../../../actions/materialityAction/materialityAction";
import { getAssignedTopics } from "../../../actions/esgStrategy/esgStrategyAction";

function SingleESGReport(props) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: 300,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 300,
      marginTop: 77,
      display: "flex",
      alignItems: "center",
      scrollbarWidth: "none",
      paddingBottom: 100,
    },
    drawerHeader: {
      backgroundColor: "#EBF1F8",
      width: "100%",
      padding: "20px 0px 50px 10px",
      marginTop: 20,
    },
  }));

  const classes = useStyles();
  const { login, location, viewActivityLog, getReportDataTask } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  let etc = {
    pathname: "/report_listing/esg_report/single_report",
    state: {
      step_no: 5,
      assigned_by: "61e53debda306d000b0eddf7",
      role: [1],
      title: "reportlisting test",
    },
    search: "",
    hash: "",
    key: "cff2lm",
  };
  useEffect(() => {
    getReportDataTask(
      login.token,
      location.state.assignment_id,
      location.state.role[0] === 0 ? false : true
    );
    viewActivityLog(
      login.token,
      location.state.assignment_id,
      location.state.step_no
    );
  }, []);
	useEffect(() => {
		// dispatch(getAssignedMaterialityTopics(login.token, location.state.assignment_id));
		dispatch(getAssignedTopics(login.token, location.state.assignment_id));
	}, [login.token, location.state.assignment_id]);
  const { surveyListing } = useSelector((state) => state);
  return (
    <div style={{ backgroundColor: "#F5F6F8" }}>
      <Grid container>
        <Grid
          item
          xs={9}
          style={{ display: "flex", alignItems: "center", paddingLeft: 80 }}
        >
          {props.location.state.fromDashboard ? (
            <IconButton
              style={{ marginRight: 20 }}
              onClick={() => {
                // history.goBack();
                history.push("/dashboard_static");

              }}
            >
              <ArrowBackIcon style={{ boxSizing: "initial" }} />
            </IconButton>
          ) : (
            <Link
              to={{
                pathname: "/report_listing/esg_report",
                state: {
                  assessment_id: props.location.state.assignment_id,
                  assessment_name: props.location.state.assessment_name,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <IconButton style={{ marginRight: 20 }}>
                <ArrowBackIcon style={{ boxSizing: "initial" }} />
              </IconButton>
            </Link>
          )}

          <Typography variant="h6">
            <b>
              {location.state.assessment_name === ""
                ? "Assessment-" +
                  location.state.assignment_id.replace(/\w(?=\w{4})/g, "")
                : location.state.assessment_name}{" "}
            </b>
          </Typography>
          <ChevronRightIcon
            style={{ color: "green", padding: "0 10px", boxSizing: "initial" }}
          />
          <Typography variant="h6"> {location.state.title} </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ color: "#77787B" }} variant="body2">
            Sent by
          </Typography>
          <Typography variant="body1">
            {props.location.state.assigned_by.name}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ paddingTop: 15 }}>
          <MoreVertIcon style={{ cursor: "pointer" }} onClick={toggleDrawer} />
          {
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="right"
              open={isDrawerOpen}
              close={toggleDrawer}
              variant="persistent"
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={{ backgroundColor: "#EBF1F8", padding: 20 }}
                  >
                    <Grid item md={1} xs={1} style={{ paddingTop: 5 }}>
                      <ArrowBackIcon
                        onClick={toggleDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                    <Grid item md={10} xs={9}>
                      <Typography variant="h6" style={{ marginLeft: "15px" }}>
                        Activity Log
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#828282",
                        }}
                      >
                        Click on listings to check the members
                      </Typography>
                    </Grid>
                    <Grid item md={1} xs={2}>
                      <CloseIcon
                        onClick={toggleDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>

                    <Grid item>
                      {surveyListing.activityLog.length > 0 ? (
                        surveyListing.activityLog.map((log, i) => {
                          return (
                            <Typography
                              style={{
                                marginTop: i !== 0 ? 15 : "0",
                                fontSize: 14,
                              }}
                            >
                              <b style={{ fontSize: 14 }}>&#62;&#62;</b>
                              {log.activity}
                            </Typography>
                          );
                        })
                      ) : (
                        <Typography style={{ fontSize: 14 }}>
                          No Activity
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Drawer>
          }
        </Grid>
      </Grid>
      
      <ESGReport {...props} />
    </div>
  );
}
export default React.memo(SingleESGReport);
