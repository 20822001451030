import { Box, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const DrawerHeader = ({ closeDrawer }) => {
  return (
    //  Header options
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 0px",
        boxShadow: "0px 4px 4px #3374B926",
      }}
    >
      <Typography style={{ fontWeight: "600", marginLeft: "20px" }}>
      Create Supplier portfolio
      </Typography>
      <IconButton style={{ marginRight: "20px" }} onClick={closeDrawer}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
export default DrawerHeader;
