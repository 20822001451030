import React from "react";
import CustomCheckBox from "../checkBox/checkBox";
import { Typography } from "@material-ui/core";

function ThreeColorPallet({
  index = 0,
  checked = false,
  handleCheckBox,
  colors = ["#D6DFA1", "#A1DFAF", "#A1BFDF"],text
}) {
  return (
    <div
      style={{
        width: "35%",
        height: "68%",
        border: "1px solid #EBF1F8",
        borderRadius: 8,
        // paddingTop: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          alignContent: "center",

          justifyContent: "space-evenly",
          backgroundColor: "#FBFBFB",
          paddingTop: 8,
        }}
      >
        <div
          name="ColorPallet"
          style={{
            width: "50px",
            height: "60px",
            border: "none",
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: colors[0],
          }}
        />
        <div
          name="ColorPallet"
          style={{
            width: "50px",
            height: "60px",
            border: "none",
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: colors[1],
          }}
        />
        <div
          name="ColorPallet"
          style={{
            width: "50px",
            height: "60px",
            border: "none",
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: colors[2],
            position: "relative",
          }}
        >
          <CustomCheckBox
            checked={checked}
            color="secondary"
            onClick={handleCheckBox}
            style={{ position: "absolute", right: '-5px',top:'-5px' }}
          />
        </div>
      </div>
      <Typography style={{ fontSize: 13, fontWeight: 500, padding: "5px" }}>
        {text}
        {/* Color Pallete {index + 1} */}
      </Typography>
    </div>
  );
}

export default ThreeColorPallet;
