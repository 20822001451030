import { Box } from "@material-ui/core";
import CustomButton from "../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization } from "../../../../actions/brm/BrmActions";

const DrawerFooter = ({ companyDetails, closeDrawer, setCompanyDetails }) => {
  const dispatch = useDispatch();
  const { current_organisation, token } = useSelector((state) => state.login);
  const {
    isin,
    supplierName,
    supplierWebsite,
    sector_id,
    industry_id,
    country_code,
    vendorWebsite,
    operation,
    no_of_employees,
    address,
    revenue,
    turnover,
    vendorLogo,
  } = companyDetails;

  const handleCreateSupplierOrg = () => {
    dispatch(
      createOrganization(
        token,
        current_organisation,
        isin,
        supplierName,
        supplierWebsite,
        sector_id,
        industry_id,
        country_code,
        vendorWebsite,
        operation,
        no_of_employees,
        address,
        revenue.map((r) => ({ ...r, revenue: parseInt(r.revenue) })),
        turnover.map((r) => ({ ...r, turnover: parseFloat(r.turnover) })),
        vendorLogo
      )
    );
    closeDrawer();
    setCompanyDetails({
      vendorName: "",
      vendorType: "",
      vendorWebsite: "",
      vendorLogo: "",
      address: [{ landmark: "", state: "" }],
      country_name: "",
      country_code: "",
      sector_id: "",
      industry_id: "",
      isin: "",
      supplierName: "",
      supplierWebsite: "",
      operation: "",
      no_of_employees: 0,
    });
  };

  return (
    //  Header options
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "10px 10px",
        gap: 20,
      }}
    >
      <CustomButton onClick={closeDrawer} variant="text" color="primary">
        Cancel
      </CustomButton>
      <CustomButton
        disabled={
          !isin || !country_code || !sector_id || !industry_id || !supplierName // !isSectorSelected()
        }
        variant="contained"
        color="primary"
        onClick={handleCreateSupplierOrg}
      >
        Create Supplier
      </CustomButton>
    </Box>
  );
};
export default DrawerFooter;
