import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import CustomCheckBox from "../../../../UI/checkBox/checkBox";
import CustomButton from "../../../../UI/button/button";

const SectorDialog = ({
  sectors,
  setSectors,
  isSectorDialogOpen,
  setIsSectorDialogOpen,
  sectorChipStyle,
  setCompanyDetails,
}) => {
  const [expanded, setExpanded] = useState(false);

  // --------------
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    let sectorL = JSON.parse(JSON.stringify(sectors));
    ////////////////
    //  comment this code to select multiple sectors & industries
    sectorL = sectorL.map((sect, i) => {
      // if (sectorIndex !== i) {
      sect.industry_data.map((ind, j) => {
        if (sectorIndex === i && industryIndex === j) {
          ind.isSelected = true;
          setCompanyDetails((prev) => ({
            ...prev,
            sector_id: sect?._id,
            industry_id:ind?._id,
          }));
        } else {
          ind.isSelected = false;
        }
        return ind;
      });
      // }
      return sect;
    });
    ///////////////////

    // sectorL[sectorIndex].industry_data[industryIndex].isSelected =
    //   !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    // console.log('sectorL',sectorL);
    setSectors(sectorL);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={isSectorDialogOpen}
      onClose={() => setIsSectorDialogOpen(false)}
    >
      <DialogTitle>
        <Grid
          container
          justifyContent="center"
          style={{ borderBottom: "1px solid #DADBE6" }}
        >
          <Grid item xs={4}>
            <Typography
              style={{
                paddingBottom: 5,
                borderBottom: "3px solid green",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Sector & Industry Selection
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ width: 900 }}>
          <Grid item xs={6}>
            <Typography>Select Sectors</Typography>
            <div style={{ marginTop: 15 }}>
              {sectors.map((sector, index) => (
                <Accordion
                  expanded={expanded === `panel1${index}`}
                  onChange={handleChange(`panel1${index}`)}
                  name={sector.Sector}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography style={{ fontWeight: 600 }} id={sector.Sector}>
                      {sector.Sector}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {sector.industry_data.map((industry, index2) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onChange={() => handleIndustryChange(index2, index)}
                        >
                          {/* {industry.isSelected} */}
                          <CustomCheckBox
                            key={industry.industry + index2}
                            checked={industry.isSelected ? true : false}
                            name={industry.Industry}
                          />
                          <Typography id={industry.Industry}>
                            {industry.Industry}
                          </Typography>
                        </div>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography>Selected</Typography>
            <Card
              variant="outlined"
              style={{ minHeight: 350, width: "95%", marginTop: 15 }}
            >
              <CardContent>
                {sectors.map((sector, index) => (
                  <div style={{ marginBottom: 8 }}>
                    {sector.industry_data.some((inds) => inds.isSelected) && (
                      <>
                        <Typography style={{ color: "#77787B", fontSize: 14 }}>
                          {sector.Sector}
                        </Typography>
                        {sector.industry_data.map(
                          (industry, index2) =>
                            industry.isSelected && (
                              <Chip
                                style={sectorChipStyle}
                                onDelete={() =>
                                  handleIndustryChange(index2, index)
                                }
                                deleteIcon={
                                  <RemoveCircleOutlinedIcon
                                    name={industry.Industry}
                                    // style={{display: "none"}}
                                  />
                                }
                                label={industry.Industry}
                              />
                            )
                        )}
                      </>
                    )}
                  </div>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => setIsSectorDialogOpen(false)}
          variant="outlined"
          color="primary"
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={() => {
            setIsSectorDialogOpen(false);
          }}
          variant="contained"
          color="primary"
        >
          Complete
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
export default SectorDialog;
