import React, { memo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: 7,
  },
})(MuiTableCell);
const useStyles = makeStyles({
  statusCell: {
    padding: '7px 2px',
  },
});

const SingleMetricReport = (props) => {
  const {
    metric,
    index,
    setFinalData,
    assessment_id,
    login,
    getdetailedMetrics,
  } = props;
  const classes = useStyles();
  return (
    <>
      {metric.sub_metrics_data.length > 0 ? (
        <>
          <TableRow>
            <TableCell component="th" scope="row">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '42px',
                  height: '30px',
                  padding: '5px',
                  border: 'none',
                  backgroundColor: '#EBF1F8',
                  color: '#3374B9',
                  borderRadius: '5px',
                }}
              >
                <div
                  style={{
                    border: '1px solid #3374B9',
                    backgroundColor: '#3374B9',
                    margin: '2px',
                    borderRadius: '5px',
                    width: '3px',
                    height: '18px',
                    fontWeight: 'bold',
                  }}
                ></div>
                <div>
                  <Typography style={{ padding: '2px 5px 2px 2px' }}>
                    {index + 1}
                  </Typography>
                </div>
              </div>
            </TableCell>
            <TableCell style={{ fontSize: '13px' }} colSpan={2}>
              {metric.metric_name}
              {metric.metric_desc}
            </TableCell>
            {/* <TableCell></TableCell> */}
            {/* <TableCell align="center"> */}
            {/* <IconButton
                size="small"
                onClick={() =>
                  setFinalData({ open: true, index: index, data: props })
                }
              >
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton> */}
            {/* </TableCell> */}
          </TableRow>

          {metric.sub_metrics_data.map((subMetric, subIndex) => (
            <TableRow>
              <TableCell
                component="th"
                style={{ textAlign: 'center' }}
                scope="row"
              >
                {index + 1}
                {`(${subMetric.metric_name.split(':')[0]})`}
              </TableCell>
              <TableCell style={{ fontSize: '13px' }}>
                {subMetric.metric_name.split(':')[1]}
                {subMetric.metric_desc}
              </TableCell>
              <TableCell className={classes.statusCell}>
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor={subMetric.in_progress ? '#EBF6EF' : '#E9E9E9'}
                  // p={1}
                  style={{
                    width: subMetric.is_completed
                      ? '100px'
                      : subMetric.is_approved
                      ? '100px'
                      : subMetric.is_awaiting_approval
                      ? '120px'
                      : subMetric.in_progress
                      ? '100px'
                      : '120px',
                    padding: '5px',
                  }}
                  borderRadius="8px"
                >
                  <Box
                    borderRadius="50%"
                    height={10}
                    width={10}
                    bgcolor={
                      subMetric.is_completed
                        ? '#5BB37F'
                        : subMetric.is_approved
                        ? '#5BB37F'
                        : subMetric.is_awaiting_approval
                        ? '#EBD947'
                        : subMetric.in_progress
                        ? '#EBD947'
                        : '#9FA1AF'
                      // subMetric.is_completed
                      //   ? subMetric.is_approved
                      //     ? '#5BB37F'
                      //     : '#EBD947'
                      //   : '#9FA1AF'
                    }
                    // color={

                    //   subMetric.is_completed
                    //     ? subMetric.is_approved
                    //       ? '#5BB37F'
                    //       : '#15314E'
                    //     : '#9FA1AF'
                    // }
                  />
                  <Box
                    style={{
                      paddingLeft: subMetric.is_completed
                        ? '8px'
                        : subMetric.is_approved
                        ? '8px'
                        : subMetric.is_awaiting_approval
                        ? '2px'
                        : subMetric.in_progress
                        ? '8px'
                        : '2px',
                      fontSize: '10px',
                      color: subMetric.is_completed
                        ? '#5BB37F'
                        : subMetric.is_approved
                        ? '#5BB37F'
                        : 'inherit',
                    }}
                  >
                    {subMetric.is_completed
                      ? 'Completed'
                      : subMetric.is_approved
                      ? 'Approved'
                      : subMetric.is_awaiting_approval
                      ? 'Awaiting Approval'
                      : subMetric.in_progress
                      ? 'In progress'
                      : 'Yet to be Assigned'}
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="center" style={{ padding: 0 }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    getdetailedMetrics(
                      login.token,
                      subMetric._id,
                      assessment_id
                    );
                    setFinalData({
                      open: true,
                      index: index,
                      data: props,
                      metric: subMetric,
                      subIndex: subIndex,
                    });
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (
        <TableRow>
          <TableCell component="th" scope="row">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '42px',
                height: '30px',
                padding: '5px',
                border: 'none',
                backgroundColor: '#EBF1F8',
                color: '#3374B9',
                borderRadius: '5px',
              }}
            >
              <div
                style={{
                  border: '1px solid #3374B9',
                  backgroundColor: '#3374B9',
                  margin: '2px',
                  borderRadius: '5px',
                  width: '3px',
                  height: '18px',
                  fontWeight: 'bold',
                }}
              ></div>
              <div>
                <Typography style={{ padding: '2px 5px 2px 2px' }}>
                  {index + 1}
                </Typography>
              </div>
            </div>
          </TableCell>
          <TableCell style={{ fontSize: '13px' }}>
            {metric.metric_name}
            {metric.metric_desc}
          </TableCell>
          <TableCell className={classes.statusCell}>
            <Box
              display="flex"
              alignItems="center"
              // justifyContent='space-evenly'
              bgcolor={metric.in_progress ? '#EBF6EF' : '#E9E9E9'}
              // p={1}
              style={{
                width: metric.is_completed
                  ? '100px'
                  : metric.is_approved
                  ? '100px'
                  : metric.is_awaiting_approval
                  ? '120px'
                  : metric.in_progress
                  ? '100px'
                  : '120px',

                padding: '5px',
              }}
              borderRadius="8px"
            >
              <Box
                borderRadius="50%"
                height={10}
                width={10}
                bgcolor={metric.is_completed
                ? '#5BB37F'
                : metric.is_approved
                ? '#5BB37F'
                : metric.is_awaiting_approval
                ? '#EBD947'
                : metric.in_progress
                ? '#EBD947'
                : '#9FA1AF'}
                
              />
              <Box
                style={{
                  paddingLeft: metric.is_completed
                    ? '8px'
                    : metric.is_approved
                    ? '8px'
                    : metric.is_awaiting_approval
                    ? '2px'
                    : metric.in_progress
                    ? '8px'
                    : '2px',
                  fontSize: '10px',
                  color: metric.is_completed
                    ? '#5BB37F'
                    : metric.is_approved
                    ? '#5BB37F'
                    : 'inherit',
                  textAlign: 'center',
                }}
              >
                {metric.is_completed
                  ? 'Completed'
                  : metric.is_approved
                  ? 'Approved'
                  : metric.is_awaiting_approval
                  ? 'Awaiting Approval'
                  : metric.in_progress
                  ? 'In progress'
                  : 'Yet to be Assigned'}
              </Box>
            </Box>
          </TableCell>
          <TableCell align="center" style={{ pading: 0 }}>
            <IconButton
              size="small"
              onClick={() => {
                getdetailedMetrics(login.token, metric._id, assessment_id);
                setFinalData({
                  open: true,
                  index: index,
                  data: props,
                  metric: metric,
                  subIndex: 0,
                });
              }}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default memo(SingleMetricReport);
