import React, { useState } from "react";
import logo from "../../../images/dashboardImg.svg";
import beginerLarge from "../../../images/beginerLarge.png";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import DashboardTables from "./dashboardTables";
import materialityAssessmentRightStyles from "../../widgets/materialityAssessmentRight/materialityAssessmentRightStyles";
import HighCharts from "highcharts/highcharts";
import HightChartsReact from "highcharts-react-official";
import graph from "../../../images/graph.png";
import { Line, Bar, Pie } from "react-chartjs-2";
import LinearWithValueLabels from "./LinearProgress";
import ChartRenderer from "./ChartRenderedDashboard";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralTabData } from "../../../actions/datahubActions/datahubActions";
import blueBag from "../../../images/dashboardIcons/blueBag.png";
import blueKey from "../../../images/dashboardIcons/blueKey.png";
import blueSpeedo from "../../../images/dashboardIcons/blueSpeedo.png";
import blueStrike from "../../../images/dashboardIcons/blueStrike.png";
import blueAir from "../../../images/dashboardIcons/blueAir.png";
import blueWater from "../../../images/dashboardIcons/blueWater.png";
import blueWastage from "../../../images/dashboardIcons/blueWastage.png";
import dataIcon from "../../../images/dashboardIcons/dataIcon.png";
import reportIcon from "../../../images/dashboardIcons/reportIcon.png";

const titleTypo = {
  fontSize: "20px",
  fontWeight: 600,
  color: "#15314E",
  paddingLeft: 10,
};
const blueTitleTypo = { fontSize: "14px", fontWeight: 600, color: "#2E6AA8" };
const contentTypo = { fontSize: "16px", fontWeight: 400, color: "#15314E" };
const blackTitleTypo = { fontSize: "14px", fontWeight: 600, color: "#15314E" };

const materialTableHead = ["Position", "Topic Name", "Relevance"];

const materialTabeBody = [
  { first: "1", second: "Economic Performance", third: "up" },
  {
    first: "2",
    second: "Asset integrity and critical incident management",
    third: "up",
  },
  { first: "3", second: "Indirect Economic Impacts", third: "up" },
  { first: "4", second: "Materials", third: "up" },
  {
    first: "5",
    second: "Climate adaptation, resilience, and transition",
    third: "up",
  },

  { first: "6", second: "GHG Emissions", third: "up" },
  { first: "7", second: "Rights of Indigenous Peoples", third: "up" },
  { first: "8", second: "Materials", third: "up" },
];

const esgTableHead = ["Key Performance Indicator", "Basline", "Target", "Unit"];

const esgTableBody = [
  {
    first: "Energy demand and consumption.",
    second: "111",
    third: "133",
    fourth: "KV",
  },
  {
    first: "Water demand and consumption.",
    second: "102",
    third: "116",
    fourth: "Tons",
  },
  {
    first:
      "Greenhouse gas emissions (scopes 1, 2, and 3) intensity and reduction",
    second: "17",
    third: "1",
    fourth: "%",
  },
  {
    first:
      "Resource efficiency metrics energy, water, and other material resource",
    second: "12",
    third: "2",
    fourth: "%",
  },
  {
    first: "Worker health and safety practices",
    second: "11",
    third: "21",
    fourth: "%",
  },

  {
    first: "Board and management diversity metrics",
    second: "18",
    third: "23",
    fourth: "%",
  },
  {
    first: "Diversity and inclusion metrics",
    second: "13",
    third: "5",
    fourth: "%",
  },
];

const esgTableHead2 = ["Activities", "Timeline", "Budget"];

const esgTableBody2 = [
  {
    first: "Carbon emissions",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹1,10,011",
  },
  {
    first: "Deforestation",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹2,10,011",
  },
  {
    first: "Employee gender and diversity",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹1,10,011",
  },
  {
    first: "Data security",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹1,13,030",
  },
  {
    first: "Company sexual harassment policies",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹1,10,011",
  },

  {
    first: "Diversity of board members",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹26,15,100",
  },
  {
    first: "Political contributions",
    second: "20 Aug 22- 20 Aug 23",
    third: "₹5,10,010",
  },
];

const myTaskTableHead = ["Assessment Name", "", ""];

const myTaskTableBody = [
  { first: "BRSR New Check", second: "13th Aug 2023", third: "Contribution" },
  { first: "BRSR New Check", second: "24th Jul 2023", third: "Reviewer" },
  { first: "SASB", second: "13th Aug 2023", third: "Approval" },
  { first: "BRSR New Check", second: "24th Jul 2023", third: "Contribution" },
  { first: "BRSR New Check", second: "24th Jul 2023", third: "Contribution" },
];

const reportsTableHead = ["Report Name", "Progress", ""];

const reportsTableBody = [
  {
    first: "Business Responsibility & Sustainable report (FY-2022)",
    second: "",
    third: "",
  },
  { first: "Maturity Matrix", second: "", third: "Complete" },
  { first: "Materiality Assessment", second: "", third: "Conplete" },
  { first: "ESG Strategy", second: "", third: "Need Approval" },
  { first: "Data Collection", second: "", third: "Need Approval" },
  { first: "Reporting", second: "", third: "Need Approval" },
];

const options = {
  chart: {
    type: "scatter",
    zoomType: "xy",

    plotBackgroundImage: graph,
    style: {
      fontFamily: "Poppins",

      backgroundColor: "#ddd",
    },
  },
  title: {
    text: "",
    display: false,
  },
  credits: {
    enabled: false,
  },

  xAxis: {
    title: {
      text: "Importance to company",
    },
    min: 0.5,
    max: 5,
  },
  yAxis: {
    display: false,
    title: {
      text: "Topic's impact on  Environment/ Social/ Governance",
      display: false,
    },
    min: 0.5,
    max: 5,
    gridLineWidth: 0,
  },
  plotOptions: {
    scatter: {
      showInLegend: false,
      opacity: 0.8,
      jitter: {
        x: 0.04,
        y: 0.05,
      },
      marker: {
        radius: 8,
        symbol: "circle",
        fillColor: "#3374b9",
      },
      tooltip: {
        pointFormat: "{point.x}, {point.y}, {point.topic}",
        headerFormat: "",
      },
    },
  },
  series: [
    {
      data: [10, 5, 2, 6, 4, 1],
    },
  ],
};

const optionsBar = {
  plugins: {
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [10, 20, 50, 10, 10, 20, 50, 10],
      backgroundColor: "#245283",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: "Dataset 2",
      data: [10, 20, 50, 10, 10, 20, 50, 10],
      backgroundColor: "#3374B9",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: "Dataset 3",
      data: [10, 20, 50, 10, 10, 20, 50, 10],
      backgroundColor: "#C0D4E9",
      borderWidth: 1,
      barThickness: 30,
      borderRadius: 5,
    },
  ],
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function GeneralTab() {
  const classes = materialityAssessmentRightStyles();
  const dispatch = useDispatch();
  const { current_organisation, token } = useSelector((state) => state.login);
  const { generalTabData, taskGeneralTab } = useSelector(
    (state) => state.dataHub
  );
  const { assessments } = useSelector((state) => state.surveyListing);

  const [scatterData, setScatterData] = React.useState([]);
  const [emissionYear, setEmissionYear] = React.useState("");
  const [kpiData, setKpiData] = React.useState({});
  const [timeDuration, setTimeDuration] = React.useState("month");

  React.useEffect(() => {
    dispatch(
      getGeneralTabData(token, current_organisation, assessments[0]?._id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    generalTabData?.materiality_map_data?.graph_data?.length > 0
      ? setScatterData(
          generalTabData?.materiality_map_data?.graph_data.map((data) => {
            return {
              x: data.relevance,
              y: data.weightage,
              topic: data.topic_name,
            };
          })
        )
      : setScatterData([]);
  }, [generalTabData?.materiality_map_data?.graph_data]);

  const options = {
    chart: {
      type: "scatter",
      zoomType: "xy",

      plotBackgroundImage: graph,
      style: {
        fontFamily: "Poppins",

        backgroundColor: "#ddd",
      },
    },
    title: {
      text: "",
      display: false,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: "Importance to company",
      },
      min: 0.5,
      max: 5,
    },
    yAxis: {
      display: false,
      title: {
        text: "Topic's impact on  Environment/ Social/ Governance",
        display: false,
      },
      min: 0.5,
      max: 5,
      gridLineWidth: 0,
    },
    plotOptions: {
      scatter: {
        showInLegend: false,
        opacity: 0.8,
        jitter: {
          x: 0.04,
          y: 0.05,
        },
        marker: {
          radius: 8,
          symbol: "circle",
          fillColor: "#3374b9",
        },
        tooltip: {
          pointFormat: "{point.x}, {point.y}, {point.topic}",
          headerFormat: "",
        },
      },
    },
    series: [
      {
        data: scatterData,
      },
    ],
  };

  const emisionMenu = generalTabData.emmission_data
    ? Object.keys(generalTabData?.emmission_data)
    : [];

  let item = {
    id: 0,
    name: "",
    vizState: {
      query: {
        limit: 5000,
        measures: [
          "Env_total_emissions.total_s1",
          "Env_total_emissions.total_s2",
        ],
        order: {
          "Env_total_emissions.total_s1": "desc",
        },
        dimensions: [
          "Env_total_emissions.total_s1_emissions",
          "Env_total_emissions.total_s2_emissions",
        ],
        timeDimensions: [
          {
            dimension: "Env_total_emissions.date",
            granularity: timeDuration,
            dateRange:
              emissionYear === "2023-2024" || emissionYear === ""
                ? ["2023-04-01", "2024-03-30"]
                : ["2022-04-01", "2023-03-30"],
          },
        ],
        filters: [
          {
            member: "Env_total_emissions.organisationid",
            operator: "equals",
            values: [current_organisation],
          },
        ],
      },
      chartType: "bar",
    },
    pivotConfig: {
      x: ["Env_total_emissions.date.month"],
      y: [
        "Env_total_emissions.total_s2_emissions",
        "Env_total_emissions.total_s1_emissions",
        "measures",
      ],
    },
    layout: {
      x: 0,
      y: 16,
      w: 6,
      h: 8,
      minW: 4,
      minH: 8,
    },
  };

  React.useEffect(() => {
    generalTabData.emmission_data && setEmissionYear(emisionMenu[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalTabData.emmission_data]);

  React.useEffect(() => {
    emissionYear && setKpiData(generalTabData.emmission_data[emissionYear]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emissionYear]);

  const maturityScore = generalTabData.overall_maturity_score
    ? generalTabData.overall_maturity_score
    : 0;

  return (
    <div style={{ padding: "10px 15px 10px 10px" }}>
      {/* company info */}
      <div
        style={{
          //   border: "solid red",
          width: "100%",
          padding: "10px 0px",
          display: "flex",
          justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <div
          style={{
            // border: "solid ",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "16%",
          }}
        >
          <img
            src={generalTabData?.logo?.url}
            alt="logo"
            style={{
              width: "90%",
              height: "85%",
              borderRadius: "10px",
              objectFit: "cover",
            }}
          />
        </div>

        <div
          style={{
            // border: "solid ",
            display: "flex",
            flexDirection: "column",
            width: "28%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueBag" src={blueBag} style={{ width: "10%" }} />
            <Typography style={titleTypo}>Company Details</Typography>
          </div>

          <div>
            <Typography style={blueTitleTypo}>Company Name</Typography>
            <Typography style={contentTypo}>
              {generalTabData?.company_name}
            </Typography>
          </div>
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blueTitleTypo}>Sector</Typography>
              <HtmlTooltip
                disableHoverListener={
                  generalTabData?.sectors?.length > 1 ? false : true
                }
                title={
                  generalTabData?.sectors?.length > 0 &&
                  generalTabData?.sectors
                    .slice(1)
                    .map((s) => (
                      <Typography style={{ ...contentTypo }}>{s}</Typography>
                    ))
                }
                placement="bottom-center"
              >
                <Typography style={{ ...contentTypo }}>
                  {generalTabData?.sectors?.length > 0
                    ? `${generalTabData?.sectors[0]} ${
                        generalTabData?.sectors?.length > 1
                          ? `+ ${generalTabData?.sectors?.length - 1}`
                          : ""
                      }`
                    : ""}
                </Typography>
              </HtmlTooltip>
            </div>
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blueTitleTypo}>Industry</Typography>
              <HtmlTooltip
                disableHoverListener={
                  generalTabData?.industries?.length > 1 ? false : true
                }
                title={
                  generalTabData?.industries?.length > 0 &&
                  generalTabData?.industries
                    .slice(1)
                    .map((s) => (
                      <Typography style={{ ...contentTypo }}>{s}</Typography>
                    ))
                }
                placement="bottom-center"
              >
                <Typography style={contentTypo}>
                  {generalTabData?.industries?.length > 0
                    ? `${generalTabData?.industries[0]} ${
                        generalTabData?.industries?.length > 1
                          ? `+ ${generalTabData?.industries?.length - 1}`
                          : ""
                      }`
                    : ""}
                </Typography>
              </HtmlTooltip>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <Typography style={blueTitleTypo}>Company ID</Typography>
              <Typography style={contentTypo}>
                {generalTabData?.company_id}
              </Typography>
            </div>
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blueTitleTypo}>Team members</Typography>
              <Typography style={contentTypo}>
                {generalTabData?.team_members}
              </Typography>
            </div>
          </div>
        </div>

        <div
          style={{
            // border: "solid ",
            display: "flex",
            flexDirection: "column",
            width: "30%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueBag" src={blueKey} style={{ width: "10%" }} />
            <Typography style={titleTypo}>Key Metrics</Typography>
          </div>
          {/* <div>
            <Typography style={blackTitleTypo}>Total no. of topics</Typography>
            <Typography style={contentTypo}>14</Typography>
          </div> */}
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blackTitleTypo}>
                Total no. of topics
              </Typography>
              <Typography style={contentTypo}>
                {generalTabData?.total_no_of_topics}
              </Typography>
            </div>
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blackTitleTypo}>
                Data points collected
              </Typography>
              <Typography style={contentTypo}>
                {generalTabData?.data_points_collected}
              </Typography>
            </div>
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blackTitleTypo}>Total no. of KPI’s</Typography>
              <Typography style={contentTypo}>
                {generalTabData?.total_no_of_kpis}
              </Typography>
            </div>
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blackTitleTypo}>
                Total no. of reports
              </Typography>
              <Typography style={contentTypo}>
                {generalTabData?.total_no_of_reports}
              </Typography>
            </div>
            <div
              style={{
                width: "60%",
              }}
            >
              <Typography style={blackTitleTypo}>
                Total no. of frameworks
              </Typography>
              <Typography style={contentTypo}>
                {generalTabData?.total_no_of_frameworks}
              </Typography>
            </div>
          </div>
        </div>

        <div
          style={{
            // border: "solid ",
            display: "flex",
            flexDirection: "column",
            width: "25%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueBag" src={blueSpeedo} style={{ width: "12%" }} />
            <Typography style={titleTypo}>Maturity Score</Typography>
          </div>
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              //   justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                // border:"solid"
              }}
            >
              <img
                src={beginerLarge}
                alt="env"
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                width: "45%",
              }}
            >
              <Typography style={blackTitleTypo}>
                Overall Maturity score
              </Typography>
              <Typography style={contentTypo}>{`${
                generalTabData.overall_maturity_score
                  ? generalTabData.overall_maturity_score
                  : 0
              }/100`}</Typography>
              <Typography style={blackTitleTypo}>Maturity Level</Typography>

              {maturityScore === 0 && (
                <Typography style={contentTypo}>
                  Share Survey To identify your Score
                </Typography>
              )}

              {maturityScore > 0 && maturityScore < 33.3 && (
                <Typography style={contentTypo}>
                  Beginner, Start progress.
                </Typography>
              )}
              {maturityScore >= 33.3 && maturityScore < 66.6 && (
                <Typography style={contentTypo}>
                  Intermediate, Good progress.
                </Typography>
              )}
              {maturityScore >= 66.6 && (
                <Typography style={contentTypo}>Advanced, Great !</Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 0px" }}>
        <Typography style={titleTypo}>My Key ESG KPI’s</Typography>
      </div>
      <div
        style={{
          display: "flex",
          //   border: "solid red",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // border: "solid",
            width: "24%",
            backgroundColor: "#FEFEFE",
            borderRadius: "8px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={blueStrike} style={{ width: "14%" }} />
            <Typography style={blackTitleTypo}>
              Total Energy Consumption
            </Typography>
          </div>
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography style={titleTypo}>
              {kpiData?.total_energy_consumption}
            </Typography>
            <Typography style={{ ...titleTypo, fontSize: "14px" }}>
              Mt
            </Typography>
            <Typography
              style={{ ...contentTypo, color: "#32A05F", marginLeft: "auto" }}
            >
              {kpiData?.total_energy_consumption_change}% ^
            </Typography>
          </div>
        </div>
        <div
          style={{
            // border: "solid",
            width: "24%",
            backgroundColor: "#FEFEFE",
            borderRadius: "8px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={blueAir} style={{ width: "14%" }} />
            <Typography style={blackTitleTypo}>Air Quality</Typography>
          </div>
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography style={titleTypo}>{kpiData?.air_quality}</Typography>
            <Typography style={{ ...titleTypo, fontSize: "14px" }}>
              Mt
            </Typography>
            <Typography
              style={{ ...contentTypo, color: "#32A05F", marginLeft: "auto" }}
            >
              {kpiData?.air_quality_change}% ^
            </Typography>
          </div>
        </div>
        <div
          style={{
            // border: "solid",
            width: "24%",
            backgroundColor: "#FEFEFE",
            borderRadius: "8px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={blueWater} style={{ width: "14%" }} />
            <Typography style={blackTitleTypo}>Water Usage</Typography>
          </div>
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography style={titleTypo}>{kpiData?.water_usage}</Typography>
            <Typography style={{ ...titleTypo, fontSize: "14px" }}>
              Mt
            </Typography>
            <Typography
              style={{ ...contentTypo, color: "#32A05F", marginLeft: "auto" }}
            >
              {kpiData?.water_usage_change}% ^
            </Typography>
          </div>
        </div>
        <div
          style={{
            // border: "solid",
            width: "24%",
            backgroundColor: "#FEFEFE",
            borderRadius: "8px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={blueWastage} style={{ width: "14%" }} />
            <Typography style={blackTitleTypo}>Wastage</Typography>
          </div>
          <div
            style={{
              //   border: "solid ",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography style={titleTypo}>{kpiData?.wastage}</Typography>
            <Typography style={{ ...titleTypo, fontSize: "14px" }}>
              Mt
            </Typography>
            <Typography
              style={{ ...contentTypo, color: "#32A05F", marginLeft: "auto" }}
            >
              {kpiData?.wastage_change}% ^
            </Typography>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          //   border: "solid",
          width: "100%",
          justifyContent: "space-between",
          // alignItems: "stretch",
          // height: "300px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "#FBFBFB",
            borderRadius: "8px",
            width: "28%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={dataIcon} style={{ width: "36px" }} />
            <Typography style={titleTypo}>Total Emissions</Typography>
          </div>

          <Typography
            style={{ fontSize: "14px", fontWeight: 400, color: "#9A9A9A" }}
          >
            Financial Year
          </Typography>

          <FormControl
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Financial Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={emissionYear}
              onChange={(e) => setEmissionYear(e.target.value)}
              label="Financial Year"
            >
              {emisionMenu.map((e) => (
                <MenuItem value={e}>
                  <Typography>{e}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div
            style={{
              backgroundColor: "#EBF1F8",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "10px",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Typography style={blackTitleTypo}>Total Emission</Typography>
            <Typography style={{ ...blackTitleTypo, marginLeft: "auto" }}>
              {kpiData?.total_emission}
            </Typography>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#76A2D0" }}
            >
              tCO2e
            </Typography>
          </div>

          <div
            style={{
              backgroundColor: "#FBFBFB",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "10px",
              alignItems: "center",
              padding: "20px",
              flexWrap: "wrap",
              border: "1px solid #EBF1F8",
            }}
          >
            <LinearWithValueLabels
              color={"#245283"}
              progress={
                kpiData?.scope_1 &&
                (kpiData?.scope_1 / kpiData?.total_emission) * 100
              }
            />
            <Typography style={blackTitleTypo}>Scope 1</Typography>
            <Typography style={{ ...blackTitleTypo, marginLeft: "auto" }}>
              {kpiData?.scope_1}
            </Typography>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#9A9A9A" }}
            >
              tCO2e
            </Typography>
          </div>

          <div
            style={{
              backgroundColor: "#FBFBFB",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "10px",
              alignItems: "center",
              padding: "20px",
              flexWrap: "wrap",
              border: "1px solid #EBF1F8",
            }}
          >
            <LinearWithValueLabels
              color={"#3374B9"}
              progress={
                kpiData?.scope_2 &&
                (kpiData?.scope_2 / kpiData?.total_emission) * 100
              }
            />
            <Typography style={blackTitleTypo}>Scope 2</Typography>
            <Typography style={{ ...blackTitleTypo, marginLeft: "auto" }}>
              {kpiData?.scope_2}
            </Typography>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#9A9A9A" }}
            >
              tCO2e
            </Typography>
          </div>

          {/* <div
            style={{
              backgroundColor: "#FBFBFB",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "10px",
              alignItems: "center",
              padding: "20px",
              flexWrap: "wrap",
              border: "1px solid #EBF1F8",
            }}
          >
            <LinearWithValueLabels color={"#C0D4E9"} progress={50} />
            <Typography style={blackTitleTypo}>Scope 3</Typography>
            <Typography style={{ ...blackTitleTypo, marginLeft: "auto" }}>
              876.4
            </Typography>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#9A9A9A" }}
            >
              tCO2e
            </Typography>
          </div> */}
        </div>
        <div
          style={{
            backgroundColor: "#FBFBFB",
            borderRadius: "8px",
            width: "70%",
            // alignSelf: "stretch",
            // height: "100%",
            padding: "0px 20px 30px 10px",
          }}
        >
          <div
            style={{
              padding: "10px 0px 0px 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={dataIcon} style={{ width: "36px" }} />
            <Typography style={titleTypo}>Reduced Over Time</Typography>
            <FormControl
              variant="outlined"
              style={{ marginLeft: "auto", width: "20%" }}
              size="small"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Time Duration
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={timeDuration}
                onChange={(e) => setTimeDuration(e.target.value)}
                label="Time Duration"
              >
                <MenuItem value={"month"}>Month</MenuItem>
                <MenuItem value={"week"}>Week</MenuItem>
                <MenuItem value={"day"}>Day</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              height: "70%",
              marginTop: "10px",
              // border:"solid",
              // display:"flex"
            }}
          >
            <ChartRenderer
              vizState={item?.vizState}
              pivotConfig={item?.pivotConfig}
              backgroundColor={item?.chartColors?.backgroundColors}
              borderColor={item?.chartColors?.borderColor}
              pieChartCustomize={item?.label}
              chartY_AxisCustomize={item?.chartY_AxisCustomize}
              baseline={item?.baseline}
              targetline={item?.targetline}
              inGeneralTab={true}
            />
            {/* <Bar options={optionsBar} data={data} /> */}
          </div>
        </div>
      </div>

      <div
        style={{ padding: "10px 0px", display: "flex", alignItems: "center" }}
      >
        <img alt="blueStrike" src={dataIcon} style={{ width: "36px" }} />
        <Typography style={titleTypo}>Materiality Topics</Typography>
      </div>
      <div
        style={{
          padding: "10px 0px",
          display: "flex",
          // border: "solid",
          justifyContent: "space-between",
          alignItems: "flex-start",
          // height:"300px"
        }}
      >
        <div style={{ width: "49%" }}>
          <DashboardTables
            head={materialTableHead}
            body={
              generalTabData.materiality_topics_data
                ? generalTabData.materiality_topics_data
                : {}
            }
          />
        </div>
        <div
          style={{ width: "49%", backgroundColor: "#FBFBFB", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div item xs={3} sm={3}>
              <Typography className={classes.blueText}>
                Last updated Now
              </Typography>
            </div>
            <div item xs={9} sm={9}>
              <div>
                <div
                  item
                  sm={8}
                  md={7}
                  lg={8}
                  className={classes.updateCountContainer}
                >
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Typography className={classes.Typography7}>
                      {generalTabData?.materiality_map_data?.responded}
                    </Typography>
                    <Typography className={classes.tableHeaderText}>
                      Responded
                    </Typography>
                  </div>
                  <div className={classes.divider2}></div>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Typography className={classes.Typography7}>
                      {generalTabData?.materiality_map_data?.total_participants}
                    </Typography>
                    <Typography className={classes.tableHeaderText}>
                      participants
                    </Typography>
                  </div>
                </div>

                {/* <div item sm={4} md={5} lg={4}>
                  <div className={classes.activeContainer}>
                    <Typography className={classes.activeText}>
                      Survey Active
                    </Typography>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                backgroundImage: "url(" + graph + ")",
                backgroundPosition: "center",
                backgroundSize: "75% 70%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <HightChartsReact highcharts={HighCharts} options={options} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ padding: "10px 0px", display: "flex", alignItems: "center" }}
      >
        <img alt="blueStrike" src={dataIcon} style={{ width: "36px" }} />
        <Typography style={titleTypo}>ESG Plan</Typography>
      </div>

      <div
        style={{
          padding: "10px 0px",
          display: "flex",
          //   border: "solid",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div style={{ width: "49%" }}>
          <DashboardTables
            head={esgTableHead}
            body={generalTabData.kpis ? generalTabData.kpis : {}}
          />
        </div>
        <div style={{ width: "49%" }}>
          <DashboardTables
            head={esgTableHead2}
            body={generalTabData.activities ? generalTabData.activities : {}}
          />
        </div>
      </div>

      <div
        style={{
          padding: "10px 0px",
          display: "flex",
          //   border: "solid",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "49%",
            // backgroundColor:"#FBFBFB"
          }}
        >
          <div
            style={{
              padding: "10px 0px 10px 10px",
              border: "1px solid #C0D4E9",
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: "#FBFBFB",
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            <img alt="blueStrike" src={dataIcon} style={{ width: "30px" }} />
            <Typography style={titleTypo}>My Tasks</Typography>
          </div>
          <div
            style={
              {
                //   padding: "10px 0px",
                //   display: "flex",
                //   border: "solid",
                //   justifyContent: "space-between",
                //   alignItems: "flex-start",
              }
            }
          >
            <div style={{ width: "100%" }}>
              <DashboardTables
                head={myTaskTableHead}
                body={taskGeneralTab ? taskGeneralTab : {}}
                hasAccordian={true}
                type="Task"
                token={token}
                // apis={viewMemberAssign}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "49%",
            // backgroundColor:"#FBFBFB"
          }}
        >
          <div
            style={{
              padding: "10px 0px 10px 10px",
              border: "1px solid #C0D4E9",
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: "#FBFBFB",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="blueStrike" src={reportIcon} style={{ width: "30px" }} />
            <Typography style={titleTypo}>Reports</Typography>
          </div>
          <div
            style={
              {
                //   padding: "10px 0px",
                //   display: "flex",
                //   border: "solid",
                //   justifyContent: "space-between",
                //   alignItems: "flex-start",
              }
            }
          >
            <div style={{ width: "100%" }}>
              <DashboardTables
                head={reportsTableHead}
                body={
                  generalTabData.report_data_assessments
                    ? generalTabData.report_data_assessments
                    : {}
                }
                hasAccordian={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralTab;
