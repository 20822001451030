import {
  TableCell,
  TableContainer,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Textfield from "components/UI/textfield/textfield";
import DynamicTable from "components/UI/dynamicTable/DynamicTable";
import AnswersTable from "./RegularTableComps/AnswersTable";

const RegularTable = ({ submittedAnswer, headers }) => {
  const columnNames = headers?.map((header) => ({
    name: header.header,
    type: header.type,
  }));

  console.log(submittedAnswer);

  if (submittedAnswer && submittedAnswer.length > 0) {
    return (
      <AnswersTable
        submittedAnswer={submittedAnswer}
        headers={headers}
        columnNames={columnNames}
      />
    );
  }

  return (
    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
      <DynamicTable headings={columnNames}>
        {headers?.map((header, index) => (
          <TableCell key={index} style={{ minWidth: 150 }}>
            {header.type === "Document" ? (
              <Box
                display="flex"
                component="span"
                alignItems="center"
                style={{ cursor: "pointer" }}
              >
                <AttachFileIcon color="disabled" />
                <Box
                  mx={2}
                  style={{ borderBottom: "2px solid gray" }}
                  width="100%"
                >
                  <Typography variant="caption" color="textSecondary">
                    Document
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Textfield
                color="primary"
                variant="outlined"
                size="small"
                label={header.type}
                type={
                  header.type === "Number"
                    ? "number"
                    : header.type === "Email"
                    ? "email"
                    : "text"
                }
                style={{ width: "100%", pointerEvents: "none" }}
                InputProps={{ readOnly: true }}
              />
            )}
          </TableCell>
        ))}
      </DynamicTable>
    </TableContainer>
  );
};

export default RegularTable;
