import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CustomTextfield from "../../UI/textfield/textfield";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grid: { paddingTop: 20 },
  TableCell: { minWidth: 100 },
  CustomTextfield: { width: "100%" },
  grid1: { marginTop: 20 },
  input: { display: "none" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});

export default function ReportTypeTwo(props) {
  const {
    surveyData,
    surveyResultData,
    fieldData,
    metricId,
    index,
    currentDetailedMetric,
    // parentId
  } = props;

  const classes = useStyles();
  const [rows] = useState(["row1"]);
  const [typeTwoData, setTypeTwoData] = useState([]);

  const [metricFile, setMetricFile] = useState(null);
  const [metricComment, setMetricComment] = useState("");
  console.log("currentDetailedMetric", currentDetailedMetric);
  useEffect(() => {
    if (currentDetailedMetric.metrics_data.hasOwnProperty("comment")) {
      setMetricComment(currentDetailedMetric.metrics_data.comment);
    }

    if (
      currentDetailedMetric.metrics_data.hasOwnProperty("file") &&
      currentDetailedMetric.metrics_data.file !== ""
    ) {
      setMetricFile(currentDetailedMetric.metrics_data.file);
    }
  }, [currentDetailedMetric]);

  useEffect(() => {
    return () => {
      setMetricFile(null);
    };
  }, [fieldData.currentMetric]);

  useEffect(() => {
    if (surveyResultData) {
      setTypeTwoData(surveyResultData);
    } else {
      const data =
        surveyData.columns !== null &&
        rows.map((r, idx) => {
          return {
            row: `row ${rows.length}`,
            columns: surveyData.columns.map((c) => {
              return {
                column: c,
                data: "",
              };
            }),
          };
        });
      setTypeTwoData(data);
    }
  }, [surveyResultData, fieldData.currentMetric, surveyData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {typeTwoData.length > 0 && (
        <Grid container justifyContent="space-evenly" className={classes.grid}>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      {typeTwoData.length > 0 &&
                        typeTwoData[0].columns &&
                        typeTwoData[0].columns.map((col, index) => (
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.TableCell}
                          >
                            {col.column}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeTwoData.length > 0 &&
                      typeTwoData.map((row, rowIndex) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          <TableCell align="center">
                            <Typography>{row.row}</Typography>
                          </TableCell>
                          {row.columns &&
                            row.columns.map((column, colIndex) => (
                              <TableCell key={colIndex} align="center">
                                <CustomTextfield
                                  name={`Text_type2${colIndex}`}
                                  size="small"
                                  variant="outlined"
                                  className={classes.CustomTextfield}
                                  value={
                                    typeTwoData[rowIndex].columns[colIndex].data
                                  }
                                  disabled
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
      <Box>
        <Box my={2}>
          <Typography className={classes.blueText}>Comments</Typography>
          <CustomTextfield
            name={`Comment_type2${metricId}`}
            label="Additional Comment"
            fullWidth
            multiline
            rows={3}
            size="small"
            variant="outlined"
            value={metricComment}
            disabled
          />
        </Box>
        <Box my={2}>
          {metricFile === null ? (
            <>
              <input
                id={metricId}
                type="file"
                index={index}
                disabled
                className={classes.input}
              />
              <label htmlFor={metricId}>
                <Box display="flex" component="span" alignItems="center">
                  <AttachFileIcon color="disabled" />
                  <Box mx={2} className={classes.Box1} width="100%">
                    <Typography variant="caption" color="textSecondary">
                      Upload document
                    </Typography>
                  </Box>
                </Box>
              </label>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <AttachFileIcon color="disabled" />
                <Box mx={2} className={classes.Box2} width="100%">
                  <Typography variant="caption" color="primary">
                    {metricFile.name}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
