import React, { Children, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

// icons
import CloseIcon from "@material-ui/icons/CloseRounded";
import CustomButton from "../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSection,
  setSelectedSection,
} from "../../../../../actions/supplier/supplierAction";

const SaveDetailsDialog = ({
  open,
  handleClose,
  handleSave,
  title,
  contentText,
  leftButtonText,
  rightButtonText,
  sectionId,
  login,
  children,
}) => {
  const dispatch = useDispatch();
  const { sectionWiseData } = useSelector((state) => state?.supplier);
  const [action, setAction] = useState("");

  const handleLeave = () => {
    // setAction("leave");
    handleClose();
    // handleSave(action);
  };

  const handleSaveDetails = () => {
    // setAction("saveDetails");
    // dispatch(deleteSection(login?.token, sectionId));
    // dispatch(
    //   setSelectedSection(sectionWiseData[sectionWiseData.length - 2]?.title)
    // );
    handleSave();
    handleClose();
    // handleSave(action);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              color: "#15314E",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {/* Save Details */}
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#3374B9" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
            padding: "16px",
            marginBottom: "15px",
          }}
        >
          <Typography
            style={{
              fontWeight: "450",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {/* It seems you have entered important details. Before leaving, would
            you like to save your work? Saving ensures that your information is
            preserved for your next session. Select 'Save details' to secure
            your progress, or 'Leave' to exit without saving. */}
            {contentText}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={handleLeave}
          style={{
            textTransform: "capitalize",
            // color: "#C45446",
            // // width: "74px",
            // height: "36px",
            // padding: "8px 16px 8px 16px",
          }}
          variant="text"
          color="primary"
        >
          {/* Leave */}
          {leftButtonText}
        </CustomButton>
        <CustomButton
          onClick={handleSaveDetails}
          variant="contained"
          color="primary"
          style={{
            textTransform: "capitalize",
            width: "205px",
            height: "36px",
            padding: "8px 16px 8px 16px",
            borderRadius: "8px",
            color: "#FFF",
            backgroundColor: rightButtonText !== "create section" && "#C45446",
            "&:hover": {
              backgroundColor: "#C45446",
              boxShadow: "1px 1px 3px #c8c0c0a1",
            },
          }}
          // className="containedWarning"

          // color='warning'
        >
          {/* Save Details */}
          {rightButtonText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default SaveDetailsDialog;
