import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import RadioButton from '../radioButton/radioButton';
import RadarChart from './RadarChart';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ScrollToTop from '../scrollto/ScrollToTop';
import ScrollToBottm from '../scrollto/ScrollToBottom';
import matutityQuestions from '../../../constants/maturityMetricsConst/maturityQuestions';
import {
  Social,
  Human,
  Business,
  Environmental,
  Governance,
} from '../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

const checkBoxStyles = makeStyles((theme) => ({
  root: {
    '&.Mui-checked': {
      color: '#3374B9',
    },
  },
  qno: { marginRight: '10px' },
}));

function SurveyUp(props) {
  const classes = checkBoxStyles();
  const {login,asessment_id,updateMaturityMatrix,maturityMatrix,getMaturityMatrix}=props;
  // console.log('props survey up',props)
  const [questions, setQuestions] = useState(maturityMatrix.questions);

  const [collect, setCollect] = useState({});
  const [chartEsgData, setChartEsgData] = useState([]);

  const [chart2EsgData, setChart2EsgData] = useState([]);
  const [chart3EsgData, setChart3EsgData] = useState([]);
  const [displayChart, setDisplayChart] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [allData, setAllData] = useState({});
  
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   // console.log('survey up')
  //   // if()
  //   getMaturityMatrix()
  // }, []);

  const checkBoxChange = (e, i, j) => {
    const { name, id, checked } = e.target;
    if (checked) {
      if (collect[name]) {
        setCollect({ ...collect, [name]: collect[name] + 1 });
      } else setCollect({ ...collect, [name]: 1 });
    } else {
      if (collect[name]) {
        setCollect({ ...collect, [name]: collect[name] - 1 });
      } else alert('NEGATIVE');
    }
  };

  const addSurvey = (e, i) => {
    const { name, id, checked } = e.target;

    // if (questions[i].type === 'radio') {
      setCollect({ ...collect, [name]: id });
    // }
  };

  const charting = () => {
    let cat1 = { e: [], s: [], g: [], b: [], h: [] };
    let cat2 = {
      Experience: [],
      Materiality: [],
      stake: [],
      esg: [],
      innovation: [],
      data: [],
      supply: [],
      policy: [],
      incentives: [],
    };
    let radarObj = {
      bmi: [],
    };
    // console.log('collect', collect);

    const array = [
      'Business Model Resilience',
      'Materials Sourcing & Efficiency',
      'Physical Impacts of Climate Change',
      'Product Design & Lifecycle Management',
      'Supply Chain Management',
      'Air Quality',
      'Ecological Impacts',
      'Energy Management',
      'GHG Emissions',
      'Waste & Hazardous Materials Management',
      'Water & Wastewater Management',
      'Employee Engagement, Diversity & Inclusion',
      'Employee Health & Safety',
      'Labor Practices',
      'Business Ethics',
      'Competitive Behavior',
      'Critical Incident Risk Management',
      'Management of the Legal & Regulatory Environment',
      'Systemic Risk Management',
      'Access & Affordability',
      'Customer Privacy',
      'Customer Welfare',
      'Data Security',
      'Human Rights & Community Relations',
      'Product Quality & Safety',
      'Selling Practices & Product Labeling',
    ];
    const environmentObj = {};
    const socialObj = {};
    const governanceObj = {};
    const businessObj = {};
    const humanObj = {};

    let chart3data = [
      radarObj.bmi.reduce((total, next) => {
        return Number(total) + Number(next);
      }, 0),
    ];
    // console.log('collect,',collect)

    if (Object.keys(collect).length > 0) {
      let qNumb=0 ;
      for (let key in collect) {
        qNumb++
        if (questions[qNumb - 1].category1 === 'Environment') {
          cat1.e.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            environmentObj[questions[qNumb - 1].category2] = environmentObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: 'Environment',
                  ans:
                    Number(environmentObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(environmentObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: 'Environment',
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === 'Social Capital') {
          cat1.s.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            socialObj[questions[qNumb - 1].category2] = socialObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: 'Social Capital',
                  ans:
                    Number(socialObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(socialObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: 'Social Capital',
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === 'Leadership & Governance') {
          cat1.g.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            governanceObj[questions[qNumb - 1].category2] = governanceObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: 'Leadership & Governance',
                  ans:
                    Number(governanceObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(governanceObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: 'Leadership & Governance',
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === 'Business Model & Innovation') {
          cat1.b.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            businessObj[questions[qNumb - 1].category2] = businessObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: 'Business Model & Innovation',
                  ans:
                    Number(businessObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(businessObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: 'Business Model & Innovation',
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === 'Human Capital') {
          cat1.h.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            humanObj[questions[qNumb - 1].category2] = humanObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: 'Human Capital',
                  ans:
                    Number(humanObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(humanObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: 'Human Capital',
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }

        if (questions[qNumb - 1].category2 === 'Experience Level') {
          cat2.Experience.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Materiality') {
          cat2.Materiality.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Stakeholder Engagement') {
          cat2.stake.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'ESG Strategy') {
          cat2.esg.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Innovation and Growth') {
          cat2.innovation.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Data Management') {
          cat2.data.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Supplychain Compliance') {
          cat2.supply.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Policy and Risk Management') {
          cat2.policy.push(collect[key]);
        }
        if (questions[qNumb - 1].category2 === 'Incentives') {
          cat2.incentives.push(collect[key]);
        }
      }

      let chartData = [
        cat1.e.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 30,
        cat1.s.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 65,
        cat1.g.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 35,
        cat1.b.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 50,
        cat1.h.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 20,
      ];

      let chart2data = [
        cat2.Experience.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.Materiality.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 30,
        cat2.stake.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 25,
        cat2.esg.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.innovation.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.data.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 10,
        cat2.supply.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.policy.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.incentives.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 10,
      ];

      setChartEsgData(chartData);
      setChart2EsgData(chart2data);
      setAllData({
        ...allData,
        environmentObj: environmentObj,
        socialObj: socialObj,
        governanceObj: governanceObj,
        businessObj: businessObj,
        humanObj: humanObj,
      });

      // if (Object.keys(collect).length === questions.length) {
      //   updateMaturityMatrix(login.token,asessment_id,collect)
      //   setDisplayChart(true);
      //   setHelperText('');
      //   scrollDown();
      // } else if (Object.keys(collect).length < questions.length) {
      //   setHelperText('Please complete Answering all the Questions');
      // }
    } else {
      // setHelperText('Please complete answering all the questions');
    }
  };

  // Scroll
  // console.log('chartEsgData', chartEsgData);
  // console.log('chart2EsgData', chart2EsgData);
  // console.log('AllData', allData);
  //  dispatch(setAllSurvey(chartEsgData))
  //  dispatch(setChartSurvey(allData))
  let scrollRef = useRef(null);

  const scrollDown = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight - window.innerHeight - 3650,
        behavior: 'smooth',
      });
    }, 600);
  };

  return (
    <div>
      {/* <Card style={{ padding: '5px', margin: '5px' }}> */}
        {questions.map((que, i) => {
          return (
            <div key={i}>
              <br />
              <Grid container>
                <Grid item md={1}>
                  <Typography className={classes.qno}>Q{i + 1}.</Typography>
                </Grid>
                <Grid item md={11}>
                  <Typography >{que.name}</Typography>
                  <FormControl on component="fieldset">
                {/* <FormLabel>
                  {que.category1 === 'Environment' && <Environmental />}
                  {que.category1 === 'Business Model & Innovation' && (
                    <Business />
                  )}
                  {que.category1 === 'Human Capital' && <Human />}
                  {que.category1 === 'Leadership & Governance' && (
                    <Governance />
                  )}
                  {que.category1 === 'Social Capital' && <Social />}
                  <Chip
                    style={{ position: 'relative', bottom: '15px' }}
                    label={que.category2}
                    size="small"
                  />
                </FormLabel> */}
                <RadioGroup name={que._id + ''}>
                  {que.ans.map((ans, j) => {
                    return (
                      <div key={j}>
                        <FormControlLabel
                          value={j + ''}
                          control={
                            // que.type === 'check' ? (
                            //   <Checkbox
                            //     className={clsx(classes.root, classes.checked)}
                            //     onChange={(e) => {
                            //       checkBoxChange(e, i, j);
                            //     }}
                            //     name={que.id + ''}
                            //     id={ans[1] + ''}
                            //   />
                            // ) : (
                              <RadioButton id={ans[1] + ''} />
                            // )
                          }
                          label={ans[0]}
                          onChange={(e) => {
                            addSurvey(e, i);
                          }}
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
              </FormControl>
              <hr></hr>

                </Grid>
              </Grid>

             
            </div>
          );
        })}
        <span
          style={{ color: 'red', fontSize: 'large', paddingBottom: '25px' }}
        >
          {helperText}
        </span>
        <br /> <br />
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => {
            charting();
          }}
        >
          Submit
        </Button> */}
        <ScrollToTop />
        <ScrollToBottm />
      {/* </Card> */}
      <div>
        {displayChart && (
          <>
            <Paper>
              <RadarChart
                data1={chartEsgData}
                data2={chart2EsgData}
                allData={allData}
              />
            </Paper>
          </>
        )}
      </div>
    </div>
  );
}

export default SurveyUp;
