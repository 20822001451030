import React, { useEffect, useState } from "react";

import { CardContent } from "@material-ui/core";

// import { corporateSurveyQuestions } from "../../../../utils/corporateSurveyQuestion/corporateSurveyQuestions";

import SurveyQuestions from "../../surveyQuestions/surveyQuestions";
// import PeerAnalysis from "../../peerAnalysis/peerAnalysis";
import EsgCompantProfile from "../../esgCompanyProfile/esgCompantProfile";
// import PeerDocuments from "../../publicInfo/peerDocuments";
import ApiConnections from "../../publicInfo/apiConnections";
import Documents from "../../publicInfo/documents";
import StakeholderList from "../stakeholderList";
import DataBreakdown from "../DataBreakdown";
import SurveyResponses from "../../surveyResponses/surveyResponses";
import Contoversies from "../../controversiesTab/controversies";
import EsgRiskRating from "../../esgRiskRating/esgRiskRating";
import ShareDialog from "./ShareDialog";
import UserDialog from "./UserDialog";
import { brmRightStyles } from "./brmRightStyle";
import CompanyDetails from "./CompanyDetails";
import { useSelector } from "react-redux";

function BrmDataRightEvaluator(props) {
  const classes = brmRightStyles();
  const { brmReducer, login } = useSelector(state => state);


  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);

  React.useEffect(() => {
    // props.viewMember(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmReducer?.organizationDetails
    //     ? brmReducer?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
   
    // props.getCalculatedSurvey(
    //   login?.token ? login?.token : localStorage.getItem("user_token"),
    //   brmReducer?.organizationDetails[0]?._id
    //     ? brmReducer?.organizationDetails[0]?._id
    //     : localStorage.getItem("organization_id")
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (props.isEvaluator) {
    //   brmReducer?.brmMainTabNo === 2 &&
    //     brmReducer?.brmSubTabNo === 0 &&
    //     props.getScores(
    //       login.token,
    //       brmReducer?.organizationDetails[0]?.isin,
    //       brmReducer?.organizationDetails[0]?._id
    //     );
    //   brmReducer?.brmMainTabNo === 2 &&
    //     brmReducer?.brmSubTabNo === 3 &&
    //     props.getControversyData(
    //       login.token,
    //       brmReducer?.organizationDetails[0]?.isin
    //     );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brmReducer?.brmMainTabNo, brmReducer?.brmSubTabNo]);

 

  return (
    <>
      {shareDialogOpen && (
        <ShareDialog
          shareDialogOpen={shareDialogOpen}
          handleClosShareDialog={() => setShareDialogOpen(false)}
        />
      )}
      {addNewUserDialogOpen && (
        <UserDialog
          addNewUserDialogOpen={addNewUserDialogOpen}
          handleCloseUserDialog={() => setAddNewUserDialogOpen(false)}
          brmData={brmReducer}
          login={login}
        />
      )} 
      <CardContent className={classes.cardContent}>
        {/* company setup */}
        {brmReducer?.brmMainTabNo === 0 && brmReducer?.brmSubTabNo === 0 && (
          <CompanyDetails brmData={brmReducer} login={login} />
        )}
        {brmReducer?.brmMainTabNo === 0 && brmReducer?.brmSubTabNo === 1 && (
          <StakeholderList
            setAddNewUserDialogOpen={setAddNewUserDialogOpen}
            // handleEditUser={handleEditUser}
            brmData={brmReducer}
            login={login}
          />
        )}

        {/* data setup */}

       {brmReducer?.brmMainTabNo === 1 && brmReducer?.brmSubTabNo === 0 && (
          <ApiConnections {...props} />
        )}
       {brmReducer?.brmMainTabNo === 1 && brmReducer?.brmSubTabNo === 1 && (
          <Documents riskEvaluator={props?.riskEvaluator} brmData={brmReducer} {...props} />
        )}
           {/* {brmReducer?.brmMainTabNo === 1 && brmReducer?.brmSubTabNo === 2 && (
          <PeerDocuments riskEvaluator={props?.riskEvaluator} {...props} />
        )} */}
       {brmReducer?.brmMainTabNo === 1 && brmReducer?.brmSubTabNo === 2 && (
          <SurveyQuestions
            login={login}
            brmReducer={brmReducer}
            setShareDialogOpen={setShareDialogOpen}
            // corporateSurveyQuestions={corporateSurveyQuestions}
          />
        )}

        {/* ESG DIAGNOSIS TABS */}

        {brmReducer?.brmMainTabNo === 2 && brmReducer?.brmSubTabNo === 0 && (
          <EsgCompantProfile {...props} />
        )}
       {brmReducer?.brmMainTabNo === 2 && brmReducer?.brmSubTabNo === 1 && (
          <DataBreakdown {...props} />
        )}
       {/*   {brmReducer?.brmMainTabNo === 2 && brmReducer?.brmSubTabNo === 2 && (
          <PeerAnalysis {...props} />
        )}*/}
        {brmReducer?.brmMainTabNo === 2 && brmReducer?.brmSubTabNo === 2 && (
          <Contoversies {...props} />
        )}
        {brmReducer?.brmMainTabNo === 2 && brmReducer?.brmSubTabNo === 3 && (
          <SurveyResponses {...props} />
        )}
         {brmReducer?.brmMainTabNo === 3 && brmReducer?.brmSubTabNo === 0 && (
          <EsgRiskRating {...props} />
        )}  
      </CardContent>
    </>
  );
}

export default BrmDataRightEvaluator;
