import {
  Box,
  //   Button,
  makeStyles,
  AppBar,
  MuiThemeProvider,
  Tab,
  Tabs,
  createTheme,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";

import InputAdornment from "@material-ui/core/InputAdornment";

import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { setCreateDrawerState } from "../../../actions/brm/BrmActions";
import SearchTextField from "../../UI/textfield/searchTextField";

// styling
const useStyles = makeStyles(() => ({
  AppBar: {
    boxShadow: "none",
    // marginBottom: "20px",
    // marginTop: "auto",
    // border: "solid",
    width: "max-content",
    height: "100%",
    marginRight: "auto",
    // flex:1
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  activeTabLabel: {
    color: "#154328",
  },
  createButton: {
    textTransform: "none",
    float: "right",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  searchCont: {
    marginRight: 10,
  },
  headerIcon: { color: "#fff", paddingLeft: "5px" },

  // Save Button
  saveButton: {
    position: "absolute",
    bottom: "20px",
    right: "50px",
    textTransform: "none",
    width: "200px",
    height: "36px",
    color: "#fff",
    borderRadius: "8px",
    padding: "8px 16px 8px 16px",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
}));

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3374B9",
    },
    secondary: {
      main: "#47BB76",
    },
  },
});

// ----------------- Main Render -----------------
const SupplierHeader = ({
  activeTab,
  handleActiveTab,
  source,
  setSearchKeyword,
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Box
      style={{
        // border: "solid red",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "50px",
        flexDirection: "row",
        gap: 20,
        flex: 1,
        //   border: "solid",
        // height:"60px"
      }}
    >
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={activeTab}
            onChange={(e, newVal) => handleActiveTab(newVal)}
            style={{
              // marginLeft: "auto",
              // marginRight: "auto",
              // width: "100%",
              // border: "solid yellow",
              alignSelf: "start",
            }}
          >
            <Tab
              disableRipple
              label={
                <span
                  className={`${classes.tabLabel} ${
                    activeTab === 0 ? classes.activeTabLabel : ""
                  }`}
                >
                  Suppliers Section
                </span>
              }
            />
            <Tab
              disableRipple
              label={
                <span
                  className={`${classes.tabLabel} ${
                    activeTab === 1 ? classes.activeTabLabel : ""
                  }`}
                >
                  Survey section
                </span>
              }
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <SearchTextField
        style={{ marginLeft: "auto" }}
        searchClick={(keyword) => setSearchKeyword(keyword)}
      />
      {/* <CustomTextfield
          name="Search"
          variant="outlined"
          size="small"
          style={{marginLeft:"auto"}}
          type="text"
          label="Search"
          onChange={(e) => {
            // setSearchCompanyText(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          //   value={searchCompanyText}
        /> */}
      <CustomButton
        onClick={() => {
          // setIsAddCompanyDrawer(true);
          dispatch(setCreateDrawerState(true));
        }}
        className={classes.createButton}
        size="medium"
      >
        <AddIcon
          className={classes.headerIcon}
          color="primary"
          variant="outlined"
        />
        {`Create ${source}`}
      </CustomButton>
    </Box>
  );
};

export default SupplierHeader;
